import React, { useEffect, useState } from 'react';
import '../../Document/InvoiceForm/InvoiceForm.scss';
import Sale from '../../../../api/controllers/Financial/Sale/Sale';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import DocumentEdit from '../../Document/DocumentEdit/DocumentEdit';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

const SalesInvoiceEdit = ({ bulk = false }) => {
  const { id } = useParams();
  const [error, setError] = useState();
  const [lineItems, setLineItems] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const handleErrors = json => {
    loading && setLoading(false);
    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };

  document.title = `Editing ${data?.documentReference || id}`;

  const documentId = id;

  useEffect(() => {
    Sale.show(documentId).then(
      json => {
        setData(json);
        const parent = json.parent
          ? {
              parentId: json.parent?.id,
              parentType: json.parent?.modelName,
            }
          : {};
        Sale.lineItems({
          entityId: json.entity.id,
          documentId: documentId,
          ...parent,
        }).then(
          json => {
            setLineItems(json.items);
            setLoading(false);
          },
          json => handleErrors(json),
        );
      },
      json => handleErrors(json),
    );
  }, []);

  return (
    <DocumentEdit
      formData={data}
      lineItems={lineItems}
      error={error}
      setError={setError}
      title={bulk ? 'Bulk Sales Invoice' : 'Sales Invoice'}
      index={bulk ? '/financials/bulk_sales_invoices/' : '/financials/sales_invoices/'}
      loading={loading}
    />
  );
};

SalesInvoiceEdit.propTypes = { bulk: PropTypes.bool };

export default SalesInvoiceEdit;
