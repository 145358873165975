import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './UserShow.scss';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import FieldList from '../../../components/FieldList/FieldList';
import FormSection from '../../../components/FormSection/FormSection';
import Ribbon from '../../../components/Ribbon/Ribbon';
import ShowField from '../../../components/ShowField/ShowField';
import TabGroup from '../../../components/TabGroup/TabGroup';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faLock, faLockOpen, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import useApi from '../../../common/hooks/useApi/useApi';
import UserApi from '../../../api/controllers/User/User';
import FormField from '../../../components/FormField/FormField';
import Message from '../../../common/layout/Message/Message';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import NoPage from '../../NoPage/NoPage';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import { useNavigate, useParams } from 'react-router';

// This page displays an individual user's details, and provides a few buttons.
const UserShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, refresh, dataLoading } = useApi({
    call: () => UserApi.show(id),
    handleError: () => setNotFound(true),
  });
  const [error, setError] = useState();
  const [notFound, setNotFound] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  document.title = `${data?.firstName} ${data?.lastName}`;

  const handleLock = () => {
    setLoading('warning');
    UserApi.lock(data.id).then(
      () => {
        setLoading();
        refresh();
      },
      json => {
        setLoading();
        setError(formatError(json));
      },
    );
  };

  const handleUnlock = () => {
    setLoading('warning');
    UserApi.unlock(data.id).then(
      () => {
        setLoading();
        refresh();
      },
      json => {
        setLoading();
        setError(formatError(json));
      },
    );
  };

  const handleDelete = () => {
    setLoading('delete');
    UserApi.destroy(data.id).then(
      () => {
        setLoading();
        navigate('/users');
      },
      json => {
        setLoading();
        setError(formatError(json));
      },
    );
  };
  if (notFound) return <NoPage />;
  if (dataLoading) return <LoadingSpinner />;
  if (!data) return null;
  const userIdentifier = data?.firstName ? `${data?.firstName} ${data?.lastName}` : data?.id;
  return (
    <div className="user-show">
      <Breadcrumbs>
        <Link to={'/users'}>Users</Link>
        <Link to={`/users/${data.id}`}>{userIdentifier}</Link>
      </Breadcrumbs>

      <Ribbon>
        <IconButton
          text={'Edit User'}
          icon={faPen}
          onClick={() => navigate(`/users/${data.id}/edit`)}
          className={'edit'}
        />
        <IconButton
          text={data.lockedAt ? 'Unlock User' : 'Lock User'}
          icon={data.lockedAt ? faLockOpen : faLock}
          onClick={data.lockedAt ? handleUnlock : handleLock}
          className={'warning'}
          loading={loading === 'warning'}
        />
        <IconButton
          text={'Delete User'}
          icon={faTrash}
          onClick={() => setOpen(prev => !prev)}
          className={'destructive'}
        />
      </Ribbon>
      <ConfirmationModal
        question={'Are you sure you want to delete the user?'}
        subText={data.email}
        confirmText={'Delete'}
        isOpen={open}
        handleConfirm={handleDelete}
        handleCancel={() => setOpen(false)}
        loading={loading === 'delete'}
      />
      <div className="page-content">
        <Message text={error?.errors} type={'error'} visible={!!error} onClick={() => setError()} />
        <h1
          className="user-name"
          title={`${data.firstName} ${data.lastName} ${data.lockedAt ? '(Locked)' : ''}`}
        >
          {data.firstName} {data.lastName} {data.lockedAt ? '(Locked)' : ''}
        </h1>
        <TabGroup labels={['General']}>
          <div className="tab-content">
            <FormSection
              title="General"
              optionalElement={<ShowField label="External Access" value={data.externalAccess} />}
            >
              <FieldList>
                <FormField label="Email">
                  <input type="email" value={data.email} autoFocus readOnly />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label="First Name">
                  <input type="text" value={data.firstName} autoFocus readOnly />
                </FormField>
                <FormField label="Last Name">
                  <input type="text" value={data.lastName} autoFocus readOnly />
                </FormField>
              </FieldList>
            </FormSection>

            <FormSection title="Roles">
              <FieldList>
                <ShowField label="Administrator" value={data.administrator} />
                <ShowField label="Sales" value={data.sales} />
                <ShowField label="Operations" value={data.operations} />
                <ShowField label="Accounts" value={data.accounts} />
              </FieldList>
            </FormSection>
          </div>
        </TabGroup>
      </div>
    </div>
  );
};

UserShow.propTypes = {};

export default UserShow;
