import React from 'react';
import PropTypes from 'prop-types';
import FieldList from '../../FieldList/FieldList';
import FormField from '../../FormField/FormField';
import IterativeInput from '../../IterativeInput/IterativeInput';
import './ContactDetailsForm.scss';
import AttributeHelper from '../../../utils/AttributeHelper/AttributeHelper';
import { contactMethodLabels } from '../../../strings/enums';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const ContactDetailsForm = ({ form, onChange, contactKinds }) => {
  const handleKindChange = (idx, val) =>
    AttributeHelper.handleAttrChange(idx, val, 'contactMethodsAttributes', form, onChange);

  const handlePlus = e => {
    e.preventDefault();
    handleKindChange(form.contactMethodsAttributes.length, { kind: null, text: '' });
  };

  const handleMinus = (e, idx) => {
    e.preventDefault();
    const contact = form.contactMethodsAttributes[idx];
    const canDestroy = form.modelName && contact.createdAt;
    if (canDestroy) return handleKindChange(idx, { ...contact, _destroy: !contact._destroy });
    return handleKindChange(idx, null);
  };

  if (!contactKinds) return null;

  const onSelectCk = (idx, ck) => handleKindChange(idx, { kind: ck, text: '' });

  return (
    <div className="contact-details-form">
      <FieldList>
        <FormField label={'First Name'} className="short">
          <input value={form.firstName} onChange={e => onChange('firstName', e.target.value)} />
        </FormField>
        <FormField label={'Last Name'} className="short">
          <input value={form.lastName} onChange={e => onChange('lastName', e.target.value)} />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label={'Job Title'} className="short">
          <input value={form.jobTitle} onChange={e => onChange('jobTitle', e.target.value)} />
        </FormField>
        <FormField label={'Job Role'} className="short">
          <input value={form.jobRole} onChange={e => onChange('jobRole', e.target.value)} />
        </FormField>
      </FieldList>
      <div className="footer-button">
        <IconButton
          text="Add Contact Method"
          className={'success'}
          icon={faPlus}
          onClick={e => handlePlus(e)}
        />
      </div>
      {form.contactMethodsAttributes.map(
        (item, idx) =>
          !item._destroy && (
            <IterativeInput
              key={idx}
              idx={idx}
              item={item}
              fieldLabel={'Contact Method'}
              dropdownVal={contactMethodLabels[item.kind]}
              onSelect={onSelectCk}
              inputVal={item.text}
              onInputChange={e => handleKindChange(idx, { text: e.target.value })}
              handleMinus={e => handleMinus(e, idx)}
            />
          ),
      )}
    </div>
  );
};

ContactDetailsForm.propTypes = {
  form: PropTypes.object,
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  contactKinds: PropTypes.array,
};

export default ContactDetailsForm;
