import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import TableHelper from '../../../../utils/TableHelper/TableHelper';
import useApi from '../../useApi/useApi';

const useAjaxTable = ({
  call,
  name = '',
  addParams = {},
  dependencies = [],
  limit = 15,
  defaultSort = {},
}) => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState(defaultSort);

  const params = {
    filter: searchText,
    limit,
    offset: limit * (currentPage - 1),
    ...sort,
    ...addParams,
  };

  const { data, refresh, dataLoading } = useApi({
    call,
    params,
    dependencies: [searchText, currentPage, sort, ...dependencies],
  });

  const onSearch = debounce(e => {
    setSearchText(e.target.value);
  }, 200);

  const pageNum = data && Math.ceil(data.total / limit);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchText]);

  const lowerBound = (currentPage - 1) * limit + 1;
  const upperBound = currentPage * limit + (data?.items.length - limit);

  const handleSort = header => TableHelper.onSort(header, sort, setSort);

  const pageElements = () => TableHelper.genPageNumbers(currentPage, setCurrentPage, pageNum);
  const pageText = TableHelper.paginationText(upperBound, lowerBound, data?.total);

  return {
    [`data${name}`]: data,
    [`loading${name}`]: dataLoading,
    [`sort${name}`]: sort,
    [`onSort${name}`]: handleSort,
    [`onSearch${name}`]: onSearch,
    [`pageElements${name}`]: pageElements,
    [`pageText${name}`]: pageText,
    [`refresh${name}`]: refresh,
    [`loading${name}`]: dataLoading,
  };
};

export default useAjaxTable;
