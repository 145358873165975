import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserApi from '../../../api/controllers/User/User';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import TabGroup from '../../../components/TabGroup/TabGroup';
import FormField from '../../../components/FormField/FormField';
import FormSection from '../../../components/FormSection/FormSection';
import FieldList from '../../../components/FieldList/FieldList';
import useForm from '../../../common/hooks/useForm/useForm';
import './UserForm.scss';
import Checkbox from '../../../components/Checkbox/Checkbox';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Message from '../../../common/layout/Message/Message';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import { useNavigate, useParams } from 'react-router';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';

// This is the "edit" page for a specific incoterm.
const UserForm = ({ onUserChange, user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { form, handleForm, alter } = useForm(undefined);

  useEffect(() => {
    if (!id) return initCreate();

    UserApi.show(id).then(
      json => {
        setData(json);
        initUpdate(json);
      },
      json => handleErrors(json),
    );
  }, []);

  const initCreate = () => {
    document.title = 'New User';

    handleForm({
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      administrator: false,
      sales: false,
      operations: false,
      accounts: false,
      externalAccess: false,
      loading: false,
      errors: [],
    });
  };

  const handleErrors = json => {
    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setLoading(false);
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };

  const initUpdate = json => {
    document.title = `Editing ${json.firstName} ${json.lastName}`;

    handleForm({
      id: json.id,
      firstName: json.firstName || '',
      lastName: json.lastName || '',
      email: json.email || '',
      password: '',
      administrator: json.administrator || false,
      sales: json.sales || false,
      operations: json.operations || false,
      accounts: json.accounts || false,
      externalAccess: json.externalAccess || false,
      loading: false,
      errors: [],
    });
  };

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    setLoading(true);
    const params = { ...form };
    if (!form.password) delete params.password;
    if (id) return UserApi.update(id, params).then(handleSuccess, handleErrors);
    UserApi.create(form).then(handleSuccess, handleErrors);
  };

  const handleSuccess = json => {
    setLoading(false);
    if (id === String(user?.id)) onUserChange(json);
    navigate(`/users/${json.id}`);
  };

  if (!form) return <LoadingSpinner />;

  const cancelUrl = id ? `/users/${id}` : '/users';
  const breadcrumbs = id ? (
    <Breadcrumbs>
      <Link to={'/users'}>Users</Link>
      <Link to={`/users/${id}`}>{data ? `${data.firstName} ${data.lastName}` : id}</Link>
      <Link to={`/users/${id}/edit`}>Edit</Link>
    </Breadcrumbs>
  ) : (
    <Breadcrumbs>
      <Link to={'/users'}>Users</Link>
      <Link to={'/users/new'}>New</Link>
    </Breadcrumbs>
  );

  const externalAccessCheckbox = (
    <Checkbox
      label={'Allow External Access'}
      checked={form.externalAccess}
      onClick={() => alter('externalAccess', !form.externalAccess)}
    />
  );

  return (
    <div className="user-form">
      {breadcrumbs}

      <div className="page-content">
        <Message type={'error'} text={error?.text} onClose={() => setError()} visible={!!error} />
        <h1>{id ? `Editing ${data?.firstName} ${data?.lastName}` : 'New User'}</h1>

        <TabGroup labels={['General']}>
          <div className="tab-content">
            <FormSection title="General" optionalElement={externalAccessCheckbox}>
              <FieldList>
                <FormField label="Email">
                  <input
                    className={'email-input'}
                    type="email"
                    value={form.email}
                    onChange={e => alter('email', e.target.value)}
                    autoFocus={true}
                    data-testid="email-input"
                  />
                </FormField>
                <FormField label="Password">
                  <input
                    type="password"
                    value={form.password}
                    onChange={e => alter('password', e.target.value)}
                    data-testid="password-input"
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label="First Name">
                  <input
                    value={form.firstName}
                    onChange={e => alter('firstName', e.target.value)}
                    data-testid="fn-input"
                  />
                </FormField>
                <FormField label="Last Name">
                  <input
                    value={form.lastName}
                    onChange={e => alter('lastName', e.target.value)}
                    data-testid="ln-input"
                  />
                </FormField>
              </FieldList>
            </FormSection>

            <FormSection title="Roles">
              <FieldList>
                <Checkbox
                  label={'Administrator'}
                  checked={form.administrator}
                  onClick={() => alter('administrator', !form.administrator)}
                />
                <Checkbox
                  label={'Sales'}
                  checked={form.sales}
                  onClick={() => alter('sales', !form.sales)}
                />
                <Checkbox
                  label={'Operations'}
                  checked={form.operations}
                  onClick={() => alter('operations', !form.operations)}
                />
                <Checkbox
                  label={'Accounts'}
                  checked={form.accounts}
                  onClick={() => alter('accounts', !form.accounts)}
                />
              </FieldList>
            </FormSection>
          </div>
        </TabGroup>
        <div className="button-group">
          <IconButton
            text={'Save'}
            icon={faCheck}
            onClick={e => handleSubmit(e)}
            loading={loading}
          />
          <IconButton
            text={'Cancel'}
            icon={faTimes}
            onClick={() => navigate(cancelUrl)}
            className="edit"
          />
        </div>
      </div>
    </div>
  );
};

UserForm.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  onUserChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default UserForm;
