import React from 'react';
import PropTypes from 'prop-types';
import './Ribbon.scss';

// Contains a list of navigation links.
const Ribbon = ({ children }) => {
  return <div className="ribbon">{children}</div>;
};

Ribbon.propTypes = {
  children: PropTypes.node,
};

export default Ribbon;
