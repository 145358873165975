import apiCall from '../../apiCall/apiCall';
import buildGetQuery from '../../helpers/buildGetQuery/buildGetQuery';

// Handles all user requests.
class User {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'users', data);
  }

  static show(id) {
    return apiCall('GET', `users/${id}`);
  }

  static create(params) {
    const data = { user: params };
    return apiCall('POST', 'users', data);
  }

  static update(id, params) {
    const data = { user: params };
    return apiCall('PATCH', `users/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `users/${id}`);
  }

  static lock(id) {
    return apiCall('POST', `users/${id}/lock`);
  }

  static unlock(id) {
    return apiCall('POST', `users/${id}/unlock`);
  }

  static updateCurrent(params) {
    const data = { user: params };
    return apiCall('PATCH', `users/current`, data);
  }
}

export default User;
