import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../../../components/FormSection/FormSection';
import CollapsibleFormSection from '../../../../../../../../components/CollapsibleFormSection/CollapsibleFormSection';
import AddressIndicator from '../../../../../../../../common/AddressIndicator/AddressIndicator';
import IconButton from '../../../../../../../../common/button/IconButton/IconButton';
import {
  faTrash,
  faPlus,
  faPen,
  faMapMarkedAlt,
  faFileInvoiceDollar,
  faArchive,
} from '@fortawesome/free-solid-svg-icons';
import OpeningTimesLine from '../OpeningTimesLine/OpeningTimesLine';
import DateHelper from '../../../../../../../../utils/DateHelper/DateHelper';
import useArray from '../../../../../../../../common/hooks/useArray/useArray';
import { formatError } from '../../../../../../../../utils/Formatters/Errors/formatError';
import AddressHelper from '../../../../../../../../utils/AddressHelper/AddressHelper';
import Address from '../../../../../../../../api/controllers/Company/Address/Address';
import LoadingSpinner from '../../../../../../../../common/LoadingSpinner/LoadingSpinner';

const AddressSection = ({
  data,
  entity,
  createAddress,
  editAddress,
  setAddress,
  setConfirmOpen,
  openOpeningTime,
  refreshData,
  setError,
  loading,
}) => {
  const { addressSections, toggleAddressSections } = useArray({ name: 'addressSections' });

  const handleAddressArchive = address =>
    Address.archive(address.id).then(
      () => refreshData(),
      json => setError(formatError(json)),
    );

  const handleAddressUnArchive = address =>
    Address.unArchive(address.id).then(
      () => refreshData(),
      json => setError(formatError(json)),
    );

  const addressArchiveButton = address => {
    const handleClick = () =>
      address.archived ? handleAddressUnArchive(address) : handleAddressArchive(address);
    const text = address.archived ? 'Un-Archive' : 'Archive';
    return (
      <IconButton
        text={text}
        icon={faArchive}
        className={'edit small'}
        onClick={() => handleClick()}
      />
    );
  };

  const addAddress = <IconButton text={'Add Address'} icon={faPlus} onClick={createAddress} />;
  return (
    <FormSection title={'All Addresses'} optionalElement={addAddress}>
      {loading && <LoadingSpinner />}
      {data &&
        data.items.map((address, i) => {
          const addressLines = AddressHelper.extractAddressToArray(address);
          const linkedAddress =
            entity.mainAddress.id === address.id || entity.invoicingAddressId === address.id;
          const formattedAddress = AddressHelper.formatAddress(addressLines);
          return (
            <CollapsibleFormSection
              key={i}
              title={formattedAddress}
              onClick={() => toggleAddressSections(address.id)}
              open={addressSections.includes(address.id)}
            >
              <div className={'section-container'}>
                <div className={'body-section'}>
                  {linkedAddress && (
                    <AddressIndicator
                      mainAddress={entity.mainAddress.id === address.id && 'Main Address'}
                      invoiceAddress={entity.invoicingAddressId === address.id && 'Invoice Address'}
                    />
                  )}
                  {addressLines
                    .filter(add => add)
                    .map((line, idx) => (
                      <div key={idx} className={'text-line'}>
                        {line}
                      </div>
                    ))}
                </div>
                <div className={'button-section'}>
                  <div className={'button-line'}>
                    <IconButton
                      text={'Edit'}
                      icon={faPen}
                      className={'edit small'}
                      onClick={() => editAddress(i)}
                    />
                    {!linkedAddress && addressArchiveButton(address)}
                    <IconButton
                      text={'Delete'}
                      icon={faTrash}
                      className={'edit small'}
                      onClick={() =>
                        setConfirmOpen({
                          item: address,
                          type: 'address',
                          subText: address.postcode,
                        })
                      }
                    />
                  </div>
                  <div className={'button-line'}>
                    {entity.mainAddress.id !== address.id && (
                      <IconButton
                        text={'Set main address'}
                        icon={faMapMarkedAlt}
                        className={'edit small'}
                        onClick={() => setAddress(address.id, 'main')}
                      />
                    )}
                    {entity.invoicingAddressId !== address.id && (
                      <IconButton
                        text={'Set invoicing address'}
                        icon={faFileInvoiceDollar}
                        className={'edit small'}
                        onClick={() => setAddress(address.id, 'invoicing')}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={'section-container'}>
                <div className={'body-section'}>
                  <hr />
                  <h1>Opening Times</h1>
                  {address.openingTimes.length > 0 &&
                    address.openingTimes.map((time, idx) => (
                      <OpeningTimesLine
                        key={idx}
                        time={time}
                        onEdit={() => openOpeningTime(address.id, idx, time)}
                        onDelete={() =>
                          setConfirmOpen({
                            item: { addressId: address.id, ...time },
                            type: 'opening',
                            subText: DateHelper.openingTimeFormat(time),
                          })
                        }
                      />
                    ))}
                  <OpeningTimesLine
                    key={0}
                    time={null}
                    onNew={() => openOpeningTime(address.id, address.openingTimes.length)}
                  />
                </div>
              </div>
            </CollapsibleFormSection>
          );
        })}
    </FormSection>
  );
};

AddressSection.propTypes = {
  data: PropTypes.object,
  entity: PropTypes.object,
  createAddress: PropTypes.func,
  editAddress: PropTypes.func,
  setAddress: PropTypes.func,
  setConfirmOpen: PropTypes.func,
  openOpeningTime: PropTypes.func,
  refreshData: PropTypes.func,
  setError: PropTypes.func,
  loading: PropTypes.bool,
};

export default AddressSection;
