import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChargeCode from '../../../../api/controllers/Financial/ChargeCode/ChargeCode';
import useApi from '../../../../common/hooks/useApi/useApi';
import './ChargeShow.scss';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Message from '../../../../common/layout/Message/Message';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import FormSection from '../../../../components/FormSection/FormSection';
import FieldList from '../../../../components/FieldList/FieldList';
import FormField from '../../../../components/FormField/FormField';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import NoPage from '../../../NoPage/NoPage';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import CheckPermit from '../../../../common/CheckPermit/CheckPermit';

const ChargeShow = ({ permissions }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, dataLoading } = useApi({
    call: () => ChargeCode.show(id),
    handleError: () => setNotFound(true),
  });
  const [error, setError] = useState();
  const [isOpen, setIsOpen] = useState();
  const [notFound, setNotFound] = useState(false);

  document.title = `${data?.code}`;
  const handleDelete = () =>
    ChargeCode.destroy(id).then(
      () => {
        navigate('/financials/charge_codes', {
          state: { detail: 'Charge Code Deleted' },
        });
        setIsOpen();
      },
      json => {
        setError(formatError(json));
        setIsOpen();
      },
    );

  if (notFound) return <NoPage />;
  if (dataLoading) return <LoadingSpinner />;
  if (!data) return null;

  return (
    <div className={'charge-show'}>
      <ConfirmationModal
        question={'Are you sure you want to delete this code?'}
        subText={data?.code}
        confirmText={'Delete'}
        isOpen={!!isOpen}
        handleConfirm={handleDelete}
        handleCancel={() => setIsOpen()}
      />
      <Breadcrumbs>
        <Link to={'/financials/charge_codes'}>Charge Codes</Link>
        <Link to={`/financials/charge_codes/${id}`}>{data?.code || id}</Link>
      </Breadcrumbs>

      <Ribbon>
        <IconButton
          text={'Edit Charge Code'}
          icon={faPen}
          onClick={() => navigate(`/financials/charge_codes/${id}/edit`)}
          className={'edit'}
        />
        <CheckPermit type={'financialsChargeCodes'} action={'destroy'} permissions={permissions}>
          <IconButton
            text={'Delete Charge Code'}
            icon={faTrash}
            onClick={() => setIsOpen(true)}
            className={'destructive'}
          />
        </CheckPermit>
      </Ribbon>
      <div className={'page-content'}>
        <Message text={error?.errors} type={'error'} visible={!!error} onClick={() => setError()} />
        <h1>{`${data?.code || id}`}</h1>

        <TabGroup labels={['General']}>
          <div className={'tab-content'}>
            <FormSection title={'Details'}>
              <FieldList>
                <FormField label={'Code'} className={'short'}>
                  <input value={data.code} readOnly />
                </FormField>
                <FormField label={'Description'} className={'short'}>
                  <input value={data.description} readOnly />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label={'Sales Nominal'} className={'short'}>
                  <input value={data.salesNominal} readOnly />
                </FormField>
                <FormField label={'Purchase Nominal'} className={'short'}>
                  <input value={data.purchaseNominal} readOnly />
                </FormField>
              </FieldList>
            </FormSection>
          </div>
        </TabGroup>
      </div>
    </div>
  );
};

ChargeShow.propTypes = {
  permissions: PropTypes.object,
};

export default ChargeShow;
