import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faLock, faTruck, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import AddressHelper from '../../../../../../../utils/AddressHelper/AddressHelper';
import './VehicleRunShowRoutesEntry.scss';
import { Link } from 'react-router-dom';
import SystemSetting from '../../../../../../../api/controllers/SystemSetting/SystemSetting';

const getJobRefs = jobs => {
  const len = jobs.length;
  if (jobs.length === 0) return '-';
  return jobs.map((job, idx) => {
    let end = '';
    if (idx !== len - 1 && idx !== len - 2) end = ', ';
    if (idx === len - 2) end = ' and ';

    return (
      <Fragment key={idx}>
        <Link to={`/jobs/bookings/${job.id}`} target={'_blank'}>
          {job.jobReference}
        </Link>
        {end}
      </Fragment>
    );
  });
};

const VehicleRunShowRoutesEntry = ({ waypoint, entity }) => {
  const [fsId, setFsId] = useState();

  const { permanent, address, estimatedTime, actualTime, deliveries, collections, instructions } =
    waypoint;

  useEffect(() => {
    SystemSetting.show().then(res => setFsId(res.freightsoftEntity?.mainAddress?.id));
  }, []);

  const getIcon = () => {
    if (collections.length === 0 && deliveries.length === 0) return faFile;
    if (address.id === fsId) return faWarehouse;
    return faTruck;
  };

  return (
    <div className="vrsr-entry">
      <div className="vrsre-icon">
        <FontAwesomeIcon icon={getIcon()} data-testid={'entry-icon'} />
      </div>
      {permanent && (
        <div className="vrsre-lock">
          <FontAwesomeIcon icon={faLock} data-testid={'lock-icon'} />
        </div>
      )}
      <div className="vrsre-content">
        <div className="vrsre-col vrsre-address">
          {entity.internalName}
          {AddressHelper.extractAddressToArray(address).map((l, i) => l && <div key={i}>{l}</div>)}
        </div>
        <div className="vrsre-col vrsre-times">
          <div className="vrsre-row">
            <div className="vrsre-header">Expected Arrival Time</div>
            {estimatedTime || '--:--'}
          </div>
          <div className="vrsre-row">
            <div className="vrsre-header">Actual Arrival Time</div>
            {actualTime || '--:--'}
          </div>
        </div>
        <div className="vrsre-col vrsre-changes">
          <div className="vrsre-row">
            <div className="vrsre-header">Offload</div>
            <div>{getJobRefs(deliveries)}</div>
          </div>
          <div className="vrsre-row">
            <div className="vrsre-header">Loading</div>
            <div>{getJobRefs(collections)}</div>
          </div>
        </div>
        <div className="vrsre-col vrsre-instructions">
          <div className="vrsre-header">Instructions</div>
          {instructions ? instructions : '-'}
        </div>
      </div>
    </div>
  );
};

VehicleRunShowRoutesEntry.propTypes = {
  waypoint: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
};

export default VehicleRunShowRoutesEntry;
