import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EmailsTab from '../../../Company/Entity/EntityShow/subComponents/EmailsTab/EmailsTab';
import AttachmentsTab from '../../../../components/AttachmentsTab/AttachmentsTab';
import DocumentShowGeneral from '../../../../components/DocumentShowGeneral/DocumentShowGeneral';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import Message from '../../../../common/layout/Message/Message';
import EmailComposeModal from '../../../../components/EmailComposeModal/EmailComposeModal';
import ErrorStatusMessage from '../../../../common/ErrorStatusMessage/ErrorStatusMessage';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import { faCheck, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../common/button/IconButton/IconButton';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import NoPage from '../../../NoPage/NoPage';
import Pdf from '../../../../api/controllers/File/Pdf/Pdf';
import SalesCredit from '../../../../api/controllers/Financial/SalesCredit/SalesCredit';
import useApi from '../../../../common/hooks/useApi/useApi';
import FinalisedOptions from '../../../../components/FinalisedOptions/FinalisedOptions';
import UnFinalisedOptions from '../../../../components/UnFinalisedOptions/UnFinalisedOptions';
import { handleDeleteDocument } from '../../../../utils/documentUtils/documentUtils';
import '../../Document/DocumentShow/DocumentShow.scss';
import NotesTab from '../../../Company/Entity/EntityShow/subComponents/NotesTab/NotesTab';

const SalesCreditNoteShow = ({ permissions, user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, refresh, dataLoading } = useApi({
    call: () => SalesCredit.show(id),
    handleError: () => setNotFound(true),
  });
  const [error, setError] = useState();
  const [notFound, setNotFound] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailModal, setEmailModal] = useState();
  const [pdfAlert, setPdfAlert] = useState();
  const [buttonLoad, setButtonLoad] = useState(false);

  const filterVal = (value, index, self) => {
    return self.findIndex(v => v.parent?.id === value.parent?.id) === index;
  };

  const handleSendEmail = (e, kind) => {
    e.preventDefault();
    setButtonLoad(true);
    Pdf.generate({ kind, id: data.id }).then(
      pdfRes => {
        setEmailModal({ kind, defaultAttachments: [{ ...pdfRes, attachmentId: pdfRes.id }] });
        setButtonLoad(false);
      },
      res => {
        setPdfAlert(res);
        setButtonLoad(false);
      },
    );
  };

  if (notFound) return <NoPage />;
  if (dataLoading) return <LoadingSpinner />;
  if (!data) return null;

  return (
    <div className={'show-booking-page'}>
      <Breadcrumbs>
        <Link to={'/financials/sales_credit_notes'}>Sales Credit Notes</Link>
        <Link to={`/financials/sales_credit_notes/${id}`}>{data.documentReference}</Link>
      </Breadcrumbs>
      <Ribbon>
        <div className="split">
          {data.finalised && (
            <IconButton
              text={'Send Email'}
              onClick={e => handleSendEmail(e, 'sales_credit_note')}
              icon={faEnvelopeOpenText}
              disabled={data?.entity.customerAccount === null || buttonLoad}
            />
          )}
        </div>
        <div className="split">
          {data.apiUrn !== null && (
            <IconButton
              text={`Ref. ${data.apiUrn}`}
              icon={faCheck}
              className={'success-disabled'}
            />
          )}
          {!data.apiUrn && data.finalised && (
            <FinalisedOptions
              documentId={id}
              refresh={refresh}
              setError={setError}
              title={'Sales Credit Note'}
              permissions={permissions}
            />
          )}
          {!data.apiUrn && !data.finalised && (
            <UnFinalisedOptions
              documentId={id}
              data={data}
              refresh={refresh}
              setError={setError}
              setOpen={setOpen}
              type={'Credit Note'}
              title={'Sales Credit Note'}
              index={'/financials/sales_credit_notes'}
              open={open}
              permissions={permissions}
            />
          )}
        </div>
      </Ribbon>
      <ConfirmationModal
        question={`Are you sure you want to delete this credit note?`}
        subText={data.documentReference}
        isOpen={open}
        confirmText={'Delete'}
        handleCancel={() => setOpen(false)}
        handleConfirm={e =>
          handleDeleteDocument(
            e,
            data,
            navigate,
            '/financials/sales_credit_notes',
            setError,
            setOpen,
          )
        }
      />
      <ErrorStatusMessage error={pdfAlert} setError={setPdfAlert} />

      {emailModal && (
        <EmailComposeModal
          user={user}
          parent={data}
          recipientEntity={data.entity}
          templateType={emailModal.kind}
          handleClose={() => setEmailModal()}
          fileTabs={[
            { tabName: 'General Files' },
            { tabName: 'Document Files', parent: data },
          ].concat(
            data?.lineItems.filter(filterVal).map(item => ({
              tabName: `Job ${item.parent.jobReference}`,
              parent: item?.parent,
            })),
          )}
          defaultAttachments={[...emailModal.defaultAttachments]}
          setAlert={setPdfAlert}
        />
      )}

      <div className={'page-content'}>
        <h1>
          Sales Credit Note for {data.entity.internalName}
          <Message
            text={error}
            type={error?.type}
            onClick={() => setError()}
            visible={!!error}
            onClose={setError}
          />
        </h1>
        <TabGroup labels={['General', 'Attachments', 'Emails', 'Notes']}>
          <DocumentShowGeneral
            data={data}
            documentDetails={{
              title: 'Sales Credit Note',
              type: 'Credit Note',
              index: '/financials/sales_credit_notes',
            }}
          />
          <AttachmentsTab
            permissions={permissions}
            parentId={data.id}
            parentType={'Financials::Document'}
            requiredPermit={'financialsDocuments'}
            setError={setError}
            dataLoad={buttonLoad}
          />
          <EmailsTab parentId={data.id} parentType={data.modelName} />
          <NotesTab
            parentId={data.id}
            parentType={data.modelName}
            canDelete={user.administrator}
            permissions={permissions}
            requiredPermit={'financialsDocuments'}
          />
        </TabGroup>
      </div>
    </div>
  );
};
SalesCreditNoteShow.propTypes = {
  permissions: PropTypes.object,
  user: PropTypes.object,
};
export default SalesCreditNoteShow;
