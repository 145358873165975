import AttributeHelper from '../../../AttributeHelper/AttributeHelper';

const handleDimensionLine = json => {
  if (json.dli)
    return AttributeHelper.convertObjectToId(json.dimensionLineItemsAttributes, 'packageType');
  const databaseLineItems = AttributeHelper.filterDatabaseAttributes(
    json.dimensionLineItemsAttributes,
  );
  return AttributeHelper.markAllForDestruction(databaseLineItems);
};

const handleFinancialLine = json => {
  const entityLine = AttributeHelper.convertObjectToId(json.financialLineItemsAttributes, 'entity');
  const chargeLine = AttributeHelper.convertObjectToId(entityLine, 'chargeCode');
  return AttributeHelper.convertObjectToId(chargeLine, 'taxCode');
};

export const formatBooking = json => {
  const dimensionLineIds = handleDimensionLine(json);
  const financialLineIds = handleFinancialLine(json);
  return {
    status: json?.status || 'active',
    customerReference: json.customerReference || '',
    collectionReference: json.collectionReference || '',
    deliveryReference: json.deliveryReference || '',
    goodsReadyDate: json.goodsReadyDate || '',
    goodsReadyFromTime: json.goodsReadyFromTime || '',
    goodsReadyUntilTime: json.goodsReadyUntilTime || '',
    deliveryDeadlineDate: json.deliveryDeadlineDate || '',
    deliveryDeadlineFromTime: json.deliveryDeadlineFromTime || '',
    deliveryDeadlineUntilTime: json.deliveryDeadlineUntilTime || '',
    collectionDate: json.collectionDate || '',
    collectionFromTime: json.collectionFromTime || '',
    collectionUntilTime: json.collectionUntilTime || '',
    deliveryDate: json.deliveryDate || '',
    deliveryFromTime: json.deliveryFromTime || '',
    deliveryUntilTime: json.deliveryUntilTime || '',
    quantity: json.quantity || null,
    grossWeightKg: json.grossWeightKg || null,
    netWeightKg: json.netWeightKg || null,
    cubicMetres: json.cubicMetres || '',
    loadingMetres: json.loadingMetres || '',
    subcontracted: json.subcontracted || false,
    hazardousCargo: json.hazardousCargo || false,
    hazardDetails: json.hazardDetails || '',
    doNotStack: json.doNotStack || false,
    doNotTransship: json.doNotTransship || false,
    doNotPartLoad: json.doNotPartLoad || false,
    doNotBreakDown: json.doNotBreakDown || false,
    tailLiftOnCollection: json.tailLiftOnCollection || false,
    tailLiftOnDelivery: json.tailLiftOnDelivery || false,
    overheadLoad: json.overheadLoad || false,
    overheadUnload: json.overheadUnload || false,
    bookingDate: json.bookingDate || '',
    description: json.description || null,
    additionalInformation: json.additionalInformation || '',
    cancellationReason: json.cancellationReason || null,
    financialLineItemsAttributes: financialLineIds || [],
    customerId: json.customer?.id || null,
    collectionOnBehalfOfId: json.collectionOnBehalfOf?.id || null,
    deliveryOnBehalfOfId: json.deliveryOnBehalfOf?.id || null,
    collectionEntityId: json.collectionEntity?.id || null,
    deliveryEntityId: json.deliveryEntity?.id || null,
    subcontractorId: json.subcontractor?.id || null,
    ownerId: json.owner?.id || null,
    vehicleId: json.vehicle?.id || null,
    packageTypeId: json?.packageType?.id || null,
    collectionAddressId: json?.collectionAddress?.id || null,
    deliveryAddressId: json?.deliveryAddress?.id || null,
    dimensionLineItemsAttributes: dimensionLineIds || [],
    waste: json.waste || false,
    foodstuffs: json.foodstuffs || false,
    allowAutolining: json.allowAutolining,
    timedCollection: json.timedCollection || false,
    timedDelivery: json.timedDelivery || false,
  };
};
