import Action from '../Action/Action';

/* 
  handles the receiving of an update from the vehicle run planner
  takes a way to set the state, what the current value of state is 
  and the newly updated Item.
*/
class UpdateAction extends Action {
  constructor(setState, currentState, updatedItem) {
    super();
    this.setState = setState;
    this.currentState = currentState;
    this.updatedItem = updatedItem;
    this.oldItem = currentState.filter(item => item.id === updatedItem.id)[0]; //stores the previous value if we need to reset
  }
  execute() {
    //removes the old value, then replaces with the new one.
    const filteredItems = this.currentState.filter(item => item.id !== this.updatedItem.id);
    this.setState([...filteredItems, this.updatedItem].sort((a, b) => a.id - b.id));
  }

  undo() {
    //removes the added item, then puts the old on back in its place, (resetting it)
    const filteredItems = this.currentState.filter(item => item.id !== this.updatedItem.id);
    this.setState([...filteredItems, this.oldItem].sort((a, b) => a.id - b.id));
  }
}

export default UpdateAction;
