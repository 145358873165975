import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './OpeningTimesForm.scss';
import StaticCheckbox from '../../StaticCheckbox/StaticCheckbox';
import TimeInput from '../../../common/input/TimeInput/TimeInput';

const OpeningTimesForm = ({ idx, form, onChange }) => {
  const item = form.openingTimesAttributes[idx];
  return (
    <Fragment>
      <h1>Opening Hours</h1>
      <TimeInput opensAt={item.opensAt} closesAt={item.closesAt} onChange={onChange} />
      <div className={'otf-checkbox-line'}>
        <StaticCheckbox
          checked={item.monday}
          onClick={() => onChange('monday', !item.monday)}
          label={'M'}
          testId={'cb-monday'}
        />
        <StaticCheckbox
          checked={item.tuesday}
          onClick={() => onChange('tuesday', !item.tuesday)}
          label={'T'}
          testId={'cb-tuesday'}
        />
        <StaticCheckbox
          checked={item.wednesday}
          onClick={() => onChange('wednesday', !item.wednesday)}
          label={'W'}
          testId={'cb-wednesday'}
        />
        <StaticCheckbox
          checked={item.thursday}
          onClick={() => onChange('thursday', !item.thursday)}
          label={'T'}
          testId={'cb-thursday'}
        />
        <StaticCheckbox
          checked={item.friday}
          onClick={() => onChange('friday', !item.friday)}
          label={'F'}
          testId={'cb-friday'}
        />
        <StaticCheckbox
          checked={item.saturday}
          onClick={() => onChange('saturday', !item.saturday)}
          label={'S'}
          testId={'cb-saturday'}
        />
        <StaticCheckbox
          checked={item.sunday}
          onClick={() => onChange('sunday', !item.sunday)}
          label={'S'}
          testId={'cb-sunday'}
        />
      </div>
    </Fragment>
  );
};

OpeningTimesForm.propTypes = {
  idx: PropTypes.number,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OpeningTimesForm;
