import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal/Modal';
import VehicleRunForm from '../../forms/VehicleRunForm/VehicleRunForm';
import FormSection from '../../FormSection/FormSection';
import './NewVehicleRun.scss';
import Message from '../../../common/layout/Message/Message';
import { formatVehicleRun } from '../../../utils/Formatters/VehicleRun/formatVehicleRun';
import VehicleRun from '../../../api/controllers/Movement/VehicleRun/VehicleRun';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import useForm from '../../../common/hooks/useForm/useForm';
import useArray from '../../../common/hooks/useArray/useArray';

// Why? Created for the VehicleRunPlanner screen, allowing the creation of Vehicle Runs
const NewVehicleRun = ({
  handleClose,
  currentFilterDate,
  subwindow,
  parent,
  currentFormContent,
  showMileage = false,
  showArchived,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [messages, setMessages] = useState({});

  //Form is stored here now so we can actively access it for the poping
  const { form, alter, handleForm } = useForm({
    dateOfUse: currentFilterDate,
    vehicle: {},
    driver: null,
    startMileage: null,
    endMileage: null,
    subcontracted: false,
    subcontractor: {},
    subcontractReference: '',
    subcontractCost: null,
    waypoints: [],
  });

  const {
    waypoints,
    addWaypoints,
    removeIdxWaypoints,
    replaceWaypoints,
    swapWaypoints,
    handleWaypoints,
  } = useArray({
    defaultValue: form.waypoints,
    name: 'waypoints',
  });

  const { destroyedWaypoints, addDestroyedWaypoints, handleDestroyedWaypoints } = useArray({
    name: 'destroyedWaypoints',
  });

  const submitNewVehicleRun = (e, form) => {
    e.preventDefault();
    setSubmitting(true);
    const params = formatVehicleRun(form);

    // We set the status to pending as all NEW VehicleRuns should be
    VehicleRun.create({ ...params, status: 'pending' }).then(
      () => {
        setSubmitting(false);
        handleClose();
      },
      err => {
        setSubmitting(false);
        setMessages(formatError(err));
      },
    );
  };

  const handleModalMove = e => {
    e.preventDefault();
    e.stopPropagation();
    if (subwindow)
      subwindow.postMessage({
        type: 'modalMove',
        variant: 'new',
        currentForm: { form, waypoints, destroyedWaypoints },
      });
    if (parent)
      parent.postMessage({
        type: 'modalMove',
        variant: 'new',
        currentForm: { form, waypoints, destroyedWaypoints },
      });
    handleClose();
  };

  useEffect(() => {
    if (currentFormContent) {
      handleDestroyedWaypoints(currentFormContent.destroyedWaypoints);
      handleWaypoints(currentFormContent.waypoints);
      handleForm(currentFormContent.form);
    }
  }, []);

  /*
    Why does the modal wrapper have isOpen set to true at all times?
      The reason for this is that ReactModal, the package we use to handle
      all of our modals does not properly unmount, it simply hides the modal.
      This causes state from previous instances to be preserved, we do not want
      this, therefore mounting and unmounting should be handled manually.
      For example: modalOpen && <NewVehicleRun />
  */
  return (
    <Modal isOpen handleClose={handleClose}>
      <FormSection
        title={'Create Vehicle Run'}
        draggable
        optionalElement={
          <IconButton
            iconOnly
            icon={faExternalLink}
            disabled={!subwindow && !parent}
            onClick={handleModalMove}
            testId={'-pop-out'}
          />
        }
      >
        <Message
          visible={!!messages?.errors}
          text={messages.errors}
          onClick={() => setMessages([])}
          type={'error'}
        />
        <VehicleRunForm
          handleSubmit={submitNewVehicleRun}
          handleClose={handleClose}
          submitting={submitting}
          waypoints={waypoints}
          addDestroyedWaypoints={addDestroyedWaypoints}
          removeIdxWaypoints={removeIdxWaypoints}
          replaceWaypoints={replaceWaypoints}
          swapWaypoints={swapWaypoints}
          form={form}
          alter={alter}
          addWaypoints={addWaypoints}
          destroyedWaypoints={destroyedWaypoints}
          showMileage={showMileage}
          showArchived={showArchived}
        />
      </FormSection>
    </Modal>
  );
};

NewVehicleRun.propTypes = {
  currentFilterDate: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  subwindow: PropTypes.object,
  parent: PropTypes.object,
  currentFormContent: PropTypes.object,
  showMileage: PropTypes.bool,
  showArchived: PropTypes.object,
};

export default NewVehicleRun;
