import { useState } from 'react';
import Account from '../../../api/controllers/Account/Account';
import { formatError } from '../../../utils/Formatters/Errors/formatError';

const useUser = () => {
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [error, setError] = useState();

  const handleLogin = userJson => {
    return Account.permissions().then(
      json => {
        setPermissions(json);
        setUser(userJson.user);
      },
      json => setError(formatError(json)),
    );
  };

  const handleRestore = () => {
    if (!localStorage.getItem('token')) return;
    Promise.all([Account.current(), Account.permissions()]).then(
      res => {
        setUser(res[0]);
        setPermissions(res[1]);
      },
      json => setError(formatError(json)),
    );
  };

  return {
    user,
    handleUserChange: setUser,
    permissions,
    handleLogin,
    handleRestore,
    userError: error,
    setUserError: setError,
  };
};

export default useUser;
