import React from 'react';
import PropTypes from 'prop-types';
import { faAnkh, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IconButton.scss';

const IconButton = ({
  text = 'Submit',
  icon = faAnkh,
  onClick,
  className,
  iconClassName,
  disabled = false,
  loading = false,
  iconOnly,
  testId = '',
  title = '',
}) => (
  <button
    className={`ib-wrap ${className ? className : ''}`}
    onClick={onClick}
    disabled={disabled || loading}
    data-testid={`icon-button${testId}`}
    title={title}
  >
    <span className={`ib-icon ${iconOnly ? 'rounded' : ''} ${iconClassName ? iconClassName : ''}`}>
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} className={'fa-spin'} data-testid={'spinner'} />
      ) : (
        <FontAwesomeIcon icon={icon} />
      )}
    </span>
    {!iconOnly && <div className={'ib-button'}>{text}</div>}
  </button>
);

IconButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  iconOnly: PropTypes.bool,
  testId: PropTypes.string,
  title: PropTypes.string,
};

export default IconButton;
