import React from 'react';
import PropTypes from 'prop-types';
import TabGroup from '../../TabGroup/TabGroup';
import ContactDetailsForm from '../ContactDetailsForm/ContactDetailsForm';
import useForm from '../../../common/hooks/useForm/useForm';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import Contact from '../../../api/controllers/Company/Contact/Contact';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../common/button/IconButton/IconButton';
import useApi from '../../../common/hooks/useApi/useApi';
import './ContactForm.scss';
import ContactFunctionForm from './ContactFunctionForm/ContactFunctionForm';

const exampleForm = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  jobRole: '',
  contactMethodsAttributes: [],
  emailInclusionsAttributes: [],
  accounts: false,
};

const ContactForm = ({
  handleSubmit,
  handleClose,
  defaultForm = exampleForm,
  submitting = false,
}) => {
  const { form, alter } = useForm(defaultForm);

  const { contactKinds, contactKindsLoading } = useApi({
    call: Contact.contactMethodsKinds,
    name: 'contactKinds',
  });

  return (
    <form className={'contact-form'} onSubmit={e => handleSubmit(e, form)}>
      <TabGroup labels={['General', 'Functions']}>
        {contactKindsLoading ? (
          <LoadingSpinner />
        ) : (
          <ContactDetailsForm form={form} onChange={alter} contactKinds={contactKinds} />
        )}
        <ContactFunctionForm form={form} onChange={alter} />
      </TabGroup>
      <div className="contact-form-button-section">
        <IconButton text={'Submit'} icon={faCheck} loading={submitting} />
        <IconButton text={'Cancel'} icon={faTimes} className={'edit'} onClick={handleClose} />
      </div>
    </form>
  );
};

ContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  defaultForm: PropTypes.object,
  submitting: PropTypes.bool,
};

export default ContactForm;
