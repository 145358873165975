import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class BulkSale {
  static potential(params) {
    const data = buildGetQuery({ ...params, credit: false, purchase: false });
    return apiCall('GET', '/financials/outstanding/entities', data);
  }

  static draft(params) {
    const data = buildGetQuery({
      ...params,
      credit: false,
      purchase: false,
      parent: false,
      submitted: false,
    });
    return apiCall('GET', '/financials/documents/', data);
  }

  static completed(params) {
    const data = buildGetQuery({
      ...params,
      credit: false,
      purchase: false,
      parent: false,
      submitted: true,
    });
    return apiCall('GET', '/financials/documents/', data);
  }
}
export default BulkSale;
