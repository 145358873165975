import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BusinessType from '../../../api/controllers/Company/BusinessType/BusinessType';
import IconButton from '../../../common/button/IconButton/IconButton';
import Message from '../../../common/layout/Message/Message';
import FormField from '../../FormField/FormField';
import AddressForm from '../../forms/AddressForm/AddressForm';
import WarehouseForm from '../../forms/WarehouseForm/WarehouseForm';
import TabGroup from '../../TabGroup/TabGroup';
import ContactDetailsForm from '../../forms/ContactDetailsForm/ContactDetailsForm';
import Contact from '../../../api/controllers/Company/Contact/Contact';
import useApi from '../../../common/hooks/useApi/useApi';
import FormSection from '../../FormSection/FormSection';
import Modal from '../../../common/Modal/Modal';
import useForm from '../../../common/hooks/useForm/useForm';
import { contactsAttributes, mainAddressAttributes } from '../../../strings/modelEnums';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import Entity from '../../../api/controllers/Company/Entity/Entity';
import './NewEntity.scss';
import GenericDropdown from '../../../common/GenericDropdown/GenericDropdown';

const defaultVal = {
  internalName: '',
  businessTypeId: null,
  status: 'active',
  ...mainAddressAttributes,
  ...contactsAttributes,
  errors: [],
};

const NewEntity = ({ open, setOpen, onSuccess, shouldDefaultCustomer = false }) => {
  const { form, alter, handleForm } = useForm(defaultVal);
  const { contactKinds } = useApi({ call: Contact.contactMethodsKinds, name: 'contactKinds' });
  const { businessTypes } = useApi({ call: BusinessType.all, name: 'businessTypes' });
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  const labels = ['General', 'Contact', 'Warehouse'];

  const handleAddressChange = (attr, val) =>
    alter('mainAddressAttributes', { ...form.mainAddressAttributes, [attr]: val });

  const handleContactChange = (attr, val) =>
    alter('contactsAttributes', [{ ...form.contactsAttributes[0], [attr]: val }]);

  const handleCancel = () => {
    setOpen(false);
    handleForm(defaultVal);
    setMessage();
  };

  if (!businessTypes) return null;
  const businessTypesValue = [{ id: null, name: '' }, ...businessTypes.items].filter(
    bt => bt.id === form.businessTypeId,
  )[0].name;

  const handleSubmit = (e, form) => {
    e.preventDefault();
    setLoading(true);
    const contactsValue =
      form.contactsAttributes[0] === contactsAttributes.contactsAttributes[0]
        ? []
        : form.contactsAttributes;
    const params = shouldDefaultCustomer
      ? { ...form, contactsAttributes: contactsValue, customer: true }
      : { ...form, contactsAttributes: contactsValue };
    Entity.create(params).then(
      json => {
        setLoading(false);
        setOpen(false);
        setMessage();
        onSuccess(json);
      },
      json => {
        setLoading(false);
        setMessage(formatError(json));
      },
    );
  };

  return (
    open && (
      <Modal isOpen={open} handleClose={handleCancel}>
        {!!form && (
          <FormSection title={'New Entity'} draggable>
            <form className={'new-entity'} onSubmit={e => handleSubmit(e, form)}>
              <Message
                text={message?.errors}
                type={'error'}
                onClick={() => setMessage()}
                visible={!!message?.errors}
              />
              <TabGroup labels={labels}>
                <div className={'tab-content general'}>
                  <FormField label={'System Name'} className={'single'}>
                    <input
                      value={form.internalName}
                      onChange={e => alter('internalName', e.target.value)}
                    />
                  </FormField>
                  <FormField label={'Business Type'} className={'single'}>
                    <GenericDropdown
                      value={businessTypesValue}
                      items={businessTypes?.items}
                      onDisplay={bt => bt?.name || bt}
                      onSelect={bt => alter('businessTypeId', bt.id)}
                      searchable={['name']}
                      testId={'business-types-dd'}
                      onClear={() => alter('businessTypeId', null)}
                    />
                  </FormField>
                  <hr />
                  <AddressForm
                    form={form.mainAddressAttributes}
                    onSet={params => alter('mainAddressAttributes', params)}
                    onChange={handleAddressChange}
                  />
                </div>
                <div className={'tab-content contact'}>
                  <ContactDetailsForm
                    form={form.contactsAttributes[0]}
                    onChange={handleContactChange}
                    contactKinds={contactKinds}
                  />
                </div>
                {form.mainAddressAttributes.warehouse && (
                  <div className={'tab-content warehouse'}>
                    <WarehouseForm
                      form={form.mainAddressAttributes}
                      onChange={handleAddressChange}
                    />
                  </div>
                )}
              </TabGroup>
              <div className={'af-button-line'}>
                <IconButton
                  text={'Create Entity'}
                  icon={faCheck}
                  className={'small'}
                  loading={loading}
                />
                <IconButton
                  text={'Cancel'}
                  icon={faTimes}
                  onClick={handleCancel}
                  className={'edit small'}
                />
              </div>
            </form>
          </FormSection>
        )}
      </Modal>
    )
  );
};

NewEntity.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onSuccess: PropTypes.func,
  shouldDefaultCustomer: PropTypes.bool,
};

export default NewEntity;
