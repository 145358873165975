import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../button/IconButton/IconButton';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './DatePicker.scss';
import DateHelper from '../../utils/DateHelper/DateHelper';

const DatePicker = ({ value, onChange }) => {
  const handleKey = e => {
    //this has to be done because the browser is dumb and although it stops the UI clearing, doesnt stop keypress
    if (e.keyCode === 8) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  //stops changing of value if its not going to be valid
  const checkAndChange = target => {
    if (target.validity.valid) onChange(target.value);
  };

  return (
    <div className="dp-container">
      <IconButton
        iconOnly
        className="dp-icon"
        iconClassName="dp-icon dp-icon-left"
        icon={faAngleLeft}
        onClick={() => onChange(DateHelper.yesterday(value))}
        disabled={value === '0001-01-01'} //prevents trying to go back into an invalid date
      />
      <input
        className="dp-input"
        type={'date'}
        value={value}
        required
        onKeyDown={handleKey}
        onChange={e => checkAndChange(e.target)}
      />
      <IconButton
        iconOnly
        className="dp-icon"
        iconClassName="dp-icon dp-icon-right"
        icon={faAngleRight}
        onClick={() => onChange(DateHelper.tomorrow(value))}
        disabled={value === '9999-12-31'} //prevents trying to go into an invalid date
      />
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default DatePicker;
