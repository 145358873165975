import { RichUtils } from 'draft-js';

const keyHandler = (command, editorState, setEditorState) => {
  const newState = RichUtils.handleKeyCommand(editorState, command);
  if (newState) return setEditorState(newState);
  if (command === 'save') {
    console.log('Save command triggered');
    return 'handled';
  }
  console.log('Defaulted, no keybind to handle');
};

export default keyHandler;
