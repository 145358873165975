import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class FuelPrice {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'movements/fuel_prices', data);
  }

  static updateAll(params) {
    const data = { fuel_price_set: params };
    return apiCall('PATCH', 'movements/fuel_prices', data);
  }
}

export default FuelPrice;
