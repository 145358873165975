import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

// Handles all address requests.
class Address {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'companies/addresses', data);
  }

  static show(id) {
    return apiCall('GET', `companies/addresses/${id}`);
  }

  static create(params) {
    const data = { address: params };
    return apiCall('POST', 'companies/addresses', data);
  }

  static update(id, params) {
    const data = { address: params };
    return apiCall('PATCH', `companies/addresses/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `companies/addresses/${id}`);
  }

  static markAsMain(id) {
    return apiCall('POST', `companies/addresses/${id}/mark_as_main`);
  }

  static markAsInvoicing(id) {
    return apiCall('POST', `companies/addresses/${id}/mark_as_invoicing`);
  }

  static archive(id) {
    return apiCall('POST', `companies/addresses/${id}/archive`);
  }

  static unArchive(id) {
    return apiCall('POST', `companies/addresses/${id}/unarchive`);
  }
}

export default Address;
