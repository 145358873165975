import { DELETE_TOKEN, EXTERNAL_ERROR } from '../../actions/types';
import store from '../../store';
import { safeParse } from '../helpers/safeParse/safeParse';

// File comes from the api-test.html in the backend
// Files must be fetched as raw binary data, so they work a bit differently:
const downloadFile = id => {
  const { token: jwt } = store.getState().userReducer;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const server = process.env.REACT_APP_API_SERVER;
    xhr.responseType = 'blob'; // Make sure to expect a bitstream, this is why we parse the response below

    xhr.open('GET', `${server}/files/items/${id}/download`);
    if (jwt) xhr.setRequestHeader('Authorization', jwt);
    xhr.send();

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) return resolve(xhr.response);
      xhr.response.text().then(text => {
        const json = safeParse(text);
        if (json?.error?.startsWith('External request:')) {
          store.dispatch({ type: EXTERNAL_ERROR, payload: json.error });
          reject({ ...json, status: xhr.status });
        }

        if (xhr.status === 401) {
          store.dispatch({ type: DELETE_TOKEN });
          reject({ ...json, status: xhr.status });
        }
        reject(json);
      });
    };

    // Mimic { 'error': 'You need to sign in or sign up before continuing.' }
    xhr.onerror = () => reject({ error: 'Could not connect to server' });
    xhr.onabort = () => reject({ error: 'Request aborted' });
    xhr.ontimeout = () => reject({ error: 'Request timed out' });
  });
};

export default downloadFile;
