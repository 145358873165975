import FuelPrice from '../../api/controllers/Movement/FuelPrice/FuelPrice';
import NumberHelper from '../NumberHelper/NumberHelper';

class FuelHelper {
  constructor(vehicleRun) {
    if (vehicleRun.subcontracted) throw Error('This cannot be ran on a subcontracted Run');
    this.run = vehicleRun;
  }

  async calculate() {
    const { vehicle, dateOfUse, startMileage, endMileage } = this.run;
    const latestFuelPrice = await this._getFuelPrice(dateOfUse);

    //calculate PencePerGallon and format milesPerGallon
    const ppl = Number(latestFuelPrice.pencePerLitre);
    const ppg = ppl * 4.546; //Litres => Gallons
    const mpg = NumberHelper.formatNumberUnlessBlank(vehicle?.milesPerGallon);

    //Calculate miles driven
    const startMile = NumberHelper.formatNumberUnlessBlank(startMileage);
    const endMile = NumberHelper.formatNumberUnlessBlank(endMileage);
    const milesDriven = endMile - startMile;

    //The Pence cost of the vehicle run
    const fuelCost = (milesDriven / mpg) * ppg;
    //Return Pound Cost
    return fuelCost / 100;
  }

  // Private functions

  //Finds the applicable fuel price for a date
  async _getFuelPrice(dateOfUse) {
    try {
      const fuel = await FuelPrice.all();
      if (fuel.items.length === 0) return { pencePerLitre: NaN };

      const fuelList = fuel.items;
      //switch to findLast when more fully supported
      fuelList.reverse();
      const applicableFuel = fuelList.find(item => item.startDate <= dateOfUse);
      return applicableFuel || { pencePerLitre: NaN };
    } catch (e) {
      return { pencePerLitre: NaN };
    }
  }
}

export default FuelHelper;
