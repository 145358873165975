import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableHelper from '../../../utils/TableHelper/TableHelper';
import DocumentRow from '../../../pages/Financial/AccountSync/DocumentRow/DocumentRow';
import TablePresenter from '../TablePresenter/TablePresenter';
import StaticCheckbox from '../../StaticCheckbox/StaticCheckbox';

const LIMIT = 15;

/*
  A special table for the AccountSync page as it needs to handle Selection of items in the headers
  and therefore requires some special logic, see: handleAllChange and allChecked
*/
const AccountSyncTable = ({
  data,
  selectedDocs,
  toggleSelectedDocs,
  handleSelectedDocs,
  calls,
  loading,
}) => {
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const onSearch = e => setSearchText(e.target.value);

  //General table stuff: searching, sorting and pagination
  const searched = TableHelper.searchData(data?.items, searchText);
  const sortFunc = TableHelper.getSortFunction(sort.type);
  const sorted = TableHelper.sortData(sortFunc, searched, sort);
  const paginated = TableHelper.paginateData(sorted, LIMIT, currentPage);

  //Get the page number
  const pageNum = TableHelper.getPageNum(searched, LIMIT);

  //change page when searches happen
  useEffect(() => {
    if (currentPage > pageNum) setCurrentPage(pageNum);
    if (currentPage < 1) setCurrentPage(1);
  }, [searchText]);

  //Checks if an array (arr) includes every element of another (target)
  const includesAll = (arr, target) => target.every(v => arr.includes(v));

  //toggles all the current page elements to either be selected or unselected
  const handleAllChange = () => {
    if (!data || !data?.items) return;
    const pageItems = paginated;
    const ids = pageItems.map(item => item.id);
    if (includesAll(selectedDocs, ids)) {
      //remove the current page IDs from the selection
      const clearedPage = selectedDocs.filter(id => !ids.includes(id));
      return handleSelectedDocs(clearedPage);
    }

    //Add the current page, using Set to remove duplication
    const nowIncluded = [...new Set(selectedDocs.concat(ids))];
    handleSelectedDocs(nowIncluded);
  };

  //determines if all the ids visible are currently selected
  const allChecked = () => {
    if (!data || !paginated) return false;

    const ids = paginated.map(item => item.id);
    if (includesAll(selectedDocs, ids)) return true;
    return false;
  };

  //function for rendering the rows
  const drawRow = (row, i) => (
    <DocumentRow
      key={row.id}
      idx={i}
      row={row}
      loading={loading}
      toggle={toggleSelectedDocs}
      checked={selectedDocs.includes(row.id)}
      apiCall={calls.find(item => item.id === row.id)}
    />
  );

  if (!data) return null;
  const lowerBound = TableHelper.getLowerBound(currentPage, LIMIT);
  const upperBound = TableHelper.getUpperBound(currentPage, LIMIT, paginated.length);

  const headers = [
    { text: 'Date', sortingAttribute: 'issueDate' },
    { text: 'Number', sortingAttribute: 'documentReference' },
    { text: 'Customer / Supplier', sortingAttribute: 'entity' },
    { text: 'Details', sortingAttribute: 'parent' },
    { text: 'Goods Value', sortingAttribute: 'totalValue', type: 'number' },
    { text: 'Status' },
    {
      text: (
        <StaticCheckbox checked={allChecked()} onClick={handleAllChange} testId={'ast-toggle'} />
      ),
    },
  ];

  return (
    <TablePresenter
      title={'Pending Documents'}
      headers={headers}
      rows={paginated}
      onDrawRow={drawRow}
      sort={sort}
      onSort={header => TableHelper.onSort(header, sort, setSort)}
      onSearch={onSearch}
      pageElements={() => TableHelper.genPageNumbers(currentPage, setCurrentPage, pageNum)}
      pageText={TableHelper.paginationText(upperBound, lowerBound, searched.length)}
    />
  );
};

AccountSyncTable.propTypes = {
  data: PropTypes.object,
  selectedDocs: PropTypes.array,
  toggleSelectedDocs: PropTypes.func.isRequired,
  handleSelectedDocs: PropTypes.func.isRequired,
  calls: PropTypes.array,
  loading: PropTypes.bool,
};

export default AccountSyncTable;
