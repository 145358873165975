import MultipleAction from '../MultipleAction/MultipleAction';

class DestroyMultipleAction extends MultipleAction {
  constructor(setState, currentState, removedItems) {
    super();
    this.setState = setState;
    this.currentState = currentState;
    this.removedItems = removedItems;
  }

  execute() {
    const newState = this.currentState.filter(
      item => !this.removedItems.find(remItem => remItem.id === item.id),
    );
    this.setState(newState);
  }

  undo(ids) {
    const keepRemoved = this.removedItems.filter(item => !ids.includes(item.id));
    const newState = this.currentState.filter(
      item => !keepRemoved.find(remItem => remItem.id === item.id),
    );
    this.setState(newState);
  }

  undoAll() {
    this.setState(this.currentState);
  }

  get oldState() {
    return this.currentState;
  }

  get itemsRemoved() {
    return this.removedItems;
  }
}

export default DestroyMultipleAction;
