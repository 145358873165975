import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BookingFormJobSummary from './subComponents/BookingFormJobSummary/BookingFormJobSummary';
import BookingFormEntities from './subComponents/BookingFormEntities/BookingFormEntities';
import BookingFormPackageDimensions from './subComponents/BookingFormPackageDimensions/BookingFormPackageDimensions';
import BookingFormConsignmentDetails from './subComponents/BookingFormConsignmentDetails/BookingFormConsignmentDetails';
import BookingFormGoodsDetails from './subComponents/BookingFormGoodsDetails/BookingFormGoodsDetails';
import BookingFormSchedule from './subComponents/BookingFormSchedule/BookingFormSchedule';
import {
  calcCubicMetres,
  calcQuantity,
  getItemByVal,
} from '../../../../../../utils/bookingUtils/bookingUtils';

const BookingFormGeneral = ({ data, alter, handleForm, openModal, changeFocus }) => {
  useEffect(() => {
    if (data.dimensionLineItemsAttributes.length > 0) alter('dli', true);
  }, []);

  const multipleTypes = formData => {
    const ogName = formData.dimensionLineItemsAttributes[0]?.packageType?.name;
    const res =
      formData.dimensionLineItemsAttributes.filter(li => li?.packageType?.name !== ogName).length >
      0;
    return res;
  };

  const autoTotal = (formData, packages) => {
    const quantity = calcQuantity(formData);
    const cubicMetres = calcCubicMetres(formData);
    const packageType = multipleTypes(formData)
      ? getItemByVal(packages.items, 'name', 'Package')
      : formData.dimensionLineItemsAttributes[0]?.packageType;
    handleForm({ ...data, ...{ quantity, cubicMetres, packageType } });
    changeFocus('bfg-gross-weight');
  };

  const handleToggle = () => {
    const lineItem =
      data.dimensionLineItemsAttributes.length === 0 ? { dimensionLineItemsAttributes: [{}] } : {};
    handleForm({ ...data, dli: !data.dli, ...lineItem });
    changeFocus(
      !data.dli
        ? `bfg-line-item-${
            data.dimensionLineItemsAttributes.length > 0
              ? data.dimensionLineItemsAttributes.length - 1
              : data.dimensionLineItemsAttributes.length
          }`
        : '',
    );
  };

  return (
    <div className={'tab-content'}>
      <BookingFormJobSummary data={data} alter={alter} />
      <BookingFormEntities
        data={data}
        alter={alter}
        handleForm={handleForm}
        openModal={openModal}
      />
      <BookingFormSchedule data={data} alter={alter} />
      {data.dli && (
        <BookingFormPackageDimensions
          data={data}
          alter={alter}
          autoTotal={autoTotal}
          changeFocus={changeFocus}
        />
      )}
      <BookingFormConsignmentDetails
        data={data}
        alter={alter}
        open={data.dli}
        toggle={handleToggle}
      />
      <BookingFormGoodsDetails data={data} alter={alter} />
    </div>
  );
};

BookingFormGeneral.propTypes = {
  data: PropTypes.object.isRequired,
  alter: PropTypes.func.isRequired,
  handleForm: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  changeFocus: PropTypes.func,
};

export default BookingFormGeneral;
