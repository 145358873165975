import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../components/FormSection/FormSection';
import IconButton from '../../../../../../common/button/IconButton/IconButton';
import LoadingSpinner from '../../../../../../common/LoadingSpinner/LoadingSpinner';
import ErrorStatusMessage from '../../../../../../common/ErrorStatusMessage/ErrorStatusMessage';
import Item from '../../../../../../api/controllers/Note/Item/Item';
import useApi from '../../../../../../common/hooks/useApi/useApi';
import useArray from '../../../../../../common/hooks/useArray/useArray';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './NotesTab.scss';
import Note from '../../../../../../components/Note/Note';
import TableHelper from '../../../../../../utils/TableHelper/TableHelper';
import ConfirmationModal from '../../../../../../components/ConfirmationModal/ConfirmationModal';
import NewNote from '../../../../../../components/modals/NewNote/NewNote';
import EditNote from '../../../../../../components/modals/EditNote/EditNote';

const LIMIT = 15; //notes per page limit

const NotesTab = ({
  parentId,
  parentType,
  subTab = false,
  readOnly = false,
  permissions,
  requiredPermit,
}) => {
  const permits = permissions[requiredPermit];
  const [error, setError] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [newNoteModal, setNewNoteModal] = useState(false);
  const [editNoteModal, setEditNoteModal] = useState();
  const [deleteModal, setDeleteModal] = useState({ open: false }); // handles delete modal open and content
  const { openNotes, toggleOpenNotes } = useArray({ name: 'openNotes' });
  const { data, dataLoading, refresh } = useApi({
    call: () => Item.all({ parentType: parentType, parentId: parentId, order: 'desc' }),
  });

  const handleExpand = id => toggleOpenNotes(id);

  const handleEditButton = id => setEditNoteModal(data?.items.find(note => note.id === id));

  //handles what happens when the delete button is clicked
  const handleDeleteButton = id => {
    const note = data.items.find(n => n.id === id);
    setDeleteModal({ open: true, note: note });
  };

  //handles the deletion of a note
  const handleDelete = () => {
    Item.destroy(deleteModal.note.id)
      .then(
        () => refresh(),
        err => setError(err),
      )
      .then(() => setDeleteModal({ open: false }));
  };

  const noteButton = () =>
    permits.update && (
      <IconButton
        text="Add Note"
        icon={faPlus}
        className={'add-button'}
        onClick={() => setNewNoteModal(true)}
      />
    );

  const handleSuccess = id => {
    refresh();
    if (!id) return setNewNoteModal(false);
    setEditNoteModal();
  };

  if (dataLoading)
    return (
      <div className="notes-tab">
        <div className="page-content">
          <LoadingSpinner />
        </div>
      </div>
    );
  if (!data) return null;

  const pageNum = data && Math.ceil(data.total / LIMIT); //get the current page
  const paginated = data.items
    .sort((a, b) => a.id < b.id)
    .slice((currentPage - 1) * LIMIT, currentPage * LIMIT); //sort by Id and then get current page data
  const lowerBound = (currentPage - 1) * LIMIT + 1;
  const upperBound = currentPage * LIMIT + (paginated.length - LIMIT);
  //While not a table, These functions do exactly what we need ot generate pages
  const pageElements = () => TableHelper.genPageNumbers(currentPage, setCurrentPage, pageNum); //gets PageNumber elements
  const pageText = TableHelper.paginationText(upperBound, lowerBound, data.total); //gets the text for pages

  if (subTab)
    return (
      <div className="notes-tab">
        <ErrorStatusMessage error={error} setError={setError} />
        <ConfirmationModal
          question={'Are you sure you want to delete the note?'}
          subText={deleteModal.note?.summary}
          confirmText={'Delete'}
          isOpen={deleteModal.open}
          handleConfirm={handleDelete}
          handleCancel={() => setDeleteModal({ open: false })}
        />
        <div className={`page-content ${!readOnly ? 'with-add' : ''}`}>
          {dataLoading && <LoadingSpinner />}
          {newNoteModal && (
            <NewNote
              handleClose={() => setNewNoteModal(false)}
              handleSuccess={handleSuccess}
              parentId={parentId}
              parentType={parentType}
            />
          )}
          {editNoteModal && (
            <EditNote
              handleClose={() => setEditNoteModal()}
              handleSuccess={handleSuccess}
              note={editNoteModal}
            />
          )}
          {!readOnly && <div className={'sub-button'}>{noteButton()}</div>}
          {data.items.length === 0 && (
            <>
              <div className="no-email">You&apos;ve not added any notes yet.</div>
            </>
          )}
          {data.items.length > 0 && (
            <>
              {paginated.map(note => (
                <Note
                  key={note.id}
                  note={note}
                  expanded={openNotes.includes(note.id)}
                  handleDelete={handleDeleteButton}
                  handleEdit={handleEditButton}
                  handleExpand={handleExpand}
                  readOnly={readOnly}
                  permits={permits}
                />
              ))}
            </>
          )}
        </div>
        {data.total > LIMIT && (
          <div className="footer">
            <div className="table-bottom">
              <div className="page-info">{pageText}</div>
              <div className="page-numbers">{pageElements()}</div>
            </div>
          </div>
        )}
      </div>
    );

  return (
    <div className="notes-tab">
      <ErrorStatusMessage error={error} setError={setError} />
      <ConfirmationModal
        question={'Are you sure you want to delete the note?'}
        subText={deleteModal.note?.summary}
        confirmText={'Delete'}
        isOpen={deleteModal.open}
        handleConfirm={handleDelete}
        handleCancel={() => setDeleteModal({ open: false })}
      />
      <div className="page-content">
        {dataLoading && <LoadingSpinner />}
        {newNoteModal && (
          <NewNote
            handleClose={() => setNewNoteModal(false)}
            handleSuccess={handleSuccess}
            parentId={parentId}
            parentType={parentType}
          />
        )}
        {editNoteModal && (
          <EditNote
            handleClose={() => setEditNoteModal()}
            handleSuccess={handleSuccess}
            note={editNoteModal}
          />
        )}
        {data.items.length === 0 && (
          <FormSection title={'Notes'} optionalElement={noteButton()}>
            <div className="no-email">You&apos;ve not added any notes yet.</div>
          </FormSection>
        )}
        {data.items.length > 0 && (
          <FormSection title={'Notes'} optionalElement={noteButton()}>
            {paginated.map(note => (
              <Note
                key={note.id}
                note={note}
                expanded={openNotes.includes(note.id)}
                handleExpand={handleExpand}
                handleDelete={handleDeleteButton}
                readOnly={readOnly}
                handleEdit={handleEditButton}
                permits={permits}
              />
            ))}
          </FormSection>
        )}
      </div>
      {data.total > LIMIT && (
        <div className="footer">
          <div className="table-bottom">
            <div className="page-info">{pageText}</div>
            <div className="page-numbers">{pageElements()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

NotesTab.propTypes = {
  parentType: PropTypes.string,
  parentId: PropTypes.number,
  canDelete: PropTypes.bool,
  subTab: PropTypes.bool,
  readOnly: PropTypes.bool,
  permissions: PropTypes.object,
  requiredPermit: PropTypes.string.isRequired,
};

export default NotesTab;
