import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadingSpinner = () => (
  <div className={'spinner'} data-testid={'loading-spinner'}>
    <FontAwesomeIcon icon={faSpinner} className={'fa-spin'} data-testid={'spinner'} />
  </div>
);

export default LoadingSpinner;
