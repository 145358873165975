import React from 'react';
import PropTypes from 'prop-types';
import './StaticCheckbox.scss';

const StaticCheckbox = ({ checked, onClick, label, testId }) => (
  <div className={'sc-container'} onClick={onClick} data-testid={testId}>
    <input type={'checkbox'} checked={checked} readOnly />
    {label}
  </div>
);

StaticCheckbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  testId: PropTypes.string,
};

export default StaticCheckbox;
