import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IconDropdown.scss';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import DropdownHelper from '../../../utils/DropdownHelper/DropdownHelper';

const IconDropdown = ({
  icon,
  text,
  children,
  className,
  testId,
  action = 'hover',
  iconOnly = false, //displays only the icon if true
}) => {
  const [open, setOpen] = useState(false);
  const nodeRef = useRef();

  // Handle clicks outside of the portal-root and dropdown
  const handleClickOutside = e => {
    if (DropdownHelper.isDropdownRelated(e, nodeRef)) return;
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  const getClassNames = () => {
    let classNames = 'id-container';
    if (className) classNames = `${classNames} ${className}`;
    if (action !== 'hover') classNames = `${classNames} no-hover`;
    return classNames;
  };

  return (
    <div
      className={getClassNames()}
      ref={nodeRef}
      data-testid={testId}
      onClick={() => (action === 'click' ? setOpen(!open) : null)}
    >
      <button className={'id-button'}>
        <span className={`id-icon${iconOnly ? ' icon-only' : ''}`}>
          {!iconOnly && <FontAwesomeIcon icon={icon} />}
          {iconOnly &&
            (open ? (
              <span className={'id-arrow open'}>
                <FontAwesomeIcon icon={faSortUp} />
              </span>
            ) : (
              <span className={'id-arrow'}>
                <FontAwesomeIcon icon={faSortDown} />
              </span>
            ))}
        </span>
        {!iconOnly && (
          <div className={'id-text'}>
            {text}
            {action === 'click' && open ? (
              <span className={'id-arrow open'}>
                <FontAwesomeIcon icon={faSortUp} />
              </span>
            ) : (
              <span className={'id-arrow'}>
                <FontAwesomeIcon icon={faSortDown} />
              </span>
            )}
          </div>
        )}
      </button>
      {(action !== 'click' || open) && <div className={'id-content'}>{children}</div>}
    </div>
  );
};

IconDropdown.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  testId: PropTypes.string,
  action: PropTypes.string,
  iconOnly: PropTypes.bool,
};

export default IconDropdown;
