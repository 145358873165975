import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../../../components/FormSection/FormSection';
import FieldList from '../../../../../../../../components/FieldList/FieldList';
import FormField from '../../../../../../../../components/FormField/FormField';
import TimeInput from '../../../../../../../../common/input/TimeInput/TimeInput';

const BookingFormSchedule = ({ data, alter }) => {
  const timeAlter = (id, val, opensAttr, closesAttr) =>
    alter(id === 'opensAt' ? opensAttr : closesAttr, val);

  return (
    <FormSection title={'Schedule'}>
      <FieldList>
        <FormField label={'Collection Date'}>
          <input
            data-testid={'bfg-collection-date'}
            type={'date'}
            value={data.collectionDate}
            onChange={e => alter('collectionDate', e.target.value)}
          />
        </FormField>
        <FormField label={'Collection Time'}>
          <TimeInput
            opensAt={data.collectionFromTime}
            closesAt={data.collectionUntilTime}
            onChange={(id, val) => timeAlter(id, val, 'collectionFromTime', 'collectionUntilTime')}
          />
        </FormField>
      </FieldList>

      <FieldList>
        <FormField label={'Delivery Date'}>
          <input
            data-testid={'bfg-delivery-date'}
            type={'date'}
            value={data.deliveryDate}
            onChange={e => alter('deliveryDate', e.target.value)}
          />
        </FormField>
        <FormField label={'Delivery Time'}>
          <TimeInput
            opensAt={data.deliveryFromTime}
            closesAt={data.deliveryUntilTime}
            onChange={(id, val) => timeAlter(id, val, 'deliveryFromTime', 'deliveryUntilTime')}
          />
        </FormField>
      </FieldList>

      <FieldList>
        <FormField label={'Goods Ready Date'}>
          <input
            data-testid={'bfg-goods-ready-date'}
            type={'date'}
            value={data.goodsReadyDate}
            onChange={e => alter('goodsReadyDate', e.target.value)}
          />
        </FormField>
        <FormField label={'Goods Ready Time'}>
          <TimeInput
            opensAt={data.goodsReadyFromTime}
            closesAt={data.goodsReadyUntilTime}
            onChange={(id, val) => timeAlter(id, val, 'goodsReadyFromTime', 'goodsReadyUntilTime')}
          />
        </FormField>
      </FieldList>

      <FieldList>
        <FormField label={'Delivery Deadline Date'}>
          <input
            data-testid={'bfg-delivery-deadline-date'}
            type={'date'}
            value={data.deliveryDeadlineDate}
            onChange={e => alter('deliveryDeadlineDate', e.target.value)}
          />
        </FormField>
        <FormField label={'Delivery Deadline Time'}>
          <TimeInput
            opensAt={data.deliveryDeadlineFromTime}
            closesAt={data.deliveryDeadlineUntilTime}
            onChange={(id, val) =>
              timeAlter(id, val, 'deliveryDeadlineFromTime', 'deliveryDeadlineUntilTime')
            }
          />
        </FormField>
      </FieldList>
    </FormSection>
  );
};

BookingFormSchedule.propTypes = {
  data: PropTypes.object,
  alter: PropTypes.func,
  handleForm: PropTypes.func,
  openModal: PropTypes.func,
};

export default BookingFormSchedule;
