import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../FormField/FormField';
import './AddressForm.scss';
import PostcodeDropdown from '../../../common/Dropdown/PostcodeDropdown/PostcodeDropdown';
import StaticCheckbox from '../../StaticCheckbox/StaticCheckbox';
import { warehouseFactors } from '../../../strings/enums';
import AddressHelper from '../../../utils/AddressHelper/AddressHelper';
import AttributeHelper from '../../../utils/AttributeHelper/AttributeHelper';

const defaultWarehouse = { name: '', applies: 'never', description: '' };

const AddressForm = ({ form, onSet, onChange }) => {
  const handleOption = item =>
    onSet({ ...form, postcode: item.postcode, region: AddressHelper.getRegion(item) });

  const drawOptions = (data, setOpen) =>
    data.result.map((item, i) => (
      <button
        key={i}
        tabIndex={0}
        type={'button'}
        className={'dd-button'}
        onClick={() => {
          handleOption(item);
          setOpen(false);
        }}
      >
        {item.postcode}
      </button>
    ));

  const warehouseToggleClick = () => {
    let warehouseRequirementsAttributes =
      AttributeHelper.importAttributes(warehouseFactors, form.warehouseRequirements) || [];
    if (!form.warehouse && !warehouseRequirementsAttributes.length === 0)
      warehouseRequirementsAttributes = Object.keys(warehouseFactors).map(f => ({
        ...defaultWarehouse,
        name: f,
      }));
    onSet({ ...form, warehouse: !form.warehouse, warehouseRequirementsAttributes });
  };

  return (
    <Fragment>
      <FormField label={'Postcode'} className={'single'}>
        <PostcodeDropdown value={form.postcode} drawOptions={drawOptions} />
      </FormField>
      <hr />
      <FormField label={'Address Line 1'} className={'single'}>
        <input value={form.line1} onChange={e => onChange('line1', e.target.value)} />
      </FormField>
      <FormField label={'Address Line 2'} className={'single'}>
        <input value={form.line2} onChange={e => onChange('line2', e.target.value)} />
      </FormField>
      <FormField label={'Address Line 3'} className={'single'}>
        <input value={form.line3} onChange={e => onChange('line3', e.target.value)} />
      </FormField>
      <FormField label={'Address Line 4'} className={'single'}>
        <input value={form.line4} onChange={e => onChange('line4', e.target.value)} />
      </FormField>
      <FormField label={'Region'} className={'single'}>
        <input value={form?.region} disabled readOnly tabIndex={-1} />
      </FormField>
      <div className={'button-line'}>
        <StaticCheckbox
          checked={form.office}
          onClick={() => onChange('office', !form.office)}
          label={'Office'}
        />
        <StaticCheckbox
          checked={form.warehouse}
          onClick={warehouseToggleClick}
          label={'Warehouse'}
          testId={'cb-warehouse'}
        />
      </div>
    </Fragment>
  );
};

AddressForm.propTypes = {
  form: PropTypes.object,
  onChange: PropTypes.func,
  onSet: PropTypes.func,
};

export default AddressForm;
