import React from 'react';
import PropTypes from 'prop-types';
import VehicleRunDetails from '../../../../../../components/VehicleRunDetails/VehicleRunDetails';

const VehicleRunShowGeneral = ({ run }) => {
  return (
    <div className="tab-content">
      <VehicleRunDetails run={run} showMileage />
    </div>
  );
};

VehicleRunShowGeneral.propTypes = { run: PropTypes.object };

export default VehicleRunShowGeneral;
