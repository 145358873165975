import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../components/FormSection/FormSection';
import './BookingShowBilling.scss';
import { useNavigate } from 'react-router';
import IconButton from '../../../../../../common/button/IconButton/IconButton';
import { faList, faSync } from '@fortawesome/free-solid-svg-icons';
import VehicleRunHelper from '../../../../../../utils/VehicleRunHelper/VehicleRunHelper';
import AutoLineCalculator from '../../../../../../utils/AutoLineCalculator/AutoLineCalculator';
import ErrorStatusMessage from '../../../../../../common/ErrorStatusMessage/ErrorStatusMessage';
import BillingLines from '../../../../../../components/BillingLines/BillingLines';
import useApi from '../../../../../../common/hooks/useApi/useApi';
import SystemSetting from '../../../../../../api/controllers/SystemSetting/SystemSetting';
import Booking from '../../../../../../api/controllers/Job/Booking/Booking';
import { formatError } from '../../../../../../utils/Formatters/Errors/formatError';

const BookingShowBilling = ({ data, fsId }) => {
  const { generals } = useApi({ call: SystemSetting.show, name: 'generals' });
  const [autoLineLoading, setAutoLineLoading] = useState(false);
  const [fsLoading, setFsLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const salesItems = data.financialLineItems.filter(li => !li.purchase && !li.credit);
  const salesCreditItems = data.financialLineItems.filter(li => !li.purchase && li.credit);
  const purchaseItems = data.financialLineItems.filter(li => li.purchase && !li.credit);
  const purchaseCreditItems = data.financialLineItems.filter(li => li.purchase && li.credit);

  const handleAutoLine = async () => {
    setAutoLineLoading(true);
    const autoLineGenerator = new AutoLineCalculator(data, fsId);
    const x = await autoLineGenerator.generate();
    if (x.error) setError(x);
    else
      navigate(`/jobs/bookings/${data.id}/edit`, {
        state: { detail: 'AutoLine Success' },
      });
    setAutoLineLoading(false);
  };

  const renderAutoLineButton = () => {
    if (data.status === 'finalised' || data.status === 'cancelled') return;
    const direction = VehicleRunHelper.getJobDirection(
      fsId,
      data.collectionAddress?.id,
      data.deliveryAddress?.id,
    );
    if (direction === 'XT') return;

    let disableReason = '';
    if (!data.collectionEntity?.allowAutolining || !data.deliveryEntity?.allowAutolining)
      disableReason = 'Auto-lining is not allowed for this entity';
    if (!data.allowAutolining) disableReason = 'Auto-lining is not allowed for this job';
    return (
      <IconButton
        text="Auto-line"
        icon={faList}
        className={'auto-line-button'}
        onClick={handleAutoLine}
        loading={autoLineLoading}
        disabled={!!disableReason}
        title={disableReason}
      />
    );
  };

  const handlePush = () => {
    setFsLoading(true);
    Booking.pushToFs(data.id)
      .then(
        () =>
          setError({
            status: 201,
            error: 'Success! Financial details have been pushed to FREIGHTsoft',
          }),
        err => setError(formatError(err)),
      )
      .finally(() => setFsLoading(false));
  };

  const renderPushToFs = () => {
    const show =
      data.financialLineItems.filter(
        fli => fli.entity?.id === generals?.freightsoftEntity?.id && !fli.purchase,
      ).length > 0;
    if (!show) return null;
    return (
      <IconButton text="Sync FREIGHTsoft" icon={faSync} onClick={handlePush} loading={fsLoading} />
    );
  };

  return (
    <div className={'tab-content'}>
      {error && <ErrorStatusMessage error={error} setError={setError} />}
      <FormSection
        title={'Sales'}
        optionalElement={
          <div className="optional-buttons">
            {renderAutoLineButton()}
            {renderPushToFs()}
          </div>
        }
      >
        <BillingLines items={salesItems} documentType={'sales'} documentTitle={'Invoice'} />
      </FormSection>
      {salesCreditItems.length > 0 && (
        <FormSection title={'Sales Credits'}>
          <BillingLines
            items={salesCreditItems}
            documentType={'sales credit'}
            documentTitle={'Credit Note'}
          />
        </FormSection>
      )}
      <FormSection title={'Costs'}>
        <BillingLines items={purchaseItems} documentType={'cost'} documentTitle={'Invoice'} />
      </FormSection>
      {purchaseCreditItems.length > 0 && (
        <FormSection title={'Purchase Credits'}>
          <BillingLines
            items={purchaseCreditItems}
            documentType={'purchase credit'}
            documentTitle={'Credit Note'}
          />
        </FormSection>
      )}
    </div>
  );
};

BookingShowBilling.propTypes = {
  data: PropTypes.object,
  fsId: PropTypes.number,
};

export default BookingShowBilling;
