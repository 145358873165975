import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './ErrorStatusMessage.scss';
import { Link } from 'react-router-dom';

const ErrorStatusMessage = ({ error, setError }) => {
  const errCodeExplanation = code => {
    const codes = {
      201: 'Success',
      404: '404 - Not Found',
      401: '401 - Unauthorised',
      400: '400 - Failed to Update',
      503: '503 - Service Unavailable',
      Download: 'Download Failed',
      RunUpdate: `Failed to re-assign job ${error.ref}`,
      RunCreate: `Failed to create a Vehicle Run for ${error.vehicle?.reference}`,
      AutoLine: 'Auto-lining failed',
      Error: 'Error',
      Warning: 'Warning',
      success: 'Success',
      dym: 'Did you mean?',
      default: `${code} - Unknown`,
    };
    return codes[code] || codes.default;
  };

  if (!error) return null;
  return (
    <div className={`error-message-container${error.status === 'success' ? ' success' : ''}`}>
      <FontAwesomeIcon
        icon={faTimes}
        className={'icon'}
        data-testid={'icon'}
        onClick={() => {
          setError();
        }}
      />
      <div className="error-message-content">
        <p>
          <b>{error.status ? `${errCodeExplanation(error.status)}` : 'Error'}</b>
        </p>
        {error.error}
        {error.errors &&
          error.errors.map((err, i) => (
            <p key={i}>{typeof err === 'object' ? <Link to={err.link}>{err.text}</Link> : err}</p>
          ))}
      </div>
    </div>
  );
};

ErrorStatusMessage.propTypes = {
  error: PropTypes.object,
  setError: PropTypes.func,
};

export default ErrorStatusMessage;
