import React, { useEffect, useState } from 'react';
import useForm from '../../../../common/hooks/useForm/useForm';
import Message from '../../../../common/layout/Message/Message';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import FormSection from '../../../../components/FormSection/FormSection';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import FieldList from '../../../../components/FieldList/FieldList';
import FormField from '../../../../components/FormField/FormField';
import './VehicleForm.scss';
import ValueInput from '../../../../common/input/ValueInput/ValueInput';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { Link } from 'react-router-dom';
import Vehicle from '../../../../api/controllers/Movement/Vehicle/Vehicle';
import { humanize } from '../../../../utils/EnumHelper/EnumHelper';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import { useParams, useNavigate } from 'react-router';
import GenericDropdown from '../../../../common/GenericDropdown/GenericDropdown';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Driver from '../../../../api/controllers/Movement/Driver/Driver';

const VehicleForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { form, handleForm, alter } = useForm();
  const [error, setError] = useState();
  const [kinds, setKinds] = useState();
  const [drivers, setDrivers] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const handleErrors = json => {
    setLoading(false);
    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };
  useEffect(() => {
    Vehicle.kinds().then(json => setKinds(json));
    Driver.all({ archived: false }).then(json => setDrivers(json.items));
    if (!id) return initCreate();
    Vehicle.show(id).then(
      json => {
        setData(json);
        initUpdate(json);
      },
      json => handleErrors(json),
    );
  }, []);

  const initUpdate = json => {
    document.title = `Editing ${json?.reference}`;
    handleForm({
      id: json.id || null,
      reference: json.reference || '',
      kind: json.kind || '',
      weightLimitKg: json.weightLimitKg || '',
      lengthCm: json.lengthCm || null,
      widthCm: json.widthCm || null,
      heightCm: json.heightCm || null,
      baseCost: json.baseCost || null,
      milesPerGallon: json.milesPerGallon || null,
      manualSelectionOnly: json.manualSelectionOnly || false,
      usesKilometres: json.usesKilometres || false,
      driver: json.driver || null,
      archived: json.archived || false,
    });
  };

  const initCreate = () => {
    document.title = 'New Vehicle';
    handleForm({
      id: null,
      reference: '',
      kind: '',
      weightLimitKg: '',
      lengthCm: null,
      widthCm: null,
      heightCm: null,
      baseCost: null,
      milesPerGallon: null,
      manualSelectionOnly: false,
      usesKilometres: false,
      driverId: null,
      driver: null,
      archived: false,
    });
  };

  const handleSuccess = json => {
    setLoading(false);
    navigate(`/vehicles/${json.id}`);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    if (id) return Vehicle.update(id, form).then(handleSuccess, handleErrors);
    Vehicle.create(form).then(handleSuccess, handleErrors);
  };

  const showOptionalElements = () => (
    <>
      <Checkbox
        checked={form.manualSelectionOnly}
        onClick={() => alter('manualSelectionOnly', !form.manualSelectionOnly)}
        label="Manual Selection Only"
        reverse
      />
      <Checkbox
        checked={form.usesKilometres}
        onClick={() => alter('usesKilometres', !form.usesKilometres)}
        label="Kilometres"
        reverse
      />
      <Checkbox
        checked={form.archived}
        onClick={() => {
          alter('archived', !form.archived);
          clearDriver();
        }}
        label="Archived"
        reverse
      />
    </>
  );

  const clearDriver = () => {
    alter('driverId', null);
    alter('driver', null);
  };

  if (!form) return null;
  const cancelUrl = id ? `/vehicles/${id}` : '/vehicles';
  const breadcrumbs = id ? (
    <Breadcrumbs>
      <Link to={'/vehicles'}>Vehicle</Link>
      <Link to={`/vehicles/${id}`}>{data ? `${data.reference}` : id}</Link>
      <Link to={`/vehicles/${id}/edit`}>Edit</Link>
    </Breadcrumbs>
  ) : (
    <Breadcrumbs>
      <Link to={'/vehicles'}>Vehicle</Link>
      <Link to={'/vehicles/new'}>New</Link>
    </Breadcrumbs>
  );

  if (!form) return <LoadingSpinner />;
  return (
    <div className={'vehicle-form'}>
      {breadcrumbs}

      <div className={'page-content'}>
        <Message type={'error'} text={error?.text} onClose={() => setError()} visible={!!error} />
        <h1>{id ? `Editing ${data?.reference}` : 'New Vehicle'}</h1>

        <TabGroup labels={['General']}>
          <div className={'tab-content'}>
            <FormSection title={'Details'} optionalElement={showOptionalElements()}>
              <FieldList>
                <FormField label={'Reference'} className={'short'}>
                  <input
                    value={form.reference || ''}
                    onChange={e => alter('reference', e.target.value)}
                    autoFocus
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label={'Type'} className={'short'}>
                  <GenericDropdown
                    value={form.kind || ''}
                    items={kinds}
                    onDisplay={kind => `${humanize(kind)}`}
                    onSelect={kind => alter('kind', kind)}
                    testId={'kindDropdown'}
                  />
                </FormField>
                <FormField label={'Driver'} className={'short'}>
                  <GenericDropdown
                    value={form.driver || ''}
                    items={drivers}
                    onSelect={driver => {
                      alter('driverId', driver.id);
                      alter('driver', driver);
                    }}
                    onDisplay={driver =>
                      form?.archived
                        ? ''
                        : `${humanize(driver.firstName)} ${humanize(driver.lastName)}`
                    }
                    onClear={() => {
                      alter('driverId', null);
                      alter('driver', null);
                    }}
                    testId={'driver-dropdown'}
                    disabled={form?.archived}
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label={'Miles-Per-Gallon'} className={'short'}>
                  <input
                    type={'number'}
                    value={form.milesPerGallon || ''}
                    min={0.1}
                    step={0.1}
                    onChange={e => alter('milesPerGallon', e.target.value)}
                  />
                </FormField>
                <FormField label={'Base Cost'} className={'short'}>
                  <ValueInput
                    value={form.baseCost || ''}
                    unit={'£'}
                    reverse
                    min={0}
                    step={0.01}
                    onChange={e => alter('baseCost', e.target.value)}
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label={'Capacity'} className={'short'}>
                  <ValueInput
                    unit={'KG'}
                    value={form.weightLimitKg || ''}
                    onChange={e => alter('weightLimitKg', e.target.value)}
                    min={1}
                  />
                </FormField>
                <FormField label={'Dimensions (L/W/H)'} className={'short'}>
                  <div className={'group'}>
                    <ValueInput
                      unit={'CM'}
                      value={form.lengthCm || ''}
                      onChange={e => alter('lengthCm', e.target.value)}
                      min={1}
                    />
                    <ValueInput
                      unit={'CM'}
                      value={form.widthCm || ''}
                      onChange={e => alter('widthCm', e.target.value)}
                      min={1}
                    />
                    <ValueInput
                      unit={'CM'}
                      value={form.heightCm || ''}
                      onChange={e => alter('heightCm', e.target.value)}
                      min={1}
                    />
                  </div>
                </FormField>
              </FieldList>
            </FormSection>
          </div>
        </TabGroup>
        <div className="button-group">
          <IconButton
            text={'Save'}
            icon={faCheck}
            onClick={e => handleSubmit(e)}
            loading={loading}
          />
          <IconButton
            text={'Cancel'}
            icon={faTimes}
            onClick={() => navigate(cancelUrl)}
            className="edit"
          />
        </div>
      </div>
    </div>
  );
};

VehicleForm.propTypes = {};

export default VehicleForm;
