import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import './ReportRow.scss';

const ReportRow = ({ row, handleRedirect }) => (
  <tr className="report-row" data-testid="report-row">
    <td className="small">
      <span className="rr-link" onClick={() => handleRedirect(row)}>
        {row.name}
      </span>
    </td>
    <td>{row.description}</td>
    <td data-testid="rr-date-req" className="tiny">
      {<FontAwesomeIcon icon={row.defaultDateField ? faCheck : faTimes} />}
    </td>
  </tr>
);

ReportRow.propTypes = {
  row: PropTypes.object.isRequired, // A single Reports::Standard object
  handleRedirect: PropTypes.func.isRequired, // Handles special logic upon redirect
};

export default ReportRow;
