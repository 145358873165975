import React from 'react';
import PropTypes from 'prop-types';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './VehicleTopBar.scss';
import logoDev from '../../../../../images/logo/transswift-development.png';
import logo from '../../../../../images/logo/transswift.png';
import { Link } from 'react-router-dom';
import VehicleGuide from '../VehicleGuide/VehicleGuide';
import VehicleGuideField from '../VehicleGuideField/VehicleGuideField';
import LoadingSpinner from '../../../../../common/LoadingSpinner/LoadingSpinner';

// The top navbar for vehicle run planner.
const VehicleTopBar = ({ disconnected, reconnect, loading }) => (
  <div className="vehicle-top-bar">
    <div className="top-bar-section">
      <Link
        className={`company-logo ${process.env.REACT_APP_ENV !== 'production' && 'development'}`}
        to={'/'}
      >
        <img src={process.env.REACT_APP_ENV === 'production' ? logo : logoDev} />
      </Link>
      {disconnected && (
        <div className="not-connected">
          <VehicleGuideField
            icon={faExclamationTriangle}
            text="Not Connected"
            className="connection"
          />
          <button className="retry-button" onClick={reconnect}>
            Retry?
          </button>
          {loading && <LoadingSpinner />}
        </div>
      )}
    </div>
    <div className="top-bar-section">
      <VehicleGuide />
    </div>
  </div>
);

VehicleTopBar.propTypes = {
  reconnect: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  disconnected: PropTypes.bool.isRequired,
};

export default VehicleTopBar;
