import React from 'react';
import PropTypes from 'prop-types';
import FieldList from '../FieldList/FieldList';
import FormField from '../FormField/FormField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import GenericDropdown from '../../common/GenericDropdown/GenericDropdown';
import { contactMethodLabels } from '../../strings/enums';

const inputPatterns = {
  get email() {
    return {
      pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,4}$', //the regex for determining if am email is valid on submit
      placeholder: 'example@example.com',
      type: 'email',
    };
  },

  get default() {
    return { pattern: null, placeholder: '' };
  },
};

const IterativeInput = ({
  idx,
  item,
  onSelect,
  fieldLabel,
  dropdownVal,
  inputVal,
  onInputChange,
  handleMinus,
  disabled = false,
}) => {
  const { placeholder, type } = inputPatterns[item.kind] || inputPatterns.default;
  const acceptedChars = {
    9: '[0-9]',
    p: '[+0-9]',
  };

  return (
    <FieldList>
      <FormField label={fieldLabel} className="short">
        <GenericDropdown
          value={dropdownVal}
          items={Object.keys(contactMethodLabels)}
          onDisplay={ck => contactMethodLabels[ck] || ck}
          onSelect={ck => onSelect(idx, ck)}
          disabled={item._destroy}
        />
      </FormField>
      <FormField className="short blank">
        <div className={'if-wrap'}>
          {item.kind === 'mobile_phone' || item.kind === 'landline_phone' ? (
            <InputMask
              mask="p9999999999999"
              maskChar=""
              formatChars={acceptedChars}
              value={inputVal}
              disabled={item._destroy}
              placeholder={'07123456789'}
              onChange={onInputChange}
            />
          ) : (
            <input
              value={inputVal}
              onChange={onInputChange}
              disabled={item._destroy}
              type={type}
              placeholder={placeholder}
            />
          )}
          <button title={'minus'} onClick={handleMinus} className={'minus'} disabled={disabled}>
            <FontAwesomeIcon icon={faMinus} />
          </button>
        </div>
      </FormField>
    </FieldList>
  );
};

IterativeInput.propTypes = {
  idx: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  fieldLabel: PropTypes.string,
  dropdownVal: PropTypes.string,
  inputVal: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  handleMinus: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default IterativeInput;
