import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './Profile.scss';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Message from '../../common/layout/Message/Message';
import TabGroup from '../../components/TabGroup/TabGroup';
import RichText from '../../components/RichText/RichText';
import User from '../../api/controllers/User/User';
import {
  draftjsToHtml,
  handleDefaultValue,
} from '../../components/RichText/helpers/contentHelper/contentHelper';
import IconButton from '../../common/button/IconButton/IconButton';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { formatError } from '../../utils/Formatters/Errors/formatError';

const Profile = ({ user, onUserChange }) => {
  const [message, setMessage] = useState();
  const [editorState, setEditorState] = useState(() => handleDefaultValue(user?.emailSignature));
  const editorRef = useRef(null);
  const handleEmailSignature = e => {
    e.preventDefault();
    const signature = draftjsToHtml(editorState);
    User.updateCurrent({ emailSignature: signature }).then(
      user => {
        onUserChange(user);
        setMessage({ type: 'success', text: 'You updated your Email Signature successfully.' });
      },
      err => setMessage({ type: 'error', text: formatError(err) }),
    );
  };

  document.title = 'My Profile';

  return (
    <div className={'profile-page'}>
      <Breadcrumbs>
        <Link to={'/profile'}>My Profile</Link>
      </Breadcrumbs>
      <div className="page-content">
        <Message
          text={message?.text}
          type={message?.type}
          onClick={() => setMessage()}
          visible={!!message}
        />
        <h1>My Profile</h1>

        <TabGroup labels={['Email']}>
          <div className="tab-content">
            <div className="rich-text-hat">Email Signature</div>
            <RichText
              placeholder="Please enter your email signature..."
              editorState={editorState}
              setEditorState={setEditorState}
              editorRef={editorRef}
            />
          </div>
        </TabGroup>
        <div className={'button-group'}>
          <IconButton icon={faCheck} onClick={handleEmailSignature} text={'Save Changes'} />
        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  user: PropTypes.object,
  onUserChange: PropTypes.func,
};

export default Profile;
