import React, { useState } from 'react';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import IconButton from '../../../../common/button/IconButton/IconButton';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import EntityApi from '../../../../api/controllers/Company/Entity/Entity';
import { Link } from 'react-router-dom';
import './EntityIndex.scss';
import SelectorDropdown from '../../../../components/SelectorDropdown/SelectorDropdown';
import useArray from '../../../../common/hooks/useArray/useArray';
import { labelForEnum } from '../../../../utils/EnumHelper/EnumHelper';
import { entityStatuses } from '../../../../strings/enums';
import NewEntity from '../../../../components/modals/NewEntity/NewEntity';
import useAjaxTable from '../../../../common/hooks/ajaxHooks/useAjaxTable/useAjaxTable';
import TablePresenter from '../../../../components/Table/TablePresenter/TablePresenter';
import AddressHelper from '../../../../utils/AddressHelper/AddressHelper';
import ErrorBoundary from '../../../../common/ErrorBoundary/ErrorBoundary';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router';

const headers = [
  { text: 'Name', sortingAttribute: 'internalName' },
  { text: 'Main Address', sortingAttribute: 'address' },
  { text: 'Postcode', sortingAttribute: 'postcode' },
  { text: 'Status' },
];

const EntityIndex = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { categories, setCategories } = useArray({
    name: 'categories',
    defaultValue: ['All'],
  });
  open ? (document.title = 'New Entity') : (document.title = 'All Entities');

  const handleCategoryToggle = val => {
    if (val === categories[0]) return setCategories('All');
    return setCategories(val);
  };

  const updateQueryParams = option => {
    navigate({
      search: `filter=${option === categories[0] ? 'All' : option}`,
    });
  };

  const handleFilter = () => {
    if (!categories || categories.length === 0) return {};
    let roleObj = {};
    if (categories.includes('Customers')) roleObj.customer = true;
    if (categories.includes('Suppliers')) roleObj.supplier = true;
    if (categories.includes('Forwarders')) roleObj.forwarder = true;
    return roleObj;
  };

  const { data, sort, onSort, onSearch, pageElements, pageText, refresh, loading } = useAjaxTable({
    call: EntityApi.all,
    addParams: { ...handleFilter() },
    dependencies: [categories],
    defaultSort: { sort: headers[0].sortingAttribute, order: 'asc' },
  });

  const handleDrawRow = (item, i) => {
    const { mainAddress } = item;
    const { line1, line2, line3, line4, postcode, region } = mainAddress;
    return (
      <tr key={i} className={i % 2 === 0 ? '' : 'coloured'}>
        <td>
          <Link to={`/companies/entities/${item.id}`}>{item.internalName}</Link>
        </td>
        <td>{AddressHelper.formatAddress([line1, line2, line3, line4, region, postcode])}</td>
        <td className="small">{item.mainAddress.postcode}</td>
        <td className="small">{labelForEnum(entityStatuses, item.status)}</td>
      </tr>
    );
  };

  const onSuccess = json => {
    refresh();
    navigate(`/companies/entities/${json.id}/edit`);
  };

  return (
    <div className="all-entities">
      <NewEntity onSuccess={onSuccess} open={open} setOpen={setOpen} />
      <Breadcrumbs>
        <Link to={'/companies/entities'}>Entities</Link>
      </Breadcrumbs>
      <Ribbon>
        <IconButton text={'Add Entity'} icon={faPlus} onClick={() => setOpen(true)} />
      </Ribbon>
      {data && (
        <ErrorBoundary>
          <TablePresenter
            title={'Entities'}
            headers={headers}
            rows={data.items}
            onDrawRow={handleDrawRow}
            sort={sort}
            onSort={onSort}
            onSearch={onSearch}
            pageElements={pageElements}
            pageText={pageText}
          >
            <SelectorDropdown
              options={['All', 'Customers', 'Suppliers', 'Forwarders']}
              items={categories}
              toggle={option => {
                handleCategoryToggle(option);
                updateQueryParams(option);
              }}
              set={setCategories}
            />
          </TablePresenter>
        </ErrorBoundary>
      )}
      {loading && <LoadingSpinner />}
    </div>
  );
};

EntityIndex.propTypes = {};

export default EntityIndex;
