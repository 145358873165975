import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ConsignmentFlagIcon.scss';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

const ConsignmentFlagIcon = ({
  icon = faQuestion,
  colour = 'var(--text)',
  title,
  description = null,
}) => {
  if (!description)
    return (
      <div className="flag-icon" style={{ color: colour }} title={title}>
        <FontAwesomeIcon icon={icon} style={{ verticalAlign: '0' }} />
      </div>
    );
  return (
    <div className="hover-cell">
      <div className="flag-icon" style={{ color: colour }}>
        <FontAwesomeIcon icon={icon} style={{ verticalAlign: '0' }} />
      </div>
      <div className="hover-cell-info cube-info">
        <div className="top">Restrictions</div>
        <div className="main">
          {_.map(description, ({ title, text }) => (
            <div key={title} style={{ marginBottom: '10px' }}>
              <strong>{title}:</strong>
              <br />
              {text != '' ? text : '--'}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ConsignmentFlagIcon.propTypes = {
  icon: PropTypes.object,
  colour: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.object),
};

export default ConsignmentFlagIcon;
