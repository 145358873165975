/*
  An abstract Action class. 
  This is not to be called directly, but the be extended 
  with the intension of having an execute and undo function, 
  which perform some action
*/
class Action {
  constructor() {
    if (new.target === Action)
      throw new TypeError('Abstract classes cannot be directly constructed.');
  }

  static execute() {}

  static undo() {}
}

export default Action;
