import { useEffect, useState } from 'react';

const useApi = ({
  call,
  params,
  dependencies = [],
  name = 'data',
  condition = true,
  handleError = e => console.log(name, 'useApi', e),
}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(!!condition);
  const [timer, setTimer] = useState(); // In seconds
  const capitalName = `${name.slice(0, 1).toUpperCase()}${name.slice(1)}`;
  const refreshName = name === 'data' ? 'refresh' : `refresh${capitalName}`;

  const fetchData = () => {
    if (!condition) return;
    const time = new Date().getTime();
    setLoading(true);
    return call(params)
      .then(
        json => setData(json),
        json => handleError(json),
      )
      .finally(() => {
        setTimer((new Date().getTime() - time) / 1000);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, dependencies);

  return {
    [refreshName]: fetchData,
    [name]: data,
    [`${name}Loading`]: loading,
    [`handle${capitalName}`]: setData,
    [`${name}Timer`]: timer,
  };
};

export default useApi;
