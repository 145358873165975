import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import LinkBar from '../../common/sidebar/LinkBar/LinkBar';
import './LeftBar.scss';
import Folder from '../../common/sidebar/Folder/Folder';
import useArray from '../../common/hooks/useArray/useArray';
import {
  faBriefcase,
  faCube,
  faPoundSign,
  faUserShield,
  faUser,
  faUserFriends,
  faCog,
  faSignOutAlt,
  faStarOfLife,
  faList,
  faCircle,
  faPlusSquare,
  faCubes,
  faFileInvoice,
  faReceipt,
  faCreditCard,
  faFileAlt,
  faFolderOpen,
  faEnvelope,
  faTruck,
  faCalendarDays,
  faInfoCircle,
  faSignsPost,
  faCalculator,
  faSackDollar,
  faTable,
  faGasPump,
  faSync,
  faLayerGroup,
  faPieChart,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewEntity from '../modals/NewEntity/NewEntity';
import CheckPermit from '../../common/CheckPermit/CheckPermit';

/*
  NOTE
  ID's of top level folders must be strings and subfolders must be numbers
  this is due to how we differentiate between them for the interactible stuff
*/
const LeftBar = ({ user, leftClosed, permissions, handleLogout }) => {
  const { openFolders, handleOpenFolders, toggleOpenFolders, clearTypeOpenFolders } = useArray({
    name: 'openFolders',
  });
  const navigate = useNavigate();
  const nodeRef = createRef();
  const [open, setOpen] = useState(false);

  /* When the user minimizes the menu, all folders will close */
  useEffect(() => {
    clearTypeOpenFolders('string');
  }, [leftClosed]);

  /* Regularly toggles id for numbers, clears all strings if the id is a string */
  /* The reason for this is that all top-level folders are differentiated by being strings */
  const handleToggle = id => {
    if (typeof id === 'string' && !openFolders.includes(id)) {
      const removeStrings = openFolders.filter(folder => typeof folder !== 'string');
      return handleOpenFolders([...removeStrings, id]);
    }
    toggleOpenFolders(id);
  };

  const handleCog = () => navigate('/profile');

  const standardProps = { toggle: handleToggle, openFolders };

  const onSuccess = json => navigate(`/companies/entities/${json.id}/edit`);

  return (
    <CSSTransition nodeRef={nodeRef} in={leftClosed} timeout={300} classNames="left-bar-transition">
      <div id={'left-bar'} className="left-bar" ref={nodeRef}>
        <div className={'lb-user'}>
          <div className={'lbu-icon'}>
            <div className={'lbu-circle'}>
              <FontAwesomeIcon icon={faUser} />
            </div>
          </div>
          <div className={'lbu-options'}>
            <div className={'lbu-name'}>{`${user.firstName} ${user.lastName}`}</div>
            <div className={'lbu-buttons'}>
              <button className={'lbu-button'} onClick={handleCog} data-testid={'left-bar-cog'}>
                <FontAwesomeIcon icon={faCog} />
              </button>
              <button data-testid={'lb-logout'} className={'lbu-button'} onClick={handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </button>
            </div>
          </div>
        </div>
        <CheckPermit type={'companiesEntities'} action={'read'} permissions={permissions}>
          <Folder {...standardProps} icon={faBriefcase} text={'Entities'} leftClosed={leftClosed}>
            <LinkBar
              icon={faStarOfLife}
              text={'View All'}
              link={{ pathname: '/companies/entities', search: 'filter=All' }}
            />
            <Folder {...standardProps} id={94} icon={faList} text={'Show Only'}>
              <LinkBar
                icon={faCircle}
                text={'Customers'}
                size={'xs'}
                link={{
                  pathname: '/companies/entities',
                  search: 'filter=Customers',
                }}
              />
              <LinkBar
                icon={faCircle}
                text={'Suppliers'}
                size={'xs'}
                link={{
                  pathname: '/companies/entities',
                  search: 'filter=Suppliers',
                }}
              />
              <LinkBar
                icon={faCircle}
                size={'xs'}
                text={'Forwarders'}
                link={{
                  pathname: '/companies/entities',
                  search: 'filter=Forwarders',
                }}
              />
            </Folder>
            <CheckPermit type={'companiesEntities'} action={'create'} permissions={permissions}>
              <LinkBar icon={faPlusSquare} text={'Create'} onClick={() => setOpen(true)} />
            </CheckPermit>
            <NewEntity onSuccess={onSuccess} open={open} setOpen={setOpen} />
          </Folder>
        </CheckPermit>
        <CheckPermit type={'jobsBookings'} action={'read'} permissions={permissions}>
          <Folder {...standardProps} icon={faCube} text={'Jobs'} leftClosed={leftClosed}>
            <LinkBar
              icon={faStarOfLife}
              text={'View All'}
              link={{ pathname: '/jobs/bookings', search: 'filter=All+Jobs' }}
            />
            <LinkBar
              icon={faCubes}
              text={'My Jobs'}
              link={{ pathname: '/jobs/bookings', search: 'filter=My+Jobs' }}
            />
            <CheckPermit type={'jobsBookings'} action={'create'} permissions={permissions}>
              <LinkBar
                icon={faPlusSquare}
                text={'Create'}
                link={{ pathname: '/jobs/bookings/new' }}
              />
            </CheckPermit>
          </Folder>
        </CheckPermit>
        <CheckPermit type={'movementsVehicleRuns'} action={'read'} permissions={permissions}>
          <Folder {...standardProps} icon={faTruck} text={'Movements'} leftClosed={leftClosed}>
            <LinkBar
              icon={faCalendarDays}
              text={'Vehicle Run Planner'}
              link={{ pathname: '/movements/vehicle_runs' }}
              target={'_blank'}
            />
            <LinkBar
              icon={faStarOfLife}
              text={'Vehicle Runs'}
              link={{ pathname: '/movements/runs' }}
            />
          </Folder>
        </CheckPermit>
        <CheckPermit type={'financialsDocuments'} action={'read'} permissions={permissions}>
          <Folder {...standardProps} icon={faPoundSign} text={'Financial'} leftClosed={leftClosed}>
            <LinkBar
              icon={faLayerGroup}
              text={'Bulk Sales Invoices'}
              link={{ pathname: '/financials/bulk_sales_invoices' }}
            />
            <LinkBar
              icon={faFileInvoice}
              text={'Sales Invoices'}
              link={{ pathname: '/financials/sales_invoices' }}
            />
            <LinkBar
              icon={faFileAlt}
              text={'Sales Credits'}
              link={{ pathname: '/financials/sales_credit_notes' }}
            />
            <LinkBar
              icon={faReceipt}
              text={'Purchase Invoices'}
              link={{ pathname: '/financials/purchase_invoices' }}
            />
            <LinkBar
              icon={faCreditCard}
              text={'Purchase Credits'}
              link={{ pathname: '/financials/purchase_credit_notes' }}
            />
            <LinkBar
              icon={faSync}
              text={'Accounts Sync'}
              link={{ pathname: '/financials/accounts_sync' }}
            />
          </Folder>
        </CheckPermit>
        <CheckPermit type={'tariffsAreas'} action={'create'} permissions={permissions}>
          <Folder {...standardProps} icon={faCalculator} text={'Tariffs'} leftClosed={leftClosed}>
            <LinkBar
              icon={faTable}
              text={'Manage Tariff'}
              link={{ pathname: '/tariffs/band_management' }}
            />
            <LinkBar
              icon={faSignsPost}
              text={'Manage Areas'}
              link={{ pathname: '/tariffs/areas' }}
            />
            <LinkBar
              icon={faSackDollar}
              text={'Manage Surcharges'}
              link={{ pathname: '/tariffs/charges' }}
            />
          </Folder>
        </CheckPermit>
        <Folder {...standardProps} icon={faInfoCircle} text={'Information'} leftClosed={leftClosed}>
          <LinkBar icon={faPieChart} text={'Reports'} link={{ pathname: '/reports/standard' }} />
          <LinkBar
            icon={faFolderOpen}
            text={'Virtual Filing Cabinet'}
            link={{ pathname: '/virtual_filing_cabinet' }}
          />
          <CheckPermit type={'jobsPackageTypes'} action={'create'} permissions={permissions}>
            <LinkBar
              icon={faCubes}
              text={'Package Types'}
              link={{ pathname: '/jobs/package_types' }}
            />
          </CheckPermit>
          <CheckPermit type={'movementsDrivers'} action={'update'} permissions={permissions}>
            <LinkBar icon={faUsers} text={'Drivers'} link={{ pathname: '/drivers' }} />
          </CheckPermit>
          <CheckPermit type={'movementsVehicles'} action={'read'} permissions={permissions}>
            <LinkBar icon={faTruck} text={'Vehicles'} link={{ pathname: '/vehicles' }} />
          </CheckPermit>
        </Folder>
        <CheckPermit type={'financialsChargeCodes'} action={'create'} permissions={permissions}>
          <Folder {...standardProps} icon={faUserShield} text={'Admin'} leftClosed={leftClosed}>
            <CheckPermit type={'financialsChargeCodes'} action={'create'} permissions={permissions}>
              <LinkBar
                icon={faList}
                text={'Charge Codes'}
                link={{ pathname: '/financials/charge_codes' }}
              />
            </CheckPermit>
            <CheckPermit type={'movementsFuelPrices'} action={'create'} permissions={permissions}>
              <LinkBar icon={faGasPump} text={'Fuel Costs'} link={{ pathname: '/fuel_costs' }} />
            </CheckPermit>
            <CheckPermit type={'users'} action={'create'} permissions={permissions}>
              <LinkBar
                icon={faEnvelope}
                text={'Email Templates'}
                link={{ pathname: '/emails/templates' }}
              />
            </CheckPermit>
            <CheckPermit type={'users'} action={'create'} permissions={permissions}>
              <LinkBar icon={faUserFriends} text={'Users'} link={{ pathname: '/users' }} />
            </CheckPermit>
          </Folder>
        </CheckPermit>
      </div>
    </CSSTransition>
  );
};

LeftBar.propTypes = {
  user: PropTypes.object,
  leftClosed: PropTypes.bool,
  permissions: PropTypes.object,
  handleLogout: PropTypes.func.isRequired,
};

export default LeftBar;
