import apiCall from '../../apiCall/apiCall';
import buildGetQuery from '../../helpers/buildGetQuery/buildGetQuery';

class Report {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'reports/standard', data);
  }
  static show(id, params) {
    const data = buildGetQuery(params);
    return apiCall('GET', `reports/standard/${id}`, data);
  }
}

export default Report;
