import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../components/FormSection/FormSection';
import FieldList from '../../../../../../components/FieldList/FieldList';
import FormField from '../../../../../../components/FormField/FormField';

const InvoiceFormDetails = ({ entityReference, issueDate, dueDate, alter }) => {
  return (
    <FormSection title={'Details'}>
      <FieldList>
        <FormField label={'Their Reference'} className={'short'}>
          <input value={entityReference} onChange={e => alter('entityReference', e.target.value)} />
        </FormField>
        <FormField label={'Issue Date'} className={'short'}>
          <input type="date" value={issueDate} onChange={e => alter('issueDate', e.target.value)} />
        </FormField>
        <FormField label={'Due Date'} className={'short'}>
          <input type="date" value={dueDate} onChange={e => alter('dueDate', e.target.value)} />
        </FormField>
      </FieldList>
    </FormSection>
  );
};

InvoiceFormDetails.propTypes = {
  entityReference: PropTypes.string,
  issueDate: PropTypes.string,
  dueDate: PropTypes.string,
  alter: PropTypes.func,
};

export default InvoiceFormDetails;
