import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

Link.propTypes = {
  contentState: PropTypes.any,
  entityKey: PropTypes.any,
  children: PropTypes.any,
};

export default Link;
