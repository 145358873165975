import React, { useEffect, useState } from 'react';
import '../../Document/InvoiceForm/InvoiceForm.scss';
import SalesCredit from '../../../../api/controllers/Financial/SalesCredit/SalesCredit';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import DocumentEdit from '../../Document/DocumentEdit/DocumentEdit';
import { useParams } from 'react-router';

const SalesCreditNoteEdit = () => {
  const { id } = useParams();
  const [error, setError] = useState();
  const [lineItems, setLineItems] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const handleErrors = json => {
    loading && setLoading(false);
    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };

  useEffect(() => {
    SalesCredit.show(id).then(
      json => {
        setData(json);
        const parent = json.parent
          ? {
              parentId: json.parent?.id,
              parentType: json.parent?.modelName,
            }
          : {};
        SalesCredit.lineItems({
          entityId: json.entity.id,
          documentId: id,
          ...parent,
        }).then(
          json => {
            setLineItems(json.items);
            setLoading(false);
          },
          json => handleErrors(json),
        );
      },
      json => handleErrors(json),
    );
  }, []);

  return (
    <DocumentEdit
      formData={data}
      lineItems={lineItems}
      error={error}
      setError={setError}
      title={'Sales Credit Note'}
      index={'/financials/sales_credit_notes/'}
      loading={loading}
    />
  );
};

SalesCreditNoteEdit.propTypes = {};

export default SalesCreditNoteEdit;
