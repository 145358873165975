import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../components/FormSection/FormSection';
import FieldList from '../../../../../../components/FieldList/FieldList';
import FormField from '../../../../../../components/FormField/FormField';
import ShowField from '../../../../../../components/ShowField/ShowField';
import DateHelper from '../../../../../../utils/DateHelper/DateHelper';
import BookingShowAdvancedVehicleRun from './BookingShowAdvancedVehicleRun/BookingShowAdvancedVehicleRun';

const BookingShowAdvanced = ({ data, setError }) => (
  <div className={'tab-content'}>
    <FormSection title={'Consignment Options'}>
      <div className={'checkbox-line'}>
        <ShowField label={'Do not stack'} value={data.doNotStack} />
        <ShowField label={'Do not transship'} value={data.doNotTransship} />
        <ShowField label={'Do not part load'} value={data.doNotPartLoad} />
        <ShowField label={'Do not break down'} value={data.doNotBreakDown} />
      </div>
      <div className={'checkbox-line'}>
        <ShowField label={'Tail lift on collection'} value={data.tailLiftOnCollection} />
        <ShowField label={'Tail lift on delivery'} value={data.tailLiftOnDelivery} />
        <ShowField label={'Overhead load'} value={data.overheadLoad} />
        <ShowField label={'Overhead unload'} value={data.overheadUnload} />
      </div>
      <div className={'checkbox-line'}>
        <ShowField label={'Timed collection'} value={data.timedCollection} />
        <ShowField label={'Timed delivery'} value={data.timedDelivery} />
        <ShowField label={'Waste'} value={data.waste} />
        <ShowField label={'Foodstuffs'} value={data.foodstuffs} />
      </div>
    </FormSection>
    <FormSection title={'Miscellaneous'}>
      <FieldList>
        <FormField label={'Booking Date'} className={'short'}>
          <input value={DateHelper.dateOnly(data.bookingDate)} readOnly />
        </FormField>
        <FormField label={'Additional Details'} className={'short'}>
          <input value={data.additionalInformation} readOnly />
        </FormField>
      </FieldList>
    </FormSection>
    <BookingShowAdvancedVehicleRun job={data} setError={setError} />
  </div>
);

BookingShowAdvanced.propTypes = {
  data: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
};

export default BookingShowAdvanced;
