import React from 'react';
import './TemplateForm.scss';
import PropTypes from 'prop-types';
import FormField from '../../../../../components/FormField/FormField';
import RichText from '../../../../../components/RichText/RichText';
import EmailHelper from '../../../../../utils/EmailHelper/EmailHelper';

const TemplateForm = ({
  user,
  editorState,
  setEditorState,
  name,
  setName,
  subject,
  setSubject,
  subjectInput,
  editorRef,
  setEditorFocused,
}) => {
  return (
    <div className="email-template">
      <div className="form-section">
        <div className="form-section-head">
          <div>Email Template</div>
        </div>
        <div className="template-form">
          <div className="form-header">
            <FormField label={'Name'} className={'single'}>
              <input value={name} onChange={e => setName(e.target.value)} />
            </FormField>
            <FormField label={'Subject'} className={'single'}>
              <input
                value={subject}
                ref={subjectInput}
                data-testid="subject-input"
                onFocus={() => setEditorFocused(false)}
                onChange={e => setSubject(e.target.value)}
              />
            </FormField>
          </div>
          <div className="salutation">
            <div dangerouslySetInnerHTML={{ __html: EmailHelper.getSalutation(' John') }}></div>
          </div>
          <div className="rich-text">
            <RichText
              editorState={editorState}
              setEditorState={setEditorState}
              editorRef={editorRef}
              onFocus={() => setEditorFocused(true)}
            />
          </div>
          <div className="signature">
            <div dangerouslySetInnerHTML={{ __html: user.emailSignature }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

TemplateForm.propTypes = {
  user: PropTypes.object,
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  subject: PropTypes.string,
  setSubject: PropTypes.func,
  subjectInput: PropTypes.object,
  editorRef: PropTypes.object,
  setEditorFocused: PropTypes.func,
};

export default TemplateForm;
