import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './LoadingCog.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faWarning } from '@fortawesome/free-solid-svg-icons';

// TSR-1288: Created for the ReportShow page to show that we are loading
//           a report into the site, then if that fails, show the error
const LoadingCog = ({ loading, errors, children }) => {
  // For clarity within the render method
  const hasError = Array.isArray(errors) && errors.length > 0;
  const showComponent = loading || hasError;

  return (
    <Fragment>
      {showComponent && (
        <div className="loading-cog">
          <div data-testid="loading-cog" className="lc-content">
            <FontAwesomeIcon icon={loading ? faCog : faWarning} />
            <div className="lc-text">
              {loading ? 'Fetching results from server' : `Error: ${errors.join(' / ')}`}
            </div>
          </div>
        </div>
      )}
      {!loading && !errors && children}
    </Fragment>
  );
};

LoadingCog.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

export default LoadingCog;
