import React from 'react';
import PropTypes from 'prop-types';
import useApi from '../../../../../../../common/hooks/useApi/useApi';
import VehicleRun from '../../../../../../../api/controllers/Movement/VehicleRun/VehicleRun';
import { formatError } from '../../../../../../../utils/Formatters/Errors/formatError';
import VehicleRunDetails from '../../../../../../../components/VehicleRunDetails/VehicleRunDetails';

/*
  Why? This component will display the information pertinent to the VehicleRun the Booking is attached to
  Notes? StartMileage and EndMileage will be later removed once Financials is implemented
*/
const BookingShowAdvancedVehicleRun = ({ job, setError }) => {
  // If the job is subcontracted then our vehicle wouldn't be used
  // If the vehicle run id is blank then there will be no run assigned to this job
  if (job.subcontracted || job.vehicleRunId === null) return null;

  // If the api call fails then set the error
  const handleError = err => setError(formatError(err));

  // Each job will only ever be assigned to one VehicleRun so we just call show
  const { vehicleRun, vehicleRunLoading } = useApi({
    call: () => VehicleRun.show(job.vehicleRunId),
    name: 'vehicleRun',
    handleError,
  });

  return <VehicleRunDetails run={vehicleRun} loading={vehicleRunLoading} showLink />;
};

BookingShowAdvancedVehicleRun.propTypes = {
  job: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
};

export default BookingShowAdvancedVehicleRun;
