import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NumberHelper from '../../utils/NumberHelper/NumberHelper';
import { capitalize } from 'lodash';
import { formatDetails } from '../../utils/bookingUtils/bookingUtils';
import TablePresenter from '../../components/Table/TablePresenter/TablePresenter';
import DateHelper from '../../utils/DateHelper/DateHelper';
import SelectorDropdown from '../../components/SelectorDropdown/SelectorDropdown';
import useArray from '../hooks/useArray/useArray';
import Booking from '../../api/controllers/Job/Booking/Booking';
import useAjaxTable from '../hooks/ajaxHooks/useAjaxTable/useAjaxTable';
import './JobsTable.scss';
import { useNavigate } from 'react-router';

const JobsTable = ({ children, tableTitle, user, entityId }) => {
  const navigate = useNavigate();
  const { jobTypes, setJobTypes } = useArray({ name: 'jobTypes', defaultValue: ['All'] });
  const [jobFilter, setJobFilter] = useState('All Jobs');

  const handleJobTypeToggle = val => {
    if (val === jobTypes[0]) return setJobTypes('All');
    return setJobTypes(val);
  };

  const handleFilterJobTypes = () => {
    if (!jobTypes || jobTypes.length === 0 || jobTypes.includes('All')) return {};
    return { status: jobTypes[0].toLowerCase() };
  };

  const handleJobsFilter = () => {
    if (jobFilter === 'All Jobs') return {};
    return { ownerId: user.id };
  };

  const updateQueryParams = jobFilter => {
    navigate({
      search: `filter=${jobFilter === 'All Jobs' ? 'My+Jobs' : 'All+Jobs'}`,
    });
  };
  const headers = [
    { text: 'Booked', sortingAttribute: 'bookingDate' },
    { text: 'Number', sortingAttribute: 'jobReference' },
    { text: 'Reference', sortingAttribute: 'customerReference' },
    { text: 'Customer', sortingAttribute: 'customer' },
    { text: 'Collection', sortingAttribute: 'collectionAddress.line1' },
    { text: 'Delivery', sortingAttribute: 'deliveryAddress.line1' },
    { text: 'Details' },
    { text: 'Total Sales Value' },
    { text: 'Status' },
  ];
  const { data, sort, onSort, onSearch, pageElements, pageText, loading } = useAjaxTable({
    call: Booking.all,
    addParams: {
      ...handleFilterJobTypes(),
      ...handleJobsFilter(),
      ...(entityId && { entityId: entityId }),
    },
    dependencies: [jobTypes, jobFilter],
    defaultSort: { sort: headers[0].sortingAttribute, order: 'desc' },
  });
  const handleDrawRow = (item, i) => {
    const customerName = item.customer?.externalName || item.customer?.internalName;
    const { collectionAddress, collectionEntity, deliveryEntity, deliveryAddress } = item;
    const colAddress =
      collectionEntity && `${collectionEntity.internalName} (${collectionAddress?.postcode})`;
    const delAddress =
      deliveryEntity && `${deliveryEntity.internalName} (${deliveryAddress?.postcode})`;

    return (
      <tr key={i} className={i % 2 === 0 ? '' : 'coloured'}>
        <td className="small">{DateHelper.dateOnly(item.bookingDate)}</td>
        <td className="small">
          <Link to={`/jobs/bookings/${item.id}`}>{item.jobReference}</Link>
        </td>
        <td className="reference" title={item.customerReference}>
          {item.customerReference}
        </td>
        <td className="medium">
          <Link to={`/companies/entities/${item.customer?.id}`} target={'_blank'}>
            {customerName}
          </Link>
        </td>
        {collectionEntity ? (
          <td title={colAddress} className="medium">
            <Link to={`/companies/entities/${item.collectionEntity.id}`} target={'_blank'}>
              {colAddress}
            </Link>
          </td>
        ) : (
          <td className="medium">{colAddress}</td>
        )}
        {deliveryEntity ? (
          <td title={delAddress} className="medium">
            <Link to={`/companies/entities/${item.deliveryEntity.id}`} target={'_blank'}>
              {delAddress}
            </Link>
          </td>
        ) : (
          <td className="medium">{delAddress}</td>
        )}
        <td className="medium" title={formatDetails(item)}>
          {formatDetails(item)}
        </td>
        <td className="value">{`${NumberHelper.formatNumToCurrency(item.salesValue)}`}</td>
        <td className="small">{`${capitalize(item.status)}`}</td>
      </tr>
    );
  };

  return (
    <TablePresenter
      title={tableTitle}
      headers={headers}
      rows={data?.items}
      onDrawRow={handleDrawRow}
      sort={sort}
      onSort={onSort}
      onSearch={onSearch}
      pageElements={pageElements}
      pageText={pageText}
      loading={loading}
    >
      <SelectorDropdown
        options={['All', 'Active', 'Pending', 'Cancelled', 'Finalised']}
        items={jobTypes}
        toggle={handleJobTypeToggle}
      />
      <SelectorDropdown
        options={['All Jobs', 'My Jobs']}
        items={[jobFilter]}
        toggle={() => {
          setJobFilter(jobFilter === 'All Jobs' ? 'My Jobs' : 'All Jobs');
          updateQueryParams(jobFilter);
        }}
        set={setJobFilter}
      />
      {children}
    </TablePresenter>
  );
};

JobsTable.propTypes = {
  children: PropTypes.node,
  tableTitle: PropTypes.string,
  user: PropTypes.object,
  entityId: PropTypes.number,
};

export default JobsTable;
