import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import '../../Document/InvoiceForm/InvoiceForm.scss';
import useForm from '../../../../common/hooks/useForm/useForm';
import Sale from '../../../../api/controllers/Financial/Sale/Sale';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import Customer from '../../../../api/controllers/Financial/Customer/Customer';
import DateHelper from '../../../../utils/DateHelper/DateHelper';
import Entity from '../../../../api/controllers/Company/Entity/Entity';
import InvoiceForm from '../../Document/InvoiceForm/InvoiceForm';
import LineItemHelper from '../../../../utils/LineItemHelper/LineItemHelper';
import { useNavigate, useParams } from 'react-router';

const SalesInvoiceNew = ({ bulk = false }) => {
  const navigate = useNavigate();
  const { entityid, bookingid } = useParams();
  const [error, setError] = useState();
  const [warning, setWarning] = useState();
  const [lineItems, setLineItems] = useState([]);
  const [customer, setCustomer] = useState(null);
  const { form, alter, handleForm } = useForm({
    entityId: entityid,
    entityReference: '',
    bookingId: bookingid || null,
    issueDate: DateHelper.today(),
    dueDate: '',
    lineItemsAttributes: [],
  });
  const [loading, setLoading] = useState(true);

  const handleErrors = json => {
    loading && setLoading(false);
    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };

  const entityId = entityid;
  const bookingId = bookingid;

  document.title = `New ${bulk ? 'Bulk ' : ''}Sales Invoice for ${
    customer?.internalName || entityId
  }`;

  useEffect(() => {
    const getData = async () => {
      let dueDate, ref;
      const resEntity = await Entity.show(entityId);
      const parent = bookingId ? { parentId: bookingId, parentType: 'Jobs::Booking' } : {};
      setCustomer(resEntity);
      try {
        const resCustomer = await Customer.show(resEntity.customerAccount);
        dueDate = calDefaultDue(resCustomer);
      } catch (json) {
        setWarning({ ...json, ...{ status: 'Warning' } });
      }
      try {
        const resLineItems = await Sale.lineItems({ entityId, ...parent });
        setLineItems(resLineItems.items);
        ref = resLineItems.items[0]?.parent?.customerReference || '';
      } catch (json) {
        handleErrors(json);
      }
      handleForm({ ...form, ...{ entityReference: ref, dueDate: dueDate }, ...parent });
      setLoading(false);
    };
    getData();
  }, []);

  const calDefaultDue = customer => {
    if (!customer) return DateHelper.today();
    return DateHelper.dueDateCalculate(customer);
  };

  const handleLineItemToggle = itemId => {
    const toggledLines = LineItemHelper.toggleLineNew(form.lineItemsAttributes, itemId);
    alter('lineItemsAttributes', toggledLines);
  };

  const handleSubmit = () => {
    const params = form;
    Sale.create(params).then(
      json =>
        navigate(
          bulk
            ? `/financials/bulk_sales_invoices/${json.id}`
            : `/financials/sales_invoices/${json.id}`,
        ),
      json => handleErrors(json),
    );
  };

  const getCrumbs = () => {
    if (bulk)
      return (
        <Breadcrumbs>
          <Link to={'/financials/bulk_sales_invoices'}>Bulk Sales Invoices</Link>
          <Link to={`/financials/bulk_sales_invoices/new/${entityId}`}>New</Link>
        </Breadcrumbs>
      );

    return (
      <Breadcrumbs>
        <Link to={'/financials/sales_invoices'}>Sales Invoices</Link>
        <Link to={`/financials/sales_invoices/new/${entityId}/${bookingId}`}>New</Link>
      </Breadcrumbs>
    );
  };

  const getTitle = () => {
    if (bulk) {
      return loading
        ? 'Creating Bulk Sales Invoice'
        : `Creating Bulk Sales Invoice for ${customer?.internalName}`;
    }

    return loading
      ? 'Creating Sales Invoice'
      : `Creating Sales Invoice for ${customer?.internalName}`;
  };

  return (
    <div className={'invoice-form'}>
      {getCrumbs()}
      <div className={'page-content'}>
        <InvoiceForm
          error={error}
          setError={setError}
          alter={alter}
          form={form}
          lineItems={lineItems}
          handleSubmit={handleSubmit}
          lineItemToggle={handleLineItemToggle}
          cancelUrl={bulk ? '/financials/bulk_sales_invoices' : '/financials/sales_invoices'}
          title={getTitle()}
          warning={warning}
          setWarning={setWarning}
          loading={loading}
        />
      </div>
    </div>
  );
};

SalesInvoiceNew.propTypes = {
  bulk: PropTypes.bool,
};

export default SalesInvoiceNew;
