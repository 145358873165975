import apiCall from '../../apiCall/apiCall';
import buildGetQuery from '../../helpers/buildGetQuery/buildGetQuery';

class EmailTemplateType {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'emails/template_types', data);
  }

  static show(id) {
    return apiCall('GET', `emails/template_types/${id}`);
  }

  static getPlaceholders(id) {
    return apiCall('GET', `emails/template_types/${id}/placeholders`);
  }
}

export default EmailTemplateType;
