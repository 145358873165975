import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faLock,
  faLockOpen,
  faSortDown,
  faSortUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import FormField from '../../../../FormField/FormField';
import IconButton from '../../../../../common/button/IconButton/IconButton';
import './WaypointRow.scss';
import { Link } from 'react-router-dom';
import Address from '../../../../../api/controllers/Company/Address/Address';
import GenericAjaxDropdown from '../../../../../common/GenericAjaxDropdown/GenericAjaxDropdown';
import AddressHelper from '../../../../../utils/AddressHelper/AddressHelper';
import Entity from '../../../../../api/controllers/Company/Entity/Entity';

/*
  Why? To present the data and facilitate the modification of waypoints

  Notes? As this component manages attributes, you should note that we differentiate rows that
    exist on the backend by checking for the presence of the modelName attribute. See the
    handleDestroy function in this component for why we differentiate them as such.
    
    Another thing to note is the difference between expected / estimated time and arrival / actual time.
    Expected / Arrival = Label
    Estimated / Actual = Actual value we'll store and the name of the attribute

    The reason for this is Client preference vs what we have on the backend, if things aren't working
    check the backend for any unexpected parameter errors that might occur first, it's easy to mix up.

  Design: https://xd.adobe.com/view/17e68168-c72e-4c78-a917-4ec3f8279d65-42b5/screen/18ad9f8f-5683-4c8a-b163-b24b392c544e/?x_product=xd-jira%2F2.0
*/
const WaypointRow = ({
  idx,
  row,
  addDestroyedWaypoints,
  removeIdxWaypoints,
  replaceWaypoints,
  handleMove,
  cannotSwap = [],
}) => {
  // For shorthand replacing a single attribute in the waypoint
  const alterValue = (attr, val) => replaceWaypoints(idx, { ...row, [attr]: val });

  // Called when an entity in the dropdown is selected
  const onSelectEntity = entity => {
    // Make an address call as we need to check whether or not there is an address we can autofill
    Address.all({ archived: false, entityId: entity.id }).then(addresses => {
      // If we have exactly one address, we want to autofill the address attribute, otherwise we want it blanked out
      const address = addresses.items.length === 1 ? addresses.items[0] : null;
      // Set the state of both address and entity
      replaceWaypoints(idx, { ...row, address, entity });
    });
  };

  const handleOpen = () => alterValue('open', !row.open);

  // Called when the lock icon button on the row is selected
  const handleLock = e => {
    e.preventDefault();
    alterValue('permanent', !row.permanent);
  };

  // Handles the destruction of both existing and local waypoints
  const handleDestroy = e => {
    e.preventDefault();
    if (row?.modelName) addDestroyedWaypoints({ ...row, _destroy: 1 }); // This is how we mark attributes for destruction on the backend
    removeIdxWaypoints(idx);
  };

  // Handles the displaying of Offload and Loading labels in the dropdown content
  // e.g. "000001, 000002 and 000003"
  const inferredJobElements = jobArr =>
    jobArr.map((job, i) => {
      const extraElement = () => {
        if (jobArr.length - 1 === i) return;
        if (jobArr.length - 2 === i) return `\xa0and\xa0`;
        return `,\xa0`;
      };

      return (
        <Fragment key={i}>
          <Link to={`/jobs/bookings/${job.id}`} target="_blank">
            {job.jobReference}
          </Link>
          {extraElement()}
        </Fragment>
      );
    });

  const getAddressApiParams = () => ({ entityId: row.entity?.id });

  return (
    <div className="waypoint-wrap">
      <div className="waypoint-row">
        <button className="dropdown-icon" onClick={handleOpen} data-testid="wr-dropdown">
          <FontAwesomeIcon icon={row.open ? faSortUp : faSortDown} />
        </button>
        <FormField label="Entity" className="wr-entity">
          <GenericAjaxDropdown
            value={row.entity}
            call={Entity.all}
            onDisplay={ent => ent?.internalName}
            onSelect={onSelectEntity}
            onClear={() => replaceWaypoints(idx, { ...row, address: null, entity: null })}
            testId={'entity-dropdown'}
          />
        </FormField>
        <FormField label="Address" className="wr-address">
          <GenericAjaxDropdown
            value={row.address}
            call={Address.all}
            altParams={getAddressApiParams()}
            onDisplay={addr =>
              AddressHelper.formatAddress(
                AddressHelper.extractAddressToArray(addr, { postcodeFirst: true }),
                { dropdown: true },
              )
            }
            onSelect={addr => alterValue('address', addr)}
            onClear={() => alterValue('address', null)}
            testId={'address-dropdown'}
          />
        </FormField>
        <FormField label="Expected Time" className="wr-time">
          <input
            type={'time'}
            value={row.estimatedTime || ''}
            onChange={e => alterValue('estimatedTime', e.target.value)}
            data-testid={'wr-expected-time'}
          />
        </FormField>
        <div className="waypoint-button-grouping" data-testid={'wr-button-grouping'}>
          {row.permanent ? (
            <IconButton icon={faLock} iconOnly className="warning icon-only" onClick={handleLock} />
          ) : (
            <IconButton icon={faLockOpen} iconOnly className="solid-grey" onClick={handleLock} />
          )}
          <IconButton
            icon={faArrowUp}
            iconOnly
            onClick={e => handleMove(e, idx, -1)}
            disabled={cannotSwap.includes('up')}
          />
          <IconButton
            icon={faArrowDown}
            iconOnly
            onClick={e => handleMove(e, idx, 1)}
            disabled={cannotSwap.includes('down')}
          />
          <IconButton
            icon={faTimes}
            iconOnly
            className="destructive icon-only"
            onClick={handleDestroy}
          />
        </div>
      </div>
      {row.open && (
        <div className="waypoint-content" data-testid={'waypoint-content'}>
          {row?.modelName && (
            <Fragment>
              {row.deliveries.length > 0 && (
                <div className="waypoint-info-row">
                  <div className="waypoint-info-label">Offload:</div>
                  <div className="waypoint-info-content">{inferredJobElements(row.deliveries)}</div>
                </div>
              )}
              {row.collections.length > 0 && (
                <div className="waypoint-info-row">
                  <div className="waypoint-info-label">Loading:</div>
                  <div className="waypoint-info-content">
                    {inferredJobElements(row.collections)}
                  </div>
                </div>
              )}
            </Fragment>
          )}
          <div className="waypoint-info-row">
            <div className="waypoint-info-label">Instructions:</div>
            <div className="waypoint-info-content">
              <input
                value={row.instructions || ''}
                onChange={e => alterValue('instructions', e.target.value)}
                data-testid="wr-instructions"
              />
            </div>
            <div className="waypoint-info-label">Actual Time:</div>
            <div className="waypoint-info-actual-time">
              <input
                type={'time'}
                value={row.actualTime || ''}
                onChange={e => alterValue('actualTime', e.target.value)}
                data-testid={'wr-arrival-time'}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

WaypointRow.propTypes = {
  idx: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  addDestroyedWaypoints: PropTypes.func.isRequired,
  removeIdxWaypoints: PropTypes.func.isRequired,
  replaceWaypoints: PropTypes.func.isRequired,
  handleMove: PropTypes.func.isRequired,
  cannotSwap: PropTypes.arrayOf(PropTypes.string),
};

export default WaypointRow;
