import apiCall from '../../apiCall/apiCall';
import buildGetQuery from '../../helpers/buildGetQuery/buildGetQuery';

// TODO: this file does not belong here, it belong in controllers/Email/Template/Template.js
class EmailTemplate {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'emails/templates', data);
  }

  static show(id, modelId = '') {
    const params = buildGetQuery({ modelId });
    return apiCall('GET', `emails/templates/${id}`, params);
  }

  static markAsMain(id, params) {
    const data = { template: params };
    return apiCall('POST', `emails/templates/${id}/mark_as_main`, data);
  }

  static create(params) {
    const data = { template: params };
    return apiCall('POST', 'emails/templates', data);
  }

  static update(id, params) {
    const data = { template: params };
    return apiCall('PATCH', `emails/templates/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `emails/templates/${id}`);
  }
}

export default EmailTemplate;
