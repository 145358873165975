import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class Booking {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'jobs/bookings', data);
  }

  static show(id) {
    return apiCall('GET', `jobs/bookings/${id}`);
  }

  static create(params) {
    const data = { booking: params };
    return apiCall('POST', 'jobs/bookings', data);
  }

  static update(id, params) {
    const data = { booking: params };
    return apiCall('PATCH', `jobs/bookings/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `jobs/bookings/${id}`);
  }

  static statuses() {
    return apiCall('POST', 'jobs/bookings/statuses');
  }

  static pushToFs(id) {
    return apiCall('POST', `jobs/bookings/${id}/push_to_freightsoft`);
  }
}

export default Booking;
