import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import '../../Document/InvoiceForm/InvoiceForm.scss';
import useForm from '../../../../common/hooks/useForm/useForm';
import PurchaseCredit from '../../../../api/controllers/Financial/PurchaseCredit/PurchaseCredit';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import DateHelper from '../../../../utils/DateHelper/DateHelper';
import InvoiceForm from '../../Document/InvoiceForm/InvoiceForm';
import LineItemHelper from '../../../../utils/LineItemHelper/LineItemHelper';
import { useNavigate, useParams } from 'react-router';

const DocumentEdit = ({ formData, title, lineItems, index, error, setError, extras, loading }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { form, alter, handleForm } = useForm({
    entityId: id,
    entityReference: '',
    issueDate: DateHelper.today(),
    dueDate: '',
    lineItemsAttributes: [],
  });

  useEffect(() => {
    handleForm({
      documentId: id,
      entity: formData?.entity,
      booking: formData?.booking,
      entityReference: formData?.entityReference,
      lineItemsAttributes: formData.lineItems,
      documentReference: formData?.documentReference,
      issueDate: formData?.issueDate,
      dueDate: formData?.dueDate,
      ...extras,
    });
  }, [formData]);

  const handleLineItemToggle = itemId => {
    const toggledLines = LineItemHelper.toggleLineEdit(form.lineItemsAttributes, itemId);
    alter('lineItemsAttributes', toggledLines);
  };

  const handleErrors = json => {
    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };

  const handleSubmit = () => {
    const params = form;
    PurchaseCredit.update(id, params).then(
      json => navigate(`${index}${json.id}`),
      json => handleErrors(json),
    );
  };

  return (
    <div className={'invoice-form'}>
      <Breadcrumbs>
        <Link to={`${index}`}>{title}s</Link>
        <Link to={`${index}${id}`}>{form?.documentReference}</Link>
        <Link to={`${index}${id}/edit`}>Edit</Link>
      </Breadcrumbs>
      <div className={'page-content'}>
        <InvoiceForm
          error={error}
          setError={setError}
          alter={alter}
          form={form}
          lineItems={lineItems}
          handleSubmit={handleSubmit}
          lineItemToggle={handleLineItemToggle}
          cancelUrl={`${index}${id}`}
          title={
            loading
              ? 'Editing Financial Document'
              : `Editing ${title} for ${form?.entity?.internalName}`
          }
          loading={loading}
        />
      </div>
    </div>
  );
};

DocumentEdit.propTypes = {
  formData: PropTypes.object,
  title: PropTypes.string,
  lineItems: PropTypes.array,
  index: PropTypes.string,
  error: PropTypes.object,
  setError: PropTypes.func,
  extras: PropTypes.object,
  loading: PropTypes.bool,
};

export default DocumentEdit;
