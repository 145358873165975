import { faLock, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import SalesCredit from '../../api/controllers/Financial/SalesCredit/SalesCredit';
import { formatError } from '../../utils/Formatters/Errors/formatError';
import CheckPermit from '../../common/CheckPermit/CheckPermit';
import IconButton from '../../common/button/IconButton/IconButton';

const UnFinalisedOptions = ({
  documentId,
  data,
  setOpen,
  type,
  index,
  title,
  setError,
  refresh,
  permissions,
}) => {
  const navigate = useNavigate();

  const handleFinalise = e => {
    e.preventDefault();
    SalesCredit.update(documentId, {
      finalised: true,
      issueDate: data.issueDate,
      dueDate: data.dueDate,
      lineItems: data.lineItems,
    }).then(
      () => {
        setError({
          message: `${title} finalised and ready to post to Sage`,
          type: 'success',
        });
        refresh();
      },
      err => {
        setError({ ...formatError(err), errorTitle: 'Finalised Failed!', type: 'error' });
      },
    );
  };

  return (
    <>
      <CheckPermit type={'financialsDocuments'} action={'update'} permissions={permissions}>
        <IconButton text={'Finalise'} onClick={handleFinalise} icon={faLock} />
        <IconButton
          text={`Edit ${type}`}
          onClick={() => navigate(`${index}/${data.id}/edit`)}
          icon={faPen}
          className={'edit'}
        />
      </CheckPermit>
      <CheckPermit type={'financialsDocuments'} action={'destroy'} permissions={permissions}>
        <IconButton
          text={`Delete ${type}`}
          onClick={() => setOpen(prev => !prev)}
          icon={faTrash}
          className={'destructive'}
        />
      </CheckPermit>
    </>
  );
};

UnFinalisedOptions.propTypes = {
  documentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.object,
  setOpen: PropTypes.func,
  type: PropTypes.string,
  index: PropTypes.string,
  title: PropTypes.string,
  refresh: PropTypes.func,
  setError: PropTypes.func,
  permissions: PropTypes.object,
};

export default UnFinalisedOptions;
