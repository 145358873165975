class VehicleRunWarningsHelper {
  //gets all the applicable run level warnings for a given vehicle run
  static getWarnings(data) {
    let warnings = [];

    warnings.push(
      this.willBeOverweight(data.waypoints, data.vehicle),
      this.areTimesDumb(data.waypoints, 'estimatedTime'),
      this.areTimesDumb(data.waypoints, 'actualTime'),
      this.hasNoVehicleOrDriver(data.vehicle, data.driver),
    );

    return warnings.filter(a => a);
  }

  /* 
    Takes list of waypoints and a string of the type (actualTime/estimatedTime)

    and returns the warning string if the time is dumb otherwise returns nothing
  */
  static areTimesDumb(waypoints, type) {
    //check actual arrival makes sense
    const times = waypoints.filter(waypoint => waypoint[type]);
    const dumbTimes = times.filter((waypoint, idx, array) => {
      if (idx === 0) return;
      return array[idx - 1][type] > waypoint[type];
    });

    if (dumbTimes.length === 0) return;

    //retrieving information from original array so we make sure it displays correctly
    const timesIndex = times.findIndex(waypoint => waypoint.id === dumbTimes[0].id) - 1;
    const prevStopIndex = waypoints.findIndex(waypoint => waypoint.id === times[timesIndex].id);
    const currentStopIndex = waypoints.findIndex(
      waypoint => waypoint.id === times[timesIndex + 1].id,
    );
    const prevStop = waypoints[prevStopIndex];

    return `Waypoint ${currentStopIndex + 1} (${dumbTimes[0][type]}) has an earlier ${
      type.split('Time')[0]
    } arrival time than waypoint ${prevStopIndex + 1} (${prevStop[type]})`;
  }

  /* 
    Takes a list of waypoints and a vehicle

    check if at any point the vehicle will be overweight, returning the warning string if true
    or returning nothing
  */
  static willBeOverweight(waypoints, vehicle) {
    if (!vehicle || !vehicle.weightLimitKg) return;

    let currentWeight = 0;
    const overweightPoints = waypoints.filter(waypoint => {
      waypoint.collections.forEach(collection => (currentWeight += collection.grossWeightKg));
      waypoint.deliveries.forEach(delivery => (currentWeight -= delivery.grossWeightKg));
      return currentWeight > vehicle.weightLimitKg;
    });

    if (overweightPoints.length === 0) return;
    const pointIndex = waypoints.findIndex(waypoint => waypoint.id === overweightPoints[0].id);
    return `Vehicle capacity is exceeded at waypoint ${pointIndex + 1} (${
      overweightPoints[0].address.postcode
    })`;
  }

  /* 
    Takes a Vehicle and a Driver
    Don't show warning if: 
      - a Vehicle Run has a Vehicle AND a Driver
      - a Vehicle Run doesn't Vehicle AND Driver
    Do show warning if: 
      - a Vehicle Run doesn't have a Vehicle but has a Driver
      - a Vehicle Run doesn't have a Driver but has a Vehicle
  */
  static hasNoVehicleOrDriver(vehicle, driver) {
    if ((vehicle && driver) || (!vehicle && !driver)) return;
    if (vehicle && !driver) return 'No driver is specified';
    if (!vehicle && driver) return 'No vehicle is specified';
  }
}

export default VehicleRunWarningsHelper;
