import React, { Fragment } from 'react';
import './TariffCharge.scss';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import FormSection from '../../../components/FormSection/FormSection';
import FieldList from '../../../components/FieldList/FieldList';
import FormField from '../../../components/FormField/FormField';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import GenericDropdown from '../../../common/GenericDropdown/GenericDropdown';
import useApi from '../../../common/hooks/useApi/useApi';
import ChargeCode from '../../../api/controllers/Financial/ChargeCode/ChargeCode';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import SystemSetting from '../../../api/controllers/SystemSetting/SystemSetting';
import useForm from '../../../common/hooks/useForm/useForm';
import { useEffect } from 'react';
import ValueInput from '../../../common/input/ValueInput/ValueInput';
import useArray from '../../../common/hooks/useArray/useArray';
import Area from '../../../api/controllers/Tariff/Area/Area';
import Message from '../../../common/layout/Message/Message';
import { useState } from 'react';
import { formatError } from '../../../utils/Formatters/Errors/formatError';

const defaultAreaSurcharge = {
  name: '',
  area: '',
  chargeCode: '',
  surcharge: '',
};

const TariffCharge = () => {
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  const { settings, refreshSettings } = useApi({ name: 'settings', call: SystemSetting.show });
  const { codes } = useApi({ name: 'codes', call: ChargeCode.all });
  const { areas } = useApi({ name: 'areas', call: Area.all });

  const validSalesCodes = codes?.items?.filter(code => code.salesNominal);
  const validCostCodes = codes?.items?.filter(code => code.purchaseNominal);

  const { form, alter, handleForm } = useForm();
  const {
    areaSurcharges,
    handleAreaSurcharges,
    replaceAreaSurcharges,
    addAreaSurcharges,
    removeIdxAreaSurcharges,
  } = useArray({
    name: 'areaSurcharges',
  });
  const { destroyedSc, addDestroyedSc, handleDestroyedSc } = useArray({ name: 'destroyedSc' });

  useEffect(() => {
    document.title = 'Tariff Surcharges';
    if (settings) {
      handleForm({
        collectionChargeCode: settings.collectionChargeCode || '',
        deliveryChargeCode: settings.deliveryChargeCode || '',
        overlengthChargeCode: settings.overlengthChargeCode || '',
        tailLiftChargeCode: settings.tailLiftChargeCode || '',
        wasteChargeCode: settings.wasteChargeCode || '',
        timedChargeCode: settings.timedChargeCode || '',
        overlengthSurcharge: settings.overlengthSurcharge || '',
        tailLiftSurcharge: settings.tailLiftSurcharge || '',
        wasteSurcharge: settings.wasteSurcharge || '',
        subcontractChargeCode: settings.subcontractChargeCode || '',
        timedSurcharge: settings.timedSurcharge || '',
      });
      handleAreaSurcharges(settings.areaSurcharges || []);
    }
  }, [settings]);

  const handleSubmit = () => {
    setLoading(true);
    const areaSurchargesAttributes = [...areaSurcharges, ...destroyedSc].map(sc => ({
      id: sc.id,
      name: sc.name,
      areaId: sc.area?.id || null,
      chargeCodeId: sc.chargeCode?.id || null,
      surcharge: sc.surcharge,
      _destroy: sc._destroy,
    }));

    const params = {
      collectionChargeCodeId: form.collectionChargeCode?.id || null,
      deliveryChargeCodeId: form.deliveryChargeCode?.id || null,
      overlengthChargeCodeId: form.overlengthChargeCode?.id || null,
      tailLiftChargeCodeId: form.tailLiftChargeCode?.id || null,
      wasteChargeCodeId: form.wasteChargeCode?.id || null,
      subcontractChargeCodeId: form.subcontractChargeCode?.id || null,
      timedChargeCodeId: form.timedChargeCode?.id || null,
      overlengthSurcharge: form.overlengthSurcharge,
      tailLiftSurcharge: form.tailLiftSurcharge,
      wasteSurcharge: form.wasteSurcharge,
      timedSurcharge: form.timedSurcharge,
      areaSurchargesAttributes,
    };

    SystemSetting.update(params)
      .then(
        () => {
          refreshSettings();
          handleDestroyedSc([]);
          setMessage({ type: 'success', text: 'Your changes have been saved' });
        },
        err => setMessage({ type: 'error', text: formatError(err) }),
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDestroy = (i, sc) => {
    if (sc.modelName) addDestroyedSc({ ...sc, _destroy: 1 });
    removeIdxAreaSurcharges(i);
  };

  return (
    <div className="tariff-charge">
      <Breadcrumbs>
        <Link to={'/tariffs/charges'}>Tariff Surcharges</Link>
      </Breadcrumbs>
      <div className="page-content">
        <h1>Manage Surcharges</h1>

        <Message
          text={message?.text}
          type={message?.type}
          onClick={() => setMessage()}
          visible={!!message}
        />

        {!codes || !form ? (
          <LoadingSpinner />
        ) : (
          <Fragment>
            <FormSection title="General">
              <Fragment>
                <FieldList>
                  <FormField label={'Collection Charge Code'}>
                    <GenericDropdown
                      value={form.collectionChargeCode}
                      items={validSalesCodes}
                      onDisplay={code => code?.code}
                      onSelect={code => alter('collectionChargeCode', code)}
                      onClear={() => alter('collectionChargeCode', '')}
                      testId={'dd-ccc'}
                    />
                  </FormField>
                  <FormField label={'Delivery Charge Code'}>
                    <GenericDropdown
                      value={form.deliveryChargeCode}
                      items={validSalesCodes}
                      onDisplay={code => code?.code}
                      onSelect={code => alter('deliveryChargeCode', code)}
                      onClear={() => alter('deliveryChargeCode', '')}
                      testId={'dd-dcc'}
                    />
                  </FormField>
                </FieldList>
                <FieldList>
                  <FormField label={'Overlength Charge Code'}>
                    <GenericDropdown
                      value={form.overlengthChargeCode}
                      items={validSalesCodes}
                      onDisplay={code => code?.code}
                      onSelect={code => alter('overlengthChargeCode', code)}
                      onClear={() => alter('overlengthChargeCode', '')}
                      testId={'dd-occ'}
                    />
                  </FormField>
                  <FormField label={'Overlength Surcharge'}>
                    <ValueInput
                      value={form.overlengthSurcharge}
                      onChange={e => alter('overlengthSurcharge', e.target.value)}
                      type={'number'}
                      min={0}
                      step={1}
                      unit={'%'}
                      testId="in-osc"
                    />
                  </FormField>
                </FieldList>
                <FieldList>
                  <FormField label={'Tail Lift Charge Code'}>
                    <GenericDropdown
                      value={form.tailLiftChargeCode}
                      items={validSalesCodes}
                      onDisplay={code => code?.code}
                      onSelect={code => alter('tailLiftChargeCode', code)}
                      onClear={() => alter('tailLiftChargeCode', '')}
                      testId="dd-tlcc"
                    />
                  </FormField>
                  <FormField label={'Tail Lift Surcharge'}>
                    <ValueInput
                      value={form.tailLiftSurcharge}
                      onChange={e => alter('tailLiftSurcharge', e.target.value)}
                      type={'number'}
                      min={0}
                      step={0.01}
                      unit={'£'}
                      reverse
                      testId="in-tlsc"
                    />
                  </FormField>
                </FieldList>
                <FieldList>
                  <FormField label={'Timed Charge Code'}>
                    <GenericDropdown
                      value={form.timedChargeCode}
                      items={validSalesCodes}
                      onDisplay={code => code?.code}
                      onSelect={code => alter('timedChargeCode', code)}
                      onClear={() => alter('timedChargeCode', '')}
                      testId="dd-tcc"
                    />
                  </FormField>
                  <FormField label={'Timed Surcharge'}>
                    <ValueInput
                      value={form.timedSurcharge}
                      onChange={e => alter('timedSurcharge', e.target.value)}
                      type={'number'}
                      min={0}
                      step={0.01}
                      unit={'£'}
                      reverse
                      testId="in-tsc"
                    />
                  </FormField>
                </FieldList>
                <FieldList>
                  <FormField label={'Waste Charge Code'}>
                    <GenericDropdown
                      value={form.wasteChargeCode}
                      items={validSalesCodes}
                      onDisplay={code => code?.code}
                      onSelect={code => alter('wasteChargeCode', code)}
                      onClear={() => alter('wasteChargeCode', '')}
                      testId="dd-wcc"
                    />
                  </FormField>
                  <FormField label={'Waste Surcharge'}>
                    <ValueInput
                      value={form.wasteSurcharge}
                      onChange={e => alter('wasteSurcharge', e.target.value)}
                      type={'number'}
                      min={0}
                      step={0.01}
                      unit={'£'}
                      reverse
                      testId="in-wsc"
                    />
                  </FormField>
                </FieldList>
              </Fragment>
            </FormSection>
            <FormSection title={'Costs'}>
              <FieldList>
                <FormField label={'Subcontract Charge Code'}>
                  <GenericDropdown
                    value={form.subcontractChargeCode}
                    items={validCostCodes}
                    onDisplay={code => code?.code}
                    onSelect={code => alter('subcontractChargeCode', code)}
                    onClear={() => alter('subcontractChargeCode', '')}
                    testId="dd-sccc"
                  />
                </FormField>
              </FieldList>
            </FormSection>
          </Fragment>
        )}
        <FormSection
          title="Special Areas"
          optionalElement={
            <IconButton
              icon={faPlus}
              text={'Add Area'}
              className="success"
              onClick={() => addAreaSurcharges(defaultAreaSurcharge)}
            />
          }
        >
          {areas &&
            areaSurcharges.map((surcharge, i) => {
              // Allows for shorthand state changes
              const alter = sc => replaceAreaSurcharges(i, sc);

              return (
                <div className="sa-wrap" key={i}>
                  <FormField label={'Name'}>
                    <input
                      value={surcharge.name}
                      onChange={e => alter({ ...surcharge, name: e.target.value })}
                    />
                  </FormField>
                  <FormField label={'Area'}>
                    <GenericDropdown
                      value={surcharge.area}
                      items={areas.items}
                      onDisplay={area => area?.name}
                      onSelect={area => alter({ ...surcharge, area })}
                      onClear={() => alter({ ...surcharge, area: '' })}
                      testId={`dd-sca${i}`}
                    />
                  </FormField>
                  <FormField label={'Charge Code'}>
                    <GenericDropdown
                      value={surcharge.chargeCode}
                      items={validSalesCodes}
                      onDisplay={cc => cc?.code}
                      onSelect={cc => alter({ ...surcharge, chargeCode: cc })}
                      onClear={() => alter({ ...surcharge, chargeCode: '' })}
                      testId={`dd-cc${i}`}
                    />
                  </FormField>
                  <FormField label={'Surcharge'}>
                    <ValueInput
                      value={surcharge.surcharge}
                      onChange={e => alter({ ...surcharge, surcharge: e.target.value })}
                      unit={'£'}
                      min={0}
                      step={0.01}
                      reverse
                    />
                  </FormField>
                  <IconButton
                    icon={faTimes}
                    iconOnly
                    className={'destructive'}
                    onClick={() => handleDestroy(i, surcharge)}
                    testId={`ib-destroy${i}`}
                  />
                </div>
              );
            })}
        </FormSection>
        <div className="tc-button-group">
          <IconButton text={'Save'} icon={faCheck} loading={loading} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default TariffCharge;
