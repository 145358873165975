import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Area from '../../../api/controllers/Tariff/Area/Area';
import IconButton from '../../../common/button/IconButton/IconButton';
import useArray from '../../../common/hooks/useArray/useArray';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import './BandManagement.scss';
import BandManagementTable from './subComponents/BandManagementTable/BandManagementTable';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import ErrorStatusMessage from '../../../common/ErrorStatusMessage/ErrorStatusMessage';
import Message from '../../../common/layout/Message/Message';
import { formatError } from '../../../utils/Formatters/Errors/formatError';

const BandManagement = () => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [taxableWeight, setTaxableWeight] = useState('');
  const [error, setError] = useState();
  const [message, setMessage] = useState();

  const { areas, handleAreas, replaceAreas } = useArray({ name: 'areas' });

  useEffect(() => {
    document.title = 'Manage Tariff';

    Area.all().then(res => {
      //Resolves issue with new areas not having all the needed bandInfo
      let maxBands = [];
      //finds the area with the max number of bands, used for auto-filling later
      res.items.forEach(({ bands }) => {
        if (bands.length > maxBands.length) maxBands = bands.map(band => ({ ...band, value: 0 }));
      });
      //checks area bands for length issues, and replaces with blanks of the correct length
      //This should only happen when an area is first made, so the fact these are blank should not be an issue
      const allSameBands = res.items.map(area => {
        if (area.bands.length !== maxBands.length) return { ...area, bands: maxBands };
        return area;
      });
      handleAreas(allSameBands);
      setLoading(false);
    });
  }, []);

  //just checks if 2 taxable weights (c & n) are the same and returns true of false
  const taxableWeightsSame = (c, n) => Number(c) === Number(n);

  /*
    Handles the changing of a value for a band in an area
  */
  const handleBandValueChange = (areaIdx, bandTaxableWeight, value) => {
    const updatedBands = areas[areaIdx].bands.map(band =>
      band.taxableWeight === bandTaxableWeight ? { ...band, value } : band,
    );
    const areaReplaced = { ...areas[areaIdx], bands: updatedBands };
    replaceAreas(areaIdx, areaReplaced);
  };

  //Changes the value of the max for an area.
  const handleMaxChange = (areaIdx, value) => {
    replaceAreas(areaIdx, { ...areas[areaIdx], maximumValue: value });
  };

  //Changes the value of the minimum for an area.
  const handleMinChange = (areaIdx, value) => {
    replaceAreas(areaIdx, { ...areas[areaIdx], minimumValue: value });
  };

  /*
    Creates a new Taxable weight band for each area, with the default value of 0.

    Errors if there is no taxable weight, and if there is already that taxable that isn't set for removal.
  */
  const generateRow = () => {
    if (!taxableWeight) return setError({ error: 'Taxable weight cannot be blank' });
    if (
      areas[0].bands.find(
        band => taxableWeightsSame(band.taxableWeight, taxableWeight) && !band._destroy,
      )
    )
      return setError({ error: 'A row with this taxable weight already exists' });
    //Filters out the formally removed band if required.
    const areasFiltered = areas[0].bands.find(band =>
      taxableWeightsSame(band.taxableWeight, taxableWeight),
    )
      ? areas.map(area => ({
          ...area,
          bands: area.bands.filter(band => !taxableWeightsSame(band.TaxableWeight, taxableWeight)),
        }))
      : areas;
    //Adds the new band to each area
    const blankBand = { value: 0, taxableWeight: taxableWeight };
    const rowInserted = areasFiltered.map(area => ({
      ...area,
      bands: [...area.bands, blankBand].sort((a, b) => a.taxableWeight - b.taxableWeight),
    }));
    handleAreas(rowInserted);
  };

  //Removes a given weight band from each area.
  const removeBand = bandWeight => {
    const bandRemoved = areas.map(area => ({
      ...area,
      bands: area.bands
        .map(band => {
          if (band.taxableWeight === bandWeight) return { ...band, _destroy: true };
          return band;
        })
        .sort((a, b) => a.taxableWeight - b.taxableWeight),
    }));
    handleAreas(bandRemoved);
  };

  //Actually updates the api with the data.
  const saveUpdates = () => {
    setSubmitting(true);
    const formatted = areas.map(area => ({ ...area, bandsAttributes: area.bands }));
    // gives the array as an object as mentioned in the backend to prevent merging fields by rails
    // see: https://bitbucket.org/vpcltd/transswift-api/src/master/spec/requests/tariffs/areas_spec.rb line 281
    const areasObject = Object.assign({}, formatted);

    Area.updateAll({ areasAttributes: areasObject })
      .then(
        () =>
          setMessage({
            message: 'Your changes have been saved',
            type: 'success',
          }),
        err => {
          const errorContent = formatError(err);
          const errorCount = errorContent.errors.length;
          setMessage({
            type: 'error',
            errorTitle: `Action Failed, ${errorCount} error${
              errorCount > 1 ? 's' : ''
            } to resolve.`,
            errors: errorContent.errors,
          });
        },
      )
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="tariff-band-management">
      <Breadcrumbs>
        <Link to={'/tariffs/band_management'}>Manage Tariff</Link>
      </Breadcrumbs>
      <ErrorStatusMessage error={error} setError={setError} />
      <div className="page-content">
        <h1>Manage Tariff</h1>
        <Message
          text={message}
          type={message?.type}
          onClick={() => setMessage()}
          visible={!!message}
          onClose={setMessage}
        />
        <div className="tab-content">
          <BandManagementTable
            areas={areas}
            loading={loading}
            handleBandValueChange={handleBandValueChange}
            handleMaxChange={handleMaxChange}
            handleMinChange={handleMinChange}
            removeBand={removeBand}
          />
          <h2>Add a new band</h2>
          <div className="tbm-row-add">
            <input
              data-testid={'tbm-taxable-weight'}
              type={'number'}
              min={1}
              value={taxableWeight}
              onChange={e => setTaxableWeight(e.target.value)}
              placeholder={'Taxable Weight'}
            />
            <IconButton
              text="Insert Row"
              icon={faPlus}
              onClick={generateRow}
              disabled={areas.length === 0}
            />
            <IconButton
              text="Save"
              loading={submitting}
              icon={faCheck}
              className="save-button"
              onClick={saveUpdates}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BandManagement.propTypes = {};

export default BandManagement;
