import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowsLeftRight,
  faArrowsUpDown,
  faArrowTurnUp,
  faLayerGroup,
  faRegistered,
  faTrash,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import VehicleRun from '../../api/controllers/Movement/VehicleRun/VehicleRun';
import { formatError } from '../Formatters/Errors/formatError';
import FuelHelper from '../FuelHelper/FuelHelper';
import NumberHelper from '../NumberHelper/NumberHelper';
import _ from 'lodash';
import DateHelper from '../DateHelper/DateHelper';
import { warehouseFactors } from '../../strings/enums';
import { capitalize } from 'lodash';

class VehicleRunHelper {
  // Miles to km
  static mToKm(m) {
    return Math.round(m * 1.60934);
  }

  // Km to miles
  static kmToM(km) {
    return Math.round(km * 0.621371);
  }

  static getConsignmentFlags(booking, vehicles) {
    if (!booking) return [];
    const height = this.getShortestVehicle(vehicles);

    const flags = [];

    const collectionRestrictions = _.filter(
      booking.collectionAddress?.warehouseRequirements,
      ({ applies }) => applies != 'never',
    );
    const deliveryRestrictions = _.filter(
      booking.deliveryAddress?.warehouseRequirements,
      ({ applies }) => applies != 'never',
    );

    if (collectionRestrictions.length > 0 && deliveryRestrictions.length > 0) {
      flags.push({
        title: 'Restrictions',
        icon: faRegistered,
        colour: 'var(--restriction-blue)',
        description: [
          { title: 'Collection', text: this.getDescription(collectionRestrictions).join('.\r\n') },
          { title: 'Delivery', text: this.getDescription(deliveryRestrictions).join('.\r\n') },
        ],
      });
    } else if (collectionRestrictions.length > 0) {
      flags.push({
        title: 'Restrictions',
        icon: faRegistered,
        colour: 'var(--restriction-blue)',
        description: [
          { title: 'Collection', text: this.getDescription(collectionRestrictions).join('.\r\n') },
        ],
      });
    } else if (deliveryRestrictions.length > 0) {
      flags.push({
        title: 'Restrictions',
        icon: faRegistered,
        colour: 'var(--restriction-blue)',
        description: [
          { title: 'Delivery', text: this.getDescription(deliveryRestrictions).join('.\r\n') },
        ],
      });
    }

    if (booking.doNotStack)
      flags.push({ title: 'Do not stack', icon: faLayerGroup, colour: 'var(--stack-blue)' });

    if (booking.waste) flags.push({ title: 'Waste', icon: faTrash, colour: 'var(--waste-yellow)' });

    if (booking.foodstuffs)
      flags.push({ title: 'Foodstuffs', icon: faUtensils, colour: 'var(--food-green)' });

    if (
      booking.dimensionLineItems?.filter(item => item.lengthCm > 300 || item.widthCm > 300).length >
      0
    )
      flags.push({
        title: 'Overlength',
        icon: faArrowsLeftRight,
        colour: 'var(--overlength-purple)',
      });

    if (booking.dimensionLineItems?.filter(item => item.heightCm > (height || 175)).length > 0)
      flags.push({ title: 'Tall packages', icon: faArrowsUpDown, colour: 'var(--tall-pink)' });

    if (booking.tailLiftOnCollection || booking.tailLiftOnDelivery)
      flags.push({
        title: 'Tail lift required',
        icon: faArrowTurnUp,
        colour: 'var(--tail-brown)',
      });

    if (booking.timedDelivery || booking.timedCollection)
      flags.push({
        title: 'Timed',
        icon: faClock,
        colour: 'var(--timed-red)',
      });

    return flags;
  }

  /*returns height of smallest vehicle in given array of objects

    begins by filtering out any null/zero values and they are not considered
    due to them not really being accurate as there wouldn't be a 0cm tall van
    this is then put through a reduce to find the min of the given value,
    returning the smallest vehicle

    the starting value is due to a weird tendency for the Math.min to return NaN if not provided, so we have to convert back if that is returned
  */
  static getShortestVehicle(vehicles) {
    const heightFiltered = vehicles.filter(vehicle => vehicle.heightCm);
    if (heightFiltered.length > 0) {
      var leastTallVehicle = heightFiltered.reduce((a, b) => Math.min(a, b.heightCm), 1000000000);
      leastTallVehicle = leastTallVehicle === 1000000000 ? undefined : leastTallVehicle;
    }

    return leastTallVehicle;
  }

  // returns an array of values from the descriptions key for a given array
  static getDescription(restrictionsArray) {
    const allDescriptions = [];
    _.forEach(restrictionsArray, ({ applies, description, name }) => {
      if (description) {
        allDescriptions.push(description);
      } else {
        allDescriptions.push(`${capitalize(applies)} ${warehouseFactors[name]}`);
      }
    });
    return allDescriptions;
  }

  static getJobDirection(fsId, collectionId, deliveryId) {
    if (!fsId) return 'XT';
    if (fsId === deliveryId) return 'COL';
    if (fsId === collectionId) return 'DEL';
    return 'XT';
  }

  //Works out if the status of a job should change on drop of new section
  static calculateNewJobStatus(currentStatus, newSection) {
    //jobs with these statuses should always be constant
    if (currentStatus === 'finalised' || currentStatus === 'cancelled') return currentStatus;
    if (newSection === 'Pending') return 'pending'; //dropping in a pending section should make an active job pending.
    return 'active'; //otherwise make it pending
  }

  static async getFuelAndBase(run) {
    if (run.subcontracted) return;
    if (run.closed) return { fuelCost: run.finalFuelCost, baseCost: run.finalBaseCost };
    if (!run?.vehicle) return;

    const FuelCalculator = new FuelHelper(run);
    const fuelCost = await FuelCalculator.calculate();
    const vehicle = run.vehicle;
    //If the value is not null or undefined, convert to number
    const baseCost = NumberHelper.formatNumberUnlessBlank(vehicle.baseCost);
    return { fuelCost, baseCost };
  }

  static async toggleFinalize(run, costs, setLoad, setMessage, refresh, callback = () => {}) {
    const { fuelCost, baseCost } = costs;
    const fuelObj = run.closed
      ? { finalFuelCost: null, finalBaseCost: null }
      : { finalFuelCost: fuelCost, finalBaseCost: baseCost };

    setLoad(true);
    VehicleRun.update(run.id, { ...fuelObj, closed: !run.closed })
      .then(
        () => {
          const success = run.closed
            ? 'This vehicle run has been re-opened for editing'
            : 'This vehicle run has been marked as closed';
          setMessage({ type: 'success', text: success });
          refresh();
          callback();
        },
        err => setMessage({ type: 'error', text: formatError(err) }),
      )
      .finally(() => setLoad(false));
  }

  static renderPrice(val) {
    if (val == null || isNaN(val)) return '--';
    return NumberHelper.formatNumToCurrency(val);
  }

  /* 
  Handles the rendering of archived drivers in the driver dropdown dependent
  on the date the vehicle run was created. Archived drivers are only shown if
  a user is editing an 'old' vehicle run with an archived driver.
*/
  static handleArchivedView(form) {
    const isDateInPast = form?.dateOfUse < DateHelper.today();
    let showArchived = { drivers: false, vehicles: false };

    if (!isDateInPast) return;
    if (isDateInPast && !!form?.driver?.archived) showArchived = { ...showArchived, drivers: true };
    if (isDateInPast && !!form?.vehicle?.archived)
      showArchived = { ...showArchived, vehicles: true };

    return showArchived;
  }
}

export default VehicleRunHelper;
