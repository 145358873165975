import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

// Handles all package type requests.
class PackageType {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'jobs/package_types', data);
  }

  static show(id) {
    return apiCall('GET', `jobs/package_types/${id}`);
  }

  static create(params) {
    const data = { package_type: params };
    return apiCall('POST', 'jobs/package_types', data);
  }

  static update(id, params) {
    const data = { package_type: params };
    return apiCall('PATCH', `jobs/package_types/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `jobs/package_types/${id}`);
  }
}

export default PackageType;
