import React from 'react';
import PropTypes from 'prop-types';

//Displays a Area Column on the BandManagement table, Essentially just a header, editable max/min and the band values
const AreaColumn = ({ area, handleBandValueChange, handleMaxChange, handleMinChange, idx }) => (
  <div key={`col-${area.name}`} className="tbm-table-col">
    <div className="tbm-table-header">Area {area.name}</div>
    <input
      type={'number'}
      className="tbm-table-value"
      data-testid={'min-input'}
      value={area.minimumValue}
      onChange={e => handleMinChange(idx, e.target.value)}
      step={0.01}
    />
    {area.bands.map(
      band =>
        !band._destroy && (
          <input
            type={'number'}
            key={`${area.id}-${band.taxableWeight}`}
            className="tbm-table-value"
            value={band.value}
            onChange={e => handleBandValueChange(idx, band.taxableWeight, e.target.value)}
            step={0.01}
            data-testid={'band-input'}
          />
        ),
    )}
    <input
      type={'number'}
      className="tbm-table-value"
      data-testid={'max-input'}
      value={area.maximumValue}
      onChange={e => handleMaxChange(idx, e.target.value)}
      step={0.01}
    />
  </div>
);

AreaColumn.propTypes = {
  area: PropTypes.object,
  handleBandValueChange: PropTypes.func.isRequired,
  handleMinChange: PropTypes.func.isRequired,
  handleMaxChange: PropTypes.func.isRequired,
  idx: PropTypes.number,
};

export default AreaColumn;
