import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { humanize } from '../../../../../utils/EnumHelper/EnumHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import './TreePanel.scss';

const TreePanel = ({
  placeholdersTree,
  className = 'model',
  setPlaceholdersArray,
  prefix = '',
  setPrefix,
  modelName,
  setModelTitle,
}) => {
  const [open, setOpen] = useState(false);
  const handleIconClick = () => {
    setOpen(prev => !prev);
  };
  const handleModelClick = modelName => {
    setPlaceholdersArray(placeholdersTree.placeholders);
    setPrefix(prefix);
    setModelTitle(modelName);
  };
  return (
    <div>
      <div className={className}>
        <span onClick={handleIconClick} className="model-icon">
          {Object.keys(placeholdersTree.subModel).length === 0 ? (
            <FontAwesomeIcon icon={faAngleRight} />
          ) : open ? (
            <FontAwesomeIcon icon={faMinus} />
          ) : (
            <FontAwesomeIcon icon={faPlus} />
          )}
        </span>
        <span onClick={() => handleModelClick(modelName)} className="model-name">
          {placeholdersTree.model && humanize(placeholdersTree.model)}
        </span>
      </div>
      <div className="panel-tree-submodels">
        {open &&
          Object.keys(placeholdersTree.subModel).map(item => {
            return (
              <TreePanel
                key={item}
                placeholdersTree={placeholdersTree.subModel[item]}
                className="submodel"
                setPlaceholdersArray={setPlaceholdersArray}
                prefix={prefix === '' ? prefix + item : prefix + '__' + item}
                setPrefix={setPrefix}
                modelName={modelName + '-' + placeholdersTree.subModel[item].model}
                setModelTitle={setModelTitle}
              />
            );
          })}
      </div>
    </div>
  );
};

TreePanel.propTypes = {
  placeholdersTree: PropTypes.object,
  className: PropTypes.string,
  setPlaceholdersArray: PropTypes.func,
  setPrefix: PropTypes.func,
  prefix: PropTypes.string,
  modelName: PropTypes.string,
  setModelTitle: PropTypes.func,
};
export default TreePanel;
