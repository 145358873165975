import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SelectorDropdown.scss';
import { useLocation } from 'react-router';

const SelectorDropdown = ({ options = [], items = [], toggle, set, onDisplay = opt => opt }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectorFilter = searchParams.get('filter');

  useEffect(() => {
    if (set && selectorFilter) set(selectorFilter);
  }, [selectorFilter]);

  const drawOptions = () =>
    options.map((option, i) => (
      <div
        key={i}
        className={`selector-option ${items.includes(option) ? 'selected' : ''}`}
        onClick={() => toggle(option)}
      >
        {onDisplay(option)}
      </div>
    ));

  const text =
    items.length > 1 ? `Categories (${items.length})` : `${onDisplay(items[0]) || 'Filter'}`;
  return (
    <div className="selector-dropdown">
      <div className="selector-value">
        {text}
        <FontAwesomeIcon icon={faSortDown} />
      </div>
      <div className="selector-options" data-testid={'selector-options'}>
        {drawOptions()}
      </div>
    </div>
  );
};

SelectorDropdown.propTypes = {
  options: PropTypes.array,
  items: PropTypes.arrayOf(PropTypes.string),
  toggle: PropTypes.func,
  set: PropTypes.func,
  onDisplay: PropTypes.func,
};

export default SelectorDropdown;
