class AddressHelper {
  //takes an input object and extracts important lines for return as an array.
  static extractAddressToArray(addressObj, { postcodeFirst } = { postcodeFirst: false }) {
    if (!addressObj) return [];
    const { line1, line2, line3, line4, region, postcode } = addressObj;
    if (postcodeFirst) return [postcode, line1, line2, line3, line4, region];
    return [line1, line2, line3, line4, region, postcode];
  }

  /*
    Takes an Address array (see extractAddressToArray) and formats it into a string for display
    Removes blank lines and then maps to string
    Formats to style '[POSTCODE] ADD LINE 1, ADD LINE 2, ADD LINE...'
  */
  static formatAddress(addressLines, { dropdown } = { dropdown: false }) {
    if (!addressLines || addressLines.length < 1) return '';
    let addressText = '';
    const noNil = addressLines.filter(adrs => adrs);
    noNil.map((line, i) => {
      if (addressLines[0] && i === 0 && dropdown) addressText = `${addressText}[${line}] `;
      else addressText = `${addressText + line}${i !== noNil.length - 1 ? ', ' : ''}`;
    });
    return addressText;
  }

  static getRegion(item) {
    if (!item || Object.keys(item).length === 0 || (!item.nuts && !item.parish)) return '';
    const cleanParish = item.parish?.replace(', unparished area', '');
    if (item.parish && item.nuts && cleanParish !== item.nuts)
      return `${cleanParish}${item.nuts ? `, ${item.nuts}` : ''}`;
    if (item.parish || cleanParish === item.nuts) return cleanParish;
    if (!item.parish && item.nuts) return item.nuts;
    return '';
  }
}

export default AddressHelper;
