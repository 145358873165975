import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/Modal/Modal';
import './SelectTemplateType.scss';
import IconButton from '../../common/button/IconButton/IconButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import EmailTemplateType from '../../api/controllers/EmailTemplateType/EmailTemplateType';
import FormField from '../FormField/FormField';
import useApi from '../../common/hooks/useApi/useApi';
import { labelForEnum } from '../../utils/EnumHelper/EnumHelper';
import Message from '../../common/layout/Message/Message';
import { emailTemplateTypes } from '../../strings/enums';
import { useNavigate } from 'react-router-dom';
import GenericDropdown from '../../common/GenericDropdown/GenericDropdown';

const SelectTemplateType = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const [templateName, setTemplateName] = useState();
  const [templateTypeId, setTemplateTypeId] = useState();
  const [templateTypeError, setTemplateTypeError] = useState();

  const { templateTypes } = useApi({
    call: EmailTemplateType.all,
    name: 'templateTypes',
  });

  const onSelectTemplate = tem => {
    setTemplateName(tem.name);
    setTemplateTypeId(tem.id);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setTemplateTypeError();
    setTemplateName();
    setTemplateTypeId();
  };

  const handleConfirm = () => {
    if (!templateName)
      return setTemplateTypeError('You must select an email template type before continuing.');

    navigate({ pathname: '/emails/templates/new', search: `templateTypeId=${templateTypeId}` });
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleCancel}>
      <div className="form-section">
        <div className="form-section-head draggable">
          <div>Select Template Type</div>
        </div>
        <div className="form-section-body">
          <div className={'et-container'}>
            <Message
              type={'error'}
              text={templateTypeError}
              onClose={() => setTemplateTypeError()}
              visible={!!templateTypeError}
            />
            <div className="et-dropdown">
              <FormField label={'Type'} className={'single'}>
                <GenericDropdown
                  value={templateName || 'Select Template Type'}
                  items={templateTypes?.items}
                  onDisplay={tem => labelForEnum(emailTemplateTypes, tem?.name || tem)}
                  onSelect={onSelectTemplate}
                  onClear={() => setTemplateName()}
                />
              </FormField>
            </div>
            <div className={'et-button-line'}>
              <IconButton
                text={'Continue'}
                icon={faCheck}
                onClick={handleConfirm}
                className={'small'}
              />
              <IconButton
                text={'Cancel'}
                icon={faTimes}
                className={'edit small'}
                onClick={handleCancel}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SelectTemplateType.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func,
};

export default SelectTemplateType;
