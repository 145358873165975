import React from 'react';
import PropTypes from 'prop-types';
import './ToolbarLink.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import LinkInput from './LinkInput/LinkInput';

const ToolbarLink = ({
  name = 'link',
  menu,
  setMenu,
  handleLink,
  getLinkKey,
  handleReplaceLink,
}) => {
  const handleToggle = () => setMenu(menu === 'link' ? null : 'link');

  return (
    <div className={'toolbar-link'}>
      <button
        className={'tbl-icon'}
        onClick={handleToggle}
        tabIndex="-1"
        data-testid="toolbar-link"
      >
        <FontAwesomeIcon icon={faLink} />
      </button>
      {menu === name && (
        <LinkInput
          handleLink={handleLink}
          open={menu === name}
          getLinkKey={getLinkKey}
          setOpen={handleToggle}
          handleReplaceLink={handleReplaceLink}
        />
      )}
    </div>
  );
};

ToolbarLink.propTypes = {
  name: PropTypes.string,
  menu: PropTypes.string,
  setMenu: PropTypes.func,
  handleLink: PropTypes.func,
  getLinkKey: PropTypes.func,
  handleReplaceLink: PropTypes.func,
};

export default ToolbarLink;
