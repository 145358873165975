// Rows - [{ Column: String }, { Column: String }, { Column: String }, ..]
// Columns - [String, String, String, ..]
// Name - String
// Formatter - (row: Object, col: String) => String
export const downloadCsv = (
  rows,
  columns,
  name = 'newfile',
  formatter = (row, col) => row[col],
) => {
  // Generate the csv file
  const contentStr = generateCsv(rows, columns, formatter);

  // Download through the browser
  _download(name, contentStr);
};

export const generateCsv = (rows, columns, formatter = (row, col) => row[col]) => {
  // Our file header to ensure it's utf-8
  let contentStr = '';

  // Add csv headers
  const cols = columns.map(col => _escapeCsv(col));
  contentStr += `${cols.join(',')}\n`;

  // Append each column in order into the contentStr variable
  // Add a newline to the end of each to keep the pattern going
  const lines = rows.map(row => _generateCol(row, columns, formatter));
  contentStr += lines.join('');
  return contentStr;
};

const _escapeCsv = str => {
  const escapeQuotes = String(str).replace(/"/g, '""');
  return `"${escapeQuotes}"`;
};

const _generateCol = (row, columns, formatter) =>
  columns
    .map(col => {
      const val = formatter(row, col);
      if (!val) return _escapeCsv('');
      return _escapeCsv(val);
    })
    .join(',') + '\n';

const _download = (id, contentStr) => {
  const a = document.createElement('a');
  a.download = `${id}.csv`;
  a.target = '_blank';
  a.href = 'data:text/csv;base64,' + btoa(unescape('%EF%BB%BF' + encodeURIComponent(contentStr)));
  a.click();
  a.remove();
};
