import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DateHelper from '../../../../utils/DateHelper/DateHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import './VehicleRunRow.scss';
import { humanize } from '../../../../utils/EnumHelper/EnumHelper';

const VehicleRunRow = ({ idx, run }) => (
  <tr key={idx} className={idx % 2 === 0 ? '' : 'coloured'}>
    <td>{DateHelper.dateOnly(run.dateOfUse)}</td>
    <td>
      <Link to={`/movements/runs/${run.id}`}>{run.runReference}</Link>
    </td>
    <td className={'vrr-bool'} data-testid="vrr-bool">
      {run.subcontracted ? (
        <FontAwesomeIcon icon={faCheck} className="vrr-check" />
      ) : (
        <FontAwesomeIcon icon={faTimes} className="vrr-cross" />
      )}
    </td>
    <td>
      {run.subcontracted ? (
        <Link to={`/companies/entities/${run.subcontractor?.id}`} target="_blank">
          {run.subcontractor?.internalName || ''}
        </Link>
      ) : (
        `${run.vehicle?.reference || ''}`
      )}
    </td>
    <td className="fit">{run.waypoints.length}</td>
    <td>{run.closed ? 'Finalised' : humanize(run.status)}</td>
  </tr>
);

VehicleRunRow.propTypes = {
  idx: PropTypes.number.isRequired,
  run: PropTypes.object.isRequired,
};

export default VehicleRunRow;
