export const colorStyleMap = {
  'color-red': {
    color: 'rgba(255, 0, 0, 1.0)',
  },
  'color-orange': {
    color: 'rgba(255, 127, 0, 1.0)',
  },
  'color-yellow': {
    color: 'rgba(180, 180, 0, 1.0)',
  },
  'color-green': {
    color: 'rgba(0, 180, 0, 1.0)',
  },
  'color-blue': {
    color: 'rgba(0, 0, 255, 1.0)',
  },
  'color-indigo': {
    color: 'rgba(75, 0, 130, 1.0)',
  },
  'color-violet': {
    color: 'rgba(127, 0, 255, 1.0)',
  },
  'color-black': {
    color: 'rgba(0, 0, 0, 1.0)',
  },
  'color-white': {
    color: 'rgba(255, 255, 255, 1.0)',
  },
};
