import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Message from '../../../../common/layout/Message/Message';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import IconButton from '../../../../common/button/IconButton/IconButton';
import InvoiceFormDetails from './subComponents/InvoiceFormDetails/InvoiceFormDetails';
import InvoiceFormLineItems from './subComponents/InvoiceFormLineItems/InvoiceFormLineItems';
import ErrorStatusMessage from '../../../../common/ErrorStatusMessage/ErrorStatusMessage';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router';

const InvoiceForm = ({
  error,
  setError,
  alter,
  form,
  lineItems,
  handleSubmit,
  lineItemToggle,
  cancelUrl,
  title,
  warning,
  setWarning,
  loading,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Message type={'error'} text={error?.text} onClose={() => setError()} visible={!!error} />
      <ErrorStatusMessage error={warning} setError={setWarning} />
      <h1>{title}</h1>
      <TabGroup labels={['General']}>
        <div className="tab-content">
          {!loading ? (
            <Fragment>
              <InvoiceFormDetails
                entityReference={form.entityReference}
                issueDate={form.issueDate}
                dueDate={form.dueDate}
                alter={alter}
              />
              <InvoiceFormLineItems
                lineItems={lineItems}
                form={form}
                lineItemToggle={lineItemToggle}
                alter={alter}
                invoice={!title.includes('Note')}
                onlyJobs={title.includes('Sale')}
              />
            </Fragment>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </TabGroup>
      <div className="button-group">
        <IconButton text={'Save'} icon={faCheck} onClick={handleSubmit} />
        <IconButton
          text={'Cancel'}
          icon={faTimes}
          onClick={() => navigate(cancelUrl)}
          className="edit"
        />
      </div>
    </>
  );
};

InvoiceForm.propTypes = {
  lineItems: PropTypes.array,
  handleSubmit: PropTypes.func,
  cancelUrl: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.object,
  alter: PropTypes.func,
  form: PropTypes.object,
  lineItemToggle: PropTypes.func,
  setError: PropTypes.func,
  warning: PropTypes.object,
  setWarning: PropTypes.func,
  loading: PropTypes.bool,
};

export default InvoiceForm;
