import React from 'react';
import './DocumentShowTable.scss';
import PropTypes from 'prop-types';
import NumberHelper from '../../utils/NumberHelper/NumberHelper';
import LineItemTable from '../../components/Table/LineItemTable/LineItemTable';

const DocumentShowTable = ({ data, onlyJobs = false }) => {
  return (
    <div className={'show-table'}>
      <LineItemTable data={data.lineItems} onlyJobs={onlyJobs} />
      <div className={'total-section'}>
        <div className={'total'}>TOTAL</div>
        <div className={'total-value'}>
          {NumberHelper.formatNumToCurrency(NumberHelper.calTotal(data.lineItems))}
        </div>
      </div>
    </div>
  );
};

DocumentShowTable.propTypes = {
  data: PropTypes.object,
  onlyJobs: PropTypes.bool,
};

export default DocumentShowTable;
