import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faCheck, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import ErrorStatusMessage from '../../../../common/ErrorStatusMessage/ErrorStatusMessage';
import EmailComposeModal from '../../../../components/EmailComposeModal/EmailComposeModal';
import Message from '../../../../common/layout/Message/Message';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import DocumentShowGeneral from '../../../../components/DocumentShowGeneral/DocumentShowGeneral';
import AttachmentsTab from '../../../../components/AttachmentsTab/AttachmentsTab';
import EmailsTab from '../../../Company/Entity/EntityShow/subComponents/EmailsTab/EmailsTab';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import NoPage from '../../../NoPage/NoPage';
import Pdf from '../../../../api/controllers/File/Pdf/Pdf';
import Item from '../../../../api/controllers/File/Item/Item';
import SalesCredit from '../../../../api/controllers/Financial/SalesCredit/SalesCredit';
import useArray from '../../../../common/hooks/useArray/useArray';
import useApi from '../../../../common/hooks/useApi/useApi';
import UnFinalisedOptions from '../../../../components/UnFinalisedOptions/UnFinalisedOptions';
import FinalisedOptions from '../../../../components/FinalisedOptions/FinalisedOptions';
import { handleDeleteDocument } from '../../../../utils/documentUtils/documentUtils';
import '../../Document/DocumentShow/DocumentShow.scss';
import { useNavigate, useParams } from 'react-router';
import NotesTab from '../../../Company/Entity/EntityShow/subComponents/NotesTab/NotesTab';
import { isEqual } from 'lodash';
import DocumentHelper from '../../../../utils/DocumentHelper/DocumentHelper';
import Sale from '../../../../api/controllers/Financial/Sale/Sale';

const SalesInvoiceShow = ({ permissions, user, bulk = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const documentId = id;
  const { data, refresh, dataLoading } = useApi({
    call: () => SalesCredit.show(documentId),
    handleError: () => setNotFound(true),
  });
  const [error, setError] = useState();
  const [notFound, setNotFound] = useState(false);
  const [open, setOpen] = useState(false);
  const [bookingPod, setBookingPod] = useState();
  const [emailModal, setEmailModal] = useState();
  const [pdfAlert, setPdfAlert] = useState();
  const [potentialLineItems, setPotentialLineItems] = useState();
  const [warnings, setWarnings] = useState({ errorTitle: 'Attention!', errors: [] });
  const [buttonLoad, setButtonLoad] = useState(false);

  const { defaultAttachments, handleDefaultAttachments } = useArray({
    name: 'defaultAttachments',
  });

  const url = url => (bulk ? url.replace('sales_invoice', 'bulk_sales_invoice') : url);
  const text = text => (bulk ? text.replace('Sales Invoice', 'Bulk Sales Invoice') : text);

  useEffect(() => {
    if (!data?.lineItems) return;
    const dJobs = getDistinctJobs(data.lineItems);
    Item.allArr(dJobs).then(jobResArr => {
      const refs = [];
      jobResArr.forEach((res, i) => {
        res.items.filter(file => file.pod).length === 0 &&
          refs.push(`${dJobs[i].parentId}`.padStart(6, 0));
      });
      setBookingPod(refs);
    });
  }, [data]);

  useEffect(() => {
    if (!data?.entity) return;

    if (bulk)
      Sale.lineItems({
        entityId: data.entity?.id,
        documentId: documentId,
      }).then(res => setPotentialLineItems(res));
  }, [data]);

  useEffect(() => {
    if (!data) return;
    const warningsArray = [];
    if (!data?.entity?.customerAccount)
      warningsArray.push('This entity does not have an associated customer account');
    if (bookingPod)
      bookingPod.forEach(ref =>
        warningsArray.push(`Job ${ref} does not yet have Proof Of Delivery`),
      );
    if (bulk && data?.lineItems) {
      const potentialLines = potentialLineItems?.items || [];
      const currentIds = data?.lineItems.map(item => item.id);
      const currentLines = potentialLines.filter(line => currentIds.includes(line.id));
      const periodMismatches = DocumentHelper.getCalendarPeriodMismatches(
        currentLines,
        data?.bulkInvoicing,
      );
      const missMatchMessage = DocumentHelper.formatMissMatchToMessage(periodMismatches);
      if (missMatchMessage) warningsArray.push(missMatchMessage);

      if (currentLines.length > 0) {
        const key = DocumentHelper.getReferenceDate(currentLines[0]);
        const { start, end } = DocumentHelper.getPeriods(key, data?.bulkInvoicing);

        const { missingItems, period } = DocumentHelper.getMissingLineItemsForPeriod(
          currentIds,
          potentialLines,
          start,
          end,
        );
        const message = DocumentHelper.formatMissingItemsToMessage(missingItems, period);
        if (message) warningsArray.push(message);
      }
    }
    setWarnings({ errorTitle: 'Attention!', errors: warningsArray });
  }, [data, bookingPod, potentialLineItems]);

  const filterVal = (value, index, self) => {
    return self.findIndex(v => v.parent?.id === value.parent?.id) === index;
  };

  // Returns the distinct id/parentType of all line items
  const getDistinctJobs = lineItems => {
    const isUniq = (res, val) => res.filter(li => isEqual(val, li)).length === 0;

    return lineItems.reduce((res, li) => {
      const val = { parentId: li.parent.id, parentType: li.parent.modelName };
      isUniq(res, val) && res.push(val);
      return res;
    }, []);
  };

  const handleSendEmail = (e, kind) => {
    e.preventDefault();
    setButtonLoad(true);

    Item.allArr(getDistinctJobs(data.lineItems))
      .then(jobResArr => {
        const podFiles = [];
        jobResArr.map(res => {
          const pods = res.items.filter(item => item.pod);
          pods.length > 0 && pods.forEach(file => podFiles.push(file));
        });
        handleDefaultAttachments([...podFiles]);
      })
      .then(() =>
        Pdf.generate({ kind, id: data.id }).then(
          pdfRes => {
            setEmailModal({ kind, defaultAttachments: [{ ...pdfRes, attachmentId: pdfRes.id }] });
            setButtonLoad(false);
          },
          res => {
            setPdfAlert(res);
            setButtonLoad(false);
          },
        ),
      );
  };

  if (notFound) return <NoPage />;
  if (dataLoading) return <LoadingSpinner />;
  if (!data) return null;

  return (
    <div className={'show-booking-page'}>
      <Breadcrumbs>
        <Link to={url(`/financials/sales_invoices`)}>{text(`Sales Invoices`)}</Link>
        <Link to={url(`/financials/sales_invoices/${documentId}`)}>{data.documentReference}</Link>
      </Breadcrumbs>
      <Ribbon>
        <div className="split">
          {data.finalised && (
            <IconButton
              text={'Send Email'}
              onClick={e => handleSendEmail(e, 'sales_invoice')}
              icon={faEnvelopeOpenText}
              disabled={data?.entity.customerAccount === null || buttonLoad}
            />
          )}
        </div>
        <div className="split">
          {data.apiUrn !== null && (
            <IconButton
              text={`Ref. ${data.apiUrn}`}
              icon={faCheck}
              className={'success-disabled'}
            />
          )}
          {!data.apiUrn && data.finalised && (
            <FinalisedOptions
              documentId={documentId}
              refresh={refresh}
              setError={setError}
              title={text(`Sales Invoice`)}
              permissions={permissions}
            />
          )}
          {!data.apiUrn && !data.finalised && (
            <UnFinalisedOptions
              documentId={documentId}
              data={data}
              refresh={refresh}
              setError={setError}
              setOpen={setOpen}
              type={'Invoice'}
              title={text(`Sales Invoice`)}
              index={url(`/financials/sales_invoices`)}
              open={open}
              permissions={permissions}
            />
          )}
        </div>
      </Ribbon>
      <ConfirmationModal
        question={`Are you sure you want to delete this invoice?`}
        subText={data.documentReference}
        isOpen={open}
        confirmText={'Delete'}
        handleCancel={() => setOpen(false)}
        handleConfirm={e =>
          handleDeleteDocument(
            e,
            data,
            navigate,
            url('/financials/sales_invoices'),
            setError,
            setOpen,
          )
        }
      />
      <ErrorStatusMessage error={pdfAlert} setError={setPdfAlert} />

      {emailModal && (
        <EmailComposeModal
          user={user}
          parent={data}
          recipientEntity={data.entity}
          templateType={url(emailModal.kind)}
          handleClose={() => setEmailModal()}
          fileTabs={[
            { tabName: 'General Files' },
            { tabName: 'Document Files', parent: data },
          ].concat(
            data?.lineItems.filter(filterVal).map(item => ({
              tabName: `Job ${item.parent.jobReference}`,
              parent: item?.parent,
            })),
          )}
          defaultAttachments={[...emailModal.defaultAttachments, ...defaultAttachments]}
          setAlert={setPdfAlert}
        />
      )}

      <div className={'page-content'}>
        <h1>
          {text('Sales Invoice')} for {data.entity.internalName}
          <Message
            text={warnings}
            type={'error non-dismissable'}
            visible={warnings.errors.length > 0}
            dismissable={false}
          />
          <Message
            text={error}
            type={error?.type}
            onClick={() => setError()}
            visible={!!error}
            onClose={setError}
          />
        </h1>
        <TabGroup labels={['General', 'Attachments', 'Emails', 'Notes']}>
          <DocumentShowGeneral
            data={data}
            documentDetails={{
              title: text('Sales Invoice'),
              type: 'Invoice',
              index: url('/financials/sales_invoices'),
            }}
          />
          <AttachmentsTab
            permissions={permissions}
            parentId={data.id}
            parentType={'Financials::Document'}
            requiredPermit={'financialsDocuments'}
            setError={setError}
          />
          <EmailsTab parentId={data.id} parentType={data.modelName} />
          <NotesTab
            parentId={data.id}
            parentType={data.modelName}
            canDelete={user.administrator}
            permissions={permissions}
            requiredPermit={'financialsDocuments'}
          />
        </TabGroup>
      </div>
    </div>
  );
};
SalesInvoiceShow.propTypes = {
  permissions: PropTypes.object,
  user: PropTypes.object,
  bulk: PropTypes.bool,
};
export default SalesInvoiceShow;
