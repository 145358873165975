import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './DropdownPresenter.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSortDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { createPortal } from 'react-dom';
import DropdownHelper from '../../../utils/DropdownHelper/DropdownHelper';

const DropdownPresenter = ({
  value,
  drawOptions,
  onSearch,
  open,
  setOpen,
  disabled = false,
  testId,
  dropdownHeight = 32,
  onClear,
  focusName,
  defaultCoords = { top: 0, left: 0, width: '200px' },
}) => {
  const [coords, setCoords] = useState(defaultCoords);
  const { isDropdownRelated } = DropdownHelper;
  const nodeRef = useRef();

  // This function gets the closest parent element with overflow
  const getScrollParent = node => {
    if (node === null) return null; // If you're not using this properly, return null
    if (node.scrollHeight > node.clientHeight) return node; // If the node has a larger scroll height that client height that's our parent

    // Else recursively search up the document FOREVEEEEEEEEEEEEEEEEEEEEEEER
    return getScrollParent(node.parentNode);
  };

  /*
    Why? This VERY IMPORTANT function places the dropdown on the screen just beneath the button that toggles it
    Notes? The reason for this is that we were asked to make the dropdown visible, even when inside of an element
      with the CSS property "overflow: hidden", without heavy CSS hackiness this is difficult to accomplish.
      Instead we have neatly sidestepped most of the problems that could spring from this through the use of React portals.
  */
  const handleReAlign = () => setCoords(nodeRef.current.getBoundingClientRect());

  // Handle the user clicking onto the main button to open the dropdown
  const handleClick = e => {
    e.preventDefault();
    if (disabled) return; // If the button is disabled, we don't want stuff to happen
    handleReAlign(); // Get the current nodeRef's coordinates / sizes
    setOpen(!open); // Toggle the dropdown
  };

  const handleClear = e => {
    e.preventDefault();
    e.stopPropagation();
    onClear();
  };

  // Handle clicks outside of the portal-root and dropdown
  const handleClickOutside = e => {
    if (isDropdownRelated(e, nodeRef)) return;
    setOpen(false);
  };

  // Gets the search of the currently active dropdown
  const getSearchInput = () =>
    document.getElementById('portal-root')?.firstChild?.firstChild?.firstChild;

  // Gets the options container (the bot below the search)
  const getDropdownContent = () => document.getElementById('portal-root')?.firstChild?.lastChild;

  // These keybinds are used within multiple contexts
  const defaultOpenKeybinds = e => {
    // Begin handling keybindings to move between dropdown search and options
    // If down arrow key pressed
    if (e.keyCode === 40) {
      e.preventDefault(); // We put this within each key instance we want to intercept only
      // Is the current focus the dropdown search?
      if (e.target.id === 'dd-search' || e.target.id === 'dd-main-button') {
        // Focus the first option in the dropdown
        document.getElementsByClassName('dd-options')[0].firstElementChild?.focus();
      } else if (e.target.nextElementSibling) {
        // Focus the next option down
        e.target.nextElementSibling.focus();
      }
    }

    // If up arrow key pressed
    if (e.keyCode === 38) {
      e.preventDefault();
      // If there is a valid element targetted
      if (e.target.previousElementSibling) {
        // Focus the element
        e.target.previousElementSibling.focus();
      } else {
        // Else just focus the search
        document.getElementById('dd-search')?.focus();
      }
    }

    // If escape pressed and dropdown is open then close and focus the dropdown
    if (e.keyCode === 27) {
      e.preventDefault();
      setOpen(false);
      nodeRef.current.firstChild.focus();
    }

    // If tab pressed, select option and tab regularly
    if (e.key === 'Tab') {
      const options = document.getElementsByClassName('dd-button');
      if (options.length === 0) {
        e.preventDefault();
      } else {
        if (e.target === getSearchInput()) return options[0].click();
        e.target.click();
      }
    }
  };

  // Handles keypresses outside of the portal-root and dropdown
  // This REALLY needs refactoring but I need this done quickly
  // The reasoning I give for this needing refactoring is that it loads in
  const handleKeydown = e => {
    if (nodeRef.current === null) return;
    handleReAlign();
    if (open && !isDropdownRelated(e, nodeRef)) {
      setOpen(false); // Close the dropdown as the interaction is out of scope
    } else if (open && e.target === getSearchInput()) {
      const content = getDropdownContent(); // Get the content container

      // If the Enter key is pressed, we simply redirect our focus to the first option available and the default action will handle submission
      if (e.key === 'Enter') {
        if (!content) return; // If we didn't get it, default action
        return content.firstChild?.focus(); // Focus and default action performed
      }

      // If the arrow down key is pressed while we focus the search, we want to skip to the second option
      if (e.key === 'ArrowDown') {
        e.preventDefault(); // This prevents the page from scrolling down
        if (!content || content.children.length < 2) return; // If there is no content, do nothing
        return content.children[1].focus(); // Focus the second option if it's present
      }

      // Default keybinds for all basic interactions when the dropdown is open
      defaultOpenKeybinds(e);
    } else if (open) {
      // Default keybinds for all basic interactions when the dropdown is open
      defaultOpenKeybinds(e);

      // If the dropdown is NOT open and the target focus is "this" specific dropdown
    } else if (!open && e.target === nodeRef.current.firstChild) {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault(); // Stops the page scrolling
        setOpen(true); // Opens the dropdown
      }

      // If the keypress is a regular key (not "Tab" or "Shift") and is alphanumeric (a relevant search value)
      // Note that we DO want an exception for % as we use %% as a debug to return all of an entity
      if (onSearch && e.key.length === 1 && /[a-zA-Z0-9%]$/i.test(e.key)) {
        setOpen(true); // Open the dropdown
        handleReAlign(); // Ensure our dropdown is correctly placed on the screen
        onSearch({ target: { value: e.key } }); // Default the search to the key from the keyPress event
      }
    }
  };

  // I wrote this function to handle people pasting from the menu bar as we can't handle it within keypress
  const handlePaste = e => {
    if (!nodeRef.current) return; // If there is no ref, return
    if (open) return; // If it is open, we don't want to handle the event
    if (nodeRef.current == e.target) {
      setOpen(true); // Open the dropdown
      handleReAlign(); // Ensure our dropdown is correctly placed on the screen
      const text = e.clipboardData.getData('text');
      onSearch({ target: { value: text } }); // Default the search to the key from the keyPress event
      getSearchInput().value = text; // As we do not handle the search through stateful means, we must set it manually
    }
  };

  /*
    Why? This useEffect handles the loading and unloading of all page event listeners
  */
  useEffect(() => {
    // dropdownScrollParent tracks which overflow we should be using to measure the position of the dropdown
    const dropdownScrollParent = getScrollParent(nodeRef.current);

    // Add all of our event listeners for handling keypresses and the like
    document.addEventListener('mousedown', handleClickOutside, { capture: true });
    document.addEventListener('keydown', handleKeydown);
    window.addEventListener('resize', handleReAlign);
    window.addEventListener('paste', handlePaste);
    if (dropdownScrollParent) dropdownScrollParent.addEventListener('scroll', handleReAlign);

    // Terminate all that jazz so it doesn't try to do anything sus
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('resize', handleReAlign);
      window.removeEventListener('paste', handlePaste);
      if (dropdownScrollParent) dropdownScrollParent.removeEventListener('scroll', handleReAlign);
    };
  }, [nodeRef, open]);

  /*
    Why? To ensure the dropdown will be placed in the correct area.
      Returns the style object for the dropdown content.
  */
  const calculateContentDimensions = () => {
    // To ensure that scrolling and resizing do not affect the location
    const scrollY = document.documentElement.scrollTop;
    const scrollX = document.documentElement.scrollLeft;

    // Set the coordinates with the above in mind
    const top = `${coords.top + dropdownHeight + scrollY}px`; // 42px is the size of the dropdown button
    const left = `${coords.left + scrollX}px`;
    const width = `${coords.width - 2}px`; // 2px for the border pixels, this keeps everything inline

    return { position: 'absolute', top, left, width };
  };

  return (
    <div className={'dd-main'} ref={nodeRef} data-testid={testId}>
      <button
        tabIndex={0}
        className={'dd-current'}
        onClick={handleClick}
        disabled={disabled}
        data-focus={focusName}
        id={'dd-main-button'}
      >
        <div className={'dd-text'}>{value}</div>
        {onClear && !disabled && value && (
          <FontAwesomeIcon icon={faTimes} className={'clear'} onClick={handleClear} />
        )}
        <FontAwesomeIcon icon={faSortDown} />
      </button>

      {open &&
        createPortal(
          <div tabIndex={-1} className={'dd-content'} style={calculateContentDimensions()}>
            {onSearch && (
              <div className={'dd-search'}>
                <input
                  autoFocus
                  className={'dd-input'}
                  id="dd-search"
                  onChange={onSearch}
                  placeholder={'Search here...'}
                />
                <FontAwesomeIcon icon={faSearch} />
              </div>
            )}
            <div className={'dd-options'} tabIndex={-1}>
              {drawOptions(nodeRef)}
            </div>
          </div>,
          document.getElementById('portal-root'),
        )}
    </div>
  );
};

DropdownPresenter.propTypes = {
  value: PropTypes.string,
  drawOptions: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  onClear: PropTypes.func,
  focusName: PropTypes.string,
  dropdownHeight: PropTypes.number,
  defaultCoords: PropTypes.object,
};

export default DropdownPresenter;
