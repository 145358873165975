import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useApi from '../../../common/hooks/useApi/useApi';
import TablePresenter from '../TablePresenter/TablePresenter';
import { debounce } from 'lodash';
import TableHelper from '../../../utils/TableHelper/TableHelper';

const AjaxTable = ({
  title,
  headers,
  call,
  onDrawRow,
  filterObj = {},
  limit = 15,
  defaultSort = '',
  children,
}) => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState(defaultSort);

  const params = {
    filter: searchText,
    limit,
    offset: limit * (currentPage - 1),
    ...sort,
    ...filterObj,
  };

  const { data } = useApi({
    call,
    params,
    dependencies: [searchText, currentPage, sort, filterObj],
  });

  const onSearch = debounce(e => {
    setSearchText(e.target.value);
  }, 200);

  const pageNum = data && Math.ceil(data.total / limit);

  useEffect(() => {
    if (!currentPage === 1) setCurrentPage(1);
  }, [searchText, filterObj]);

  if (!data) return null;
  const lowerBound = (currentPage - 1) * limit + 1;
  const upperBound = currentPage * limit + (data.items.length - limit);

  return (
    <TablePresenter
      title={title}
      headers={headers}
      rows={data.items}
      onDrawRow={onDrawRow}
      sort={sort}
      onSort={header => TableHelper.onSort(header, sort, setSort)}
      onSearch={onSearch}
      pageElements={() => TableHelper.genPageNumbers(currentPage, setCurrentPage, pageNum)}
      pageText={TableHelper.paginationText(upperBound, lowerBound, data.total)}
    >
      {children}
    </TablePresenter>
  );
};

AjaxTable.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.object),
  call: PropTypes.func,
  onDrawRow: PropTypes.func,
  filterObj: PropTypes.object,
  limit: PropTypes.number,
  defaultSort: PropTypes.object,
  children: PropTypes.node,
};

export default AjaxTable;
