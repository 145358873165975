import BaseXlsx from '../BaseXlsx/BaseXlsx';
import { writeFile, utils } from 'xlsx-js-style';
import VehicleRunHelper from '../../VehicleRunHelper/VehicleRunHelper';
import { capitalize } from 'lodash';
import DateHelper from '../../DateHelper/DateHelper';
import search from '../../search/search';

const COL_WIDTHS = [
  { width: 5 }, // COL/DEL/XT
  { width: 13 }, //status
  { width: 10 }, //job ref 000001
  { width: 15 }, //customer
  { width: 15 }, //customer ref
  { width: 25 }, //collection
  { width: 10 }, //col.postcode
  { width: 25 }, //delivery
  { width: 10 }, //del.postcode
  { width: 8 }, // packages
  { width: 8 }, // grossWeight
  { width: 8 }, // cubicMetres
  { width: 8 }, // loadingMetres
  { width: 10 }, //Hazards
  { width: 13 }, //col.date
  { width: 13 }, //del.date
];

//Used for generating excel files of the current Vehicle Run Planner view
class VehicleRunPlannerXlsx extends BaseXlsx {
  constructor(bookings = [], vehicleRuns = [], openRuns = [], filters, fsId, hasSubwindow = false) {
    super();

    this.bookings = bookings;
    this.vehicleRuns = vehicleRuns;
    this.openRuns = openRuns;
    this.filters = filters;
    this.fsId = fsId;
    this.hasSubwindow = hasSubwindow;
    this.genVehicleRunRows = this.genVehicleRunRows.bind(this);
    this.genSectionRows = this.genSectionRows.bind(this);
  }

  genVehicleRunRows() {
    let runRows = [];
    //applies the current search filter, to match UI
    this._getFilteredRuns(this.filters).forEach(run => {
      //checked the subcontract filter matches
      if (this.filters.subcontracted !== null && run.subcontracted !== this.filters.subcontracted)
        return [];
      //checks the status filter matches
      if (!this.filters.status.includes(run.status)) return [];

      let row = [];
      //adds subcontract ref or vehicle ref
      if (run.subcontracted) {
        row.push(this._cell(run.subcontractReference || '???', 'vehicleRunHeader'));
      } else {
        row.push(this._cell(run.vehicle?.reference || '???', 'vehicleRunHeader'));
      }

      //add spacer and run date of Use
      row = row.concat([
        this._cell('', 'vehicleRunHeader'),
        this._cell('', 'vehicleRunHeader'),
        this._cell(DateHelper.dateOnly(run.dateOfUse), 'vehicleRunHeader'),
      ]);

      //add subcontractor if needed
      if (run.subcontracted) {
        row.push(
          this._cell(
            run.subcontractor?.shortName || run.subcontractor?.internalName || '???',
            'vehicleRunHeader',
          ),
        );
      } else if (run.driver) {
        row.push(this._cell(`${run.driver.firstName} ${run.driver.lastName}`, 'vehicleRunHeader'));
      } else {
        row.push(this._cell('???', 'vehicleRunHeader'));
      }

      //fill out run header background
      row = row.concat(this._fillEmpty(8, 'vehicleRunHeader'));

      //add subcontractor if needed
      if (run.subcontracted) {
        row.push(this._cell('Subcontracted', 'vehicleRunHeader'));
      } else {
        row.push(this._cell('', 'vehicleRunHeader'));
      }

      //fill out run header background
      row = row.concat(this._fillEmpty(2, 'vehicleRunHeader'));

      runRows.push(row);

      //add the job rows
      const runBookings = this.bookings.filter(booking => booking.vehicleRunId === run.id);
      runBookings.forEach(booking => runRows.push(this._genJobRow(booking)));

      //Add the run footer.
      runRows.push([
        this._cell('', 'vehicleRunFooter'),
        this._cell(
          `${run.bookings.length} Job${run.bookings.length !== 1 ? 's' : ''}`,
          'vehicleRunFooter',
        ),
        this._cell('', 'vehicleRunFooter'),
        this._cell('COL', `job-COL`),
        this._runFooterInfo(run.bookings, 'COL'),
        this._cell('', 'vehicleRunFooter'),
        this._cell('DEL', `job-DEL`),
        this._runFooterInfo(run.bookings, 'DEL'),
        this._cell('', 'vehicleRunFooter'),
        this._cell('XT', `job-XT`),
        this._runFooterInfo(run.bookings, 'XT'),
        ...this._fillEmpty(5, 'vehicleRunFooter'),
      ]);

      //add a spacer after run
      runRows.push([]);
    });
    return runRows;
  }

  genSectionRows(section) {
    let runRows = [];

    //add the section headers
    runRows.push([
      this._cell(capitalize(section), `${section}Header`),
      this._cell('', `${section}Header`),
      this._cell('Job', `${section}Header`),
      this._cell('Customer', `${section}Header`),
      this._cell('Customer Ref', `${section}Header`),
      this._cell('Collection', `${section}Header`),
      this._cell('Col. PC', `${section}Header`),
      this._cell('Delivery', `${section}Header`),
      this._cell('Del. PC', `${section}Header`),
      this._cell('Pkg', `${section}Header`),
      this._cell('Kg', `${section}Header`),
      this._cell('Cube', `${section}Header`),
      this._cell('LDM', `${section}Header`),
      this._cell('Hazards', `${section}Header`),
      this._cell('Col.', `${section}Header`),
      this._cell('Del.', `${section}Header`),
    ]);

    //add the job rows
    const runBookings = this.bookings.filter(booking => this._jobFilter(section, booking));
    runBookings.forEach(booking => runRows.push(this._genJobRow(booking)));

    //Add the section footer.
    runRows.push(this._fillEmpty(16, `${section}Footer`));

    //add a spacer after section
    runRows.push([]);
    return runRows;
  }

  generate() {
    //initializes the Array that will hold the generated data
    let wsArray = [];
    wsArray = wsArray.concat(this.genVehicleRunRows());
    //Don't show unassigned / pending if there is currently a sub-window
    if (!this.hasSubwindow) {
      wsArray = wsArray.concat(this.genSectionRows('unassigned'));
      wsArray = wsArray.concat(this.genSectionRows('pending'));
    }
    // creates a new Workbook
    const wb = utils.book_new();
    //converts Array of arrays to WorkSheet
    const ws = utils.aoa_to_sheet(wsArray);
    //sets the column widths
    ws['!cols'] = COL_WIDTHS;
    utils.book_append_sheet(wb, ws);
    writeFile(wb, 'planner.xlsx'); //save the generated document
  }

  // returns a list of vehicle runs, after applying a set of filters
  _getFilteredRuns(currentFilters) {
    //filters the run statuses, and if its subcontracted
    const filteredRuns = this.vehicleRuns.filter(
      run =>
        (currentFilters.subcontracted === null ||
          run.subcontracted === currentFilters.subcontracted) &&
        currentFilters.status.includes(run.status),
    );
    //applies the search filter, and sorts so its in the correct order.
    return search(currentFilters.filter, filteredRuns, [
      'vehicle.reference',
      'subcontractReference',
    ]).sort((a, b) => a.id - b.id);
  }

  _fillEmpty(length, className) {
    return Array.from({ length: length }, () => this._cell('', className));
  }

  //returns the row of a given job
  _genJobRow(job) {
    const direction = VehicleRunHelper.getJobDirection(
      this.fsId,
      job.collectionAddress?.id,
      job.deliveryAddress?.id,
    );
    const row = [
      this._cell(direction, `job-${direction}`),
      this._cell(capitalize(job.status)),
      this._cell(job.jobReference),
      this._cell(job.customer?.shortName || job.customer?.internalName),
      this._cell(job.customerReference),
      this._cell(this._getColDelString(job.collectionEntity, job.collectionAddress)),
      this._cell(job.collectionAddress?.postcode),
      this._cell(this._getColDelString(job.deliveryEntity, job.deliveryAddress)),
      this._cell(job.deliveryAddress?.postcode),
      this._cell(job.quantity, '', 'n'),
      this._cell(job.grossWeightKg, '', 'n'),
      this._cell(job.cubicMetres, '', 'n'),
      this._cell(job.loadingMetres, '', 'n'),
      job.hazardousCargo ? this._cell('Hazards', 'hazards') : this._cell(''),
      this._cell(job.collectionDate ? DateHelper.dateOnly(job.collectionDate) : ''),
      this._cell(job.deliveryDate ? DateHelper.dateOnly(job.deliveryDate) : ''),
    ];

    return row;
  }

  //returns the filter required to job filtering sections
  _jobFilter(section, job) {
    const checkDeliveryType = (job, deliveryType) => {
      if (!deliveryType?.type) return true;
      return (
        deliveryType.type ===
        VehicleRunHelper.getJobDirection(
          this.fsId,
          job.collectionAddress?.id,
          job.deliveryAddress?.id,
        )
      );
    };

    if (section === 'unassigned')
      return (
        job &&
        job.status === 'active' &&
        !job.subcontracted &&
        !job.vehicleRunId &&
        checkDeliveryType(job, this.filters.deliveryTypeUnassigned)
      );
    if (section === 'pending')
      return (
        job &&
        job.status === 'pending' &&
        !job.subcontracted &&
        !job.vehicleRunId &&
        checkDeliveryType(job, this.filters.deliveryTypePending)
      );
  }

  _runFooterInfo(jobs, section) {
    const filteredJobs = jobs.filter(
      job =>
        VehicleRunHelper.getJobDirection(
          this.fsId,
          job.collectionAddressId,
          job.deliveryAddressId,
        ) === section,
    );
    const totalPackages = filteredJobs.reduce((prev, curr) => prev + Number(curr.quantity), 0);
    const totalGrossWeight = filteredJobs.reduce(
      (prev, curr) => prev + Number(curr.grossWeightKg),
      0,
    );
    const totalCubicMetres = filteredJobs.reduce(
      (prev, curr) => prev + Number(curr.cubicMetres),
      0,
    );
    const totalLoadingMetres = filteredJobs.reduce(
      (prev, curr) => prev + Number(curr.loadingMetres),
      0,
    );
    return this._cell(
      `${totalPackages} pkg${
        totalPackages !== 1 ? 's' : ''
      } / ${totalGrossWeight}kg / ${totalCubicMetres}m³ / ${totalLoadingMetres}m`,
      'vehicleRunFooter',
    );
  }

  //Returns a cell in the correct format
  _cell(value, style = '', type) {
    if (type) {
      return { v: value, t: type, s: this._styles(style) };
    }
    return { v: value, s: this._styles(style) };
  }

  //gets the string for a collection / delivery field on a job
  _getColDelString(entity, address) {
    if (!entity && !address) return '';
    return `${entity?.shortName || entity?.internalName || ''} / ${address?.region || ''}`;
  }

  //returns the relevant styling for a cell
  _styles(type) {
    switch (true) {
      case type === 'vehicleRunHeader':
        return {
          font: { name: 'Calibri', bold: true, color: { rgb: 'FFFFFF' } },
          fill: { fgColor: { rgb: '555555' } },
        };
      case type === 'vehicleRunFooter':
        return {
          font: { name: 'Calibri', color: { rgb: 'FFFFFF' } },
          fill: { fgColor: { rgb: '676666' } },
        };
      case type === 'unassignedHeader':
        return {
          font: { name: 'Calibri', bold: true, color: { rgb: 'FFFFFF' } },
          fill: { fgColor: { rgb: '176195' } },
        };
      case type === 'unassignedFooter':
        return {
          font: { name: 'Calibri', color: { rgb: 'FFFFFF' } },
          fill: { fgColor: { rgb: '1977b4' } },
        };
      case type === 'pendingHeader':
        return {
          font: { name: 'Calibri', bold: true, color: { rgb: 'FFFFFF' } },
          fill: { fgColor: { rgb: '269de9' } },
        };
      case type === 'hazards':
        return {
          font: { name: 'Calibri', color: { rgb: 'b94a48' } },
          fill: { fgColor: { rgb: 'ebccd1' } },
          alignment: { horizontal: 'center' },
        };
      case type === 'pendingFooter':
        return {
          font: { name: 'Calibri', color: { rgb: 'FFFFFF' } },
          fill: { fgColor: { rgb: '4fabe6' } },
        };
      case type === 'job-COL':
        return {
          font: { name: 'Calibri', color: { rgb: 'FFFFFF' } },
          fill: { fgColor: { rgb: '1977b4' } },
          alignment: { horizontal: 'center' },
        };
      case type === 'job-DEL':
        return {
          font: { name: 'Calibri', color: { rgb: 'FFFFFF' } },
          fill: { fgColor: { rgb: '5fbd5e' } },
          alignment: { horizontal: 'center' },
        };
      case type === 'job-XT':
        return {
          font: { name: 'Calibri', color: { rgb: 'FFFFFF' } },
          fill: { fgColor: { rgb: 'cbcbcb' } },
          alignment: { horizontal: 'center' },
        };

      default:
        return {
          font: { name: 'Calibri', color: { rgb: '555555' } },
        };
    }
  }
}

export default VehicleRunPlannerXlsx;
