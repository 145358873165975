import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Dropzone.scss';
import Booking from '../../../../../api/controllers/Job/Booking/Booking';
import DestroyMultipleAction from '../../actions/DestroyMultipleAction/DestroyMultipleAction';
import UpdateMultipleAction from '../../actions/UpdateMultipleAction/UpdateMultipleAction';
import VehicleRunHelper from '../../../../../utils/VehicleRunHelper/VehicleRunHelper';

//a dropzone wrapper for vehicle runs, allowing for the DnD of jobs onto vehicle runs or
// onto the pending/unassigned sections
const Dropzone = ({
  section,
  setError,
  children,
  set = () => {},
  current,
  rowRemovalAction,
  setHighlighted,
  error,
  setCurrentDraggingJobs,
  parent,
  subwindow,
}) => {
  /* this decides if we are currently over the dropzone
     this cannot just be a simple true false as there is layers for each child element
     0 is not in zone, 1 is parent (this component), 2 (first child), ... 
  */
  const [dragDepth, setDragDepth] = useState(0);

  //Controls what happens when you enter a dropzone while dragging.
  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    //set the depth to be 1 deeper
    setDragDepth(dragDepth + 1);
  };

  //Controls what happens when you leave a dropzone while dragging.
  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    // reduce depth
    setDragDepth(dragDepth - 1);
  };

  /*
    Handles what happens when you let go of a drag while over a dropzone.

    This resets the drag depth (removing the hover CSS), then clears the fake image we create for the dragImage. 
    We then get the data from the the drag, parsing it as JSON, if its current section is the same, we just revert the local removal.
    Otherwise, we then create a list of updated jobs with hte new section, then update them locally while a request is made.
  */
  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();

    //reset the depth back to zero
    setDragDepth(0);

    const portal = document.getElementById('portal-root');
    portal.className = '';
    portal.innerHTML = '';

    const text = e.dataTransfer.getData('application/json');
    if (!text) return; // prevent user dropping something unexpected

    const json = JSON.parse(text);

    // if the previous section is the same as the new one, undo local removal and stop
    if (json.oldSection === section) return rowRemovalAction.undoAll();

    //Locally updates the job so the user gets instant feedback
    const updatedJobs = json.jobs.map(job => ({
      ...job,
      vehicleRunId: typeof section === 'number' ? section : null,
      status: VehicleRunHelper.calculateNewJobStatus(job.status, section),
    }));

    /*
      In response to TSR-1152
      Joins the rowRemovalAction / currentJobs, then filters them, so theres only unique ones.
      Then update the jobs to their new section
    */
    const currentJobs = current
      .concat(rowRemovalAction.oldState)
      .filter((value, index, self) => index === self.findIndex(t => t.id === value.id));
    const action = new UpdateMultipleAction(set, currentJobs, updatedJobs);
    action.execute();

    //Stores the Ids of jobs its fails to update
    let failedUpdates = [];
    let moveErrors = error;

    /*
      Goes through the list of jobs and updates them to the new sections, displaying an error and updating 
      the list of failed jobs in the instance that we cant update it, the anonymous function allows us to
      make sure it happens in order so the backend encounters no race condition.
      This is followed by a .then to signify when the operations are all completes and undoes any failed 
      actions. 
    */
    (async () => {
      for (const [idx] of updatedJobs.entries()) {
        try {
          await Booking.update(json.jobs[idx].id, {
            vehicleRunId: typeof section === 'number' ? section : null,
            status: VehicleRunHelper.calculateNewJobStatus(json.jobs[idx].status, section),
          });
        } catch (e) {
          moveErrors.push({
            status: 'RunUpdate',
            ref: json.jobs[idx].jobReference,
            error: e.error,
            errors: e.errors,
          });
          //If it fails, mark id to be undo
          failedUpdates.push(json.jobs[idx].id);
        }
      }
    })().then(() => {
      //if any updates failed, revert them here
      if (failedUpdates.length > 0) {
        setError(moveErrors);
        rowRemovalAction.undo(failedUpdates);
        action.undo(failedUpdates);
      }

      //sends a message to any sub/parent window
      if (subwindow) {
        subwindow.postMessage({
          type: 'droppedInSection',
          failedUpdates: failedUpdates,
          moveErrors: moveErrors,
        });
      }
      if (parent) {
        parent.postMessage({
          type: 'droppedInSection',
          failedUpdates: failedUpdates,
          moveErrors: moveErrors,
        });
      }

      //Then clear the current dragging items and highlighted ones ready for the next time a user selects items.
      setCurrentDraggingJobs([]);
      setHighlighted([]);
    });
  };

  //i dont know why i have to handle drag over but the drop handler doesn't work without it
  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={`dropzone${dragDepth ? ' awaiting' : ''}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {children}
    </div>
  );
};

Dropzone.propTypes = {
  section: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setError: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  set: PropTypes.func,
  current: PropTypes.array,
  rowRemovalAction: PropTypes.instanceOf(DestroyMultipleAction),
  setHighlighted: PropTypes.func.isRequired,
  setCurrentDraggingJobs: PropTypes.func.isRequired,
  error: PropTypes.array,
  parent: PropTypes.object,
  subwindow: PropTypes.object,
};

export default Dropzone;
