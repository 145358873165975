import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SalesCredit from '../../api/controllers/Financial/SalesCredit/SalesCredit';
import { formatError } from '../../utils/Formatters/Errors/formatError';
import { faArrowUp, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../common/button/IconButton/IconButton';
import CheckPermit from '../../common/CheckPermit/CheckPermit';

const FinalisedOptions = ({ documentId, title, setError, refresh, permissions }) => {
  const [sageLoad, setSageLoad] = useState(false);

  const handleUnfinalise = e => {
    e.preventDefault();
    SalesCredit.update(documentId, {
      finalised: false,
    }).then(
      () => {
        setError({
          message: `${title} has successfully been un-finalised.`,
          type: 'success',
        });
        refresh();
      },
      err => {
        setError({ ...formatError(err), errorTitle: 'Unfinalise Failed!', type: 'error' });
      },
    );
  };

  const handlePostToSage = e => {
    e.preventDefault();
    setSageLoad(true);
    SalesCredit.submit(documentId).then(
      () => {
        setError({ message: `${title} posted to Sage`, type: 'success' });
        refresh();
        setSageLoad(false);
      },
      err => {
        setError({ ...formatError(err), errorTitle: 'Post to Sage Failed!', type: 'error' });
        setSageLoad(false);
      },
    );
  };

  return (
    <CheckPermit type={'financialsDocuments'} action={'update'} permissions={permissions}>
      <IconButton
        text={'Post to Sage'}
        onClick={handlePostToSage}
        icon={faArrowUp}
        loading={sageLoad}
      />
      <IconButton
        text={'Unfinalise'}
        onClick={handleUnfinalise}
        icon={faLockOpen}
        className={'import-job'}
      />
    </CheckPermit>
  );
};

FinalisedOptions.propTypes = {
  documentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  refresh: PropTypes.func,
  setError: PropTypes.func,
  permissions: PropTypes.object,
};

export default FinalisedOptions;
