import MultipleAction from '../MultipleAction/MultipleAction';

class UpdateMultipleAction extends MultipleAction {
  constructor(setState, currentState, updatedItems) {
    super();
    this.setState = setState;
    this.currentState = currentState;
    this.updatedItems = updatedItems;
  }

  //filters out the old items and adds the new items.
  execute() {
    const filteredItems = this.currentState.filter(
      item => !this.updatedItems.find(remItem => remItem?.id === item?.id),
    );
    this.setState(filteredItems.concat(this.updatedItems).sort((a, b) => a?.id > b?.id));
  }

  /*
    finds the ones that need to stay updated.
    filters their old version then concatenates.
  */
  undo(ids) {
    const keepUpdated = this.updatedItems.filter(item => !ids.includes(item.id));
    const newState = this.currentState.filter(
      item => !keepUpdated.find(remItem => remItem.id === item.id),
    );
    this.setState(newState.concat(keepUpdated));
  }

  //reverts all the updates to their previous states.
  undoAll() {
    this.setState(this.currentState);
  }
}

export default UpdateMultipleAction;
