import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../../../common/LoadingSpinner/LoadingSpinner';
import AreaColumn from '../AreaColumn/AreaColumn';
import './BandManagementTable.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';

/*Displays the table for band management
  Maps columns instead of rows as it makes more sense for the format of data
*/
const BandManagementTable = ({
  areas = [],
  loading = false,
  handleBandValueChange,
  handleMaxChange,
  handleMinChange,
  removeBand,
}) => {
  //Renders the very first column in the table, telling the user the weight bands
  const renderFirstCol = bands => (
    <div key={'headers'} className="tbm-table-col first">
      <div key={'weight'} className="tbm-table-header">
        Weight
      </div>
      <div key={'minimum'} className="tbm-table-header">
        Minimum
      </div>
      {bands.map(band => {
        if (band._destroy) return null;
        return (
          <div key={`headers-${band.taxableWeight}`} className="tbm-table-header">
            Up to {band.taxableWeight}kg
            <button tabIndex={'-1'} onClick={() => removeBand(band.taxableWeight)}>
              <FontAwesomeIcon icon={faXmarkCircle} />
            </button>
          </div>
        );
      })}
      <div key={'maximum'} className="tbm-table-header">
        Maximum
      </div>
    </div>
  );

  if (loading) return <LoadingSpinner />;
  if (areas.length === 0) return <div>No tariff areas are defined yet</div>;
  return (
    <div className="tbm-table">
      {areas.map((area, idx) => {
        if (idx === 0) {
          return (
            <Fragment key={idx}>
              {renderFirstCol(area.bands)}
              <AreaColumn
                area={area}
                idx={idx}
                handleBandValueChange={handleBandValueChange}
                handleMaxChange={handleMaxChange}
                handleMinChange={handleMinChange}
              />
            </Fragment>
          );
        }
        return (
          <AreaColumn
            key={idx}
            area={area}
            idx={idx}
            handleBandValueChange={handleBandValueChange}
            handleMaxChange={handleMaxChange}
            handleMinChange={handleMinChange}
          />
        );
      })}
    </div>
  );
};

BandManagementTable.propTypes = {
  areas: PropTypes.array,
  loading: PropTypes.bool,
  handleBandValueChange: PropTypes.func.isRequired,
  handleMinChange: PropTypes.func.isRequired,
  handleMaxChange: PropTypes.func.isRequired,
  removeBand: PropTypes.func.isRequired,
};

export default BandManagementTable;
