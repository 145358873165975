import React from 'react';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import './VehicleRunRowPackageInfo.scss';

const VehicleRunRowPackageInfo = ({ job }) => {
  const formatDescription = (description, quant) => {
    let splitWords;
    if (description) {
      splitWords = description.split(' ');
    } else splitWords = ['Package'];
    const lastPluralized = Pluralize(splitWords[splitWords.length - 1], quant);
    return `${splitWords.slice(0, -1).join(' ')} ${lastPluralized} `;
  };

  return (
    <>
      <div className="cube-meters">{job.cubicMetres && `${job.cubicMetres} m³`}</div>
      <div className="hover-cell-info cube-info">
        <div className="top">Package Dimensions</div>
        <div className="main">
          {job.dimensionLineItems.length === 0 && 'No specific dimensions have been provided yet'}
          {job.dimensionLineItems?.map(item => (
            <div key={item.id} className="vrr-package-item">
              <strong>
                {`${item.quantity} ${formatDescription(
                  item.packageType.description,
                  item.quantity,
                )}`}
              </strong>
              ({item.lengthCm || '---'} × {item.widthCm || '---'} × {item.heightCm || '---'}cm)
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

VehicleRunRowPackageInfo.propTypes = {
  job: PropTypes.object.isRequired,
};

export default VehicleRunRowPackageInfo;
