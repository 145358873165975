import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../../../../../../../common/button/IconButton/IconButton';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import './OpeningTimesLine.scss';
import DateHelper from '../../../../../../../../utils/DateHelper/DateHelper';

const OpeningTimesLine = ({ time, onNew, onEdit, onDelete }) => (
  <div className={'otl-container'}>
    <div className={'otl-time'}>
      {time && `${DateHelper.openingTimeFormat(time)} ${time.opensAt} - ${time.closesAt}`}
    </div>
    <div className={'otl-buttons'}>
      {onNew && <IconButton text={'New'} icon={faPlus} onClick={onNew} className={'small'} />}
      {onEdit && (
        <IconButton text={'Edit'} icon={faPen} onClick={onEdit} className={'small edit'} />
      )}
      {onDelete && (
        <IconButton text={'Delete'} icon={faTrash} onClick={onDelete} className={'small'} />
      )}
    </div>
  </div>
);

OpeningTimesLine.propTypes = {
  time: PropTypes.object,
  onNew: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default OpeningTimesLine;
