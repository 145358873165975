import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';
import DropdownPresenter from '../Dropdown/DropdownPresenter/DropdownPresenter';
import useApi from '../hooks/useApi/useApi';
import { debounce } from 'lodash';

const GenericAjaxDropdown = ({
  value,
  call,
  condition = () => true,
  minSearch = 2,
  onSelect = () => {},
  onDisplay,
  altParams = {},
  onClear,
  testId,
  disabled,
  onError,
}) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');

  // Make sure we properly cleanup when we toggle the dropdown
  useEffect(() => {
    filter !== '' && setFilter('');
  }, [open]);

  const params = { filter, limit: 15, ...altParams };

  const { data, dataLoading } = useApi({
    call,
    condition: open && minSearch <= filter.length && condition(filter),
    params,
    dependencies: [open, filter],
    handleError: onError,
  });

  const handleSearch = debounce(e => setFilter(e.target.value), 800);

  const drawOptions = ref => {
    // Handles the click action on any option
    const handleClick = (e, item, i) => {
      e.preventDefault(); // Stops it submitting if it is contained within a form
      onSelect(item, i); // Trigger our onSelect and pass the relevant item
      ref.current.firstChild.focus(); // Focus the dropdown button again so we can immediately interact more
      setOpen(false); // Close the dropdown
    };

    // Handle initial data load and the case in which the api call fails
    if (dataLoading) return <LoadingSpinner />;
    if ((!data && !dataLoading && !data?.errors) || data?.total <= 0) {
      if (minSearch > filter.length)
        return (
          <div className={'dd-message'}>
            Please enter {minSearch - filter.length} more
            {` character${minSearch - filter.length === 1 ? '' : 's'}`}
          </div>
        );
      return <div className={'dd-message'}>No Results</div>;
    }

    // Returns our options
    return data.items.map((item, i) => (
      <button key={i} className="dd-button" onClick={e => handleClick(e, item, i)}>
        {onDisplay(item, i)}
      </button>
    ));
  };

  return (
    <DropdownPresenter
      value={onDisplay(value)}
      open={open}
      setOpen={setOpen}
      onSearch={handleSearch}
      drawOptions={drawOptions}
      testId={testId}
      onClear={onClear}
      disabled={disabled}
    />
  );
};

GenericAjaxDropdown.propTypes = {
  value: PropTypes.any,
  call: PropTypes.func.isRequired,
  condition: PropTypes.func,
  minSearch: PropTypes.number,
  onSelect: PropTypes.func,
  onDisplay: PropTypes.func.isRequired,
  altParams: PropTypes.object,
  onClear: PropTypes.func,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  onError: PropTypes.func,
};

export default GenericAjaxDropdown;
