import React from 'react';
import PropTypes from 'prop-types';
import AttachmentsTab from '../../AttachmentsTab/AttachmentsTab';
import './EmailFileTab.scss';

const EmailFileTab = ({
  parent,
  setMessage,
  addFileArray,
  fileArray,
  children,
  attachFileToTemplate,
  removeIdxRemovalArray,
  removalArray,
}) => {
  const handleAttach = file => {
    if (attachFileToTemplate) {
      const addFileOnSuccess = () =>
        addFileArray({ ...file, attachmentId: file.id, optional: true });
      attachFileToTemplate(file.id, addFileOnSuccess);
    } else {
      const id = removalArray.findIndex(elem => elem === file.id);
      id !== -1 && removeIdxRemovalArray(id);
      addFileArray({ ...file, attachmentId: file.id, optional: true });
    }
  };

  return (
    <div className="tab-content email-tab">
      {children}
      <AttachmentsTab
        parentId={parent?.id}
        parentType={parent?.modelName}
        setError={setMessage}
        emailTab
        handleAttach={handleAttach}
        attachments={fileArray}
      />
    </div>
  );
};

EmailFileTab.propTypes = {
  parent: PropTypes.object,
  setMessage: PropTypes.func,
  addFileArray: PropTypes.func,
  fileArray: PropTypes.array,
  children: PropTypes.node,
  attachFileToTemplate: PropTypes.func,
  removeIdxRemovalArray: PropTypes.func,
  removalArray: PropTypes.array,
};

export default EmailFileTab;
