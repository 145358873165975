import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class Sale {
  static potential(params) {
    const data = buildGetQuery({ ...params, credit: false, purchase: false });
    return apiCall('GET', '/financials/outstanding/parents', data);
  }

  static draft(params) {
    const data = buildGetQuery({
      ...params,
      credit: false,
      purchase: false,
      parent: true,
      submitted: false,
    });
    return apiCall('GET', '/financials/documents/', data);
  }

  static completed(params) {
    const data = buildGetQuery({
      ...params,
      credit: false,
      purchase: false,
      parent: true,
      submitted: true,
    });
    return apiCall('GET', '/financials/documents/', data);
  }

  static lineItems(params) {
    const data = buildGetQuery({ ...params, credit: false, purchase: false });
    return apiCall('GET', '/financials/outstanding/line_items', data);
  }

  static create(params) {
    const data = { document: { ...params, credit: false, purchase: false } };
    return apiCall('POST', '/financials/documents/', data);
  }

  static show(id) {
    return apiCall('GET', `/financials/documents/${id}`);
  }

  static update(id, params) {
    const data = { document: params };
    return apiCall('PATCH', `/financials/documents/${id}`, data);
  }
}

export default Sale;
