import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useForm from '../../../../common/hooks/useForm/useForm';
import { basicBooking } from '../../../../utils/bookingUtils/bookingUtils';
import Booking from '../../../../api/controllers/Job/Booking/Booking';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import Message from '../../../../common/layout/Message/Message';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../common/button/IconButton/IconButton';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import './BookingForm.scss';
import BookingFormGeneral from './subComponents/BookingFormGeneral/BookingFormGeneral';
import { formatBooking } from '../../../../utils/Formatters/Job/Booking/formatBooking';
import BookingFormAdvanced from './subComponents/BookingFormAdvanced/BookingFormAdvanced';
import BookingFormBilling from './subComponents/BookingFormBilling/BookingFormBilling';
import NewEntity from '../../../../components/modals/NewEntity/NewEntity';
import useFocus from '../../../../common/hooks/useFocus/useFocus';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import { useLocation, useNavigate, useParams } from 'react-router';
import useApi from '../../../../common/hooks/useApi/useApi';
import SystemSetting from '../../../../api/controllers/SystemSetting/SystemSetting';

const BookingForm = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState();
  const [shouldDisplayArchived, setShouldDisplayArchived] = useState(false);
  const [error, setError] = useState();
  const { form, handleForm, alter } = useForm();
  const { changeFocus } = useFocus();
  const [newEntity, setNewEntity] = useState(false);
  const [loading, setLoading] = useState();
  const [pageLoad, setPageLoad] = useState(true);
  const [defaultBooking, setDefaultBooking] = useState(false);
  const { settings } = useApi({ name: 'settings', call: SystemSetting.show });

  const initCreate = () => {
    setPageLoad(false);
    setData({});
    document.title = 'New Booking';
    handleForm(basicBooking(user));
    changeFocus('job-controller');
  };

  const initUpdate = json => {
    setPageLoad(false);
    document.title = `Editing Booking`;

    handleForm({
      status: json?.status || 'active',
      customerReference: json.customerReference || '',
      collectionReference: json.collectionReference || '',
      deliveryReference: json.deliveryReference || '',
      goodsReadyDate: json.goodsReadyDate || '',
      goodsReadyFromTime: json.goodsReadyFromTime || '',
      goodsReadyUntilTime: json.goodsReadyUntilTime || '',
      deliveryDeadlineDate: json.deliveryDeadlineDate || '',
      deliveryDeadlineFromTime: json.deliveryDeadlineFromTime || '',
      deliveryDeadlineUntilTime: json.deliveryDeadlineUntilTime || '',
      quantity: json.quantity || '',
      grossWeightKg: json.grossWeightKg || '',
      netWeightKg: json.netWeightKg || '',
      cubicMetres: json.cubicMetres || '',
      loadingMetres: json.loadingMetres || '',
      subcontracted: json.subcontracted || false,
      hazardousCargo: json.hazardousCargo || false,
      hazardDetails: json.hazardDetails || '',
      doNotStack: json.doNotStack || false,
      doNotTransship: json.doNotTransship || false,
      doNotPartLoad: json.doNotPartLoad || false,
      doNotBreakDown: json.doNotBreakDown || false,
      tailLiftOnCollection: json.tailLiftOnCollection || false,
      tailLiftOnDelivery: json.tailLiftOnDelivery || false,
      overheadLoad: json.overheadLoad || false,
      overheadUnload: json.overheadUnload || false,
      waste: json.waste || false,
      foodstuffs: json.foodstuffs || false,
      allowAutolining: json.allowAutolining,
      bookingDate: json.bookingDate || '',
      description: json.description || '',
      additionalInformation: json.additionalInformation || '',
      cancellationReason: json.cancellationReason || '',
      financialLineItemsAttributes: json.financialLineItems || [],
      customer: json.customer || null,
      collectionOnBehalfOf: json.collectionOnBehalfOf || null,
      deliveryOnBehalfOf: json.deliveryOnBehalfOf || null,
      collectionEntity: json.collectionEntity || null,
      deliveryEntity: json.deliveryEntity || null,
      subcontractor: json.subcontractor || null,
      owner: json.owner || null,
      vehicle: json.vehicle || null,
      packageType: json?.packageType || null,
      collectionAddress: json?.collectionAddress || null,
      collectionDate: json.collectionDate || '',
      collectionFromTime: json.collectionFromTime || '',
      collectionUntilTime: json.collectionUntilTime || '',
      deliveryDate: json.deliveryDate || '',
      deliveryFromTime: json.deliveryFromTime || '',
      deliveryUntilTime: json.deliveryUntilTime || '',
      deliveryAddress: json?.deliveryAddress || null,
      dimensionLineItemsAttributes: json?.dimensionLineItems || [],
      pcfi: false,
      scfi: false,
      dli: json?.dimensionLineItems?.length > 0,
      timedCollection: json.timedCollection || false,
      timedDelivery: json.timedDelivery || false,
    });
    setShouldDisplayArchived(!!json.financialLineItems?.find(li => li.taxCode?.archived));
    changeFocus('job-controller');
  };

  const handleErrors = json => {
    setLoading(false);
    setPageLoad(false);

    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };

  useEffect(() => {
    if (!id) return initCreate();
    if (state?.detail === 'AutoLine Success') {
      setDefaultBooking(true);
      state.detail = undefined;
    }

    Booking.show(id).then(
      json => {
        setData(json);
        initUpdate(json);
      },
      json => handleErrors(json),
    );
  }, []);

  const breadcrumbs =
    id && data ? (
      <Breadcrumbs>
        <Link to={'/jobs/bookings'}>Jobs</Link>
        <Link to={`/jobs/bookings/${id}`}>{`${data.jobReference}`}</Link>
        <Link to={`/jobs/bookings/${id}/edit`}>Edit</Link>
      </Breadcrumbs>
    ) : (
      <Breadcrumbs>
        <Link to={'/jobs/bookings'}>Jobs</Link>
        <Link to={'/jobs/bookings/new'}>New</Link>
      </Breadcrumbs>
    );

  const cancelUrl = id ? `/jobs/bookings/${id}` : '/jobs/bookings';

  const handleSubmit = () => {
    setLoading(true);
    setError();
    const params = formatBooking(form);
    const call = id ? () => Booking.update(id, params) : () => Booking.create(params);
    call().then(
      json => {
        setLoading(false);
        navigate(`/jobs/bookings/${json.id}`);
      },
      json => handleErrors(json),
    );
  };

  const onSuccess = json => alter('customer', json);

  // Determines whether we should allow autolining
  const shouldAllowAl = () => {
    if (!settings) return true;

    // If ITAL is delivering to itself, then we don't want to autoline anything
    if (
      form.collectionEntity?.id === settings.freightsoftEntity.id &&
      form.deliveryEntity?.id === settings.freightsoftEntity.id
    )
      return false;

    // If the collection entity does not allow autolining, we don't allow that either
    if (form.collectionEntity?.allowAutolining === false) return false;
    return true;
  };

  useEffect(() => {
    if (!form || !settings || !form.allowAutolining) return;
    if (!shouldAllowAl()) return handleForm({ ...form, allowAutolining: false });
  }, [form]);

  return (
    <div className={'booking-form'}>
      {newEntity && (
        <NewEntity
          open={newEntity}
          setOpen={setNewEntity}
          onSuccess={onSuccess}
          changeFocus={changeFocus}
          shouldDefaultCustomer={true}
        />
      )}
      {breadcrumbs}
      <div className={'page-content'}>
        <Message
          type={'error'}
          text={error?.text}
          onClose={() => setError()}
          visible={!!error?.text}
          snapTo
        />
        {!pageLoad && data && form ? (
          <Fragment>
            <h1>{id ? `Editing ${data.jobReference}` : 'New Job'}</h1>
            <TabGroup
              labels={['General', 'Advanced', 'Billing']}
              defaultTab={defaultBooking ? 2 : 0}
            >
              <BookingFormGeneral
                data={form}
                alter={alter}
                handleForm={handleForm}
                openModal={() => setNewEntity(true)}
                changeFocus={changeFocus}
                settings={settings}
              />
              <BookingFormAdvanced data={form} alter={alter} />
              <BookingFormBilling
                data={form}
                alter={alter}
                allowAutolining={shouldAllowAl()}
                settings={settings}
                shouldDisplayArchived={shouldDisplayArchived}
                handleForm={handleForm}
              />
            </TabGroup>
          </Fragment>
        ) : (
          <LoadingSpinner />
        )}
        <div className="button-group">
          <IconButton text={'Save'} icon={faCheck} onClick={handleSubmit} disabled={loading} />
          <IconButton
            text={'Cancel'}
            icon={faTimes}
            onClick={() => navigate(cancelUrl)}
            className="edit"
          />
        </div>
      </div>
    </div>
  );
};

BookingForm.propTypes = {
  user: PropTypes.object,
};

export default BookingForm;
