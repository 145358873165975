import React from 'react';
import PropTypes from 'prop-types';
import './ValueInput.scss';

// To be used with FormField
const ValueInput = ({
  type = 'number',
  value,
  unit,
  onChange,
  readOnly,
  reverse,
  testId = 'vi-input',
  focusId,
  min,
  step,
  autofocus,
  unitTitle = '',
}) => (
  <div className={`vi-container${reverse ? ' reverse' : ''}`}>
    <div className={'vi-input-container'}>
      <input
        type={type}
        className={'vi-input'}
        data-testid={testId}
        data-focus={focusId}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        min={min}
        step={step}
        autoFocus={autofocus}
        title={unitTitle}
      />
    </div>
    <div className={'vi-type'} title={unitTitle} data-testid={'vi-type'}>
      {unit}
    </div>
  </div>
);

ValueInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  reverse: PropTypes.bool,
  testId: PropTypes.string,
  focusId: PropTypes.string,
  min: PropTypes.number,
  step: PropTypes.number,
  autofocus: PropTypes.bool,
  unitTitle: PropTypes.string,
};

export default ValueInput;
