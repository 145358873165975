import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowsLeftRight,
  faArrowsUpDown,
  faArrowTurnUp,
  faLayerGroup,
  faRegistered,
  faTrash,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import VehicleGuideField from '../VehicleGuideField/VehicleGuideField';
import './VehicleGuide.scss';

const VehicleGuide = () => (
  <div className="guide-section">
    <div>Guide:</div>
    <VehicleGuideField text="Restrictions" icon={faRegistered} className="restrictions" />
    <VehicleGuideField text="Do not stack" icon={faLayerGroup} className="do-not-stack" />
    <VehicleGuideField text="Waste" icon={faTrash} className="waste" />
    <VehicleGuideField text="Foodstuffs" icon={faUtensils} className="foodstuffs" />
    <VehicleGuideField text="Overlength" icon={faArrowsLeftRight} className="overlength" />
    <VehicleGuideField text="Tall packages" icon={faArrowsUpDown} className="tall-package" />
    <VehicleGuideField text="Tail lift required" icon={faArrowTurnUp} className="tail-lift" />
    <VehicleGuideField text="Timed" icon={faClock} className="timed" />
  </div>
);

export default VehicleGuide;
