import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DropdownPresenter from '../Dropdown/DropdownPresenter/DropdownPresenter';
import search from '../../utils/search/search';

/*
  Why? This dropdown can handle an array of anything and create a dropdown with all presenter helper features
  Notes? This is not an ajax dropdown, it simply takes an array and presents it as the props dictate
*/
const GenericDropdown = ({
  value, // Our current value
  items, // Our array of objects
  onSelect = () => {}, // The function that triggers when an option is selected
  onDisplay, // The function that triggers when we draw our options to format the text value of that option
  onClear, // The function that triggers when the x button is clicked on the dropdown
  searchable, // The array of columns to search with our input
  testId, // For testing purposes, allowing for easy grabbing of a particular dropdown
  focusName, // This I really don't know, it wasn't implented by me but apparently it's needed for SOMETHING
  disabled, // Disable the dropdown
}) => {
  const [open, setOpen] = useState(false); // Is our dropdown open or closed
  const [filter, setFilter] = useState(''); // The value of our search input

  // Make sure we properly cleanup when we toggle the dropdown
  useEffect(() => {
    filter !== '' && setFilter('');
  }, [open]);

  // Our quickhand function to set the value of our search
  const handleFilter = e => setFilter(e.target.value);

  // Draws the generic dropdown options, all options are buttons for tabbing purposes
  const handleDraw = ref => {
    // Handles the click action on any option
    const handleClick = (item, i) => {
      onSelect(item, i); // Trigger our onSelect and pass the relevant item
      ref.current.firstChild.focus(); // Focus the dropdown button again so we can immediately interact more
      setOpen(false); // Close the dropdown
    };

    if (!items) return null; // If there's no items, why would we even try
    const filteredItems = search(filter, items, searchable); // Search results as an array
    if (filteredItems.length === 0) return <div className="dd-message">No Results</div>; // Report nothing if nothing left after filtering
    // Print all of our options to the DOM
    return filteredItems.map((item, i) => {
      return (
        <button key={i} className={'dd-button'} onClick={() => handleClick(item, i)}>
          {onDisplay(item, i)}
        </button>
      );
    });
  };

  return (
    <DropdownPresenter
      value={onDisplay(value)}
      drawOptions={handleDraw}
      open={open}
      setOpen={setOpen}
      onSearch={handleFilter}
      onClear={onClear}
      testId={testId}
      focusName={focusName}
      disabled={disabled}
    />
  );
};

GenericDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  items: PropTypes.array,
  onSelect: PropTypes.func,
  onDisplay: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  searchable: PropTypes.array,
  testId: PropTypes.string,
  focusName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default GenericDropdown;
