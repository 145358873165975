import { useState, useEffect } from 'react';

const useFocus = () => {
  const [currentFocus, setCurrentFocus] = useState('');

  useEffect(() => {
    document.querySelector(`[data-focus="${currentFocus}"]`)?.focus();
  }, [currentFocus]);

  return { currentFocus, changeFocus: setCurrentFocus };
};

export default useFocus;
