import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import DirectionLabel from '../../DirectionLabel/DirectionLabel';

//Renders the Job Direction (COL, DEL, XT) and the status of the job (Active, Pending, etc)
const VehicleRunRowDirection = ({ job, fsId }) => (
  <div className={'vrr-direction-field'}>
    <DirectionLabel
      fsId={fsId}
      deliveryId={job.deliveryAddress?.id}
      collectionId={job.collectionAddress?.id}
    />
    <div className={'vrr-status-container desktop'}>
      <FontAwesomeIcon className={`status-icon ${job.status}`} icon={faCircle} />
      <div className={'vrr-status-text'}>{capitalize(job.status)}</div>
    </div>
  </div>
);

VehicleRunRowDirection.propTypes = {
  job: PropTypes.object,
  fsId: PropTypes.number,
};

export default VehicleRunRowDirection;
