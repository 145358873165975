import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../components/FormSection/FormSection';
import Checkbox from '../../../../../../components/Checkbox/Checkbox';
import ValueInput from '../../../../../../common/input/ValueInput/ValueInput';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../../../common/button/IconButton/IconButton';
import AttributeHelper from '../../../../../../utils/AttributeHelper/AttributeHelper';
import useApi from '../../../../../../common/hooks/useApi/useApi';
import ChargeCode from '../../../../../../api/controllers/Financial/ChargeCode/ChargeCode';
import Entity from '../../../../../../api/controllers/Company/Entity/Entity';
import TaxCode from '../../../../../../api/controllers/Financial/TaxCode/TaxCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericDropdown from '../../../../../../common/GenericDropdown/GenericDropdown';
import GenericAjaxDropdown from '../../../../../../common/GenericAjaxDropdown/GenericAjaxDropdown';

const BookingFormBilling = ({
  data,
  alter,
  allowAutolining = true,
  settings = {},
  handleForm,
  shouldDisplayArchived = false,
}) => {
  const { chargeCodes } = useApi({ call: ChargeCode.all, name: 'chargeCodes' });
  const { taxCodes } = useApi({
    call: TaxCode.all,
    name: 'taxCodes',
    params: shouldDisplayArchived ? {} : { archived: false },
  });
  const handleKindChange = (idx, val) =>
    AttributeHelper.handleAttrChange(idx, val, 'financialLineItemsAttributes', data, alter);

  const salesItems = data.financialLineItemsAttributes.filter(li => !li.purchase && !li.credit);
  const salesCreditItems = data.financialLineItemsAttributes.filter(
    li => !li.purchase && li.credit,
  );
  const purchaseItems = data.financialLineItemsAttributes.filter(li => li.purchase && !li.credit);
  const purchaseCreditItems = data.financialLineItemsAttributes.filter(
    li => li.purchase && li.credit,
  );
  const salesCodes = chargeCodes && chargeCodes.items.filter(code => code.salesNominal && code);
  const costCodes = chargeCodes && chargeCodes.items.filter(code => code.purchaseNominal && code);

  const handleDelete = (idx, li) => {
    const val = li.modelName ? { _destroy: 1 } : null;
    handleKindChange(idx, val);
  };

  const handleNewLine = (credit, purchase) => {
    const param = purchase ? { entity: data.subcontractor } : { entity: data.customer };
    handleKindChange(data.financialLineItemsAttributes.length, {
      credit,
      purchase,
      ...param,
    });
  };

  useEffect(() => {
    handleForm({
      ...data,
      scfi: salesCreditItems.length > 0 ? true : data.scfi,
      pcfi: purchaseCreditItems.length > 0 ? true : data.pcfi,
    });
  }, []);

  const renderItems = (items, type) =>
    items.length > 0 && (
      <table>
        <thead>
          <tr>
            <th>Charge Code</th>
            <th>Description</th>
            <th>{type === 'sales' ? 'Invoicing Party' : 'Supplier'}</th>
            <th>Value</th>
            <th>Tax Code</th>
            <th>Finalise</th>
            <th></th>
          </tr>
        </thead>
        <tbody className={'disableable'}>
          {items.map((li, i) => {
            if (li?._destroy === 1) return null;
            const itemIndex = data.financialLineItemsAttributes.indexOf(li);
            return (
              <tr key={i}>
                <td className={'small'}>
                  <GenericDropdown
                    value={li.chargeCode}
                    items={type === 'sales' ? salesCodes : costCodes}
                    onDisplay={code => code?.code}
                    onSelect={code =>
                      handleKindChange(itemIndex, {
                        chargeCode: code,
                        description: code.description,
                      })
                    }
                    testId={'bfg-charge-code'}
                    onClear={() => handleKindChange(itemIndex, { chargeCode: null })}
                    disabled={!!li?.document}
                  />
                </td>
                <td className={'medium'}>
                  <input
                    data-testid={'bfg-description'}
                    value={li?.description || ''}
                    onChange={e => handleKindChange(itemIndex, { description: e.target.value })}
                    disabled={!!li?.document}
                  />
                </td>
                <td className={'medium'}>
                  <GenericAjaxDropdown
                    value={li.entity}
                    call={Entity.all}
                    onDisplay={ent => ent?.internalName}
                    onSelect={ent => handleKindChange(itemIndex, { entity: ent })}
                    testId={'bfg-line-item-entity'}
                    altParams={{ [type === 'sales' ? 'customer' : 'supplier']: true }}
                    onClear={() => handleKindChange(itemIndex, { entity: null })}
                    disabled={!!li?.document}
                  />
                </td>
                <td className={'small'}>
                  <ValueInput
                    unit={'£'}
                    value={li.value}
                    reverse
                    onChange={e => handleKindChange(itemIndex, { value: e.target.value })}
                    readOnly={!!li?.document}
                    min={0}
                    step={0.01}
                  />
                </td>
                <td className={'medium'}>
                  <GenericDropdown
                    value={li.taxCode}
                    items={taxCodes?.items || []}
                    onDisplay={tc => tc?.description}
                    onSelect={tc => handleKindChange(itemIndex, { taxCode: tc })}
                    disabled={!!li?.document}
                    testId={'bfg-tax-code'}
                    onClear={() => handleKindChange(itemIndex, { taxCode: null })}
                  />
                </td>
                <td className={'small'}>
                  <Checkbox
                    checked={li?.finalised}
                    onClick={() => handleKindChange(itemIndex, { finalised: !li?.finalised })}
                    disabled={!!li?.document}
                  />
                </td>
                <td className={'small'}>
                  <button
                    data-testid={'bfg-delete'}
                    className={'fs-table-cross'}
                    onClick={() => handleDelete(itemIndex, li)}
                    disabled={!!li?.document || !!li?.finalised}
                    tabIndex={'-1'}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );

  // Show autolining
  const showAl =
    data.collectionEntity?.id === settings.freightsoftEntity?.id ||
    data.deliveryEntity?.id === settings.freightsoftEntity?.id;

  return (
    <div className={'tab-content'}>
      <FormSection
        title={'Sales'}
        optionalElement={
          <div className={'double-head-checkbox'}>
            {showAl && (
              <Checkbox
                label={'Allow Autolining'}
                checked={data.allowAutolining}
                onClick={() => alter('allowAutolining', !data.allowAutolining)}
                disabled={!allowAutolining}
                title={allowAutolining ? '' : 'Autolining is not allowed for this entity'}
                reverse
              />
            )}
            <Checkbox
              label={'Sales Credits'}
              checked={data.scfi || false}
              onClick={() => alter('scfi', !data.scfi)}
              reverse
            />
          </div>
        }
      >
        {renderItems(salesItems, 'sales')}
        <div className={'button-section'}>
          <div className={'button-line'}>
            <IconButton
              icon={faPlus}
              className={'small'}
              text={'Add Sales Line Item'}
              onClick={() => handleNewLine(false, false)}
            />
          </div>
        </div>
      </FormSection>
      {data.scfi && (
        <FormSection title={'Sales Credits'}>
          {renderItems(salesCreditItems, 'sales')}
          <div className={'button-section'}>
            <div className={'button-line'}>
              <IconButton
                icon={faPlus}
                className={'small'}
                text={'Add Credit Line Item'}
                onClick={() => handleNewLine(true, false)}
              />
            </div>
          </div>
        </FormSection>
      )}
      <FormSection
        title={'Cost'}
        optionalElement={
          <Checkbox
            label={'Purchase Credits'}
            checked={data.pcfi || false}
            onClick={() => alter('pcfi', !data.pcfi)}
            reverse
          />
        }
      >
        {renderItems(purchaseItems, 'cost')}
        <div className={'button-section'}>
          <div className={'button-line'}>
            <IconButton
              icon={faPlus}
              className={'small'}
              text={'Add Cost Line Item'}
              onClick={() => handleNewLine(false, true)}
            />
          </div>
        </div>
      </FormSection>
      {data.pcfi && (
        <FormSection title={'Purchase Credits'}>
          {renderItems(purchaseCreditItems, 'cost')}
          <div className={'button-section'}>
            <div className={'button-line'}>
              <IconButton
                icon={faPlus}
                className={'small'}
                text={'Add Credit Line Item'}
                onClick={() => handleNewLine(true, true)}
              />
            </div>
          </div>
        </FormSection>
      )}
    </div>
  );
};

BookingFormBilling.propTypes = {
  data: PropTypes.object,
  alter: PropTypes.func,
  allowAutolining: PropTypes.bool,
  settings: PropTypes.object,
  handleForm: PropTypes.func.isRequired,
  shouldDisplayArchived: PropTypes.bool,
};

export default BookingFormBilling;
