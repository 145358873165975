import React from 'react';
import PropTypes from 'prop-types';
import VehicleRunFormGeneral from '../../../../../components/forms/VehicleRunForm/VehicleRunFormGeneral/VehicleRunFormGeneral';
import FormSection from '../../../../../components/FormSection/FormSection';
import Checkbox from '../../../../../components/Checkbox/Checkbox';

const VehicleRunEditGeneral = ({ form, alter, showArchived }) => {
  const subcontractedCheckbox = (
    <Checkbox
      label={'Subcontracted'}
      checked={form?.subcontracted}
      onClick={() => alter('subcontracted', !form?.subcontracted)}
      reverse
    />
  );

  return (
    <div className="tab-content">
      <FormSection title={'General'} optionalElement={subcontractedCheckbox}>
        <VehicleRunFormGeneral form={form} alter={alter} showMileage showArchived={showArchived} />
      </FormSection>
    </div>
  );
};

VehicleRunEditGeneral.propTypes = {
  form: PropTypes.object,
  alter: PropTypes.func.isRequired,
  showArchived: PropTypes.object,
};

export default VehicleRunEditGeneral;
