import React from 'react';

class DropdownHelper {
  static drawDropdown(id, setOpen, alter, message, dataObj, outputCallback) {
    if (message) return <div className={'dd-message'}>{message}</div>;
    const handleClick = ent => {
      setOpen(false);
      alter(id, ent);
    };

    return dataObj.items.map((ent, i) => (
      <button key={i} className={'dd-button'} onClick={() => handleClick(ent)}>
        {outputCallback(ent)}
      </button>
    ));
  }

  /*
    Why? Used to return a valid string based on the number of characters searched
    Notes? Filter should be a string
  */
  static returnMinCharacterString(filter, limit = 2) {
    return `Please enter ${limit - filter.length} more character${
      limit - filter.length === 1 ? '' : 's'
    }`;
  }

  static isDropdownRelated(e, nodeRef) {
    if (nodeRef.current === null) return true; // If no target do nothing
    if (nodeRef.current.contains(e.target)) return true; // If target contains this dropdown, do nothing
    if (nodeRef.current === e.target) return true;
    if (document.getElementById('portal-root').contains(e.target)) return true; // If target is contained within portal-root, do nothing
    return false;
  }
}

export default DropdownHelper;
