import React from 'react';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import DocumentIndex from '../../Document/DocumentIndex/DocumentIndex';
import useAjaxTable from '../../../../common/hooks/ajaxHooks/useAjaxTable/useAjaxTable';
import Sale from '../../../../api/controllers/Financial/Sale/Sale';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DateHelper from '../../../../utils/DateHelper/DateHelper';
import NumberHelper from '../../../../utils/NumberHelper/NumberHelper';
import { useNavigate } from 'react-router';
import LineItemHelper from '../../../../utils/LineItemHelper/LineItemHelper';

const newHeaders = [
  { text: 'Entity', sortingAttribute: 'entity', sortable: true },
  { text: 'Job', sortingAttribute: 'job', sortable: true },
  { text: 'Total Value', sortingAttribute: 'value', sortable: true },
  { text: 'Actions', sortable: false },
];

const draftHeaders = [
  { text: 'Issue Date', sortingAttribute: 'issue_date' },
  { text: 'Document Reference', sortingAttribute: 'doc_ref' },
  { text: 'Their Reference', sortingAttribute: 'their_ref' },
  { text: 'Entity', sortingAttribute: 'entity' },
  { text: 'Job', sortingAttribute: 'job' },
  { text: 'Total Value', sortingAttribute: 'value' },
];

const completedHeaders = [
  { text: 'Issue Date', sortingAttribute: 'issue_date' },
  { text: 'Due Date', sortingAttribute: 'due_date' },
  { text: 'Document Reference', sortingAttribute: 'doc_ref' },
  { text: 'Their Reference', sortingAttribute: 'their_ref' },
  { text: 'Entity', sortingAttribute: 'entity' },
  { text: 'Job', sortingAttribute: 'job' },
  { text: 'Total Value', sortingAttribute: 'value' },
  { text: 'Sage URN', sortingAttribute: 'sage_urn' },
];

const SalesInvoiceIndex = () => {
  const navigate = useNavigate();
  const { dataNew, sortNew, onSortNew, onSearchNew, pageElementsNew, pageTextNew, refreshNew } =
    useAjaxTable({
      call: Sale.potential,
      name: 'New',
      defaultSort: { sort: newHeaders[0].sortingAttribute, order: 'asc' },
    });

  const {
    dataDraft,
    sortDraft,
    onSortDraft,
    onSearchDraft,
    pageElementsDraft,
    pageTextDraft,
    refreshDraft,
  } = useAjaxTable({
    call: Sale.draft,
    name: 'Draft',
    defaultSort: { sort: draftHeaders[0].sortingAttribute, order: 'desc' },
  });

  const {
    dataCompleted,
    sortCompleted,
    onSortCompleted,
    onSearchCompleted,
    pageElementsCompleted,
    pageTextCompleted,
    refreshCompleted,
  } = useAjaxTable({
    call: Sale.completed,
    name: 'Completed',
    defaultSort: { sort: completedHeaders[0].sortingAttribute, order: 'desc' },
  });

  document.title = 'Sales Invoices';

  const handleDrawRowNew = (item, i) => {
    return (
      <tr key={`${item.entity.id}-${item.parent.id}`} className={i % 2 === 0 ? '' : 'coloured'}>
        <td>
          <Link to={`/companies/entities/${item.entity.id}`} target="_blank">
            {item.entity.internalName}
          </Link>
        </td>
        <td>{LineItemHelper.renderSourceLine(item.parent)}</td>
        <td>{NumberHelper.formatNumToCurrency(item.amountOutstanding)}</td>
        <td className={'act-row'}>
          <IconButton
            icon={faPlus}
            text={'Invoice'}
            onClick={() =>
              navigate(`/financials/sales_invoices/new/${item.entity.id}/${item.parent?.id}`)
            }
            className={'invoice-button'}
          />
        </td>
      </tr>
    );
  };

  const handleDrawRowDraft = (item, i) => {
    return (
      <tr key={item.id} className={i % 2 === 0 ? '' : 'coloured'}>
        <td>{item.issueDate && DateHelper.dateOnly(item.issueDate)}</td>
        <td>
          <Link to={`/financials/sales_invoices/${item.id}`}>{item.documentReference}</Link>
        </td>
        <td>{item.entityReference}</td>
        <td>
          <Link to={`/companies/entities/${item.entity.id}`} target="_blank">
            {item.entity.internalName}
          </Link>
        </td>
        <td>{LineItemHelper.renderSourceLine(item.parent)}</td>
        <td>{NumberHelper.formatNumToCurrency(item.totalValue)}</td>
      </tr>
    );
  };

  const handleDrawRowCompleted = (item, i) => {
    return (
      <tr key={item.id} className={i % 2 === 0 ? '' : 'coloured'}>
        <td>{item.issueDate && DateHelper.dateOnly(item.issueDate)}</td>
        <td>{item.dueDate && DateHelper.dateOnly(item.dueDate)}</td>
        <td>
          <Link to={`/financials/sales_invoices/${item.id}`}>{item.documentReference}</Link>
        </td>
        <td>{item.entityReference}</td>
        <td>
          <Link to={`/companies/entities/${item.entity.id}`} target="_blank">
            {item.entity.internalName}
          </Link>
        </td>
        <td>{LineItemHelper.renderSourceLine(item.parent)}</td>
        <td>{NumberHelper.formatNumToCurrency(item.totalValue)}</td>
        <td>{item.apiUrn}</td>
      </tr>
    );
  };

  const newParams = {
    title: 'Create New Sales Invoice',
    data: dataNew,
    sort: sortNew,
    onSort: onSortNew,
    onSearch: onSearchNew,
    pageElements: pageElementsNew,
    handleDrawRow: handleDrawRowNew,
    pageText: pageTextNew,
    refresh: refreshNew,
    headers: newHeaders,
  };

  const draftParams = {
    title: 'Draft Sales Invoices',
    data: dataDraft,
    sort: sortDraft,
    onSort: onSortDraft,
    onSearch: onSearchDraft,
    pageElements: pageElementsDraft,
    handleDrawRow: handleDrawRowDraft,
    pageText: pageTextDraft,
    refresh: refreshDraft,
    headers: draftHeaders,
  };

  const completedParams = {
    title: 'Completed Sales Invoices',
    data: dataCompleted,
    sort: sortCompleted,
    onSort: onSortCompleted,
    onSearch: onSearchCompleted,
    pageElements: pageElementsCompleted,
    handleDrawRow: handleDrawRowCompleted,
    pageText: pageTextCompleted,
    refresh: refreshCompleted,
    headers: completedHeaders,
  };

  return (
    <div className="all-sales-credits">
      <Breadcrumbs>
        <Link to={'/financials/sales_invoices'}>Sales Invoices</Link>
      </Breadcrumbs>
      <DocumentIndex
        newParams={newParams}
        draftParams={draftParams}
        completedParams={completedParams}
      />
    </div>
  );
};

SalesInvoiceIndex.propTypes = {};

export default SalesInvoiceIndex;
