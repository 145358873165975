import React from 'react';
import PropTypes from 'prop-types';
import { Editor, RichUtils } from 'draft-js';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';
import 'draft-js/dist/Draft.css';
import bindings from './helpers/bindings/bindings';
import keyHandler from './helpers/keyHandler/keyHandler';
import Toolbar from './subComponents/Toolbar/Toolbar';
import './RichText.scss';
import { colorStyleMap } from './helpers/colourmaps/colorStyleMap';
import { backgroundColorStyleMap } from './helpers/colourmaps/backgroundColorStyleMap';
import blockRendererFn from './helpers/blockRendererFn/blockRendererFn';
import { HANDLED } from './constants/constants';

const RichText = ({
  placeholder = 'Please enter something...',
  editorState,
  setEditorState,
  editorRef,
  onFocus = () => {},
}) => {
  const handleBlockRender = block => blockRendererFn(block, editorState);

  /*
    By default, it handles return key for inserting soft breaks (BRs in HTML)
  */
  const handleReturn = e => {
    if (isSoftNewlineEvent(e)) {
      setEditorState(RichUtils.insertSoftNewline(editorState));
      return HANDLED;
    }
  };

  return (
    <div className={'rt-editor'} data-testid={'editor-wrap'}>
      <Toolbar editorState={editorState} setEditorState={setEditorState} />
      <Editor
        ref={editorRef}
        customStyleMap={{ ...colorStyleMap, ...backgroundColorStyleMap }}
        editorState={editorState}
        onChange={setEditorState}
        handleKeyCommand={command => keyHandler(command, editorState, setEditorState)}
        handleReturn={handleReturn}
        keyBindingFn={bindings}
        blockRendererFn={handleBlockRender}
        placeholder={placeholder}
        onFocus={onFocus}
      />
    </div>
  );
};

RichText.propTypes = {
  placeholder: PropTypes.string,
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  editorRef: PropTypes.object,
  onFocus: PropTypes.func,
};

export default RichText;
