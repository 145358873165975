import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router';
import '../../Document/InvoiceForm/InvoiceForm.scss';
import useForm from '../../../../common/hooks/useForm/useForm';
import SalesCredit from '../../../../api/controllers/Financial/SalesCredit/SalesCredit';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import Customer from '../../../../api/controllers/Financial/Customer/Customer';
import DateHelper from '../../../../utils/DateHelper/DateHelper';
import Entity from '../../../../api/controllers/Company/Entity/Entity';
import InvoiceForm from '../../Document/InvoiceForm/InvoiceForm';
import LineItemHelper from '../../../../utils/LineItemHelper/LineItemHelper';

const SalesCreditNoteNew = () => {
  const { entityid, bookingid } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [warning, setWarning] = useState();
  const [lineItems, setLineItems] = useState([]);
  const [customer, setCustomer] = useState(null);
  const { form, alter, handleForm } = useForm({
    entityId: entityid,
    entityReference: '',
    issueDate: DateHelper.today(),
    bookingId: bookingid || null,
    dueDate: '',
    lineItemsAttributes: [],
  });
  const [loading, setLoading] = useState(true);

  const handleErrors = json => {
    loading && setLoading(false);
    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };

  const entityId = entityid;
  const bookingId = bookingid;

  useEffect(() => {
    const getData = async () => {
      let dueDate, ref;
      const resEntity = await Entity.show(entityId);
      const parent = bookingId ? { parentId: bookingId, parentType: 'Jobs::Booking' } : {};
      setCustomer(resEntity);
      try {
        const resCustomer = await Customer.show(resEntity.customerAccount);
        dueDate = calDefaultDue(resCustomer);
      } catch (json) {
        setWarning({ ...json, ...{ status: 'Warning' } });
      }
      try {
        const resLineItems = await SalesCredit.lineItems({ entityId, ...parent });
        setLineItems(resLineItems.items);
        ref = resLineItems.items[0]?.parent?.customerReference || '';
      } catch (json) {
        handleErrors(json);
      }
      handleForm({ ...form, ...{ entityReference: ref, dueDate: dueDate }, ...parent });
      setLoading(false);
    };
    getData();
  }, []);

  const calDefaultDue = customer => {
    if (!customer) return DateHelper.today();
    return DateHelper.dueDateCalculate(customer);
  };

  const handleLineItemToggle = itemId => {
    const toggledLines = LineItemHelper.toggleLineNew(form.lineItemsAttributes, itemId);
    alter('lineItemsAttributes', toggledLines);
  };

  const handleSubmit = () => {
    const params = form;
    SalesCredit.create(params).then(
      json => navigate(`/financials/sales_credit_notes/${json.id}`),
      json => handleErrors(json),
    );
  };

  return (
    <div className={'invoice-form'}>
      <Breadcrumbs>
        <Link to={'/financials/sales_credit_notes'}>Sales Credit Notes</Link>
        <Link to={`/financials/sales_credit_notes/new/${entityId}/${bookingId}`}>New</Link>
      </Breadcrumbs>
      <div className={'page-content'}>
        <InvoiceForm
          error={error}
          setError={setError}
          alter={alter}
          form={form}
          lineItems={lineItems}
          handleSubmit={handleSubmit}
          lineItemToggle={handleLineItemToggle}
          cancelUrl="/financials/sales_credit_notes"
          title={`Creating Sales Credit Note for ${customer?.internalName}`}
          warning={warning}
          setWarning={setWarning}
          loading={loading}
        />
      </div>
    </div>
  );
};

SalesCreditNoteNew.propTypes = {
  user: PropTypes.object,
};

export default SalesCreditNoteNew;
