import React from 'react';
import PropTypes from 'prop-types';
import './ToolbarIcon.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ToolbarIcon = ({ icon, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={'toolbar-icon-button'}
      tabIndex="-1"
      data-testid="toolbar-icon"
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );
};

ToolbarIcon.propTypes = {
  icon: PropTypes.object,
  onClick: PropTypes.func,
};

export default ToolbarIcon;
