import React from 'react';
import { Link } from 'react-router-dom';

export default class LineItemHelper {
  static toggleLineNew(currentItems, id) {
    if (currentItems.filter(item => item.id === id).length > 0)
      return currentItems.filter(item => item.id !== id);
    return [...currentItems, { id: id }];
  }

  static toggleLineEdit(currentItems, id) {
    let newLineItems;
    const foundItems = currentItems.filter(item => item.id === id);
    if (foundItems.length > 0) {
      newLineItems = currentItems.filter(index => index.id !== id);
      if (foundItems[0]._remove) return [...newLineItems, { id: id }];
      else return [...newLineItems, { id: id, _remove: true }];
    }
    return [...currentItems, { id: id }];
  }

  static toggleAllEdit(currentLineItems, allLineItems) {
    if (
      allLineItems.length === currentLineItems.length &&
      currentLineItems.filter(item => item._remove).length === 0
    )
      return currentLineItems.map(item => ({ id: item.id, _remove: true }));
    return allLineItems.map(x => ({ id: x.id }));
  }

  static toggleAllNew(currentLineItems, allLineItems) {
    if (allLineItems.length === currentLineItems.length) return [];
    return allLineItems.map(x => ({ id: x.id }));
  }

  static renderSourceLine(parent) {
    if (!parent) return;
    if (parent.modelName === 'Jobs::Booking')
      return (
        <Link to={`/jobs/bookings/${parent.id}`} target="_blank">
          {parent.jobReference}
        </Link>
      );
    if (parent.modelName === 'Movements::VehicleRun')
      return (
        <Link to={`/movements/runs/${parent.id}`} target="_blank">
          {parent.runReference}
        </Link>
      );
    console.error('unhandled parent', parent.modelName);
  }
}
