import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useForm from '../../../common/hooks/useForm/useForm';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import Driver from '../../../api/controllers/Movement/Driver/Driver';
import Modal from '../../../common/Modal/Modal';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Message from '../../../common/layout/Message/Message';
import FormSection from '../../FormSection/FormSection';
import FormField from '../../FormField/FormField';
import TabGroup from '../../TabGroup/TabGroup';
import './NewDriver.scss';
import Checkbox from '../../Checkbox/Checkbox';

const defaultVal = {
  firstName: '',
  lastName: '',
  archived: false,
};

const NewDriver = ({ onSuccess, open, setOpen }) => {
  const { form, alter, handleForm } = useForm(defaultVal);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const labels = ['General'];

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const params = form;
    Driver.create(params).then(
      () => {
        setLoading(false);
        setOpen(false);
        setMessage();
        onSuccess();
        handleForm(defaultVal);
      },
      json => {
        setLoading(false);
        setMessage(formatError(json));
      },
    );
  };

  const handleCancel = () => {
    setOpen(false);
    handleForm(defaultVal);
    setMessage();
  };
  return (
    open && (
      <Modal isOpen={open} handleClose={handleCancel}>
        {!!form && (
          <FormSection title={'New Driver'} draggable>
            <form className={'new-driver'} onSubmit={e => handleSubmit(e, form)}>
              <Message
                text={message?.errors}
                type={'error'}
                onClick={() => setMessage()}
                visible={!!message?.errors}
              />
              <TabGroup labels={labels}>
                <div className={'tab-content general'}>
                  <FormField label={'First Name'} className={'single'}>
                    <input
                      value={form.firstName}
                      onChange={e => alter('firstName', e.target.value)}
                    />
                  </FormField>
                  <FormField label={'Last Name'} className={'single'}>
                    <input
                      value={form.lastName}
                      onChange={e => alter('lastName', e.target.value)}
                    />
                  </FormField>
                </div>
              </TabGroup>
              <div className={'bottom-tab-content'}>
                <Checkbox
                  checked={form.archived}
                  onClick={() => alter('archived', !form.archived)}
                  label="Archived"
                />
                <div className={'af-button-line'}>
                  <IconButton
                    text={'Create Driver'}
                    icon={faCheck}
                    className={'small'}
                    loading={loading}
                  />
                  <IconButton
                    text={'Cancel'}
                    icon={faTimes}
                    onClick={handleCancel}
                    className={'edit small'}
                  />
                </div>
              </div>
            </form>
          </FormSection>
        )}
      </Modal>
    )
  );
};

NewDriver.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default NewDriver;
