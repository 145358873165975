import { get } from 'lodash';

const search = (search = '', data, searchable) => {
  if (Array.isArray(data) && typeof data[0] === 'string') return _stringSearch(search, data);
  if (!inputValid(search, data)) return data;
  const keysToSearch = searchable || Object.keys(data[0]);
  const listOfResultsByColumn = keysToSearch.map(key => _searchColumn(key, data, search));
  return _mergeAndMakeUnique(listOfResultsByColumn);
};

const inputValid = (search, data) => {
  // No null / undefined values
  if (!search || !data) return false;
  // No empty datasets
  if (search.length === 0 || data.length === 0) return false;
  // Edge cases like if someone throws in an array or data is a weird value
  if (Array.isArray(search) || typeof data !== 'object' || data === null) return false;

  return true;
};

const _searchColumn = (header, data, search) =>
  data.filter(
    row => get(row, header) && `${get(row, header)}`.toUpperCase().includes(search.toUpperCase()),
  );

const _mergeAndMakeUnique = results => {
  if (results === undefined) return null;
  return [...new Set([].concat(...results))];
};

const _stringSearch = (searchVal, data) =>
  data.filter(opt => opt.toUpperCase().includes(searchVal.toUpperCase()));

export default search;
