import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NumberHelper from '../../../../utils/NumberHelper/NumberHelper';
import DateHelper from '../../../../utils/DateHelper/DateHelper';
import StaticCheckbox from '../../../../components/StaticCheckbox/StaticCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import './DocumentRow.scss';

//Displays a row for a document on the AccountSyncTable
const DocumentRow = ({ row, toggle, checked, apiCall, loading }) => {
  //displays the details column, either showing info about the parent or returning nothing
  const getDetails = () => {
    if (!row.parent) return;
    const { modelName, id } = row.parent;
    switch (true) {
      case modelName === 'Movements::VehicleRun':
        return (
          <Link to={`/movement/runs/${id}`} target="_blank">
            {row.parent?.runReference}
          </Link>
        );
      case modelName === 'Jobs::Booking':
        return (
          <Link to={`/jobs/bookings/${id}`} target="_blank">
            {row.parent?.jobReference}
          </Link>
        );
      default:
        return;
    }
  };

  //determines where we should link to for a given document
  const getLinkType = () => {
    const { purchase, credit } = row;
    if (!purchase && !credit) return 'sales_invoices';
    if (!purchase && credit) return 'sales_credit_notes';
    if (purchase && !credit) return 'purchase_invoices';
    return 'purchase_credit_notes';
  };

  // changes if the row is selected
  const handleToggle = () => {
    toggle(row.id);
  };

  /*
    Displays the relevant icon for the submission of a document

    Displays nothing if its not being submitted
    Displays a loading spinner if we have no result or its loading,
    Displays the tick if successful
    Displays a warning if failed with the hover text of the reason
  */
  const getApiIcon = () => {
    if (!apiCall) return;
    if (!apiCall?.result || loading)
      return <FontAwesomeIcon icon={faSpinner} className={'fa-spin'} data-testid={'dr-loading'} />;
    if (apiCall.result.status === 'fulfilled')
      return (
        <FontAwesomeIcon icon={faCheckCircle} className={'dr-success'} data-testid={'dr-success'} />
      );
    return (
      <FontAwesomeIcon
        icon={faWarning}
        className={'dr-warn'}
        data-testid={'dr-warn'}
        title={apiCall.result.reason?.error || apiCall.result.reason?.errors[0]}
      />
    );
  };

  if (!row) return;
  return (
    <tr className="document-row">
      <td>{row.issueDate && DateHelper.dateOnly(row.issueDate)}</td>
      <td>
        <Link to={`/financials/${getLinkType()}/${row.id}`} target="_blank">
          {row.documentReference}
        </Link>
      </td>
      <td>
        <Link to={`/companies/entities/${row.entity.id}`} target="_blank">
          {row.entity.internalName}
        </Link>
      </td>
      <td>{getDetails()}</td>
      <td>{NumberHelper.formatNumToCurrency(row.totalValue)}</td>
      <td className="doc-select">{getApiIcon()}</td>
      <td className="doc-select">
        <StaticCheckbox checked={checked} onClick={handleToggle} testId={'dr-checkbox'} />
      </td>
    </tr>
  );
};

DocumentRow.propTypes = {
  row: PropTypes.object,
  checked: PropTypes.bool,
  loading: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  apiCall: PropTypes.object,
};

export default DocumentRow;
