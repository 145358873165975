import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class ChargeCode {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'financials/charge_codes', data);
  }

  static show(id) {
    return apiCall('GET', `financials/charge_codes/${id}`);
  }

  static create(params) {
    const data = { charge_code: params };
    return apiCall('POST', 'financials/charge_codes', data);
  }

  static update(id, params) {
    const data = { charge_code: params };
    return apiCall('PATCH', `financials/charge_codes/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `financials/charge_codes/${id}`);
  }
}

export default ChargeCode;
