import buildGetQuery from '../../helpers/buildGetQuery/buildGetQuery';
import postcodeCall from '../../postcodeCall/postcodeCall';

// Wrapper for postcodes.io
class Postcode {
  /*
    {
      status: XXX
      result: [ query: queryPostcode, result: { postcode, etc, etc } ]
      error: 'error'
    }
  */
  static all(codeArr) {
    const data = { postcodes: codeArr };
    return postcodeCall('POST', 'postcodes', data);
  }

  /*
    {
      status: XXX
      result: { postcode, etc, etc }
      error: 'error'
    }
  */
  static show(code) {
    return postcodeCall('GET', `postcodes/${code}`);
  }

  /*
    status: XXX
    result: true / false / undefined because error
    error: 'error'
  */
  static random() {
    return postcodeCall('GET', `random/postcodes`);
  }

  /*
    status: XXX
    result: [ 'postcode', 'postcode' ]
    error: string Error
  */
  static search(code) {
    return postcodeCall('GET', `postcodes/${code}/autocomplete`);
  }

  /*
    {
      status: XXX
      result: [ { possiblePostcodeMatch }, { possiblePostcodeMatch } ]
      error: 'error'
    }
  */
  static query(code) {
    const data = buildGetQuery({ q: code });
    return postcodeCall('GET', `postcodes`, data);
  }
}

export default Postcode;
