class BookingHelper {
  static calculateTaxableWeight(booking) {
    const cubicTaxable = (booking.cubicMetres / 3) * 1000;
    const loadingTaxable = booking.loadingMetres * 1750;
    return Math.max(booking.grossWeightKg, cubicTaxable, loadingTaxable);
  }

  // Gets the number of notes in a booking
  static getNoteNum(booking) {
    const noteAttrs = ['jobNotes', 'customerNotes', 'collectionNotes', 'deliveryNotes'];
    return noteAttrs.reduce((total, attr) => booking[attr] + total, 0);
  }

  // A boolean which checks whether or not the booking has a valid FS entry
  static canBeLinked(booking) {
    return booking.customerReference && booking.freightsoftReference;
  }
}

export default BookingHelper;
