import {
  AtomicBlockUtils,
  ContentState,
  convertFromHTML,
  EditorState,
  Modifier,
  RichUtils,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import decorator from '../../decorator/decorator';

export const htmlRenderMap = block => {
  if (block.type === 'HR') return '<hr />';
};

export const customChunkRenderer = nodeName => {
  if (nodeName === 'hr')
    return {
      type: 'HR',
      mutability: 'IMMUTABLE',
      data: {
        customType: 'horizontalRule',
      },
    };
};

export const draftjsToHtml = editorState => {
  const rawState = convertToRaw(editorState.getCurrentContent());
  const html = draftToHtml(rawState, null, null, htmlRenderMap);
  return html;
};

export const htmlToDraftJs = html => {
  const blocksFromHtml = htmlToDraft(html, customChunkRenderer);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const editorState = EditorState.createWithContent(contentState, decorator);
  return editorState;
};

export const handleDefaultValue = defaultValue => {
  if (defaultValue) return htmlToDraftJs(defaultValue);
  return EditorState.createEmpty(decorator);
};

export const insertHtml = (html, editorState, setEditorState) => {
  const { contentBlocks, entityMap } = convertFromHTML(html);
  const contentState = Modifier.replaceWithFragment(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap(),
  );
  setEditorState(EditorState.createWithContent(contentState));
};

export const insertHorizontalRule = (editorState, setEditorState) => {
  const currentContent = editorState.getCurrentContent();
  const contentStateWithEntity = currentContent.createEntity('HR', 'IMMUTABLE', {
    customType: 'horizontalRule',
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
};

export const applyLink = (editorState, setEditorState, link) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
    url: link,
    targetOption: '_blank',
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
  setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
};

export const replaceLink = (editorState, setEditorState, linkKey, link) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.replaceEntityData(linkKey, {
    url: link,
    targetOption: '_blank',
  });
  const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
  setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), linkKey));
};

export const removeEntities = (editorState, setEditorState) => {
  const contentState = editorState.getCurrentContent();
  const newContentState = Modifier.applyEntity(contentState, editorState.getSelection(), null);
  setEditorState(EditorState.set(editorState, { currentContent: newContentState }));
};

export const getCurrentBlock = editorState => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(selectionState.getStartKey());
  return block;
};

export const getMatchStartAndEnd = (regex, contentBlock) => {
  const text = contentBlock.getText();
  let matchArr;
  while ((matchArr = regex.exec(text)) !== null) {
    const start = matchArr.index;
    const end = start + matchArr[0].length;
    return { start, end };
  }
};

export const findWithRegex = (regex, contentBlock) => {
  const text = contentBlock.getText();
  return text.match(regex);
};
