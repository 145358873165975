import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../../../../../../components/Checkbox/Checkbox';
import FormSection from '../../../../../../../../components/FormSection/FormSection';

const BookingFormConsignmentOptions = ({ data, alter }) => {
  return (
    <FormSection title={'Consignment Options'}>
      <div className={'checkbox-line'}>
        <Checkbox
          label={'Do not stack'}
          checked={data.doNotStack}
          reverse
          onClick={() => alter('doNotStack', !data.doNotStack)}
        />
        <Checkbox
          label={'Do not transship'}
          checked={data.doNotTransship}
          reverse
          onClick={() => alter('doNotTransship', !data.doNotTransship)}
        />
        <Checkbox
          label={'Do not part load'}
          checked={data.doNotPartLoad}
          reverse
          onClick={() => alter('doNotPartLoad', !data.doNotPartLoad)}
        />
        <Checkbox
          label={'Do not break down'}
          checked={data.doNotBreakDown}
          reverse
          onClick={() => alter('doNotBreakDown', !data.doNotBreakDown)}
        />
      </div>
      <div className={'checkbox-line'}>
        <Checkbox
          label={'Tail lift on collection'}
          checked={data.tailLiftOnCollection}
          reverse
          onClick={() => alter('tailLiftOnCollection', !data.tailLiftOnCollection)}
        />
        <Checkbox
          label={'Tail lift on delivery'}
          checked={data.tailLiftOnDelivery}
          reverse
          onClick={() => alter('tailLiftOnDelivery', !data.tailLiftOnDelivery)}
        />
        <Checkbox
          label={'Overhead load'}
          checked={data.overheadLoad}
          reverse
          onClick={() => alter('overheadLoad', !data.overheadLoad)}
        />
        <Checkbox
          label={'Overhead unload'}
          checked={data.overheadUnload}
          reverse
          onClick={() => alter('overheadUnload', !data.overheadUnload)}
        />
      </div>
      <div className={'checkbox-line'}>
        <Checkbox
          label={'Timed collection'}
          checked={data.timedCollection}
          reverse
          onClick={() => alter('timedCollection', !data.timedCollection)}
        />
        <Checkbox
          label={'Timed delivery'}
          checked={data.timedDelivery}
          reverse
          onClick={() => alter('timedDelivery', !data.timedDelivery)}
        />
        <Checkbox
          label={'Waste'}
          checked={data.waste}
          reverse
          onClick={() => alter('waste', !data.waste)}
        />
        <Checkbox
          label={'Foodstuffs'}
          checked={data.foodstuffs}
          reverse
          onClick={() => alter('foodstuffs', !data.foodstuffs)}
        />
      </div>
    </FormSection>
  );
};

BookingFormConsignmentOptions.propTypes = {
  data: PropTypes.object,
  alter: PropTypes.func,
};

export default BookingFormConsignmentOptions;
