import buildGetQuery from '../../helpers/buildGetQuery/buildGetQuery';
import apiCall from '../../apiCall/apiCall';

// Handles all system settings requests
class SystemSetting {
  static show(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'system_settings/general', data);
  }

  static update(params) {
    return apiCall('PATCH', 'system_settings/general', { general: params });
  }
}

export default SystemSetting;
