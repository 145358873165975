import React from 'react';
import PropTypes from 'prop-types';
import './ActivityTab.scss';
import JobsTable from '../../../../../../common/JobsTable/JobsTable';

const ActivityTab = ({ entityId, user }) => {
  return (
    <div className="entity-activities">
      <div className="page-content">
        <JobsTable tableTitle={'Entity Activity'} entityId={entityId} user={user} />
      </div>
    </div>
  );
};

ActivityTab.propTypes = {
  entityId: PropTypes.number,
  user: PropTypes.object,
};

export default ActivityTab;
