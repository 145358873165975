import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './VehicleRunStatus.scss';
import VehicleRun from '../../../../../../api/controllers/Movement/VehicleRun/VehicleRun';
import { formatError } from '../../../../../../utils/Formatters/Errors/formatError';
import DropdownHelper from '../../../../../../utils/DropdownHelper/DropdownHelper';

/*
  Why? It's a cleaner way to manage the changes to the component
*/
const statusObject = {
  pending: { className: 'vrs-status vrs-pending', text: 'Pending' },
  in_progress: { className: 'vrs-status vrs-in-progress', text: 'In Progress' },
  complete: { className: 'vrs-status vrs-completed', text: 'Completed' },
};

/*
  Why? To handle the status logic inside of the VehicleRunCollapsibleTable.
    Moving the logic here severely limits the clutter and lower refactoring costs.

  Notes? The possible values for runInfo.status would be: pending / in_progress / completed
*/
const VehicleRunStatus = ({ runInfo, setToast }) => {
  const [open, setOpen] = useState(false);
  const nodeRef = useRef();

  // Stops the component crashing if an invalid value passed
  if (!runInfo || !runInfo?.status || !Object.keys(statusObject).includes(runInfo.status))
    return null;

  const { className, text } = statusObject[runInfo.status];

  // Handle clicks outside of the portal-root and dropdown
  const handleClickOutside = e => {
    if (DropdownHelper.isDropdownRelated(e, nodeRef)) return;
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  // Actually updates the run with the new status
  const updateRun = status =>
    VehicleRun.update(runInfo.id, { status })
      .then(
        () => {},
        err => setToast(formatError(err)),
      )
      .then(() => setOpen(false));

  return (
    <div className="vrs-wrap" ref={nodeRef}>
      <div className={className} onClick={() => setOpen(!open)}>
        {text}
      </div>
      {open && (
        <div className={'vrs-content'} data-testid={'vrs-content'}>
          {Object.keys(statusObject).map((key, i) => {
            if (runInfo.status === key) return null;

            return (
              <button key={i} className={'vrs-option'} onClick={() => updateRun(key)}>
                {statusObject[key].text}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

VehicleRunStatus.propTypes = {
  runInfo: PropTypes.object.isRequired,
  setToast: PropTypes.func.isRequired,
};

export default VehicleRunStatus;
