import React, { useState } from 'react';
import useApi from '../../../../common/hooks/useApi/useApi';
import './PackageShow.scss';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Message from '../../../../common/layout/Message/Message';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import FormSection from '../../../../components/FormSection/FormSection';
import FieldList from '../../../../components/FieldList/FieldList';
import FormField from '../../../../components/FormField/FormField';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import ValueInput from '../../../../common/input/ValueInput/ValueInput';
import PackageType from '../../../../api/controllers/Job/PackageType/PackageType';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import NoPage from '../../../NoPage/NoPage';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import { useNavigate, useParams } from 'react-router';

const PackageShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, dataLoading } = useApi({
    call: () => PackageType.show(id),
    handleError: () => setNotFound(true),
  });
  const [error, setError] = useState();
  const [notFound, setNotFound] = useState(false);
  const [isOpen, setIsOpen] = useState();
  document.title = `${data?.name}`;

  const handleDelete = () =>
    PackageType.destroy(id).then(
      () => {
        setIsOpen();
        navigate('/jobs/package_types', { state: { detail: 'Package Deleted' } });
      },
      json => {
        setError(formatError(json));
        setIsOpen();
      },
    );

  if (notFound) return <NoPage />;
  if (dataLoading) return <LoadingSpinner />;
  if (!data) return null;

  return (
    <div className={'package-show'}>
      <ConfirmationModal
        question={'Are you sure you want to delete this package type?'}
        subText={data?.name}
        confirmText={'Delete'}
        isOpen={!!isOpen}
        handleConfirm={handleDelete}
        handleCancel={() => setIsOpen()}
      />
      <Breadcrumbs>
        <Link to={'/jobs/package_types'}>Package Types</Link>
        <Link to={`/jobs/package_types/${id}`}>{data?.name || id}</Link>
      </Breadcrumbs>

      <Ribbon>
        <IconButton
          text={'Edit Package Type'}
          icon={faPen}
          onClick={() => navigate(`/jobs/package_types/${id}/edit`)}
          className={'edit'}
        />
        <IconButton
          text={'Delete Package Type'}
          icon={faTrash}
          onClick={() => setIsOpen(true)}
          className={'destructive'}
        />
      </Ribbon>
      <div className={'page-content'}>
        <Message text={error?.errors} type={'error'} visible={!!error} onClick={() => setError()} />
        <h1>{`${data?.name || id}`}</h1>

        <TabGroup labels={['General']}>
          <div className={'tab-content'}>
            <FormSection title={'Details'}>
              <FieldList>
                <FormField label={'Name'} className={'short'}>
                  <input value={data.name || ''} readOnly />
                </FormField>
                <FormField label={'Description'} className={'short'}>
                  <input value={data.description || ''} readOnly />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label={'Abbreviation'} className={'short'}>
                  <input value={data.abbreviation || ''} readOnly />
                </FormField>
                <FormField label={'Dimensions (L/W/H)'} className={'short'}>
                  <div className={'group'}>
                    <ValueInput value={data.lengthCm || ''} unit={'CM'} readOnly />
                    <ValueInput value={data.widthCm || ''} unit={'CM'} readOnly />
                    <ValueInput value={data.heightCm || ''} unit={'CM'} readOnly />
                  </div>
                </FormField>
              </FieldList>
            </FormSection>
          </div>
        </TabGroup>
      </div>
    </div>
  );
};

PackageShow.propTypes = {};

export default PackageShow;
