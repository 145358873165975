import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LinkInput.scss';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../../../../../common/Modal/Modal';
import FormSection from '../../../../../FormSection/FormSection';
import FormField from '../../../../../FormField/FormField';
import IconButton from '../../../../../../common/button/IconButton/IconButton';

const LinkInput = ({ handleLink, open, setOpen, getLinkKey, handleReplaceLink }) => {
  const { linkKey, ent } = getLinkKey(); //checks to see if selection is already a link and gets its key + val
  const [link, setLink] = useState(ent.url);

  const submitLink = e => (linkKey ? handleReplaceLink(e, linkKey, link) : handleLink(e, link)); //updates to new URL if edit otherwise creates url
  const onLinkInputKeyDown = e => e.which === 13 && submitLink(e); // Return key pressed confirm

  return (
    <Modal isOpen={open} handleClose={setOpen}>
      <div className={'link-input-wrap'}>
        <FormSection title={'Insert/Edit Link'} draggable>
          <hr />
          <FormField label={'URL'} className={'single'}>
            <input
              autoFocus
              className="link-input-input"
              onChange={e => setLink(e.target.value)}
              type="text"
              value={link}
              onKeyDown={onLinkInputKeyDown}
            />
          </FormField>
          <hr />
          <div className={'button-group'}>
            <IconButton text={'Save'} icon={faCheck} className={'small'} onClick={submitLink} />
            <IconButton text={'Cancel'} icon={faTimes} onClick={setOpen} className={'edit small'} />
          </div>
        </FormSection>
      </div>
    </Modal>
  );
};

LinkInput.propTypes = {
  handleLink: PropTypes.func,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  getLinkKey: PropTypes.func,
  handleReplaceLink: PropTypes.func,
};

export default LinkInput;
