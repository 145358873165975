import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './ComposeField.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';

const ComposeField = ({ label, icon, types, typesOnClick, setTypesOpen, children, open }) => {
  const ref = useRef();

  const handleClick = type => {
    typesOnClick(type);
    setTypesOpen(false);
  };

  return (
    <div className="cf-wrap">
      <div
        className="cf-label"
        ref={ref}
        style={setTypesOpen ? { cursor: 'pointer' } : null}
        onClick={() => setTypesOpen(!open)}
      >
        {capitalize(label)} {icon && <FontAwesomeIcon icon={icon} />}
        {open && (
          <div className="cf-types">
            {types &&
              types.map((type, i) => (
                <button key={i} className="cf-type" onClick={() => handleClick(type)}>
                  {capitalize(type)}
                </button>
              ))}
          </div>
        )}
      </div>
      <div className="cf-children">{children}</div>
    </div>
  );
};

ComposeField.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.object,
  types: PropTypes.array,
  typesOnClick: PropTypes.func,
  children: PropTypes.node,
  setTypesOpen: PropTypes.func,
  open: PropTypes.bool,
};

export default ComposeField;
