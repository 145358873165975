import React from 'react';
import PropTypes from 'prop-types';
import './LoginForm.scss';
import IconInput from '../../../common/input/IconInput/IconInput';
import { faLock, faSignInAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../common/button/IconButton/IconButton';

const LoginForm = ({ onChange, onSubmit }) => (
  <form className={'basic-form'} onSubmit={onSubmit}>
    <div className={'row'}>
      <IconInput
        icon={faUser}
        type={'email'}
        onChange={e => onChange('email', e.target.value)}
        placeholder={'Email'}
        autoFocus
      />
    </div>
    <div className={'row'}>
      <IconInput
        icon={faLock}
        type={'password'}
        onChange={e => onChange('password', e.target.value)}
        placeholder={'Password'}
      />
    </div>
    <div className={'submit-row'}>
      <IconButton
        icon={faSignInAlt}
        autoFocus={true}
        text={`Sign in${
          process.env.REACT_APP_ENV !== 'production' ? ' to the testing server' : ''
        }`}
      />
    </div>
  </form>
);

LoginForm.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default LoginForm;
