import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './EmailsTab.scss';
import FormSection from '../../../../../../components/FormSection/FormSection';
import CollapsibleFormSection from '../../../../../../components/CollapsibleFormSection/CollapsibleFormSection';
import Envelope from '../../../../../../api/controllers/Email/Envelope/Envelope';
import Item from '../../../../../../api/controllers/File/Item/Item';
import DownloadHelper from '../../../../../../utils/DownloadHelper/DownloadHelper';
import useApi from '../../../../../../common/hooks/useApi/useApi';
import useArray from '../../../../../../common/hooks/useArray/useArray';
import IconButton from '../../../../../../common/button/IconButton/IconButton';
import DateHelper from '../../../../../../utils/DateHelper/DateHelper';
import IconHelper from '../../../../../../utils/IconHelper/IconHelper';
import ErrorStatusMessage from '../../../../../../common/ErrorStatusMessage/ErrorStatusMessage';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../../../../../common/LoadingSpinner/LoadingSpinner';

const EmailsTab = ({ parentType, parentId }) => {
  const [error, setError] = useState();
  const { emailSection, setEmailSection, toggleEmailSection } = useArray({ name: 'emailSection' });
  const { data, dataLoading } = useApi({
    call: () => Envelope.all({ parentType: parentType, parentId: parentId, order: 'desc' }),
  });

  const handleDownload = (id, name) => {
    Item.download(id).then(
      res => {
        DownloadHelper.saveFile(name, res);
        setError();
      },
      err => {
        setError({ error: err.error, status: 'Error' });
      },
    );
  };

  const handleExpand = id => {
    if (emailSection.includes(id)) return toggleEmailSection(id);
    setEmailSection(id);
  };

  if (dataLoading)
    return (
      <div className="emails-tab">
        <div className="page-content">
          <LoadingSpinner />
        </div>
      </div>
    );
  if (!data) return null;
  return (
    <div className="emails-tab">
      <ErrorStatusMessage error={error} setError={setError} />
      <div className="page-content">
        {data?.items.length > 0 && (
          <FormSection title={'Emails'}>
            {data.items.map(el => {
              return (
                <CollapsibleFormSection
                  key={el.id}
                  title={DateHelper.format(el.createdAt).replace(',', '')}
                  splitTitle={` | ${el.subject}`}
                  onClick={() => handleExpand(el.id)}
                  open={emailSection.includes(el.id)}
                  icon={faEnvelope}
                >
                  {el.recipients.map(recipient => {
                    return (
                      <div className="recipients" key={recipient.id}>
                        <span className="recipient-type">{recipient.ccType.toUpperCase()}: </span>
                        <span className="recipient-name">
                          {recipient.contact.firstName + ' ' + recipient.contact.lastName}
                        </span>
                        <span className="recipient-mail">
                          {' <' + recipient.emailAddress + '>'}
                        </span>
                      </div>
                    );
                  })}

                  <div className="email-body">
                    <div dangerouslySetInnerHTML={{ __html: el.body }}></div>
                  </div>
                  <div className="attachments">
                    {el.attachments.map(attachment => {
                      return (
                        <IconButton
                          text={attachment.file.fileName}
                          icon={IconHelper.getFolderIcon(attachment.file.mimeType)}
                          className="small mail-attachment"
                          key={attachment.id}
                          onClick={() =>
                            handleDownload(attachment.file.id, attachment.file.fileName)
                          }
                        />
                      );
                    })}
                  </div>
                </CollapsibleFormSection>
              );
            })}
          </FormSection>
        )}
        {data.items.length === 0 && (
          <FormSection title={'Emails'}>
            <div className="no-email">No email has been sent yet</div>
          </FormSection>
        )}
      </div>
    </div>
  );
};

EmailsTab.propTypes = {
  parentType: PropTypes.string,
  parentId: PropTypes.number,
};

export default EmailsTab;
