class NumberHelper {
  static formatNumToDecPlaces(num, places) {
    return new Intl.NumberFormat('en-GB', {
      maximumFractionDigits: places,
      minimumFractionDigits: places,
    })
      .format(num)
      .replace(',', '');
  }
  static formatNumToCurrency(num) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      maximumFractionDigits: 2,
    }).format(num);
  }

  static calTotal(data) {
    return data
      .map(li => li.value)
      .reduce((a, b) => (a * 100 + b * 100) / 100, 0)
      .toString();
  }

  static formatBytes(bytes, decimals = 0) {
    if (bytes === 0) return '0 KB';
    if (bytes === null || bytes === undefined) return null;

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  //returns NAN if blank (undefined / null)
  static formatNumberUnlessBlank(val) {
    if (val == null) return NaN;
    return Number(val);
  }
}

export default NumberHelper;
