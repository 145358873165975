import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import VehicleRun from '../../../api/controllers/Movement/VehicleRun/VehicleRun';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import SelectorDropdown from '../../../components/SelectorDropdown/SelectorDropdown';
import AjaxTable from '../../../components/Table/AjaxTable/AjaxTable';
import { vehicleRunStatuses } from '../../../strings/enums';
import { humanize, labelForEnum } from '../../../utils/EnumHelper/EnumHelper';
import './VehicleRunIndex.scss';
import VehicleRunRow from './VehicleRunRow/VehicleRunRow';

const VehicleRunIndex = () => {
  const [status, setStatus] = useState('all');
  const [subcontracted, setSubcontracted] = useState('everything');

  useEffect(() => {
    document.title = 'All Vehicle Runs';
  }, []);

  const headers = [
    { text: 'Date of Use', sortingAttribute: 'dateOfUse' },
    { text: 'Number', type: 'number', sortingAttribute: 'runReference' },
    { text: 'Subcontracted' },
    { text: 'Vehicle/Subcontractor' },
    { text: 'Stops' },
    { text: 'Status' },
  ];

  const handleFilter = () => {
    const validStatus = ['pending', 'in_progress', 'complete'];
    let filterObj = {};
    if (validStatus.includes(status) && status !== 'closed') filterObj['status'] = status;
    //Don't filter closed if we want to see everything
    if (status !== 'all') filterObj['closed'] = status === 'closed';
    if (subcontracted !== 'everything') {
      if (subcontracted === 'subcontracted') {
        filterObj['subcontracted'] = true;
      } else {
        filterObj['subcontracted'] = false;
      }
    }

    return filterObj;
  };

  return (
    <div className="all-vehicle-runs">
      <Breadcrumbs>
        <Link to="/movements/runs">Vehicle Runs</Link>
      </Breadcrumbs>
      <div className="page-content">
        <AjaxTable
          title={'Vehicle Runs'}
          call={VehicleRun.all}
          headers={headers}
          filterObj={handleFilter()}
          onDrawRow={(run, i) => <VehicleRunRow key={i} idx={i} run={run} />}
          defaultSort={{ sort: 'dateOfUse', order: 'desc' }}
        >
          <div className="vri-selector-group">
            <SelectorDropdown
              options={['all', 'pending', 'in_progress', 'complete', 'closed']}
              items={[status]}
              toggle={opt => setStatus(opt)}
              onDisplay={opt => labelForEnum(vehicleRunStatuses, opt)}
            />
            <SelectorDropdown
              options={['everything', 'subcontracted', 'non_subcontracted']}
              items={[subcontracted]}
              toggle={opt => setSubcontracted(opt)}
              onDisplay={opt => humanize(opt)}
            />
          </div>
        </AjaxTable>
      </div>
    </div>
  );
};

export default VehicleRunIndex;
