import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class SalesCredit {
  static potential(params) {
    const data = buildGetQuery({ ...params, credit: true, purchase: false });
    return apiCall('GET', '/financials/outstanding/parents', data);
  }
  static show(id) {
    return apiCall('GET', `/financials/documents/${id}`);
  }
  static update(id, params) {
    const data = { document: params };
    return apiCall('PATCH', `/financials/documents/${id}`, data);
  }
  static destroy(id) {
    return apiCall('DELETE', `/financials/documents/${id}`);
  }
  static submit(id) {
    return apiCall('POST', `/financials/documents/${id}/submit_to_api`);
  }
  static draft(params) {
    const data = buildGetQuery({ ...params, credit: true, purchase: false, submitted: false });
    return apiCall('GET', '/financials/documents/', data);
  }

  static completed(params) {
    const data = buildGetQuery({ ...params, credit: true, purchase: false, submitted: true });
    return apiCall('GET', '/financials/documents/', data);
  }

  static lineItems(params) {
    const data = buildGetQuery({ ...params, credit: true, purchase: false });
    return apiCall('GET', '/financials/outstanding/line_items', data);
  }

  static create(params) {
    const data = { document: { ...params, credit: true, purchase: false } };
    return apiCall('POST', '/financials/documents/', data);
  }
}

export default SalesCredit;
