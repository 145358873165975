import { get } from 'lodash';

const findName = entity => {
  if (!entity) return '';
  return entity.shortName || entity.internalName;
};

class Sorter {
  static stringSort(params) {
    const { sort, order, data } = params;
    const sortedData = data.sort((a, b) => {
      const strA = `${get(a, sort) || ''}`.toLowerCase();
      const strB = `${get(b, sort) || ''}`.toLowerCase();
      return order === 'asc' ? strA.localeCompare(strB) : strA.localeCompare(strB) * -1;
    });
    return sortedData;
  }

  static numberSort(params) {
    const { sort, order, data } = params;
    const sortedData = data.sort((a, b) => {
      const intA = Number(get(a, sort));
      const intB = Number(get(b, sort));
      if (isFinite(intA - intB)) {
        return order === 'asc' ? intA - intB : intB - intA;
      } else {
        return isFinite(intA) ? -1 : 1;
      }
    });
    return sortedData;
  }

  static entitySort(params) {
    const { sort, order, data } = params;
    const sortedData = data.sort((a, b) => {
      const entityA = findName(get(a, sort)).toLowerCase();
      const entityB = findName(get(b, sort)).toLowerCase();
      return order === 'asc' ? entityA.localeCompare(entityB) : entityA.localeCompare(entityB) * -1;
    });
    return sortedData;
  }
}

export default Sorter;
