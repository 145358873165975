import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Item from '../../../api/controllers/Note/Item/Item';
import NoteForm from '../../forms/NoteForm/NoteForm';
import Message from '../../../common/layout/Message/Message';
import FormSection from '../../FormSection/FormSection';
import Modal from '../../../common/Modal/Modal';
import useForm from '../../../common/hooks/useForm/useForm';

const EditNote = ({ note, handleClose, handleSuccess }) => {
  const { form, alter } = useForm(note);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState({});

  const handleSubmit = (e, form) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    Item.update(note.id, form).then(
      () => handleSuccess(note.id),
      err => {
        setMessages(err);
        setLoading(false);
      },
    );
  };

  return (
    <Modal isOpen handleClose={handleClose}>
      <FormSection title={'Edit Note'} draggable>
        <Message
          visible={!!messages?.errors || messages.error}
          text={messages.errors || messages.error}
          onClick={() => setMessages([])}
          type={'error'}
        />
        <NoteForm
          form={form}
          alter={alter}
          handleClose={handleClose}
          submitText="Update Note"
          submitting={loading}
          handleSubmit={handleSubmit}
        />
      </FormSection>
    </Modal>
  );
};

EditNote.propTypes = {
  note: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

export default EditNote;
