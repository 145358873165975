import React from 'react';
import { useEffect } from 'react';
import useUser from '../common/hooks/useUser/useUser';
import AppRoutes from './AppRoutes';
import { Provider } from 'react-redux';
import store from '../store';

const App = () => {
  const { user, permissions, handleLogin, handleRestore, handleUserChange, setUserError } =
    useUser();

  useEffect(() => {
    /* Handles the scenario when a JWT is valid/invalid */
    handleRestore();
  }, []);

  return (
    <Provider store={store}>
      <AppRoutes
        user={user}
        permissions={permissions}
        onLogin={handleLogin}
        onUserChange={handleUserChange}
        setUserError={setUserError}
      />
    </Provider>
  );
};
export default App;
