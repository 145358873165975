import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../../../components/FormSection/FormSection';
import Checkbox from '../../../../../../../../components/Checkbox/Checkbox';
import FormField from '../../../../../../../../components/FormField/FormField';

const BookingFormGoodsDetails = ({ data, alter }) => {
  if (!data) return null;
  return (
    <FormSection
      title={'Goods Details'}
      optionalElement={
        <Checkbox
          checked={!!data.hazardousCargo}
          onClick={() => alter('hazardousCargo', !data.hazardousCargo)}
          label={'Cargo Hazardous'}
          reverse
        />
      }
    >
      <div className={'space-fields'}>
        <FormField label={'Description'} className={'fill'}>
          <input
            data-testid={'bfg-description'}
            value={data.description || ''}
            onChange={e => alter('description', e.target.value)}
          />
        </FormField>
        {data.hazardousCargo && (
          <FormField label={'Hazard Details'} className={'fill'}>
            <input
              data-testid={'bfg-hazard-details'}
              value={data.hazardDetails}
              onChange={e => alter('hazardDetails', e.target.value)}
            />
          </FormField>
        )}
      </div>
    </FormSection>
  );
};

BookingFormGoodsDetails.propTypes = {
  data: PropTypes.object,
  alter: PropTypes.func,
};

export default BookingFormGoodsDetails;
