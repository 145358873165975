import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ImportJob from '../../components/modals/ImportJob/ImportJob';
import './QuickLinks.scss';

const QuickLinks = ({ permissions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleCustomers = (route, text) => navigate({ pathname: route, search: `filter=${text}` });
  const handleSuppliers = (route, text) => navigate({ pathname: route, search: `filter=${text}` });
  const handleForwarders = (route, text) => navigate({ pathname: route, search: `filter=${text}` });
  const handleCreateJob = (route, text) => navigate(route, { state: { detail: text } });
  const handleViewMyJobs = (route, _, filter) => navigate({ pathname: route, search: filter });
  const handleImportJob = () => setIsOpen(prev => !prev);
  const links = [
    {
      text: 'Customers',
      key: 'customers',
      route: '/companies/entities',
      permission: permissions?.companiesEntities?.read,
      onClick: handleCustomers,
    },
    {
      text: 'Suppliers',
      key: 'suppliers',
      route: '/companies/entities',
      permission: permissions?.companiesEntities?.read,
      onClick: handleSuppliers,
    },
    {
      text: 'Forwarders',
      key: 'forwarders',
      route: '/companies/entities',
      permission: permissions?.companiesEntities?.read,
      onClick: handleForwarders,
    },
    {
      text: 'Create Job',
      key: 'create-job',
      route: '/jobs/bookings/new',
      permission: permissions?.jobsBookings?.create,
      onClick: handleCreateJob,
    },
    {
      text: 'Import Job',
      key: 'import-job',
      route: 'import job',
      permission: permissions?.jobsBookings?.create,
      onClick: handleImportJob,
    },
    {
      text: 'View My Jobs',
      filter: 'filter=My+Jobs',
      key: 'my-jobs',
      route: '/jobs/bookings',
      permission: permissions?.jobsBookings?.read,
      onClick: handleViewMyJobs,
    },
  ];

  const renderLinks = () => (
    <div className={'quick-links'}>
      {links.map(
        el =>
          el.permission && (
            <div
              className={`link`}
              key={el.key}
              onClick={() => el.onClick(el.route, el.text, el?.filter)}
            >
              {el.text}
            </div>
          ),
      )}
    </div>
  );
  return (
    <div className={'links-container'}>
      <div className={'toggle-links'}>Quick Links</div>
      {renderLinks()}
      {isOpen && <ImportJob isOpen={isOpen} setIsOpen={setIsOpen} />}
    </div>
  );
};
QuickLinks.propTypes = {
  permissions: PropTypes.object,
};

export default QuickLinks;
