import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { humanize } from '../../../../../utils/EnumHelper/EnumHelper';
import CheckPermit from '../../../../../common/CheckPermit/CheckPermit';
import IconButton from '../../../../../common/button/IconButton/IconButton';
import { faCheck, faPen, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import NumberHelper from '../../../../../utils/NumberHelper/NumberHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VehicleRow = ({ vehicle, id, handleDelete, permissions }) => {
  const navigate = useNavigate();

  const driverFullName = `${humanize(vehicle.driver?.firstName)} ${humanize(
    vehicle.driver?.lastName,
  )}`;

  return (
    <tr key={id} className={`vehicleRow ${id % 2 === 0 ? '' : 'coloured'}`}>
      <td>
        <Link to={`/vehicles/${vehicle.id}`}>{vehicle.reference}</Link>
      </td>
      <td title={driverFullName}>{driverFullName}</td>
      <td className="large">{humanize(vehicle.kind)}</td>
      <td>{vehicle.milesPerGallon}</td>
      <td data-testid="vrr-bool" className="tiny">
        <FontAwesomeIcon icon={vehicle.usesKilometres ? faCheck : faTimes} />
      </td>
      <td>{vehicle.weightLimitKg}</td>
      <td>{vehicle.lengthCm}</td>
      <td>{vehicle.widthCm}</td>
      <td>{vehicle.heightCm}</td>
      <td>{vehicle.baseCost ? NumberHelper.formatNumToDecPlaces(vehicle.baseCost, 2) : ''}</td>
      <td data-testid="vrr-bool" className="tiny">
        <FontAwesomeIcon icon={vehicle.manualSelectionOnly ? faCheck : faTimes} />
      </td>
      <td data-testid="vrr-bool" className="tiny">
        <FontAwesomeIcon icon={vehicle.archived ? faCheck : faTimes} />
      </td>
      <td>
        <span className="td-buttons">
          <CheckPermit type={'movementsVehicles'} action={'update'} permissions={permissions}>
            <IconButton
              text={'Edit'}
              icon={faPen}
              onClick={() => navigate(`/vehicles/${vehicle.id}/edit`)}
              className="edit small"
            />
          </CheckPermit>
          <CheckPermit type={'movementsVehicles'} action={'destroy'} permissions={permissions}>
            <IconButton
              text={'Delete'}
              icon={faTrash}
              className="destructive small"
              onClick={() => handleDelete(vehicle)}
            />
          </CheckPermit>
        </span>
      </td>
    </tr>
  );
};

VehicleRow.propTypes = {
  vehicle: PropTypes.object,
  id: PropTypes.number,
  handleDelete: PropTypes.func,
  permissions: PropTypes.object,
};

export default VehicleRow;
