import { EXTERNAL_ERROR, SAVE_TOKEN } from '../../actions/types';
import store from '../../store';
import { safeParse } from '../helpers/safeParse/safeParse';

// A generic API call. Used internally by various other API "helper" classes.
const apiCall = (verb, path, data) => {
  const { token: jwt } = store.getState().userReducer;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const server = process.env.REACT_APP_API_SERVER;

    // GET requests have no body, so any params must be part of the URL:
    let url = [server, path].join('/');
    if (data instanceof URLSearchParams) url = [url, data].join('?');

    xhr.open(verb, url, true);
    if (jwt) xhr.setRequestHeader('Authorization', jwt);

    if (!data || data instanceof URLSearchParams) {
      xhr.send(); // No request body; the params (if any) are part of the URL.
    } else if (data instanceof FormData) {
      xhr.send(data); // Binary file uploads (such as avatar images) use this.
    } else {
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(data)); // Unlike FormData, this is simply text.
    }

    xhr.onload = () => {
      const json = safeParse(xhr.responseText);

      if (json?.error?.startsWith('External request:')) {
        store.dispatch({
          type: EXTERNAL_ERROR,
          payload:
            'You appear to be trying to sign in externally. However your user account does not have permission to do this. If you would like to be able to access your account from an external network, please request permission from your system administrator.',
        });
        return;
      }

      if (xhr.status === 401) {
        //Using External_Error here as it allows for setting of UserError at the same time as deleting token
        store.dispatch({
          type: EXTERNAL_ERROR,
          payload: 'Your session has expired. Please sign in again.',
        });
        reject({ ...json, status: xhr.status });
      }

      if (xhr.status < 200 || xhr.status >= 300) reject({ ...json, status: xhr.status });

      const token = xhr.getResponseHeader('Authorization');

      if (!jwt) store.dispatch({ type: SAVE_TOKEN, payload: token });
      resolve(token ? { ...json, token: token } : json);
    };

    // Mimic { "error": "You need to sign in or sign up before continuing." }
    xhr.onerror = () => reject({ error: 'Could not connect to server.' });
    xhr.onabort = () => reject({ error: 'Request aborted.' });
    xhr.ontimeout = () => reject({ error: 'Request timed out.' });
  });
};

export default apiCall;
