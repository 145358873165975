import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class Vehicle {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'movements/vehicles', data);
  }

  static show(id) {
    return apiCall('GET', `movements/vehicles/${id}`);
  }

  static create(params) {
    const data = { vehicle: params };
    return apiCall('POST', 'movements/vehicles', data);
  }

  static update(id, params) {
    const data = { vehicle: params };
    return apiCall('PATCH', `movements/vehicles/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `movements/vehicles/${id}`);
  }

  static kinds() {
    return apiCall('GET', 'movements/vehicles/kinds');
  }
}

export default Vehicle;
