import React, { Fragment } from 'react';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import DocumentIndex from '../../Document/DocumentIndex/DocumentIndex';
import useAjaxTable from '../../../../common/hooks/ajaxHooks/useAjaxTable/useAjaxTable';
import PurchaseCredit from '../../../../api/controllers/Financial/PurchaseCredit/PurchaseCredit';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DateHelper from '../../../../utils/DateHelper/DateHelper';
import { useNavigate } from 'react-router';
import NumberHelper from '../../../../utils/NumberHelper/NumberHelper';
import LineItemHelper from '../../../../utils/LineItemHelper/LineItemHelper';

const newHeaders = [
  { text: 'Entity', sortingAttribute: 'entity' },
  { text: 'Jobs / Vehicle Runs' },
  { text: 'Total Value', sortingAttribute: 'value' },
  { text: 'Actions' },
];

const draftHeaders = [
  { text: 'Issue Date', sortingAttribute: 'issue_date' },
  { text: 'Document Reference', sortingAttribute: 'doc_ref' },
  { text: 'Their Reference', sortingAttribute: 'their_ref' },
  { text: 'Entity', sortingAttribute: 'entity' },
  { text: 'Total Value', sortingAttribute: 'value' },
];

const completedHeaders = [
  { text: 'Issue Date', sortingAttribute: 'issue_date' },
  { text: 'Due Date', sortingAttribute: 'due_date' },
  { text: 'Document Reference', sortingAttribute: 'doc_ref' },
  { text: 'Their Reference', sortingAttribute: 'their_ref' },
  { text: 'Entity', sortingAttribute: 'entity' },
  { text: 'Total Value', sortingAttribute: 'value' },
  { text: 'Sage URN', sortingAttribute: 'sage_urn' },
];

const PurchaseCreditIndex = () => {
  const navigate = useNavigate();
  const {
    dataNew,
    sortNew,
    onSortNew,
    onSearchNew,
    pageElementsNew,
    pageTextNew,
    refreshNew,
    loadingNew,
  } = useAjaxTable({
    call: PurchaseCredit.potential,
    name: 'New',
    defaultSort: { sort: newHeaders[0].sortingAttribute, order: 'asc' },
  });

  const {
    dataDraft,
    sortDraft,
    onSortDraft,
    onSearchDraft,
    pageElementsDraft,
    pageTextDraft,
    refreshDraft,
    loadingDraft,
  } = useAjaxTable({
    call: PurchaseCredit.draft,
    name: 'Draft',
    defaultSort: { sort: draftHeaders[0].sortingAttribute, order: 'desc' },
  });

  const {
    dataCompleted,
    sortCompleted,
    onSortCompleted,
    onSearchCompleted,
    pageElementsCompleted,
    pageTextCompleted,
    refreshCompleted,
    loadingCompleted,
  } = useAjaxTable({
    call: PurchaseCredit.completed,
    name: 'Completed',
    defaultSort: { sort: completedHeaders[0].sortingAttribute, order: 'desc' },
  });

  document.title = 'Purchase Credit Notes';

  const handleDrawRowNew = (item, i) => {
    return (
      <tr key={item.id} className={i % 2 === 0 ? '' : 'coloured'}>
        <td>
          <Link to={`/companies/entities/${item.id}`} target="_blank">
            {item.internalName}
          </Link>
        </td>
        <td>
          {item.sources.map((parent, i) => (
            <Fragment key={parent.id}>
              {LineItemHelper.renderSourceLine(parent)}
              {i !== item.sources.length - 1 ? ', ' : null}
            </Fragment>
          ))}
        </td>
        <td>{NumberHelper.formatNumToCurrency(item.amountOutstanding)}</td>
        <td className={'act-row'}>
          <IconButton
            icon={faPlus}
            text={'Credit Note'}
            onClick={() => navigate(`/financials/purchase_credit_notes/new/${item.id}`)}
            className={'invoice-button'}
          />
        </td>
      </tr>
    );
  };

  const handleDrawRowDraft = (item, i) => {
    return (
      <tr key={item.id} className={i % 2 === 0 ? '' : 'coloured'}>
        <td>{item.issueDate && DateHelper.dateOnly(item.issueDate)}</td>
        <td>
          <Link to={`/financials/purchase_credit_notes/${item.id}`}>{item.documentReference}</Link>
        </td>
        <td>{item.entityReference}</td>
        <td>
          <Link to={`/companies/entities/${item.entity.id}`} target="_blank">
            {item.entity.internalName}
          </Link>
        </td>
        <td>{NumberHelper.formatNumToCurrency(item.totalValue)}</td>
      </tr>
    );
  };

  const handleDrawRowCompleted = (item, i) => {
    return (
      <tr key={item.id} className={i % 2 === 0 ? '' : 'coloured'}>
        <td>{item.issueDate && DateHelper.dateOnly(item.issueDate)}</td>
        <td>{item.dueDate && DateHelper.dateOnly(item.dueDate)}</td>
        <td>
          <Link to={`/financials/purchase_credit_notes/${item.id}`}>{item.documentReference}</Link>
        </td>
        <td>{item.entityReference}</td>
        <td>
          <Link to={`/companies/entities/${item.entity.id}`} target="_blank">
            {item.entity.internalName}
          </Link>
        </td>
        <td>{NumberHelper.formatNumToCurrency(item.totalValue)}</td>
        <td>{item.apiUrn}</td>
      </tr>
    );
  };

  const newParams = {
    title: 'Create New Purchase Credit Note',
    data: dataNew,
    sort: sortNew,
    onSort: onSortNew,
    onSearch: onSearchNew,
    pageElements: pageElementsNew,
    handleDrawRow: handleDrawRowNew,
    pageText: pageTextNew,
    refresh: refreshNew,
    headers: newHeaders,
    loading: loadingNew,
  };

  const draftParams = {
    title: 'Draft Purchase Credit Notes',
    data: dataDraft,
    sort: sortDraft,
    onSort: onSortDraft,
    onSearch: onSearchDraft,
    pageElements: pageElementsDraft,
    handleDrawRow: handleDrawRowDraft,
    pageText: pageTextDraft,
    refresh: refreshDraft,
    headers: draftHeaders,
    loading: loadingDraft,
  };

  const completedParams = {
    title: 'Completed Purchase Credit Notes',
    data: dataCompleted,
    sort: sortCompleted,
    onSort: onSortCompleted,
    onSearch: onSearchCompleted,
    pageElements: pageElementsCompleted,
    handleDrawRow: handleDrawRowCompleted,
    pageText: pageTextCompleted,
    refresh: refreshCompleted,
    headers: completedHeaders,
    loading: loadingCompleted,
  };

  return (
    <div className="all-sales-credits">
      <Breadcrumbs>
        <Link to={'/financials/purchase_credit_notes'}>Purchase Credit Notes</Link>
      </Breadcrumbs>
      <DocumentIndex
        newParams={newParams}
        draftParams={draftParams}
        completedParams={completedParams}
      />
    </div>
  );
};

PurchaseCreditIndex.propTypes = {};

export default PurchaseCreditIndex;
