import React, { useEffect, useState } from 'react';
import useForm from '../../../../common/hooks/useForm/useForm';
import ChargeCode from '../../../../api/controllers/Financial/ChargeCode/ChargeCode';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import Message from '../../../../common/layout/Message/Message';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import FormSection from '../../../../components/FormSection/FormSection';
import FieldList from '../../../../components/FieldList/FieldList';
import FormField from '../../../../components/FormField/FormField';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import './ChargeForm.scss';
import InputMask from 'react-input-mask';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';

const acceptedChars = {
  a: '[a-zA-Z]',
  A: '[a-zA-Z]',
  9: '[0-9]',
};
const ChargeForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { form, handleForm, alter } = useForm();
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(!!id);

  const handleSuccess = json => {
    setLoading(false);
    navigate(`/financials/charge_codes/${json.id}`);
  };
  const handleErrors = json => {
    if (loading) setLoading(false);
    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setLoading(false);
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };

  useEffect(() => {
    if (!id) return initCreate();

    ChargeCode.show(id).then(
      json => {
        setData(json);
        initUpdate(json);
        setLoading(false);
      },
      json => handleErrors(json),
    );
  }, []);

  const initUpdate = json => {
    document.title = `Editing ${json?.code}`;
    handleForm({
      id: null,
      code: json.code || '',
      description: json.description || '',
      purchaseNominal: json.purchaseNominal || '',
      salesNominal: json.salesNominal || '',
    });
  };

  const initCreate = () => {
    document.title = 'New Charge Code';
    handleForm({
      id: null,
      code: '',
      description: '',
      purchase_nominal: '',
      sales_nominal: '',
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (/(_){5,}/gi.test(form.code))
      return handleErrors({ errors: ['Charge Code must be complete'] });
    form.code = form.code.replace(/_/g, '');
    setLoading(true);
    if (id) return ChargeCode.update(id, form).then(handleSuccess, handleErrors);
    ChargeCode.create(form).then(handleSuccess, handleErrors);
  };

  const cancelUrl = id ? `/financials/charge_codes/${id}` : '/financials/charge_codes';
  const breadcrumbs = id ? (
    <Breadcrumbs>
      <Link to={'/financials/charge_codes'}>Charge Codes</Link>
      <Link to={`/financials/charge_codes/${id}`}>{data ? `${data.code}` : id}</Link>
      <Link to={`/financials/charge_codes/${id}/edit`}>Edit</Link>
    </Breadcrumbs>
  ) : (
    <Breadcrumbs>
      <Link to={'/financials/charge_codes'}>Charge Codes</Link>
      <Link to={'/financials/charge_codes/new'}>New</Link>
    </Breadcrumbs>
  );

  return (
    <div className={'charge-code-form'}>
      {breadcrumbs}

      <div className={'page-content'}>
        <Message
          type={error?.type}
          text={error?.text}
          onClose={() => setError()}
          visible={!!error}
        />
        {!loading && form && <h1>{id ? `Editing ${data?.code}` : 'New Charge Code'}</h1>}

        <TabGroup labels={['General']}>
          <div className={'tab-content'}>
            {!loading && form ? (
              <FormSection title={'Details'}>
                <FieldList>
                  <FormField label={'Name'} className={'short'}>
                    <InputMask
                      mask="AAAAAAA"
                      formatChars={acceptedChars}
                      value={form.code}
                      onChange={e => alter('code', e.target.value.toUpperCase())}
                      onKeyDown={e => (e.key !== '_' ? null : e.preventDefault())}
                      data-testid={'code'}
                    />
                  </FormField>
                  <FormField label={'Description'} className={'short'}>
                    <input
                      value={form.description}
                      onChange={e => alter('description', e.target.value)}
                    />
                  </FormField>
                </FieldList>
                <FieldList>
                  <FormField label={'Sales Nominal'} className={'short'}>
                    <input
                      value={form.salesNominal}
                      onChange={e => alter('salesNominal', e.target.value)}
                    />
                  </FormField>
                  <FormField label={'Purchase Nominal'} className={'short'}>
                    <input
                      value={form.purchaseNominal}
                      onChange={e => alter('purchaseNominal', e.target.value)}
                    />
                  </FormField>
                </FieldList>
              </FormSection>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </TabGroup>

        <div className="button-group">
          <IconButton
            text={'Save'}
            icon={faCheck}
            onClick={e => handleSubmit(e)}
            disabled={loading}
          />
          <IconButton
            text={'Cancel'}
            icon={faTimes}
            onClick={() => navigate(cancelUrl)}
            className="edit"
          />
        </div>
      </div>
    </div>
  );
};

ChargeForm.propTypes = {};

export default ChargeForm;
