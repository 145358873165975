class AttributeHelper {
  static returnAttribute(attrArr, conditionCallback, returnOnFail = attrArr) {
    if (
      !attrArr ||
      !Array.isArray(attrArr) ||
      attrArr.length === 0 ||
      typeof conditionCallback !== 'function'
    )
      return returnOnFail;
    return attrArr.filter(li => conditionCallback(li) && li)[0];
  }

  static filterDatabaseAttributes(attrArr) {
    if (!attrArr || !Array.isArray(attrArr) || attrArr.length === 0) return attrArr;
    return attrArr.filter(li => li.modelName);
  }

  static markAllForDestruction(attrArr) {
    if (!attrArr || !Array.isArray(attrArr) || attrArr.length === 0) return attrArr;
    return attrArr.map(li => ({ ...li, _destroy: 1 }));
  }

  static convertObjectToId(attrArr, attr) {
    if (!attrArr || !Array.isArray(attrArr) || attrArr.length === 0) return attrArr;
    return attrArr.map(li => ({ ...li, [`${attr}Id`]: li[attr]?.id }));
  }
  static importAttributes(factors, attributes) {
    return Object.keys(factors).map(f =>
      AttributeHelper.returnAttribute(attributes, attr => attr?.name === f, null),
    );
  }
  static handleAttrChange(idx, val, attr, form, onChange) {
    if (val === null) {
      let methodStore = form[attr];
      delete methodStore[idx];
      return onChange(
        attr,
        methodStore.filter(item => item !== undefined),
      );
    }
    onChange(attr, [
      ...form[attr].slice(0, idx),
      { ...form[attr][idx], ...val },
      ...form[attr].slice(idx + 1),
    ]);
  }

  static handlePersistentAttrChange(idx, val, attr, form, onChange, defaultVal = {}) {
    let arr = [...form[attr]];
    arr[idx] = { ...defaultVal, ...arr[idx], ...val };
    onChange(attr, arr);
  }
}

export default AttributeHelper;
