import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class Envelope {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'emails/envelopes', data);
  }
  static show(id) {
    return apiCall('GET', `emails/envelopes/${id}`);
  }

  // Sends the email
  static create(params) {
    const data = { envelope: params };
    return apiCall('POST', 'emails/envelopes', data);
  }
}

export default Envelope;
