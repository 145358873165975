import React, { useState } from 'react';
import FormSection from '../FormSection/FormSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheck, faTimes, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../common/button/IconButton/IconButton';
import useApi from '../../common/hooks/useApi/useApi';
import Item from '../../api/controllers/File/Item/Item';
import './AttachmentsTab.scss';
import { formatError } from '../../utils/Formatters/Errors/formatError';
import NumberHelper from '../../utils/NumberHelper/NumberHelper';
import DateHelper from '../../utils/DateHelper/DateHelper';
import IconHelper from '../../utils/IconHelper/IconHelper';
import FileUpload from '../../common/FileUpload/FileUpload';
import DownloadHelper from '../../utils/DownloadHelper/DownloadHelper';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox/Checkbox';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { labelForEnum } from '../../utils/EnumHelper/EnumHelper';
import { fileCategories } from '../../strings/enums';
import ErrorStatusMessage from '../../common/ErrorStatusMessage/ErrorStatusMessage';
import { useEffect } from 'react';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';

const AttachmentsTab = ({
  permissions,
  parentId,
  parentType,
  requiredPermit,
  setError,
  emailTab = false,
  handleAttach,
  attachments = [],
  dataLoad = [],
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [fileName, setFilename] = useState();
  const [downloadErr, setDownloadErr] = useState();
  const [fileId, setFileId] = useState();

  // Added so that the attachments tab can be instantiated with basic data, also to allow general files
  const callParams = parentId && parentType && { parentId: parentId, parentType: parentType };
  const { data, refresh, dataLoading } = useApi({
    call: () => Item.all(callParams),
  });

  useEffect(() => {
    refresh();
  }, [dataLoad.length]);

  const canEditParent = permissions?.[requiredPermit].update;
  const canDestroyParent = permissions?.[requiredPermit].destroy;

  const handleDownload = (id, name) => {
    Item.download(id).then(
      res => {
        DownloadHelper.saveFile(name, res);
        setError();
      },
      err => setDownloadErr({ ...err, status: 'Download' }),
    );
  };

  const renderIcon = (mimeType, id, name) => {
    return (
      <div className={'td-icon'} onClick={() => handleDownload(id, name)}>
        <FontAwesomeIcon icon={IconHelper.getFolderIcon(mimeType)} data-testid={'file-icon'} />
      </div>
    );
  };
  const handleCheckbox = (id, pod) => {
    Item.update(id, { pod: !pod }).then(
      () => refresh(),
      err =>
        setError({ ...formatError(err), errorTitle: 'Could not update POD status', type: 'error' }),
    );
  };

  const renderCategory = (category, parentType, pod, id) => {
    const isPod = parentType === 'Jobs::Booking' && !category;
    if (isPod && canEditParent)
      return <Checkbox label="POD Status" checked={pod} onClick={() => handleCheckbox(id, pod)} />;
    if (isPod && !canEditParent)
      return (
        <>
          <FontAwesomeIcon icon={pod ? faCheck : faTimes} />
          <span> POD Status</span>
        </>
      );
    if (!category) return null;
    return labelForEnum(fileCategories, category);
  };

  const handleDelete = () => {
    Item.destroy(fileId).then(
      () => {
        refresh();
      },
      err => setError({ ...formatError(err), errorTitle: 'Delete failed!', type: 'error' }),
    );
    setFilename();
    setFileId();
    setDeleteModal(false);
  };

  return (
    <div className="show-tab-content attachments-tab">
      <ErrorStatusMessage error={downloadErr} setError={setDownloadErr} />
      <ConfirmationModal
        question={'Are you sure you want to delete this file?'}
        subText={fileName}
        confirmText={'Delete'}
        isOpen={deleteModal}
        handleConfirm={handleDelete}
        handleCancel={() => setDeleteModal(false)}
      />
      <FormSection
        title={'Attachments'}
        optionalElement={
          <FileUpload
            setError={setError}
            refresh={refresh}
            parentId={parentId}
            parentType={parentType}
            canEditParent={canEditParent}
          />
        }
      >
        <div className="show-table">
          <table border="1" frame="void" rules="rows">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Size</th>
                {!emailTab && <th>Category</th>}
                <th>Created At</th>
                {!emailTab && <th>Sent At</th>}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!dataLoading &&
                data?.items.map(li => (
                  <tr key={li.id} data-testid={'bsg-line-item'}>
                    <td className={'tiny'}>{renderIcon(li.mimeType, li.id, li.fileName)}</td>
                    <td
                      className={'big td-name'}
                      onClick={() => handleDownload(li.id, li.fileName)}
                    >
                      {li.fileName}
                    </td>
                    <td className={'small'}>{NumberHelper.formatBytes(li.fileSize)}</td>
                    {!emailTab && (
                      <td className={'small'}>
                        {renderCategory(li.category, li.parentType, li.pod, li.id)}
                      </td>
                    )}
                    <td className={'small'}>{DateHelper.format(li.createdAt).replace(',', '')}</td>
                    {!emailTab && (
                      <td className={'small'}>
                        {li.lastSent ? DateHelper.format(li.lastSent).replace(',', '') : 'Not Sent'}
                      </td>
                    )}
                    {!emailTab && (
                      <td className={'tiny'}>
                        {canDestroyParent && (
                          <IconButton
                            text={'Delete'}
                            onClick={() => {
                              setFileId(li.id);
                              setDeleteModal(true);
                              setFilename(li.fileName);
                            }}
                            icon={faTrash}
                            className={'destructive delete-file'}
                          />
                        )}
                      </td>
                    )}
                    {emailTab && (
                      <td className={'small'}>
                        <IconButton
                          text={'Attach'}
                          onClick={() => handleAttach(li)}
                          icon={faPaperclip}
                          disabled={attachments.filter(file => file.id === li.id).length > 0}
                        />
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          {dataLoading && (
            <div style={{ padding: '10px 0' }}>
              <LoadingSpinner />
            </div>
          )}
          {data?.items.length === 0 && (
            <p style={{ textAlign: 'center' }}>No files have been uploaded yet.</p>
          )}
        </div>
      </FormSection>
    </div>
  );
};

AttachmentsTab.propTypes = {
  permissions: PropTypes.object,
  parentId: PropTypes.number,
  parentType: PropTypes.string,
  requiredPermit: PropTypes.string,
  setError: PropTypes.func,
  emailTab: PropTypes.bool,
  handleAttach: PropTypes.func,
  attachments: PropTypes.array,
  dataLoad: PropTypes.array,
};

export default AttachmentsTab;
