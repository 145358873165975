import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Login from '../pages/Login/Login';
import { useSelector } from 'react-redux';
import ProtectedRoute from '../common/ProtectedRoute/ProtectedRoute';
import VehicleRunPlanner from '../pages/Movement/VehicleRunPlanner/VehicleRunPlanner';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import NoPage from '../pages/NoPage/NoPage';
import UserIndex from '../pages/User/UserIndex/UserIndex';
import UserShow from '../pages/User/UserShow/UserShow';
import UserForm from '../pages/User/UserForm/UserForm';
import EntityIndex from '../pages/Company/Entity/EntityIndex/EntityIndex';
import EntityShow from '../pages/Company/Entity/EntityShow/EntityShow';
import EntityEdit from '../pages/Company/Entity/EntityEdit/EntityEdit';
import PackageIndex from '../pages/Job/PackageType/PackageIndex/PackageIndex';
import PackageShow from '../pages/Job/PackageType/PackageShow/PackageShow';
import PackageForm from '../pages/Job/PackageType/PackageForm/PackageForm';
import ChargeIndex from '../pages/Financial/ChargeCode/ChargeIndex/ChargeIndex';
import ChargeShow from '../pages/Financial/ChargeCode/ChargeShow/ChargeShow';
import ChargeForm from '../pages/Financial/ChargeCode/ChargeForm/ChargeForm';
import BookingForm from '../pages/Job/Booking/BookingForm/BookingForm';
import BookingShow from '../pages/Job/Booking/BookingShow/BookingShow';
import BookingIndex from '../pages/Job/Booking/BookingIndex/BookingIndex';
import SalesCreditNoteIndex from '../pages/Financial/SalesCreditNote/SalesCreditNoteIndex/SalesCreditNoteIndex';
import SalesCreditNoteShow from '../pages/Financial/SalesCreditNote/SalesCreditNoteShow/SalesCreditNoteShow';
import PurchaseCreditIndex from '../pages/Financial/PurchaseCredit/PurchaseCreditIndex/PurchaseCreditIndex';
import PurchaseInvoiceIndex from '../pages/Financial/PurchaseInvoice/PurchaseInvoiceIndex/PurchaseIndex';
import PurchaseInvoiceShow from '../pages/Financial/PurchaseInvoice/PurchaseInvoiceShow/PurchaseInvoiceShow';
import PurchaseCreditNoteShow from '../pages/Financial/PurchaseCreditNote/PurchaseCreditNoteShow/PurchaseCreditNoteShow';
import SalesInvoiceShow from '../pages/Financial/SalesInvoice/SalesInvoiceShow/SalesInvoiceShow';
import SalesCreditNoteNew from '../pages/Financial/SalesCreditNote/SalesCreditNoteNew/SalesCreditNoteNew';
import PurchaseInvoiceNew from '../pages/Financial/PurchaseInvoice/PurchaseInvoiceNew/PurchaseInvoiceNew';
import SalesInvoiceNew from '../pages/Financial/SalesInvoice/SalesInvoiceNew/SalesInvoiceNew';
import PurchaseCreditNoteNew from '../pages/Financial/PurchaseCreditNote/PurchaseCreditNoteNew/PurchaseCreditNoteNew';
import SalesCreditNoteEdit from '../pages/Financial/SalesCreditNote/SalesCreditNoteEdit/SalesCreditNoteEdit';
import PurchaseInvoiceEdit from '../pages/Financial/PurchaseInvoice/PurchaseInvoiceEdit/PurchaseInvoiceEdit';
import PurchaseCreditNoteEdit from '../pages/Financial/PurchaseCreditNote/PurchaseCreditNoteEdit/PurchaseCreditNoteEdit';
import SalesInvoiceEdit from '../pages/Financial/SalesInvoice/SalesInvoiceEdit/SalesInvoiceEdit';
import FileCabinet from '../pages/FileCabinet/FileCabinet';
import Profile from '../pages/Profile/Profile';
import EmailTemplateIndex from '../pages/EmailTemplate/EmailTemplateIndex/EmailTemplateIndex';
import EmailTemplateShow from '../pages/EmailTemplate/EmailTemplateShow/EmailTemplateShow';
import EmailTemplateForm from '../pages/EmailTemplate/EmailTemplateForm/EmailTemplateForm';
import DriverIndex from '../pages/Movement/DriverIndex/DriverIndex';
import VehicleForm from '../pages/Movement/Vehicle/VehicleForm/VehicleForm';
import VehicleIndex from '../pages/Movement/Vehicle/VehicleIndex/VehicleIndex';
import VehicleShow from '../pages/Movement/Vehicle/VehicleShow/VehicleShow';
import SalesInvoiceIndex from '../pages/Financial/SalesInvoice/SaleInvoiceIndex/SalesInvoiceIndex';
import BandManagement from '../pages/Tariff/BandManagement/BandManagement';
import TariffCharge from '../pages/Tariff/TariffCharge/TariffCharge';
import TariffArea from '../pages/Tariff/TariffArea/TariffArea';
import FuelCost from '../pages/Movement/Vehicle/FuelCost/FuelCost';
import VehicleRunShow from '../pages/Movement/VehicleRun/VehicleRunShow/VehicleRunShow';
import VehicleRunIndex from '../pages/Movement/VehicleRunIndex/VehicleRunIndex';
import VehicleRunEdit from '../pages/Movement/VehicleRun/VehicleRunEdit/VehicleRunEdit';
import AccountSync from '../pages/Financial/AccountSync/AccountSync';
import BulkSalesInvoiceIndex from '../pages/Financial/BulkSalesInvoice/BulkSalesInvoiceIndex/BulkSalesInvoiceIndex';
import ReportIndex from '../pages/Report/ReportIndex/ReportIndex';
import ReportShow from '../pages/Report/ReportShow/ReportShow';

/*
  Handles all routing in the App.
  As of React Router V6, all children of <Route> components must be Route or React Frags
  We also use nested routing here, meaning its easier to traverse each section as they all
  follow the index/new/show/edit format.

  If theres no token in the reducer, show the login page.
  We have a separated route for VRP as it doesn't contain the pageWrapper
*/
const AppRoutes = ({ user, permissions, onLogin, setUserError, onUserChange }) => {
  const { token } = useSelector(state => state.userReducer);

  return (
    <BrowserRouter>
      {!token && <Login onLogin={onLogin} setUserError={setUserError} />}
      {token && user && permissions && (
        <PageWrapper user={user} permissions={permissions}>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute
                  type={'jobsBookings'}
                  action={'read'}
                  permissions={permissions}
                  user={user}
                >
                  <BookingIndex user={user} permissions={permissions} />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="profile"
              element={
                <Profile permissions={permissions} user={user} onUserChange={onUserChange} />
              }
            />
            <Route
              exact
              path="/virtual_filing_cabinet"
              element={<FileCabinet permissions={permissions} />}
            />
            <Route exact path="financials/charge_codes/">
              <Route
                path="new"
                element={
                  <ProtectedRoute
                    permissions={permissions}
                    type={'financialsChargeCodes'}
                    action={'create'}
                    user={user}
                  >
                    <ChargeForm permissions={permissions} />
                  </ProtectedRoute>
                }
              ></Route>
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      permissions={permissions}
                      type={'financialsChargeCodes'}
                      action={'update'}
                      user={user}
                    >
                      <ChargeForm permissions={permissions} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      permissions={permissions}
                      type={'financialsChargeCodes'}
                      action={'read'}
                      user={user}
                    >
                      <ChargeShow permissions={permissions} />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    permissions={permissions}
                    type={'financialsChargeCodes'}
                    action={'read'}
                    user={user}
                  >
                    <ChargeIndex permissions={permissions} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="financials/sales_credit_notes/">
              <Route
                path="new/:entityid/:bookingid"
                element={
                  <ProtectedRoute
                    type={'financialsDocuments'}
                    action={'create'}
                    permissions={permissions}
                    user={user}
                  >
                    <SalesCreditNoteNew />
                  </ProtectedRoute>
                }
              />
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      type={'financialsDocuments'}
                      action={'update'}
                      permissions={permissions}
                      user={user}
                    >
                      <SalesCreditNoteEdit />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      type={'financialsDocuments'}
                      action={'read'}
                      permissions={permissions}
                      user={user}
                    >
                      <SalesCreditNoteShow user={user} permissions={permissions} />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'financialsDocuments'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <SalesCreditNoteIndex />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="financials/bulk_sales_invoices/">
              <Route
                path="new/:entityid"
                element={
                  <ProtectedRoute
                    type={'financialsDocuments'}
                    action={'create'}
                    permissions={permissions}
                    user={user}
                  >
                    <SalesInvoiceNew bulk />
                  </ProtectedRoute>
                }
              />
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      type={'financialsDocuments'}
                      action={'update'}
                      permissions={permissions}
                      user={user}
                    >
                      <SalesInvoiceEdit bulk />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      type={'financialsDocuments'}
                      action={'read'}
                      permissions={permissions}
                      user={user}
                    >
                      <SalesInvoiceShow user={user} permissions={permissions} bulk />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'financialsDocuments'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <BulkSalesInvoiceIndex />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="financials/sales_invoices/">
              <Route
                path="new/:entityid/:bookingid"
                element={
                  <ProtectedRoute
                    type={'financialsDocuments'}
                    action={'create'}
                    permissions={permissions}
                    user={user}
                  >
                    <SalesInvoiceNew />
                  </ProtectedRoute>
                }
              />
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      type={'financialsDocuments'}
                      action={'update'}
                      permissions={permissions}
                      user={user}
                    >
                      <SalesInvoiceEdit />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      type={'financialsDocuments'}
                      action={'read'}
                      permissions={permissions}
                      user={user}
                    >
                      <SalesInvoiceShow user={user} permissions={permissions} />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'financialsDocuments'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <SalesInvoiceIndex />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="financials/purchase_credit_notes/">
              <Route
                path="new/:id"
                element={
                  <ProtectedRoute
                    type={'financialsDocuments'}
                    action={'create'}
                    permissions={permissions}
                    user={user}
                  >
                    <PurchaseCreditNoteNew />
                  </ProtectedRoute>
                }
              />
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      type={'financialsDocuments'}
                      action={'update'}
                      permissions={permissions}
                      user={user}
                    >
                      <PurchaseCreditNoteEdit />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      type={'financialsDocuments'}
                      action={'read'}
                      permissions={permissions}
                      user={user}
                    >
                      <PurchaseCreditNoteShow permissions={permissions} user={user} />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'financialsDocuments'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <PurchaseCreditIndex />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="financials/purchase_invoices/">
              <Route
                path="new/:id"
                element={
                  <ProtectedRoute
                    type={'financialsDocuments'}
                    action={'create'}
                    permissions={permissions}
                    user={user}
                  >
                    <PurchaseInvoiceNew />
                  </ProtectedRoute>
                }
              />
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      type={'financialsDocuments'}
                      action={'update'}
                      permissions={permissions}
                      user={user}
                    >
                      <PurchaseInvoiceEdit />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      type={'financialsDocuments'}
                      action={'read'}
                      permissions={permissions}
                      user={user}
                    >
                      <PurchaseInvoiceShow permissions={permissions} user={user} />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'financialsDocuments'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <PurchaseInvoiceIndex />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              exact
              path="financials/accounts_sync"
              element={
                <ProtectedRoute
                  type={'financialsDocuments'}
                  action={'read'}
                  permissions={permissions}
                  user={user}
                >
                  <AccountSync />
                </ProtectedRoute>
              }
            />
            <Route exact path="jobs/bookings/">
              <Route
                path="new"
                element={
                  <ProtectedRoute
                    type={'jobsBookings'}
                    action={'create'}
                    permissions={permissions}
                    user={user}
                  >
                    <BookingForm user={user} />
                  </ProtectedRoute>
                }
              />
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      type={'jobsBookings'}
                      action={'update'}
                      permissions={permissions}
                      user={user}
                    >
                      <BookingForm user={user} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      type={'jobsBookings'}
                      action={'read'}
                      permissions={permissions}
                      user={user}
                    >
                      <BookingShow user={user} permissions={permissions} />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'jobsBookings'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <BookingIndex user={user} permissions={permissions} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="jobs/package_types/">
              <Route
                path="new"
                element={
                  <ProtectedRoute
                    type={'jobsPackageTypes'}
                    action={'create'}
                    permissions={permissions}
                    user={user}
                  >
                    <PackageForm />
                  </ProtectedRoute>
                }
              />
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      type={'jobsPackageTypes'}
                      action={'update'}
                      permissions={permissions}
                      user={user}
                    >
                      <PackageForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      type={'jobsPackageTypes'}
                      action={'read'}
                      permissions={permissions}
                      user={user}
                    >
                      <PackageShow />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'jobsPackageTypes'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <PackageIndex />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="companies/entities">
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      type={'companiesEntities'}
                      action={'update'}
                      permissions={permissions}
                      user={user}
                    >
                      <EntityEdit />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      type={'companiesEntities'}
                      action={'read'}
                      permissions={permissions}
                      user={user}
                    >
                      <EntityShow user={user} permissions={permissions} />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'companiesEntities'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <EntityIndex />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="users/">
              <Route
                path="new"
                element={
                  <ProtectedRoute
                    type={'users'}
                    action={'create'}
                    permissions={permissions}
                    user={user}
                  >
                    <UserForm onUserChange={onUserChange} user={user} />
                  </ProtectedRoute>
                }
              />
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      type={'users'}
                      action={'update'}
                      permissions={permissions}
                      user={user}
                    >
                      <UserForm onUserChange={onUserChange} user={user} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      type={'users'}
                      action={'read'}
                      permissions={permissions}
                      user={user}
                    >
                      <UserShow />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'users'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <UserIndex />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="emails/templates/">
              <Route
                path="new"
                element={
                  <ProtectedRoute
                    type={'emailsTemplates'}
                    action={'create'}
                    permissions={permissions}
                    user={user}
                  >
                    <EmailTemplateForm user={user} />
                  </ProtectedRoute>
                }
              />
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      type={'emailsTemplates'}
                      action={'update'}
                      permissions={permissions}
                      user={user}
                    >
                      <EmailTemplateForm user={user} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      type={'emailsTemplates'}
                      action={'read'}
                      permissions={permissions}
                      user={user}
                    >
                      <EmailTemplateShow user={user} />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'emailsTemplates'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <EmailTemplateIndex />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="drivers/">
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'movementsDrivers'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <DriverIndex permissions={permissions} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="vehicles/">
              <Route
                path="new"
                element={
                  <ProtectedRoute
                    type={'movementsVehicles'}
                    action={'create'}
                    permissions={permissions}
                    user={user}
                  >
                    <VehicleForm />
                  </ProtectedRoute>
                }
              />
              <Route path=":id">
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute
                      type={'movementsVehicles'}
                      action={'update'}
                      permissions={permissions}
                      user={user}
                    >
                      <VehicleForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      type={'movementsVehicles'}
                      action={'read'}
                      permissions={permissions}
                      user={user}
                    >
                      <VehicleShow permissions={permissions} />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <ProtectedRoute
                    type={'movementsVehicles'}
                    action={'read'}
                    permissions={permissions}
                    user={user}
                  >
                    <VehicleIndex permissions={permissions} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route exact path="tariffs/">
              <Route
                path="band_management"
                element={
                  <ProtectedRoute
                    type={'tariffsAreas'}
                    action={'update'}
                    permissions={permissions}
                    user={user}
                  >
                    <BandManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="areas"
                element={
                  <ProtectedRoute
                    type={'tariffsAreas'}
                    action={'update'}
                    permissions={permissions}
                    user={user}
                  >
                    <TariffArea />
                  </ProtectedRoute>
                }
              />
              <Route
                path="charges"
                element={
                  <ProtectedRoute
                    type={'tariffsAreas'}
                    action={'update'}
                    permissions={permissions}
                    user={user}
                  >
                    <TariffCharge />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="fuel_costs/"
              element={
                <ProtectedRoute
                  type={'movementsFuelPrices'}
                  action={'update'}
                  permissions={permissions}
                  user={user}
                >
                  <FuelCost />
                </ProtectedRoute>
              }
            />
            <Route exact path="movements/">
              <Route path="runs/">
                <Route path=":id">
                  <Route
                    path="edit"
                    element={
                      <ProtectedRoute
                        permissions={permissions}
                        type={'movementsVehicleRuns'}
                        action={'update'}
                        user={user}
                      >
                        <VehicleRunEdit />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path=""
                    element={
                      <ProtectedRoute
                        permissions={permissions}
                        type={'movementsVehicleRuns'}
                        action={'read'}
                        user={user}
                      >
                        <VehicleRunShow permissions={permissions} user={user} />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      permissions={permissions}
                      type={'movementsVehicleRuns'}
                      action={'read'}
                      user={user}
                    >
                      <VehicleRunIndex permissions={permissions} />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="vehicle_runs"
                exact
                element={
                  <ProtectedRoute
                    type="movementsVehicleRuns"
                    action="read"
                    permissions={permissions}
                  >
                    <VehicleRunPlanner user={user} permissions={permissions} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="reports/standard">
              <Route
                path=":id"
                element={
                  <ProtectedRoute
                    permissions={permissions}
                    type={'users'}
                    action={'read'}
                    user={user}
                  >
                    <ReportShow />
                  </ProtectedRoute>
                }
              />
              <Route
                path=""
                element={
                  <ProtectedRoute
                    permissions={permissions}
                    type={'users'}
                    action={'read'}
                    user={user}
                  >
                    <ReportIndex />
                  </ProtectedRoute>
                }
              />
            </Route>

            {/*This should be unreachable, but it here just to make sure */}
            <Route path="*" element={<NoPage />} />
          </Routes>
        </PageWrapper>
      )}
    </BrowserRouter>
  );
};

AppRoutes.propTypes = {
  userError: PropTypes.string,
  setUserError: PropTypes.func,
  user: PropTypes.object,
  permissions: PropTypes.object,
  onLogin: PropTypes.func,
  onUserChange: PropTypes.func,
};

export default AppRoutes;
