import React from 'react';
import PropTypes from 'prop-types';
import './FieldList.scss';

// Wraps a list of fields within a particular form section.
const FieldList = ({ children, className = '' }) => {
  return <div className={`field-list${` ${className}`}`}>{children}</div>;
};

FieldList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default FieldList;
