import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal/Modal';
import VehicleRunForm from '../../forms/VehicleRunForm/VehicleRunForm';
import FormSection from '../../FormSection/FormSection';
import './EditVehicleRun.scss';
import Message from '../../../common/layout/Message/Message';
import { formatVehicleRun } from '../../../utils/Formatters/VehicleRun/formatVehicleRun';
import VehicleRun from '../../../api/controllers/Movement/VehicleRun/VehicleRun';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import useApi from '../../../common/hooks/useApi/useApi';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import Entity from '../../../api/controllers/Company/Entity/Entity';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import useForm from '../../../common/hooks/useForm/useForm';
import useArray from '../../../common/hooks/useArray/useArray';

// Why? Created for the VehicleRunPlanner screen to facilitate the updating of existing VehicleRun entities
const EditVehicleRun = ({
  vehicleId,
  handleClose,
  subwindow,
  parent,
  currentFormContent,
  showMileage = false,
  showArchived,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [messages, setMessages] = useState({});

  // Handles errors caused by the vehicles useApi call below
  const handleError = err => setMessages(formatError(err));

  // Retrieves the vehicleRun entity for the Id provided
  const { vehicleRun, vehicleRunLoading } = useApi({
    call: () => VehicleRun.show(vehicleId),
    name: 'vehicleRun',
    handleError,
    dependencies: [vehicleId],
  });

  //Form is stored here now so we can actively access it for the poping
  const { form, alter, handleForm } = useForm();

  const {
    waypoints,
    addWaypoints,
    removeIdxWaypoints,
    replaceWaypoints,
    swapWaypoints,
    handleWaypoints,
  } = useArray({ name: 'waypoints' });

  const { destroyedWaypoints, addDestroyedWaypoints, handleDestroyedWaypoints } = useArray({
    name: 'destroyedWaypoints',
  });

  // Submits the update request for this vehicle
  const submitVehicle = (e, form) => {
    e.preventDefault();
    setSubmitting(true);
    const params = formatVehicleRun(form);

    VehicleRun.update(vehicleId, params).then(
      () => {
        setSubmitting(false);
        handleClose();
      },
      err => {
        setSubmitting(false);
        setMessages(formatError(err));
      },
    );
  };

  /*
    Why? This useEffect triggers when vehicleRun is valid and will add an entity to each waypoint
      This means that we will be making an api call for every single waypoint the user has for autofilling purposes
      Yes it sucks, and yes I've looked for alternatives, if you can think of one - go implement it <(^=^)>
  */
  useEffect(() => {
    setMessages({});
    // This small function simply retrieves an array of entity id's e.g. [1, 2, 3]
    const getEntitiesFromWaypoints = waypoints =>
      waypoints.map(waypoint => waypoint.address.entityId);

    if (currentFormContent) {
      const { waypoints } = currentFormContent;
      const idArray = getEntitiesFromWaypoints(waypoints); // Get our entity id's

      /*
        Why? This API controller endpoint simply creates a Promise.all and returns the values as an array
        Notes? The ONLY REASON this works is that we're using Promise.all, a quirk of this method is that
          it MAINTAINS THE ORDER OF PROMISES PASSED TO IT. This is REALLY important which is why its in caps.
          If you screw with the ordering, everything is going to break... so yeah. Don't.
      */
      Entity.showMany(idArray).then(res => {
        // Get a new variant of the waypoints attribute which contains the relevant entity
        const newWaypoints = waypoints.map((waypoint, i) => ({ ...waypoint, entity: res[i] }));
        // Set our default form ready for autofilling
        handleForm({ ...vehicleRun, waypoints: newWaypoints });
        handleWaypoints(newWaypoints);
      });
      handleDestroyedWaypoints(currentFormContent.destroyedWaypoints);
      handleForm(currentFormContent.form);
    }
    // Makes sure this only runs once everything is prepared
    else if (vehicleRun && !vehicleRunLoading) {
      const { waypoints } = vehicleRun;
      const idArray = getEntitiesFromWaypoints(waypoints); // Get our entity id's

      /*
        Why? This API controller endpoint simply creates a Promise.all and returns the values as an array
        Notes? The ONLY REASON this works is that we're using Promise.all, a quirk of this method is that
          it MAINTAINS THE ORDER OF PROMISES PASSED TO IT. This is REALLY important which is why its in caps.
          If you screw with the ordering, everything is going to break... so yeah. Don't.
      */
      Entity.showMany(idArray).then(res => {
        // Get a new variant of the waypoints attribute which contains the relevant entity
        const newWaypoints = waypoints.map((waypoint, i) => ({ ...waypoint, entity: res[i] }));
        // Set our default form ready for autofilling
        handleForm({ ...vehicleRun, waypoints: newWaypoints });
        handleWaypoints(newWaypoints);
      });
    }
    // If we update the vehiclerun then yeah, we're gonna want a fresh variant
  }, [vehicleRun]);

  const handleModalMove = e => {
    e.preventDefault();
    e.stopPropagation();
    if (subwindow)
      subwindow.postMessage({
        type: 'modalMove',
        variant: 'edit',
        id: vehicleId,
        currentForm: { form, waypoints, destroyedWaypoints },
      });
    if (parent)
      parent.postMessage({
        type: 'modalMove',
        variant: 'edit',
        id: vehicleId,
        currentForm: { form, waypoints, destroyedWaypoints },
      });
    handleClose();
  };

  return (
    <Modal isOpen handleClose={handleClose}>
      <FormSection
        title={'Edit Vehicle Run'}
        draggable
        optionalElement={
          <IconButton
            iconOnly
            icon={faExternalLink}
            disabled={!subwindow && !parent}
            onClick={handleModalMove}
            testId={'-pop-out'}
          />
        }
      >
        <Message
          visible={!!messages?.errors}
          text={messages.errors}
          onClick={() => setMessages([])}
          type={'error'}
        />
        {form ? (
          <VehicleRunForm
            handleSubmit={submitVehicle}
            handleClose={handleClose}
            submitting={submitting}
            waypoints={waypoints}
            addDestroyedWaypoints={addDestroyedWaypoints}
            removeIdxWaypoints={removeIdxWaypoints}
            replaceWaypoints={replaceWaypoints}
            swapWaypoints={swapWaypoints}
            form={form}
            alter={alter}
            addWaypoints={addWaypoints}
            destroyedWaypoints={destroyedWaypoints}
            showMileage={showMileage}
            showArchived={showArchived}
          />
        ) : (
          <LoadingSpinner />
        )}
      </FormSection>
    </Modal>
  );
};

EditVehicleRun.propTypes = {
  vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleClose: PropTypes.func.isRequired,
  subwindow: PropTypes.object,
  parent: PropTypes.object,
  currentFormContent: PropTypes.object,
  showMileage: PropTypes.bool,
  showArchived: PropTypes.object,
};

export default EditVehicleRun;
