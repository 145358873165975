import React from 'react';
import PropTypes from 'prop-types';
import VehicleRunFormGeneral from './VehicleRunFormGeneral/VehicleRunFormGeneral';
import VehicleRunFormWaypoints from './VehicleRunFormWaypoints/VehicleRunFormWaypoints';
import FormSection from '../../FormSection/FormSection';
import Checkbox from '../../Checkbox/Checkbox';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import './VehicleRunForm.scss';

const exampleWaypoint = {
  entity: {},
  address: {},
  estimatedTime: '',
  actualTime: '',
  instructions: '',
  permanent: true,
  open: false,
};

/*
  Why? This form was added for the creation or editing of a VehicleRun

  Design: https://xd.adobe.com/view/3956ae15-d40c-4d6c-b54d-30083e6c2c8e-6c05/?x_product=xd-jira%2F2.0
*/
const VehicleRunForm = ({
  handleSubmit,
  handleClose,
  form,
  alter,
  addWaypoints,
  addDestroyedWaypoints,
  waypoints,
  destroyedWaypoints,
  removeIdxWaypoints,
  replaceWaypoints,
  swapWaypoints,
  submitting = false,
  showMileage = false,
  showArchived,
}) => {
  // Attached to the General FormSection
  const subcontractedCheckbox = (
    <Checkbox
      label={'Subcontracted'}
      checked={form.subcontracted}
      onClick={() => alter('subcontracted', !form.subcontracted)}
      reverse
    />
  );

  // Attached to the Waypoints FormSection
  const newWaypointButton = (
    <IconButton
      text={'Add Waypoint'}
      onClick={e => {
        e.preventDefault();
        addWaypoints(exampleWaypoint);
      }}
      icon={faPlus}
    />
  );

  return (
    <div className={'vehicle-run-form'}>
      <FormSection title={'General'} optionalElement={subcontractedCheckbox}>
        <VehicleRunFormGeneral
          form={form}
          alter={alter}
          showMileage={showMileage}
          showArchived={showArchived}
        />
      </FormSection>
      <FormSection
        title={'Waypoints'}
        optionalElement={newWaypointButton}
        extraClassName={'vrf-waypoints'}
      >
        <VehicleRunFormWaypoints
          waypoints={waypoints}
          addDestroyedWaypoints={addDestroyedWaypoints}
          removeIdxWaypoints={removeIdxWaypoints}
          replaceWaypoints={replaceWaypoints}
          swapWaypoints={swapWaypoints}
        />
      </FormSection>
      <div className="vehicle-run-form-button-section">
        <IconButton
          text={'Save'}
          icon={faCheck}
          onClick={e =>
            handleSubmit(e, { ...form, waypoints: [...waypoints, ...destroyedWaypoints] })
          }
          loading={submitting}
        />
        <IconButton text={'Cancel'} icon={faTimes} className={'edit'} onClick={handleClose} />
      </div>
    </div>
  );
};

VehicleRunForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired, // This function will be called upon form submission
  handleClose: PropTypes.func.isRequired, // This function is called when cancel is clicked
  submitting: PropTypes.bool, // This bool handles whether or not the form is in the process of being submitted
  waypoints: PropTypes.array.isRequired,
  destroyedWaypoints: PropTypes.array.isRequired,
  addDestroyedWaypoints: PropTypes.func.isRequired,
  removeIdxWaypoints: PropTypes.func.isRequired,
  replaceWaypoints: PropTypes.func.isRequired,
  swapWaypoints: PropTypes.func.isRequired,
  addWaypoints: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  alter: PropTypes.func.isRequired,
  showMileage: PropTypes.bool,
  showArchived: PropTypes.object,
};

export default VehicleRunForm;
