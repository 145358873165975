import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class Item {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'notes/items', data);
  }
  static show(id) {
    return apiCall('GET', `notes/items/${id}`);
  }

  // Sends the email
  static create(params) {
    const data = { item: params };
    return apiCall('POST', 'notes/items', data);
  }

  // Sends the email
  static update(id, params) {
    const data = { item: params };
    return apiCall('PATCH', `notes/items/${id}`, data);
  }

  // Sends the email
  static destroy(id) {
    return apiCall('DELETE', `notes/items/${id}`);
  }
}

export default Item;
