import React from 'react';
import PropTypes from 'prop-types';
import './ErrorBoundary.scss';

// Stolen wholesale from https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error: String(error) };
  }

  render() {
    if (this.state.error) {
      return <div className="error-boundary">{this.state.error}</div>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
