class DownloadHelper {
  static saveFile(filename, blob) {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = `${filename}`;
    a.click();
  }
}

export default DownloadHelper;
