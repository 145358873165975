import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './LinkBar.scss';
import { CSSTransition } from 'react-transition-group';

const LinkBar = ({ icon, text = 'Link Name', link, onClick, open, size = '1x', target = '' }) => {
  const nodeRef = createRef();
  const renderLinkDetails = () => (
    <>
      <div className="lb-icon">
        {icon && <FontAwesomeIcon icon={icon} size={size} title={'primary'} />}
      </div>
      <div className="lb-text">{text}</div>
      <CSSTransition nodeRef={nodeRef} in={open} timeout={200} classNames={'arrow-transition'}>
        <div ref={nodeRef} className="lb-arrow">
          {open !== undefined && <FontAwesomeIcon icon={faAngleRight} title={'arrow'} />}
        </div>
      </CSSTransition>
    </>
  );

  if (link)
    return (
      <Link className="lb-container" to={link} target={target}>
        {renderLinkDetails()}
      </Link>
    );
  return (
    <div className="lb-container" onClick={onClick}>
      {renderLinkDetails()}
    </div>
  );
};

LinkBar.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  open: PropTypes.any /* boolean or undefined */,
  link: PropTypes.object,
  target: PropTypes.string,
};

export default LinkBar;
