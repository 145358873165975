import Action from '../Action/Action';

/*
  Appends a new item created to state, with the option to undo if required.
*/
class CreateAction extends Action {
  constructor(setState, currentState, newItem) {
    super();
    this.setState = setState;
    this.currentState = currentState;
    this.newItem = newItem;
  }

  execute() {
    this.setState([...this.currentState, this.newItem]);
  }

  undo() {
    this.setState(this.currentState.filter(item => item.id !== this.newItem.id));
  }
}

export default CreateAction;
