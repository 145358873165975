import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CollapsibleTable from '../../../../../components/CollapsibleTable/CollapsibleTable';
import { faBoxesStacked } from '@fortawesome/free-solid-svg-icons';
import VehicleRunRow from '../VehicleRunRow/VehicleRunRow';
import Dropzone from '../Dropzone/Dropzone';
import './SectionCollapsibleTable.scss';
import { vehicleRunHeaders as headers } from '../../../../../strings/arrays/VehicleRun/vehicleRunHeaders';
import DestroyMultipleAction from '../../actions/DestroyMultipleAction/DestroyMultipleAction';
import DropdownPresenter from '../../../../../common/Dropdown/DropdownPresenter/DropdownPresenter';

const filterTypes = [
  { type: '', value: 'Everything' },
  { type: 'COL', value: 'Collection' },
  { type: 'DEL', value: 'Delivery' },
  { type: 'XT', value: 'X-Trade' },
];

// renders the none vehicle run jobs, eg an unassigned section or pending.
const SectionCollapsibleTable = ({
  title,
  colours,
  fsId,
  defaultOpen = false, //specifies if we want this to be open by default, usually no
  data, // the bookings within the section
  vehicles = [],
  setError,
  action,
  deliveryFilter,
  alter,
  bookingsLoading, // if we are currently loading the list of bookings
  jobs = [],
  setJobs = () => {},
  setCurrentDraggingJobs,
  rowRemovalAction,
  highlighted,
  error,
  setHighlighted,
  parent,
  subwindow,
  handleNoteModal,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  /*
    Handles the opening and closing of the section
    if its already Open, we close the section and also clear the highlighted jobs
    as we do not want the user to drag something that may no longer be present on the DOM

    If its not open and we also have data (some jobs), just open the section
    otherwise, we open the section but also call the GET action to receive the jobs
  */
  const toggleOpen = () => {
    if (open) {
      setHighlighted([]);
      return setOpen(false);
    }

    if (data.length !== 0) return setOpen(true);
    setOpen(true);
    action.execute();
  };

  const drawRow = (item, idx) => (
    <VehicleRunRow
      job={item}
      key={idx}
      fsId={fsId}
      setError={setError}
      section={title}
      error={error}
      vehicles={vehicles}
      setCurrentDraggingJobs={setCurrentDraggingJobs}
      highlighted={highlighted}
      setHighlighted={setHighlighted}
      handleNoteModal={handleNoteModal}
    />
  );

  const drawSubcontractedFilter = () => {
    return filterTypes.map((item, idx) => (
      <button
        key={idx}
        className={'dd-button'}
        onClick={() => {
          alter(`deliveryType${title}`, item);
          setDropdownOpen(false);
        }}
      >
        {item.value}
      </button>
    ));
  };

  const drawActions = () => (
    <DropdownPresenter
      value={deliveryFilter.value}
      drawOptions={drawSubcontractedFilter}
      open={dropdownOpen}
      setOpen={setDropdownOpen}
      dropdownHeight={27}
    />
  );

  return (
    <Dropzone
      section={title}
      setError={setError}
      set={setJobs}
      current={jobs}
      rowRemovalAction={rowRemovalAction}
      setHighlighted={setHighlighted}
      setCurrentDraggingJobs={setCurrentDraggingJobs}
      error={error}
      subwindow={subwindow}
      parent={parent}
    >
      <CollapsibleTable
        title={title}
        icon={faBoxesStacked}
        headers={headers}
        onDrawRow={drawRow}
        actions={drawActions()}
        rows={data}
        colours={colours}
        footer={<span className="vr-section-footer"></span>}
        open={open}
        setOpen={toggleOpen}
        loading={bookingsLoading}
        defaultSort={{ sort: headers.job.sortingAttribute, order: 'asc' }}
        setHighlighted={setHighlighted}
        handleNoteModal={handleNoteModal}
        searchable
        canHighlight
      />
    </Dropzone>
  );
};

SectionCollapsibleTable.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  colours: PropTypes.object,
  fsId: PropTypes.number,
  defaultOpen: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  vehicles: PropTypes.arrayOf(PropTypes.object),
  setError: PropTypes.func.isRequired,
  bookingsLoading: PropTypes.bool.isRequired,
  error: PropTypes.array,
  setJobs: PropTypes.func,
  jobs: PropTypes.arrayOf(PropTypes.object),
  setCurrentDraggingJobs: PropTypes.func.isRequired,
  rowRemovalAction: PropTypes.instanceOf(DestroyMultipleAction),
  action: PropTypes.object.isRequired,
  highlighted: PropTypes.arrayOf(PropTypes.object).isRequired,
  setHighlighted: PropTypes.func.isRequired,
  deliveryFilter: PropTypes.object.isRequired,
  alter: PropTypes.func.isRequired,
  parent: PropTypes.object,
  subwindow: PropTypes.object,
  handleNoteModal: PropTypes.func.isRequired,
};

export default SectionCollapsibleTable;
