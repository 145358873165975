import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './CollapsibleTable.scss';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';
import TableHelper from '../../utils/TableHelper/TableHelper';
import Sorter from '../../utils/Sorter/Sorter';
import search from '../../utils/search/search';

const findSort = {
  number: Sorter.numberSort,
  string: Sorter.stringSort,
  entity: Sorter.entitySort,
  default: Sorter.stringSort,
};

const CollapsibleTable = ({
  icon,
  title,
  headers,
  rows,
  onDrawRow,
  colours = {
    header: 'var(--blue-1)',
    table: 'var(--blue-3)',
    icon: 'var(--blue-2)',
    tableText: 'var(--white)',
    footer: 'var(--collapsible-footer)',
  },
  footer,
  actions,
  open,
  setOpen,
  loading = false,
  searchable = false,
  defaultSort = {},
  canHighlight = false,
  setHighlighted,
}) => {
  const nodeRef = createRef();
  const [sort, setSort] = useState(defaultSort);
  const [searchText, setSearchText] = useState('');

  const onSort = header => TableHelper.onSort(header, sort, setSort);

  const getIcon = header => {
    const headerAttr = header.sortingAttribute;
    if (!sort || sort.sort !== headerAttr) return faSort;
    if (sort.order === 'desc') return faSortUp;
    return faSortDown;
  };

  const headerCells = Object.keys(headers).map((name, i) => {
    const header = headers[name];
    return (
      <div
        key={i}
        onClick={header.sortingAttribute ? () => onSort(header) : null}
        className={`header${header.sortingAttribute ? '' : ' no-sort'}${
          header.shrinkClass ? ` ${header.shrinkClass}` : ''
        }`}
        style={header.width ? { width: header.width, flexShrink: 0 } : { flex: 1 }}
      >
        <div className="header-content">
          {header.text}
          {header.sortingAttribute && (
            <FontAwesomeIcon icon={getIcon(header)} data-testid="sorticon" className="sort-icon" />
          )}
        </div>
      </div>
    );
  });

  const onSearch = e => {
    if (canHighlight) setHighlighted([]);
    setSearchText(e.target.value);
  };
  const sortFunc = findSort[sort.type] || findSort.default;

  const searched =
    rows &&
    search(searchText, rows, [
      'jobReference',
      'customer.internalName',
      'customer.shortName',
      'customerReference',
      'collectionEntity.internalName',
      'collectionEntity.shortName',
      'collectionAddress.postcode',
      'collectionAddress.region',
      'deliveryEntity.internalName',
      'deliveryEntity.shortName',
      'deliveryAddress.postcode',
      'deliveryAddress.region',
    ]);
  const sorted = searched && sortFunc({ ...sort, data: [...searched] });

  const tableRows = sorted.map((row, i) => onDrawRow(row, i));

  const loadingRow = (
    <div>
      <div colSpan="99">
        <LoadingSpinner />
      </div>
    </div>
  );

  return (
    <div className="c-table-section">
      <div className="c-table-header" style={{ backgroundColor: colours.header }}>
        <div className="c-table-header-section" style={{ padding: '5px' }}>
          <div
            className="c-table-header-icon"
            style={{ backgroundColor: colours.icon }}
            onClick={() => setOpen(!open)}
          >
            {icon && <FontAwesomeIcon icon={icon} />}
            {!icon &&
              (open ? (
                <FontAwesomeIcon icon={faSortUp} data-testid="uparrow" />
              ) : (
                <FontAwesomeIcon icon={faSortDown} data-testid="downarrow" />
              ))}
          </div>
          {typeof title === 'object' ? title : <div style={{ margin: 'auto' }}>{title}</div>}
        </div>

        <div className="c-table-header-section">
          {actions}
          {searchable && <input value={searchText} onChange={onSearch} placeholder={'Search...'} />}
        </div>
      </div>
      <CSSTransition
        nodeRef={nodeRef}
        in={open}
        timeout={150}
        classNames={'c-table-transition'}
        unmountOnExit
      >
        <div ref={nodeRef} className="c-table-body">
          <div
            className="c-table-headers"
            style={{ display: 'flex', backgroundColor: colours.table, color: colours.tableText }}
          >
            {headerCells}
          </div>
          <div className="c-table-content">{loading ? loadingRow : tableRows}</div>
        </div>
      </CSSTransition>
      {footer && (
        <div
          className="c-table-footer"
          style={{ backgroundColor: colours.footer || colours.header }}
        >
          {footer}
        </div>
      )}
    </div>
  );
};

CollapsibleTable.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headers: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDrawRow: PropTypes.func.isRequired,
  colours: PropTypes.object,
  footer: PropTypes.element,
  actions: PropTypes.element,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  loading: PropTypes.bool,
  sort: PropTypes.object,
  searchable: PropTypes.bool,
  onSort: PropTypes.func,
  defaultSort: PropTypes.object,
  canHighlight: PropTypes.bool,
  setHighlighted: PropTypes.func,
};

export default CollapsibleTable;
