import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

// Handles all contact requests.
class Contact {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'companies/contacts', data);
  }

  static show(id) {
    return apiCall('GET', `companies/contacts/${id}`);
  }

  static create(params) {
    const data = { contact: params };
    return apiCall('POST', 'companies/contacts', data);
  }

  static update(id, params) {
    const data = { contact: params };
    return apiCall('PATCH', `companies/contacts/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `companies/contacts/${id}`);
  }

  static contactMethodsKinds() {
    return apiCall('GET', `companies/contacts/contact_method_kinds`);
  }

  static archive(id) {
    return apiCall('POST', `companies/contacts/${id}/archive`);
  }

  static unArchive(id) {
    return apiCall('POST', `companies/contacts/${id}/unarchive`);
  }
}

export default Contact;
