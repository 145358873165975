import React from 'react';
import PropTypes from 'prop-types';
import './AddressIndicator.scss';

const AddressIndicator = ({ mainAddress, invoiceAddress }) => {
  const addressIndicatorText =
    mainAddress && invoiceAddress
      ? mainAddress + ' / ' + invoiceAddress
      : mainAddress !== false
      ? mainAddress
      : invoiceAddress;

  return <div className={'address-indicator'}>{addressIndicatorText}</div>;
};

AddressIndicator.propTypes = {
  mainAddress: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  invoiceAddress: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default AddressIndicator;
