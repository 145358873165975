import {
  faEnvelope,
  faFile,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
} from '@fortawesome/free-solid-svg-icons';

class IconHelper {
  static getFolderIcon(mimeType) {
    if (mimeType?.includes('image/')) return faFileImage;

    const folderIcons = {
      'application/pdf': faFilePdf,
      'application/x-pdf': faFilePdf,
      'application/x-bzpdf': faFilePdf,
      'application/x-gzpdf': faFilePdf,
      'application/msword': faFileWord,
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': faFileWord,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': faFileExcel,
      'application/vnd.ms-excel': faFileExcel,
      'application/vnd.ms-powerpoint': faFilePowerpoint,
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': faFilePowerpoint,
      'application/vnd.ms-outlook': faEnvelope,
      'text/csv': faFileCsv,
    };
    if (!folderIcons?.[mimeType]) return faFile;
    return folderIcons[mimeType];
  }
}

export default IconHelper;
