import React, { useState } from 'react';
import Modal from '../../../common/Modal/Modal';
import FormSection from '../../FormSection/FormSection';
import PropTypes from 'prop-types';
import NoteForm from '../../forms/NoteForm/NoteForm';
import Item from '../../../api/controllers/Note/Item/Item';
import Message from '../../../common/layout/Message/Message';
import useForm from '../../../common/hooks/useForm/useForm';

const NewNote = ({ handleClose, parentType, parentId, handleSuccess }) => {
  const { form, alter } = useForm({
    parentId,
    parentType,
    summary: '',
    details: '',
    showOnPdfs: false,
  });
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState({});

  const handleSubmit = (e, form) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    Item.create(form).then(
      () => handleSuccess(),
      err => {
        setMessages(err);
        setLoading(false);
      },
    );
  };

  return (
    <Modal isOpen handleClose={handleClose}>
      <FormSection title={'Create Note'} draggable>
        <Message
          visible={!!messages?.errors || messages.error}
          text={messages.errors || messages.error}
          onClick={() => setMessages([])}
          type={'error'}
        />
        <NoteForm
          form={form}
          alter={alter}
          handleClose={handleClose}
          submitText="Create Note"
          submitting={loading}
          handleSubmit={handleSubmit}
        />
      </FormSection>
    </Modal>
  );
};

NewNote.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  parentType: PropTypes.string,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NewNote;
