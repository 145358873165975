import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../../../components/FormSection/FormSection';
import FieldList from '../../../../../../../../components/FieldList/FieldList';
import FormField from '../../../../../../../../components/FormField/FormField';
import ValueInput from '../../../../../../../../common/input/ValueInput/ValueInput';
import PackageType from '../../../../../../../../api/controllers/Job/PackageType/PackageType';
import Checkbox from '../../../../../../../../components/Checkbox/Checkbox';
import useApi from '../../../../../../../../common/hooks/useApi/useApi';
import GenericDropdown from '../../../../../../../../common/GenericDropdown/GenericDropdown';

const BookingFormConsignmentDetails = ({ data, alter, open, toggle }) => {
  const { packages } = useApi({
    call: PackageType.all,
    name: 'packages',
  });

  return (
    <FormSection
      title={'Consignment Details'}
      optionalElement={
        <Checkbox checked={open} onClick={toggle} label={'Dimensions Lines'} reverse />
      }
    >
      <FieldList>
        <FormField label={'Quantity'}>
          <input
            data-testid={'bfg-quantity'}
            type={'number'}
            min={0}
            value={data.quantity || ''}
            onChange={e => alter('quantity', e.target.value)}
          />
        </FormField>
        <FormField label={'Package Type'}>
          <GenericDropdown
            value={data.packageType}
            items={packages?.items}
            onDisplay={pt => pt?.name}
            onSelect={pt => alter('packageType', pt)}
            onClear={() => alter('packageType', null)}
          />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label={'Gross Weight'}>
          <ValueInput
            testId={'bfg-gross-weight'}
            focusId={'bfg-gross-weight'}
            unit={'kg'}
            value={data.grossWeightKg || ''}
            onChange={e => alter('grossWeightKg', e.target.value)}
            min={0}
          />
        </FormField>
        <FormField label={'Net Weight'}>
          <ValueInput
            unit={'kg'}
            value={data.netWeightKg || ''}
            onChange={e => alter('netWeightKg', e.target.value)}
            min={0}
          />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label={'Cubic Metres'}>
          <ValueInput
            unit={'m³'}
            value={data.cubicMetres || ''}
            onChange={e => alter('cubicMetres', e.target.value)}
            min={0}
            step={0.01}
          />
        </FormField>
        <FormField label={'Loading Metres'}>
          <ValueInput
            unit={'m'}
            value={data.loadingMetres || ''}
            min={0}
            step={0.1}
            onChange={e => alter('loadingMetres', e.target.value)}
          />
        </FormField>
      </FieldList>
    </FormSection>
  );
};

BookingFormConsignmentDetails.propTypes = {
  data: PropTypes.object,
  alter: PropTypes.func,
  open: PropTypes.bool,
  toggle: PropTypes.func,
};

export default BookingFormConsignmentDetails;
