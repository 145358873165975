import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal/Modal';
import FormSection from '../../FormSection/FormSection';
import BookingShowNotes from '../../../pages/Job/Booking/BookingShow/subComponents/BookingShowNotes/BookingShowNotes';

/*
  TSR-1242: Add the ability to view notes from the VehicleRunPlanner screen
  JIRA: https://yozudev.atlassian.net/browse/TSR-1242
*/
const ShowJobNotes = ({ booking, user, permissions, handleClose }) => (
  <Modal isOpen handleClose={handleClose}>
    <FormSection title={'Notes'} extraClassName="nopad large">
      <BookingShowNotes booking={booking} user={user} permissions={permissions} readOnly />
    </FormSection>
  </Modal>
);

ShowJobNotes.propTypes = {
  booking: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ShowJobNotes;
