import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal/Modal';
import FormSection from '../../FormSection/FormSection';
import './NewContactAlt.scss';
import Message from '../../../common/layout/Message/Message';
import ContactForm from '../../forms/ContactForm/ContactForm';
import Contact from '../../../api/controllers/Company/Contact/Contact';
import { formatError } from '../../../utils/Formatters/Errors/formatError';

// Why? Created for the EntityShow screen Contact tab, allowing the creation of Contact's
const NewContactAlt = ({ handleClose, entityId }) => {
  const [submitting, setSubmitting] = useState(false);
  const [messages, setMessages] = useState({});

  const handleSubmit = (e, form) => {
    e.preventDefault();
    setSubmitting(true);
    Contact.create({ ...form, entityId })
      .then(handleClose, err => setMessages(formatError(err)))
      .finally(setSubmitting(false));
  };

  return (
    <Modal isOpen handleClose={handleClose}>
      <FormSection title={'New Contact Details'} draggable>
        <Message
          visible={!!messages?.errors}
          text={messages.errors}
          onClick={() => setMessages([])}
          type={'error'}
        />
        <ContactForm
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          submitting={submitting}
        />
      </FormSection>
    </Modal>
  );
};

NewContactAlt.propTypes = {
  handleClose: PropTypes.func.isRequired,
  entityId: PropTypes.number.isRequired,
};

export default NewContactAlt;
