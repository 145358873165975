import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import './CollapsibleFormSection.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';

// Wraps a list of fields within a particular form section that will collapse.
const CollapsibleFormSection = ({
  title,
  open,
  onClick,
  children,
  splitTitle = '',
  optionalElement = null,
  icon,
}) => {
  const nodeRef = createRef();

  return (
    <div className="c-form-section">
      <div className="c-form-section-head" onClick={onClick} title={title}>
        <div className="c-form-section-title">
          <div>
            {icon && <FontAwesomeIcon icon={icon} />}
            {title}
            <span className="c-form-section-title-split">{splitTitle}</span>
          </div>
          <div className="c-form-optional">{optionalElement}</div>
        </div>
        <FontAwesomeIcon icon={open ? faSortUp : faSortDown} />
      </div>
      <CSSTransition
        nodeRef={nodeRef}
        in={open}
        timeout={150}
        classNames={'c-form-section-transition'}
        unmountOnExit
      >
        <div ref={nodeRef} className="c-form-section-body">
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};

CollapsibleFormSection.propTypes = {
  title: PropTypes.string || PropTypes.node,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  splitTitle: PropTypes.string,
  optionalElement: PropTypes.node,
  icon: PropTypes.object,
};

export default CollapsibleFormSection;
