import React from 'react';
import PropTypes from 'prop-types';
import TabGroup from '../../../../../../components/TabGroup/TabGroup';
import NotesTab from '../../../../../Company/Entity/EntityShow/subComponents/NotesTab/NotesTab';
import './BookingShowNotes.scss';

const BookingShowNotes = ({ booking, user, permissions, subTab, readOnly = false }) => (
  <div className="booking-notes-tab">
    <TabGroup
      defaultTab={subTab}
      labels={['Job Notes', 'Customer Notes', 'Collection Address Notes', 'Delivery Address Notes']}
    >
      <NotesTab
        parentType="Jobs::Booking"
        parentId={booking.id}
        subTab
        canDelete={user.administrator}
        permissions={permissions}
        requiredPermit={'jobsBookings'}
        readOnly={readOnly}
      />
      <NotesTab
        parentType="Companies::Entity"
        parentId={booking.customer.id}
        subTab
        readOnly
        permissions={permissions}
        requiredPermit={'jobsBookings'}
      />
      <NotesTab
        parentType="Companies::Entity"
        parentId={booking.collectionEntity?.id}
        subTab
        readOnly
        permissions={permissions}
        requiredPermit={'jobsBookings'}
      />
      <NotesTab
        parentType="Companies::Entity"
        parentId={booking.deliveryEntity?.id}
        subTab
        readOnly
        permissions={permissions}
        requiredPermit={'jobsBookings'}
      />
    </TabGroup>
  </div>
);

BookingShowNotes.propTypes = {
  booking: PropTypes.object,
  user: PropTypes.object,
  permissions: PropTypes.object,
  readOnly: PropTypes.bool,
  subTab: PropTypes.number,
};

export default BookingShowNotes;
