import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import { Link } from 'react-router-dom';
import './EntityShow.scss';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import EntityApi from '../../../../api/controllers/Company/Entity/Entity';
import useApi from '../../../../common/hooks/useApi/useApi';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faPen, faTrash, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ContactTab from './subComponents/ContactTab/ContactTab';
import ActivityTab from './subComponents/ActivityTab/ActivityTab';
import EmailsTab from './subComponents/EmailsTab/EmailsTab';
import Message from '../../../../common/layout/Message/Message';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import ErrorStatusMessage from '../../../../common/ErrorStatusMessage/ErrorStatusMessage';
import NoPage from '../../../NoPage/NoPage';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import AttachmentsTab from '../../../../components/AttachmentsTab/AttachmentsTab';
import EmailComposeModal from '../../../../components/EmailComposeModal/EmailComposeModal';
import { useNavigate, useParams } from 'react-router';
import NotesTab from './subComponents/NotesTab/NotesTab';
import GeneralTab from './subComponents/GeneralTab/GeneralTab';
import ProfitabilityTab from './subComponents/ProfitabilityTab/ProfitabilityTab';

const EntityShow = ({ user, permissions }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, refresh, dataLoading } = useApi({
    call: () => EntityApi.show(id),
    handleError: e => {
      setError(e);
      setNotFound(true);
    },
    dependencies: [id],
  });
  const [error, setError] = useState();
  const [modalError, setModalError] = useState();
  const [notFound, setNotFound] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [emailModal, setEmailModal] = useState();

  const handleDelete = () => {
    EntityApi.destroy(data.id).then(
      () => navigate('/companies/entities'),
      json => {
        setError(formatError(json));
        setIsOpen(false);
      },
    );
  };
  document.title = `${data?.externalName || data?.internalName || data?.id}`;

  if (notFound) return <NoPage />;
  if (dataLoading) return <LoadingSpinner />;

  const entityName = data.externalName || data.internalName || data.id;

  return (
    <div className="show-entity">
      {emailModal && (
        <EmailComposeModal
          user={user}
          parent={data}
          recipientEntity={data}
          templateType={emailModal.kind}
          handleClose={() => setEmailModal()}
          fileTabs={[{ tabName: 'General Files' }, { tabName: 'Entity Files', parent: data }]}
          setAlert={setModalError}
          defaultAttachments={[]}
        />
      )}
      <ConfirmationModal
        question={'Are you sure you want to delete the entity?'}
        subText={entityName}
        confirmText={'Delete'}
        isOpen={isOpen}
        handleConfirm={handleDelete}
        handleCancel={() => setIsOpen(false)}
      />
      <ErrorStatusMessage error={modalError} setError={setModalError} />
      <Breadcrumbs>
        <Link to={'/companies/entities'}>Entities</Link>
        <Link to={`/companies/entities/${id}`}>{data.internalName || data.id}</Link>
      </Breadcrumbs>
      <Ribbon>
        <div className="split">
          <IconButton
            text={'Send Email'}
            icon={faEnvelope}
            onClick={() => setEmailModal({ kind: 'general_entity' })}
          />
          {data?.customer && user.accounts && (
            <IconButton
              text={'Credit Limit Email'}
              icon={faEnvelope}
              onClick={() => setEmailModal({ kind: 'credit_limit' })}
            />
          )}
        </div>
        <div className="split">
          <IconButton
            text={'Edit Entity'}
            icon={faPen}
            onClick={() => navigate(`/companies/entities/${id}/edit`)}
            className={'edit'}
          />
          <IconButton
            text={'Delete Entity'}
            icon={faTrash}
            onClick={() => setIsOpen(true)}
            className={'destructive'}
          />
        </div>
      </Ribbon>
      <div className="page-content">
        <Message text={error} type={'error'} onClick={() => setError()} visible={!!error} />
        <h1>{entityName}</h1>

        <TabGroup
          labels={[
            'General',
            'Contacts',
            'Activity',
            'Profitability',
            'Attachments',
            'Emails',
            'Notes',
          ]}
        >
          <GeneralTab data={data} />
          <ContactTab entity={data} entityRefresh={refresh} setError={setError} />
          <ActivityTab entityId={data.id} user={user} />
          <ProfitabilityTab entityId={id} />
          <AttachmentsTab
            permissions={permissions}
            parentId={data.id}
            parentType={'Companies::Entity'}
            requiredPermit={'companiesEntities'}
            setError={setError}
          />
          <EmailsTab parentType={data.modelName} parentId={data.id} />
          <NotesTab
            parentType={data.modelName}
            parentId={data.id}
            permissions={permissions}
            requiredPermit={'companiesEntities'}
          />
        </TabGroup>
      </div>
    </div>
  );
};

EntityShow.propTypes = {
  user: PropTypes.object,
  permissions: PropTypes.object,
};

export default EntityShow;
