import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useForm from '../../../common/hooks/useForm/useForm';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import Driver from '../../../api/controllers/Movement/Driver/Driver';
import Modal from '../../../common/Modal/Modal';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Message from '../../../common/layout/Message/Message';
import FormSection from '../../FormSection/FormSection';
import FormField from '../../FormField/FormField';
import TabGroup from '../../TabGroup/TabGroup';
import useApi from '../../../common/hooks/useApi/useApi';
import './EditDriver.scss';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import Checkbox from '../../Checkbox/Checkbox';

const EditDriver = ({ id, setID, open, setOpen, onSuccess }) => {
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const { data, dataLoading } = useApi({ call: () => Driver.show(id), setMessage });
  const { form, alter, handleForm } = useForm();
  const labels = ['General'];

  useEffect(() => {
    data &&
      handleForm({
        firstName: data.firstName,
        lastName: data.lastName,
        archived: data.archived,
      });
  }, [data]);

  const handleSubmit = (e, form) => {
    e.preventDefault();
    setLoading(true);
    Driver.update(id, form).then(
      () => {
        setLoading(false);
        setOpen(false);
        setID(null);
        setMessage();
        onSuccess();
        handleForm();
      },
      json => {
        setLoading(false);
        setMessage(formatError(json));
      },
    );
  };

  const handleCancel = () => {
    setOpen(false);
    setID(null);
    handleForm();
    setMessage();
  };

  return (
    open && (
      <Modal isOpen={open} handleClose={handleCancel}>
        <FormSection title={'Edit Driver'} draggable>
          <form className={'edit-driver'} onSubmit={e => handleSubmit(e, form)}>
            <Message
              text={message?.errors}
              type={'error'}
              onClick={() => setMessage()}
              visible={!!message?.errors}
            />
            <TabGroup labels={labels}>
              <div className={'tab-content general'}>
                {dataLoading || !form ? (
                  <LoadingSpinner />
                ) : (
                  <Fragment>
                    <FormField label={'First Name'} className={'single'}>
                      <input
                        value={form.firstName}
                        onChange={e => alter('firstName', e.target.value)}
                      />
                    </FormField>
                    <FormField label={'Last Name'} className={'single'}>
                      <input
                        value={form.lastName}
                        onChange={e => alter('lastName', e.target.value)}
                      />
                    </FormField>
                  </Fragment>
                )}
              </div>
            </TabGroup>
            <div className={'bottom-tab-content'}>
              {!dataLoading && form && (
                <Checkbox
                  checked={form.archived}
                  onClick={() => alter('archived', !form.archived)}
                  label="Archived"
                />
              )}
              <div className={'af-button-line'}>
                <IconButton text={'Save'} icon={faCheck} className={'small'} loading={loading} />
                <IconButton
                  text={'Cancel'}
                  icon={faTimes}
                  onClick={handleCancel}
                  className={'edit small'}
                />
              </div>
            </div>
          </form>
        </FormSection>
      </Modal>
    )
  );
};

EditDriver.propTypes = {
  id: PropTypes.number,
  setID: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default EditDriver;
