import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class Driver {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'movements/drivers', data);
  }

  static show(id) {
    return apiCall('GET', `movements/drivers/${id}`);
  }

  static create(params) {
    const data = { driver: params };
    return apiCall('POST', 'movements/drivers', data);
  }

  static update(id, params) {
    const data = { driver: params };
    return apiCall('PATCH', `movements/drivers/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `movements/drivers/${id}`);
  }
}

export default Driver;
