import React from 'react';
import PropTypes from 'prop-types';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import './DocumentIndex.scss';
import InvoiceIndexTable from './subComponents/DocumentIndexTable/InvoiceIndexTable';
import ErrorBoundary from '../../../../common/ErrorBoundary/ErrorBoundary';

const DocumentIndex = ({ newParams, draftParams, completedParams }) => {
  return (
    <div className="invoices">
      <TabGroup labels={['New', 'Draft', 'Completed']}>
        <ErrorBoundary>
          <InvoiceIndexTable {...newParams} />
        </ErrorBoundary>
        <ErrorBoundary>
          <InvoiceIndexTable {...draftParams} />
        </ErrorBoundary>
        <ErrorBoundary>
          <InvoiceIndexTable {...completedParams} />
        </ErrorBoundary>
      </TabGroup>
    </div>
  );
};

DocumentIndex.propTypes = {
  newParams: PropTypes.object,
  draftParams: PropTypes.object,
  completedParams: PropTypes.object,
};

export default DocumentIndex;
