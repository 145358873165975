import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './TagInput.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const TagInput = ({
  tags,
  addTag,
  removeTag,
  onDisplay = () => {},
  addNewTags = () => {}, //Handles adding a tag to the newTags Array
  removeNewTags = () => {}, //removes a tag from the newTags Array
  newTags = [], //An array of tags, if a tag is included changes CSS so a user knows its new
}) => {
  const ref = useRef();

  //Controls the Adding of a tag, then resets the input.
  const handleAdd = e => {
    e.preventDefault();
    if (!tags.find(code => ref.current.value === code) && ref.current.value.replace(/[ ,]/, '')) {
      addTag(ref.current.value);
      addNewTags(ref.current.value);
    }
    ref.current.value = '';
  };

  const handleKeyDown = e => {
    // If this exact instance of TagInput has focus
    if (document.activeElement === ref.current) {
      const acceptedKeys = ['Comma', 'Enter', 'Space'];

      if (acceptedKeys.includes(e.code)) handleAdd(e);
    }
  };

  //If there is currently a value when focus lost on input, add it to the list.
  const handleFocusLoss = e => ref.current.value && handleAdd(e);

  const handleRemove = tag => {
    removeTag(tag);
    removeNewTags(tag);
  };

  return (
    <div className="ti-wrap">
      <div className="ti-tags">
        {tags.sort().map((tag, i) => (
          <button
            key={i}
            className={`ti-tag${newTags.includes(tag) ? ' new' : ''}`}
            onClick={() => handleRemove(tag)}
          >
            {onDisplay(tag)}
            <FontAwesomeIcon icon={faTimes} />
          </button>
        ))}
      </div>
      <input
        ref={ref}
        className="ti-input"
        onKeyDown={handleKeyDown}
        onBlur={handleFocusLoss}
        placeholder="Enter comma separated partial postcodes..."
      />
    </div>
  );
};

TagInput.propTypes = {
  tags: PropTypes.array.isRequired,
  addTag: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  onDisplay: PropTypes.func.isRequired,
  newTags: PropTypes.array,
  removeNewTags: PropTypes.func,
  addNewTags: PropTypes.func,
};

export default TagInput;
