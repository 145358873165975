import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  faBold,
  faFont,
  faGripLines,
  faHighlighter,
  faItalic,
  faListOl,
  faListUl,
  faRedo,
  faUndo,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons';
import { EditorState, RichUtils } from 'draft-js';
import ToolbarIcon from './ToolbarIcon/ToolbarIcon';
import './Toolbar.scss';
import { toggleInlineStyle } from 'draft-js/lib/RichTextEditorUtil';
import ToolbarColourSelect from './ToolbarColourSelect/ToolbarColourSelect';
import {
  applyLink,
  insertHorizontalRule,
  removeEntities,
  replaceLink,
} from '../../helpers/contentHelper/contentHelper';
import ToolbarLink from './ToolbarLink/ToolbarLink';

const Toolbar = ({ editorState, setEditorState }) => {
  const [menu, setMenu] = useState();

  const handleUndo = () => setEditorState(EditorState.undo(editorState));
  const handleRedo = () => setEditorState(EditorState.redo(editorState));
  const handleBold = () => setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  const handleItalic = () => setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
  const handleUnorderedList = () =>
    setEditorState(RichUtils.toggleBlockType(editorState, 'unordered-list-item'));
  const handleOrderedList = () =>
    setEditorState(RichUtils.toggleBlockType(editorState, 'ordered-list-item'));
  const handleTextColour = val => {
    setMenu();
    setEditorState(toggleInlineStyle(editorState, val));
  };
  const handleBackgroundColour = val => {
    setMenu();
    setEditorState(toggleInlineStyle(editorState, val));
  };
  const handleHorizontalRule = () => insertHorizontalRule(editorState, setEditorState);
  const handleLink = (e, link) => {
    e.preventDefault();
    setMenu();
    applyLink(editorState, setEditorState, link);
  };
  const handleUnlink = () => removeEntities(editorState, setEditorState);

  const getCurrentLinkKey = () => {
    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const linkKey = content.getBlockForKey(startKey).getEntityAt(startOffset);
    const ent = linkKey ? content.getEntity(linkKey).getData() : { url: '' }; //checks if already a link
    return { linkKey, ent };
  };

  const handleReplaceLink = (e, linkKey, link) => {
    e.preventDefault();
    setMenu();
    replaceLink(editorState, setEditorState, linkKey, link);
  };

  return (
    <div className="rt-toolbar">
      <ToolbarIcon icon={faUndo} onClick={handleUndo} />
      <ToolbarIcon icon={faRedo} onClick={handleRedo} />
      <ToolbarIcon icon={faBold} onClick={handleBold} />
      <ToolbarIcon icon={faItalic} onClick={handleItalic} />
      <ToolbarColourSelect
        icon={faFont}
        name={'text'}
        menu={menu}
        setMenu={setMenu}
        setColour={handleTextColour}
        modifier={'color-'}
        currentStyle={editorState.getCurrentInlineStyle()}
      />
      <ToolbarColourSelect
        icon={faHighlighter}
        name={'highlight'}
        menu={menu}
        setMenu={setMenu}
        setColour={handleBackgroundColour}
        modifier={'bgcolor-'}
        currentStyle={editorState.getCurrentInlineStyle()}
      />
      <ToolbarIcon icon={faListUl} onClick={handleUnorderedList} />
      <ToolbarIcon icon={faListOl} onClick={handleOrderedList} />
      <ToolbarIcon icon={faGripLines} onClick={handleHorizontalRule} />
      <ToolbarLink
        menu={menu}
        setMenu={setMenu}
        handleLink={handleLink}
        getLinkKey={getCurrentLinkKey}
        handleReplaceLink={handleReplaceLink}
      />
      <ToolbarIcon icon={faUnlink} onClick={handleUnlink} />
    </div>
  );
};

Toolbar.propTypes = {
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
};

export default Toolbar;
