import React from 'react';
import PropTypes from 'prop-types';
import GenericDropdown from '../../../../../../common/GenericDropdown/GenericDropdown';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '../../../../../../components/Checkbox/Checkbox';
import ValueInput from '../../../../../../common/input/ValueInput/ValueInput';
import Entity from '../../../../../../api/controllers/Company/Entity/Entity';
import GenericAjaxDropdown from '../../../../../../common/GenericAjaxDropdown/GenericAjaxDropdown';
import useApi from '../../../../../../common/hooks/useApi/useApi';
import TaxCode from '../../../../../../api/controllers/Financial/TaxCode/TaxCode';
import ChargeCode from '../../../../../../api/controllers/Financial/ChargeCode/ChargeCode';

const VehicleRunEditLineItems = ({
  data,
  items,
  handleKindChange,
  handleDelete,
  shouldDisplayArchived = false,
}) => {
  const { chargeCodes } = useApi({ call: ChargeCode.all, name: 'chargeCodes' });
  const { taxCodes } = useApi({
    call: TaxCode.all,
    name: 'taxCodes',
    params: shouldDisplayArchived ? {} : { archived: false },
  });

  const costCodes = chargeCodes && chargeCodes.items.filter(code => code.purchaseNominal && code);

  return (
    items.length > 0 && (
      <table>
        <thead>
          <tr>
            <th>Charge Code</th>
            <th>Description</th>
            <th>Supplier</th>
            <th>Value</th>
            <th>Tax Code</th>
            <th>Finalise</th>
            <th></th>
          </tr>
        </thead>
        <tbody className={'disableable'}>
          {items.map((li, i) => {
            if (li?._destroy === 1) return null;
            const itemIndex = data.financialLineItemsAttributes.indexOf(li);
            return (
              <tr key={i}>
                <td className={'small'}>
                  <GenericDropdown
                    value={li.chargeCode}
                    items={costCodes}
                    onDisplay={code => code?.code}
                    onSelect={code =>
                      handleKindChange(itemIndex, {
                        chargeCode: code,
                        description: code.description,
                      })
                    }
                    testId={'bfg-charge-code'}
                    onClear={() => handleKindChange(itemIndex, { chargeCode: null })}
                    disabled={!!li?.document}
                  />
                </td>
                <td className={'medium'}>
                  <input
                    data-testid={'bfg-description'}
                    value={li?.description || ''}
                    onChange={e => handleKindChange(itemIndex, { description: e.target.value })}
                    disabled={!!li?.document}
                  />
                </td>
                <td className={'medium'}>
                  <GenericAjaxDropdown
                    value={li.entity}
                    call={Entity.all}
                    onDisplay={ent => ent?.internalName}
                    onSelect={ent => handleKindChange(itemIndex, { entity: ent })}
                    testId={'bfg-line-item-entity'}
                    altParams={{ supplier: true }}
                    onClear={() => handleKindChange(itemIndex, { entity: null })}
                    disabled={!!li?.document}
                  />
                </td>
                <td className={'small'}>
                  <ValueInput
                    unit={'£'}
                    value={li.value}
                    reverse
                    onChange={e => handleKindChange(itemIndex, { value: e.target.value })}
                    readOnly={!!li?.document}
                    min={0}
                    step={0.01}
                  />
                </td>
                <td className={'medium'}>
                  <GenericDropdown
                    value={li.taxCode}
                    items={taxCodes?.items || []}
                    onDisplay={tc => tc?.description}
                    onSelect={tc => handleKindChange(itemIndex, { taxCode: tc })}
                    disabled={!!li?.document}
                    testId={'bfg-tax-code'}
                    onClear={() => handleKindChange(itemIndex, { taxCode: null })}
                  />
                </td>
                <td className={'small'}>
                  <Checkbox
                    checked={li?.finalised}
                    onClick={() => handleKindChange(itemIndex, { finalised: !li?.finalised })}
                    disabled={!!li?.document}
                  />
                </td>
                <td className={'small'}>
                  <button
                    data-testid={'bfg-delete'}
                    className={'fs-table-cross'}
                    onClick={() => handleDelete(itemIndex, li)}
                    disabled={!!li?.document || !!li?.finalised}
                    tabIndex={'-1'}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    )
  );
};

VehicleRunEditLineItems.propTypes = {
  items: PropTypes.array,
  handleKindChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  data: PropTypes.object,
  shouldDisplayArchived: PropTypes.bool,
};

export default VehicleRunEditLineItems;
