import { CompositeDecorator } from 'draft-js';
import Link from './subComponents/Link/Link';
import { findLinkEntities } from './utils/decoratorUtils';

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

export default decorator;
