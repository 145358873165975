import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import Ribbon from '../../../components/Ribbon/Ribbon';
import IconButton from '../../../common/button/IconButton/IconButton';
import EmailTemplate from '../../../api/controllers/EmailTemplate/EmailTemplate';
import { faPen, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import TabGroup from '../../../components/TabGroup/TabGroup';
import TemplateGeneralTab from './subComponents/TemplateGeneralTab/TemplateGeneralTab';
import './EmailTemplateShow.scss';
import { humanize, labelForEnum } from '../../../utils/EnumHelper/EnumHelper';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import ErrorStatusMessage from '../../../common/ErrorStatusMessage/ErrorStatusMessage';
import Message from '../../../common/layout/Message/Message';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import EmailFileTab from '../../../components/EmailComposeModal/EmailFileTab/EmailFileTab';
import AttachmentSection from '../../../components/EmailComposeModal/AttachmentSection/AttachmentSection';
import useArray from '../../../common/hooks/useArray/useArray';
import { emailTemplateTypes } from '../../../strings/enums';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import NoPage from '../../NoPage/NoPage';
import { useNavigate, useParams } from 'react-router';

const EmailTemplateShow = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [message, setMessage] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const { fileArray, addFileArray, removeIdxFileArray, handleFileArray } = useArray({
    name: 'fileArray',
  });

  useEffect(() => {
    getData();
  }, []);

  document.title = data
    ? `${humanize(data?.name)} | ${labelForEnum(emailTemplateTypes, data?.templateType?.name)}`
    : 'ITAL Transport';

  const getData = () => {
    setDataLoading(true);
    EmailTemplate.show(id).then(
      res => {
        setDataLoading(false);
        setData(res);
        if (res?.templateAttachments.length > 0) {
          const templateAttachments = res.templateAttachments
            ? res.templateAttachments.map(att => att.file)
            : [];
          handleFileArray([...templateAttachments]);
        }
      },
      () => {
        setDataLoading(false);
      },
    );
  };

  const setTemplateAsDefault = id => {
    setDisable(true);
    EmailTemplate.markAsMain(id, { templateType: { defaultTemplateId: id } }).then(
      async () => {
        await getData();
        setError();
        setDisable(false);
      },
      err => {
        setError(err);
        setDisable(false);
      },
    );
  };

  const attachFileToTemplate = (fileId, addFileOnSuccess) => {
    EmailTemplate.update(data.id, {
      templateAttachmentsAttributes: [{ attachmentId: fileId }],
    }).then(
      async () => {
        getData();
        setError();
        addFileOnSuccess();
      },
      err => {
        setError({ error: err.error, status: 'Error' });
      },
    );
  };

  const removeFileFromTemplate = (fileId, removeFileOnSuccess) => {
    const fileLinkId = data.templateAttachments.filter(el => el.file.id === fileId)[0].id;
    EmailTemplate.update(data.id, {
      templateAttachmentsAttributes: [{ id: fileLinkId, _destroy: true }],
    }).then(
      async () => {
        getData();
        setError();
        removeFileOnSuccess();
      },
      err => {
        setError({ error: err.error, status: 'Error' });
      },
    );
  };

  const handleDelete = () => {
    setLoading(true);
    EmailTemplate.destroy(data.id)
      .then(
        async () => {
          setLoading(false);
          setMessage();
          navigate({
            pathname: '/emails/templates',
          });
        },
        err => {
          setLoading(false);
          setMessage(formatError(err));
        },
      )
      .finally(() => {
        setDeleteModal(false);
      });
  };

  const handleEdit = id => {
    navigate(`/emails/templates/${id}/edit`);
  };

  const isDisabled = data => {
    if (disable) return true;
    return data.templateType.defaultTemplateId === data.id;
  };

  if (!data) return <NoPage />;
  return (
    <div className="email-template-show">
      <Breadcrumbs>
        <Link to={'/emails/templates'}>Email Templates</Link>
        <Link to={`/emails/templates/${data.id}`}>{humanize(data.name)}</Link>
      </Breadcrumbs>
      <Ribbon>
        <div className="split">
          <IconButton
            text={'Set as default'}
            icon={faStar}
            onClick={() => setTemplateAsDefault(data.id)}
            disabled={data.templateType.defaultTemplateId === data.id}
            loading={disable}
            className={`small success ${isDisabled(data) ? 'success-disabled' : ''}`}
          />
        </div>
        <IconButton
          text={'Edit Template'}
          onClick={() => handleEdit(data.id)}
          icon={faPen}
          className={'edit'}
        />
        <IconButton
          text={'Delete Template'}
          onClick={() => setDeleteModal(true)}
          icon={faTrash}
          className={'destructive'}
        />
      </Ribbon>
      <ConfirmationModal
        question={'Are you sure you want to delete this Template?'}
        subText={data.name}
        confirmText={'Delete'}
        isOpen={deleteModal}
        handleConfirm={handleDelete}
        handleCancel={() => setDeleteModal(false)}
        loading={loading}
      />
      <ErrorStatusMessage error={error} setError={setError} />

      <div className="page-content">
        <Message
          text={message}
          type={'error'}
          onClick={() => setMessage()}
          visible={!!message}
          onClose={setMessage}
        />
        <h1 className="template-title">
          {humanize(data.name)} |{' '}
          <span className="latter-title">
            {labelForEnum(emailTemplateTypes, data.templateType.name)}
          </span>
        </h1>

        <TabGroup labels={['General', 'Attachments']}>
          <TemplateGeneralTab body={data.body} subject={data.subject} user={user} />
          {dataLoading ? (
            <LoadingSpinner />
          ) : (
            <EmailFileTab
              parent={null}
              setMessage={setMessage}
              addFileArray={addFileArray}
              fileArray={fileArray}
              attachFileToTemplate={attachFileToTemplate}
            >
              <AttachmentSection
                fileArray={fileArray}
                removeIdxFileArray={removeIdxFileArray}
                setMessage={setMessage}
                removeFileFromTemplate={removeFileFromTemplate}
              />
            </EmailFileTab>
          )}
        </TabGroup>
      </div>
    </div>
  );
};

EmailTemplateShow.propTypes = {
  user: PropTypes.object,
};

export default EmailTemplateShow;
