import { DELETE_TOKEN, SAVE_TOKEN, SET_USER_ERROR } from './types';

export const saveToken = token => ({
  type: SAVE_TOKEN,
  payload: token,
});
export const deleteToken = () => ({
  type: DELETE_TOKEN,
});
export const setUserError = err => ({
  type: SET_USER_ERROR,
  payload: err,
});
