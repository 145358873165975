import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class Area {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'tariffs/areas', data);
  }
  static show(id) {
    return apiCall('GET', `tariffs/areas/${id}`);
  }

  static create(params) {
    const data = { area: params };
    return apiCall('POST', 'tariffs/areas', data);
  }

  static update(id, params) {
    const data = { area: params };
    return apiCall('PATCH', `tariffs/areas/${id}`, data);
  }

  static updateAll(params) {
    const data = { area_set: params };
    return apiCall('PATCH', `tariffs/areas`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `tariffs/areas/${id}`);
  }

  static forPostcode(postcode) {
    const data = buildGetQuery({ postcode });
    return apiCall('GET', 'tariffs/areas/for_postcode/', data);
  }
}

export default Area;
