import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faFileExcel,
  faPlus,
  faTruckFast,
  faWindowMaximize,
  faWindowRestore,
} from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../../common/button/IconButton/IconButton';
import DatePicker from '../../../../../common/DatePicker/DatePicker';
import DropdownPresenter from '../../../../../common/Dropdown/DropdownPresenter/DropdownPresenter';
import StaticCheckbox from '../../../../../components/StaticCheckbox/StaticCheckbox';
import './VehicleFilterBar.scss';
import { humanize } from '../../../../../utils/EnumHelper/EnumHelper';

const filterTypes = [
  { label: 'Show Everything', value: null },
  { label: 'Subcontracted', value: true },
  { label: 'Non-subcontracted', value: false },
];

const statuses = ['pending', 'in_progress', 'complete'];

const VehicleFilterBar = ({
  openNewVehicleModal,
  allOpen,
  closeAll,
  openAll,
  form,
  alter,
  handleForm,
  subwindow,
  xlsxLoading = false,
  toggleSubwindow,
  handleXlsxClick,
  handleGenerateRuns,
  runsGenerating = false,
  generateRunsDisabled = false,
}) => {
  const [open, setOpen] = useState(false);

  const drawSubcontractedFilter = () => {
    return filterTypes.map((item, idx) => (
      <button
        key={idx}
        className={'dd-button'}
        onClick={() => {
          alter('subcontracted', item.value);
          setOpen(false);
        }}
      >
        {item.label}
      </button>
    ));
  };

  const alterStatus = status => {
    if (form.status.find(item => item === status))
      alter(
        'status',
        form.status.filter(item => item !== status),
      );
    else alter('status', [...form.status, status]);
  };

  return (
    <div className="vehicle-filter-bar">
      <div className="vehicle-filter-section">
        <div className="vehicle-filter-item">
          <DatePicker
            value={form.startDate}
            onChange={newDate => handleForm({ ...form, startDate: newDate, endDate: newDate })}
          />
        </div>
        <hr />
        <div className="vehicle-filter-item selector-dropdown">
          <DropdownPresenter
            value={filterTypes.filter(item => item.value === form.subcontracted)[0].label}
            drawOptions={drawSubcontractedFilter}
            open={open}
            setOpen={setOpen}
          />
        </div>
        <hr />
        {statuses.map(status => (
          <div className="vehicle-filter-item" key={status}>
            <StaticCheckbox
              label={humanize(status)}
              checked={!!form.status.find(item => item === status)}
              onClick={() => alterStatus(status)}
              testId={`${status}-checkbox`}
            />
          </div>
        ))}
      </div>
      <div className="vehicle-filter-section">
        <div className="vehicle-filter-item">
          <input
            value={form.filter}
            onChange={e => alter('filter', e.target.value)}
            placeholder="Search Vehicle Runs..."
          />
        </div>
        <div className="vehicle-filter-item">
          <IconButton icon={faPlus} text="New Vehicle Run" onClick={openNewVehicleModal} />
        </div>
        <div
          className="vehicle-filter-item"
          onClick={!generateRunsDisabled ? handleGenerateRuns : () => {}}
          title="Generate runs for all vehicles"
        >
          <IconButton
            iconOnly
            icon={faTruckFast}
            testId={'VehicleGen'}
            loading={runsGenerating}
            disabled={generateRunsDisabled}
          />
        </div>
        <div className="vehicle-filter-item" onClick={() => (allOpen ? closeAll() : openAll())}>
          <IconButton iconOnly icon={allOpen ? faAngleDoubleUp : faAngleDoubleDown} />
        </div>
        <div className="vehicle-filter-item" onClick={toggleSubwindow}>
          <IconButton
            iconOnly
            icon={subwindow ? faWindowMaximize : faWindowRestore}
            className="subwindow"
          />
        </div>
        <div
          className="vehicle-filter-item"
          onClick={handleXlsxClick}
          title="Export current view to excel"
        >
          <IconButton iconOnly icon={faFileExcel} className="xlsx-button" loading={xlsxLoading} />
        </div>
      </div>
    </div>
  );
};

VehicleFilterBar.propTypes = {
  openNewVehicleModal: PropTypes.func,
  allOpen: PropTypes.bool,
  closeAll: PropTypes.func,
  openAll: PropTypes.func,
  form: PropTypes.object,
  alter: PropTypes.func,
  handleForm: PropTypes.func,
  subwindow: PropTypes.object,
  toggleSubwindow: PropTypes.func,
  xlsxLoading: PropTypes.bool,
  handleXlsxClick: PropTypes.func.isRequired,
  handleGenerateRuns: PropTypes.func.isRequired,
  runsGenerating: PropTypes.bool,
  generateRunsDisabled: PropTypes.bool,
};

export default VehicleFilterBar;
