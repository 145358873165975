import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../components/FormSection/FormSection';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../common/button/IconButton/IconButton';
import useApi from '../../common/hooks/useApi/useApi';
import Item from '../../api/controllers/File/Item/Item';
import './FileCabinet.scss';
import NumberHelper from '../../utils/NumberHelper/NumberHelper';
import { formatError } from '../../utils/Formatters/Errors/formatError';
import IconHelper from '../../utils/IconHelper/IconHelper';
import Message from '../../common/layout/Message/Message';
import DateHelper from '../../utils/DateHelper/DateHelper';
import FileUpload from '../../common/FileUpload/FileUpload';
import DownloadHelper from '../../utils/DownloadHelper/DownloadHelper';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';

const FileCabinet = ({ permissions }) => {
  const [error, setError] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [fileName, setFilename] = useState();
  const [fileId, setFileId] = useState();
  const [loading, setLoading] = useState(false);

  const { data, refresh, dataLoading } = useApi({
    call: () => Item.all(),
  });

  useEffect(() => {
    document.title = 'Virtual Filing Cabinet';
  }, []);

  const handleDownload = (id, name) => {
    Item.download(id).then(
      res => {
        DownloadHelper.saveFile(name, res);
        setError();
      },
      err => {
        setError({ ...formatError(err), errorTitle: 'Could not download file' });
      },
    );
  };

  const renderIcon = (mimeType, id, name) => {
    return (
      <div className={'td-icon'} onClick={() => handleDownload(id, name)}>
        <FontAwesomeIcon icon={IconHelper.getFolderIcon(mimeType)} data-testid={'file-icon'} />
      </div>
    );
  };

  const handleDelete = () => {
    setLoading(true);
    Item.destroy(fileId).then(
      () => {
        refresh();
      },
      err => setError({ ...formatError(err), errorTitle: 'Delete failed!' }),
    );
    setLoading(false);
    setFilename();
    setFileId();
    setDeleteModal(false);
  };

  if (dataLoading) return <LoadingSpinner />;
  if (!data) return null;
  return (
    <>
      <Breadcrumbs>
        <Link to={'virtual_filing_cabinet'}>Virtual File Cabinet</Link>
      </Breadcrumbs>
      <div className="show-tab-content file-cabinet">
        <ConfirmationModal
          question={'Are you sure you want to delete this file?'}
          subText={fileName}
          confirmText={'Delete'}
          isOpen={deleteModal}
          handleConfirm={handleDelete}
          handleCancel={() => setDeleteModal(false)}
          loading={loading}
        />
        <Message
          text={error}
          type={'error'}
          onClick={() => setError()}
          visible={!!error}
          onClose={setError}
        />
        <FormSection
          title={'Virtual Filing Cabinet'}
          optionalElement={
            <FileUpload
              setError={setError}
              refresh={refresh}
              canEditParent={permissions?.users.create}
            />
          }
        >
          <div className="show-table">
            <table border="1" frame="void" rules="rows">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Size</th>
                  <th>Created At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.items.map(li => (
                  <tr key={li.id} data-testid={'bsg-line-item'}>
                    <td className={'tiny'}>{renderIcon(li.mimeType, li.id, li.fileName)}</td>
                    <td
                      className={'big td-name'}
                      onClick={() => handleDownload(li.id, li.fileName)}
                    >
                      {li.fileName}
                    </td>
                    <td className={'small'}>{NumberHelper.formatBytes(li.fileSize)}</td>
                    <td className={'medium'}>{DateHelper.format(li.createdAt).replace(',', '')}</td>
                    <td className={'tiny'}>
                      {permissions?.users.create && (
                        <IconButton
                          text={'Delete'}
                          onClick={() => {
                            setFileId(li.id);
                            setDeleteModal(true);
                            setFilename(li.fileName);
                          }}
                          icon={faTrash}
                          className={'destructive delete-file'}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data?.items.length === 0 && (
              <p style={{ textAlign: 'center' }}>No files have been uploaded yet.</p>
            )}
          </div>
        </FormSection>
      </div>
    </>
  );
};

FileCabinet.propTypes = {
  permissions: PropTypes.object,
};

export default FileCabinet;
