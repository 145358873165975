import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ImportJob.scss';
import { useNavigate } from 'react-router';
import Modal from '../../../common/Modal/Modal';
import FormSection from '../../FormSection/FormSection';
import Message from '../../../common/layout/Message/Message';
import FormField from '../../FormField/FormField';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faTimes, faFileImport } from '@fortawesome/free-solid-svg-icons';
import FreightsoftBooking from '../../../api/controllers/Job/FreightsoftBooking/FreightsoftBooking';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import GenericAjaxDropdown from '../../../common/GenericAjaxDropdown/GenericAjaxDropdown';

const ImportJob = ({ isOpen, setIsOpen }) => {
  const [freightSoftJob, setFreightsoftJob] = useState('');
  const [jobId, setJobId] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    jobId &&
      FreightsoftBooking.import(jobId).then(
        res => {
          setJobId();
          setFreightsoftJob('');
          setIsOpen(false);
          navigate(`/jobs/bookings/${res.id}/edit`);
        },
        err => setError(formatError(err)),
      );
    !jobId && setError(formatError({ error: 'Please select a job first.' }));
  };

  const handleState = (job, id, open) => {
    setFreightsoftJob(job);
    setJobId(id);
    setIsOpen(open);
  };

  return (
    <Modal isOpen={isOpen} handleClose={() => handleState('', null, false)}>
      <FormSection title={'Import Job'} draggable>
        <form onSubmit={handleSubmit} className={'job-modal'}>
          <Message
            text={error?.errors}
            type={'error'}
            onClick={() => setError()}
            visible={!!error}
          />
          <FormField label={'Job Reference'} className={'single job-ref'}>
            <GenericAjaxDropdown
              value={freightSoftJob || { jobReference: 'Select job reference' }}
              call={FreightsoftBooking.all}
              onDisplay={job => job?.jobReference}
              onSelect={job => handleState(job, job?.id, true)}
              altParams={{ status: 'active' }}
              onError={err => setError(formatError(err))}
            />
          </FormField>
          <div className={'af-button-line'}>
            <IconButton
              text={'Import Job'}
              icon={faFileImport}
              className={'small'}
              onClick={handleSubmit}
            />
            <IconButton
              text={'Cancel'}
              icon={faTimes}
              onClick={() => handleState('', null, false)}
              className={'edit small'}
            />
          </div>
        </form>
      </FormSection>
    </Modal>
  );
};
ImportJob.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};
export default ImportJob;
