import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../../../components/FormSection/FormSection';
import FieldList from '../../../../../../../../components/FieldList/FieldList';
import FormField from '../../../../../../../../components/FormField/FormField';
import AddressHelper from '../../../../../../../../utils/AddressHelper/AddressHelper';
import Entity from '../../../../../../../../api/controllers/Company/Entity/Entity';
import Address from '../../../../../../../../api/controllers/Company/Address/Address';
import Checkbox from '../../../../../../../../components/Checkbox/Checkbox';
import AttributeHelper from '../../../../../../../../utils/AttributeHelper/AttributeHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { capitalize } from 'lodash';
import GenericAjaxDropdown from '../../../../../../../../common/GenericAjaxDropdown/GenericAjaxDropdown';

const BookingFormEntities = ({ data, alter, handleForm, openModal }) => {
  const isMounted = useRef();

  const onSelectAddressEntity = (type, ent) => {
    // Determine the attribute that we want to update
    const attr = type === 'collection' ? 'collectionAddress' : 'deliveryAddress';
    const dataSet = {
      ...data,
      ...{
        [`${type}Entity`]: ent,
      },
    };

    // Get fresh list of addresses, this removes the reliance on a state refresh
    Address.all({ archived: false, entityId: ent.id }).then(addresses => {
      // If there is only one relevant address, attempt to autofill the relevant field
      if (addresses.items.length === 1) {
        // If it's there, get the tailLiftRequired attribute
        const liftReq = addresses.items[0].warehouseRequirements.filter(
          li => li.name === 'tailLiftRequired',
        )[0];

        // Submit and return
        return handleForm({
          ...dataSet,
          [attr]: addresses.items[0] || null,
          [`tailLiftOn${capitalize(type)}`]: ['sometimes', 'always'].includes(liftReq?.applies),
        });
      }

      // Default to just setting the address
      handleForm({ ...dataSet, [attr]: null });
    });
  };

  const onSelectAddress = (type, addr) => {
    const attr = type === 'collection' ? 'collectionAddress' : 'deliveryAddress';
    const listRequired = AttributeHelper.returnAttribute(
      addr.warehouseRequirements,
      li => li.name === 'tailLiftRequired',
      () => {},
    );

    handleForm({
      ...data,
      ...{
        [attr]: addr,
        [`tailLiftOn${capitalize(type)}`]:
          !!listRequired && ['sometimes', 'always'].includes(listRequired.applies),
      },
    });
  };

  const toggleSubcontracted = () => {
    if (data.subcontracted)
      return handleForm({ ...data, subcontracted: false, subcontractor: null });
    return handleForm({ ...data, subcontracted: true, vehicle: null });
  };

  const onDisplayAddress = addr =>
    AddressHelper.formatAddress(
      AddressHelper.extractAddressToArray(addr, { postcodeFirst: true }),
      { dropdown: true },
    );

  return (
    <FormSection
      title={'Entities, Addressing & References'}
      optionalElement={
        <FormField label={'Subcontracted'} className={'checkbox grid subcontracted-option'}>
          <Checkbox checked={data.subcontracted} onClick={toggleSubcontracted} />
        </FormField>
      }
    >
      <FieldList>
        <FormField label={'Customer'}>
          <div className={'button-input'} ref={isMounted}>
            <GenericAjaxDropdown
              value={data.customer}
              call={Entity.all}
              onSelect={cus => alter('customer', cus)}
              onDisplay={cus => cus?.internalName}
              altParams={{ customer: true }}
            />
            <button className={'plus'} onClick={openModal} data-testid={'bfg-customer-create'}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </FormField>
        <FormField label={'Customer Reference'}>
          <input
            data-testid={'bfg-customer-ref'}
            value={data.customerReference}
            onChange={e => alter('customerReference', e.target.value)}
          />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label={'Collection Entity'}>
          <GenericAjaxDropdown
            value={data.collectionEntity}
            call={Entity.all}
            onSelect={ce => onSelectAddressEntity('collection', ce)}
            onDisplay={ce => ce?.internalName}
            onClear={() =>
              handleForm({ ...data, ...{ collectionEntity: null, collectionAddress: null } })
            }
          />
        </FormField>
        <FormField label={'Collection On Behalf Of'}>
          <GenericAjaxDropdown
            value={data.collectionOnBehalfOf}
            call={Entity.all}
            onSelect={cobo => alter('collectionOnBehalfOf', cobo)}
            onDisplay={cobo => cobo?.internalName}
            onClear={() => alter('collectionOnBehalfOf', null)}
          />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField
          label={'Collection Address'}
          hasRestrictions={data.collectionAddress?.warehouseRequirements.filter(
            ({ applies }) => applies != 'never',
          )}
        >
          <GenericAjaxDropdown
            value={data.collectionAddress}
            call={Address.all}
            onSelect={ca => onSelectAddress('collection', ca)}
            onDisplay={onDisplayAddress}
            onClear={() => alter('collectionAddress', null)}
            altParams={{ archived: false, entityId: data.collectionEntity?.id }}
            minSearch={0}
          />
        </FormField>
        <FormField label={'Collection Reference'}>
          <input
            data-testid={'bfg-collection-reference'}
            value={data.collectionReference}
            onChange={e => alter('collectionReference', e.target.value)}
          />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label={'Delivery Entity'}>
          <GenericAjaxDropdown
            value={data.deliveryEntity}
            call={Entity.all}
            onSelect={de => onSelectAddressEntity('delivery', de)}
            onDisplay={de => de?.internalName}
            onClear={() =>
              handleForm({ ...data, ...{ deliveryEntity: null, deliveryAddress: null } })
            }
          />
        </FormField>
        <FormField label={'Delivery On Behalf Of'}>
          <GenericAjaxDropdown
            value={data.deliveryOnBehalfOf}
            call={Entity.all}
            onSelect={dobo => alter('deliveryOnBehalfOf', dobo)}
            onDisplay={dobo => dobo?.internalName}
            onClear={() => alter('deliveryOnBehalfOf', null)}
          />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField
          label={'Delivery Address'}
          hasRestrictions={data.deliveryAddress?.warehouseRequirements.filter(
            ({ applies }) => applies != 'never',
          )}
        >
          <GenericAjaxDropdown
            value={data.deliveryAddress}
            call={Address.all}
            onSelect={ca => onSelectAddress('delivery', ca)}
            onDisplay={onDisplayAddress}
            onClear={() => alter('deliveryAddress', null)}
            altParams={{ archived: false, entityId: data.deliveryEntity?.id }}
            minSearch={0}
          />
        </FormField>
        <FormField label={'Delivery Reference'}>
          <input
            data-testid={'bfg-delivery-reference'}
            value={data.deliveryReference}
            onChange={e => alter('deliveryReference', e.target.value)}
          />
        </FormField>
      </FieldList>

      {data.subcontracted && (
        <FieldList>
          <FormField label={'Subcontractor'}>
            <GenericAjaxDropdown
              value={data.subcontractor}
              call={Entity.all}
              onSelect={sub => alter('subcontractor', sub)}
              onDisplay={sub => sub?.internalName}
              onClear={() => alter('subcontractor', null)}
              testId={'bfg-subcontractor'}
            />
          </FormField>
        </FieldList>
      )}
    </FormSection>
  );
};

BookingFormEntities.propTypes = {
  data: PropTypes.object,
  alter: PropTypes.func,
  handleForm: PropTypes.func,
  openModal: PropTypes.func,
};

export default BookingFormEntities;
