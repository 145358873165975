import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './BookingShow.scss';
import useApi from '../../../../common/hooks/useApi/useApi';
import Booking from '../../../../api/controllers/Job/Booking/Booking';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import IconButton from '../../../../common/button/IconButton/IconButton';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import BookingShowGeneral from './subComponents/BookingShowGeneral/BookingShowGeneral';
import {
  faEnvelope,
  faFile,
  faMousePointer,
  faPen,
  faUndo,
  faEnvelopeOpenText,
} from '@fortawesome/free-solid-svg-icons';
import IconDropdown from '../../../../common/button/IconDropdown/IconDropdown';
import FinaliseJob from '../../../../components/modals/FinaliseJob/FinaliseJob';
import Message from '../../../../common/layout/Message/Message';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import BookingShowAdvanced from './subComponents/BookingShowAdvanced/BookingShowAdvanced';
import BookingShowBilling from './subComponents/BookingShowBilling/BookingShowBilling';
import CancelJob from '../../../../components/modals/CancelJob/CancelJob';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import CheckPermit from '../../../../common/CheckPermit/CheckPermit';
import NoPage from '../../../NoPage/NoPage';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import Pdf from '../../../../api/controllers/File/Pdf/Pdf';
import Item from '../../../../api/controllers/File/Item/Item';
import DownloadHelper from '../../../../utils/DownloadHelper/DownloadHelper';
import ErrorStatusMessage from '../../../../common/ErrorStatusMessage/ErrorStatusMessage';
import useArray from '../../../../common/hooks/useArray/useArray';
import AttachmentsTab from '../../../../components/AttachmentsTab/AttachmentsTab';
import EmailComposeModal from '../../../../components/EmailComposeModal/EmailComposeModal';
import EmailsTab from '../../../Company/Entity/EntityShow/subComponents/EmailsTab/EmailsTab';
import { useParams, useNavigate } from 'react-router';
import { getBookingWarnings } from '../../../../utils/bookingUtils/bookingUtils';
import BookingShowNotes from './subComponents/BookingShowNotes/BookingShowNotes';
import SystemSetting from '../../../../api/controllers/SystemSetting/SystemSetting';
import { default as NotesItem } from '../../../../api/controllers/Note/Item/Item';

const BookingShow = ({ permissions, user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [notFound, setNotFound] = useState(false);
  const [notesTab, setNoteTab] = useState();
  const { data, refresh, dataLoading } = useApi({
    call: () => Booking.show(id),
    handleError: () => setNotFound(true),
    dependencies: [id],
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [openDeleteJob, setOpenDeleteJob] = useState(false);
  const [openFinaliseJob, setOpenFinaliseJob] = useState(false);
  const [openCancelJob, setOpenCancelJob] = useState(false);
  const [error, setError] = useState();
  const { buttonLoad, addButtonLoad, removeButtonLoad } = useArray({ name: 'buttonLoad' });
  const [pdfAlert, setPdfAlert] = useState();
  const [emailModal, setEmailModal] = useState();
  const [warnings, setWarnings] = useState([]);
  const [fsLocation, setFsLocation] = useState();
  const [notes, setNotes] = useState({
    customer: false,
    collectionEntity: false,
    deliveryEntity: false,
  });
  const [restrictions, setRestrictions] = useState({
    collectionAddress: null,
    deliveryAddress: null,
  });

  const handleErrors = (json, title) => {
    const errorContent = formatError(json);
    setError({
      type: 'error',
      text: {
        errorTitle: title,
        errors: errorContent.errors,
      },
    });
  };

  const handleUnfinalise = () => {
    Booking.update(data.id, { status: 'active' }).then(
      () => {
        refresh();
        setSuccessMessage('Job has successfully been Un-Finalised.');
      },
      err => {
        handleErrors(err);
        setOpenFinaliseJob(false);
      },
    );
  };

  const handleUncancel = () => {
    Booking.update(data.id, { status: 'active' }).then(
      () => {
        refresh();
        setSuccessMessage('Job has successfully been Un-Cancelled.');
      },
      err => {
        handleErrors(err);
        setOpenCancelJob(false);
      },
    );
  };

  document.title = data?.jobReference;

  const renderMenuOptions = status => {
    if (status === 'finalised')
      return <IconButton text={'Un-Finalise'} onClick={handleUnfinalise} icon={faUndo} />;
    if (status === 'cancelled')
      return <IconButton text={'Un-Cancel'} onClick={handleUncancel} icon={faUndo} />;
    if (status === 'pending') {
      return (
        <IconDropdown text={'Actions'} icon={faMousePointer}>
          <button onClick={() => setOpenCancelJob(prev => !prev)}>Cancel Job</button>
          <button onClick={() => setOpenDeleteJob(prev => !prev)}>Delete Job</button>
        </IconDropdown>
      );
    }
    if (status === 'active')
      return (
        <IconDropdown text={'Actions'} icon={faMousePointer}>
          <button onClick={() => setOpenFinaliseJob(prev => !prev)}>Finalise Job</button>
          <button onClick={() => setOpenCancelJob(prev => !prev)}>Cancel Job</button>
          <button onClick={() => setOpenDeleteJob(prev => !prev)}>Delete Job</button>
        </IconDropdown>
      );
  };

  const handleDeleteBooking = () => {
    Booking.destroy(data.id).then(
      () => {
        setOpenDeleteJob();
        navigate('/jobs/bookings', {
          state: { detail: 'Job Deleted' },
        });
      },
      json => {
        handleErrors(json);
        setOpenDeleteJob(false);
      },
    );
  };

  const handlePdf = (e, kind = 'booking_confirmation') => {
    e.preventDefault();
    addButtonLoad(kind);
    Pdf.generate({ kind, id }).then(
      pdfRes => {
        if (kind === 'subcontract_instruction' || kind === 'booking_confirmation') {
          removeButtonLoad(kind);
          return setEmailModal({
            kind,
            defaultAttachments: [{ ...pdfRes, attachmentId: pdfRes.id }],
          });
        }
        Item.download(pdfRes.id).then(
          res => {
            DownloadHelper.saveFile(pdfRes.fileName, res);
            removeButtonLoad(kind);
          },
          res => {
            setPdfAlert(res);
            removeButtonLoad(kind);
          },
        );
      },
      res => {
        setPdfAlert(res);
        removeButtonLoad(kind);
      },
    );
  };

  // Check entity has notes and store in state
  const hasNotes = () => {
    const res = Object.keys(notes).map(async key => {
      // If key has an entity check for notes
      if (data[key]?.id) {
        return {
          key,
          promise: await NotesItem.all({
            parentType: 'Companies::Entity',
            parentId: data[key]?.id,
            order: 'desc',
          }),
        };
      } else return { key, promise: null };
    });
    Promise.all(res).then(res => {
      const newNotes = {};
      res.map(({ key, promise }) => (newNotes[key] = promise?.total > 0));
      setNotes(newNotes);
    });
  };

  const hasRestrictions = () => {
    Object.keys(restrictions).forEach(key => {
      const filteredRequirements = data[key]?.warehouseRequirements.filter(
        ({ applies }) => applies != 'never',
      );
      if (filteredRequirements) {
        setRestrictions(prev => ({
          ...prev,
          [key]: filteredRequirements,
        }));
      }
    });
  };

  useEffect(() => {
    if (data) {
      hasNotes();
      hasRestrictions();
    }
  }, [data]);

  const getRecipientEntity = () => {
    if (
      emailModal.kind === 'booking_confirmation' ||
      (emailModal.kind === 'general_job' && data.customer)
    )
      return data.customer;
    return data.subcontractor;
  };

  const handleNoteClick = subTab => {
    setNoteTab({ subTab });
  };

  const handleNoteClear = () => {
    setNoteTab();
  };

  useEffect(() => {
    setWarnings(getBookingWarnings(data));
  }, [data]);

  useEffect(() => {
    SystemSetting.show().then(
      res => setFsLocation(res.freightsoftEntity?.mainAddress?.id),
      () => setPdfAlert({ error: 'Failed to get Freightsoft Entity' }),
    );
  }, []);

  if (notFound) return <NoPage />;
  if (dataLoading) return <LoadingSpinner />;
  if (!data) return null;

  return (
    <div className={'show-booking-page'}>
      <Breadcrumbs>
        <Link to={'/jobs/bookings'}>Jobs</Link>
        <Link to={`/jobs/bookings/${id}`}>{data?.jobReference}</Link>
      </Breadcrumbs>
      <Ribbon>
        <div className="split">
          <IconButton
            text={'Send Email'}
            icon={faEnvelope}
            onClick={() => setEmailModal({ kind: 'general_job' })}
            disabled={buttonLoad.includes('general_job')}
          />
          <IconButton
            text={'Booking Confirmation'}
            icon={faEnvelopeOpenText}
            onClick={handlePdf}
            disabled={buttonLoad.includes('booking_confirmation')}
          />
          {!data?.subcontracted && (
            <IconButton
              text={'Collection/Delivery Note'}
              icon={faFile}
              onClick={e => handlePdf(e, 'collection_delivery_note')}
              disabled={buttonLoad.includes('collection_delivery_note')}
            />
          )}
          {data?.subcontracted && (
            <IconButton
              text={'Subcontract Instruction'}
              icon={faEnvelopeOpenText}
              onClick={e => handlePdf(e, 'subcontract_instruction')}
              disabled={!data?.subcontractor || buttonLoad.includes('subcontract_instruction')}
            />
          )}
          {data?.subcontracted && data?.subcontractor && (
            <IconButton
              text={'Request POD'}
              icon={faEnvelope}
              onClick={() => setEmailModal({ kind: 'pod_request' })}
            />
          )}
        </div>
        <div className="split">
          {(data.status === 'active' || data.status === 'pending') && (
            <IconButton
              text={'Edit Job'}
              icon={faPen}
              onClick={() => navigate(`/jobs/bookings/${id}/edit`)}
              className={'edit'}
            />
          )}
          <CheckPermit type={'jobsBookings'} action={'update'} permissions={permissions}>
            {renderMenuOptions(data.status)}
          </CheckPermit>
        </div>
      </Ribbon>
      {emailModal && (
        <EmailComposeModal
          user={user}
          parent={data}
          recipientEntity={getRecipientEntity()}
          templateType={emailModal.kind}
          handleClose={() => setEmailModal()}
          setAlert={setPdfAlert}
          fileTabs={[{ tabName: 'General Files' }, { tabName: 'Job Files', parent: data }]}
          defaultAttachments={emailModal.defaultAttachments}
        />
      )}
      <ConfirmationModal
        question={'Are you sure you want to delete this booking?'}
        subText={data.jobReference}
        isOpen={openDeleteJob}
        confirmText={'Delete'}
        handleCancel={() => setOpenDeleteJob()}
        handleConfirm={handleDeleteBooking}
      />
      <FinaliseJob
        isOpen={openFinaliseJob}
        setIsOpen={setOpenFinaliseJob}
        jobId={data.id}
        jobReference={data.jobReference}
        setSuccessMessage={setSuccessMessage}
        refresh={refresh}
      />
      <CancelJob
        isOpen={openCancelJob}
        setIsOpen={setOpenCancelJob}
        jobId={data.id}
        setSuccessMessage={setSuccessMessage}
        refresh={refresh}
      />
      <ErrorStatusMessage error={pdfAlert} setError={setPdfAlert} />
      <Message
        text={successMessage}
        type={'success'}
        onClick={() => setSuccessMessage()}
        visible={!!successMessage}
        onClose={setSuccessMessage}
      />
      {data.status === 'cancelled' && (
        <Message
          text={{ errors: [`Reason: ${data.cancellationReason}`], errorTitle: 'Cancelled Job' }}
          type={'warning'}
          visible={true}
          dismissable={false}
        />
      )}

      <div className={'page-content'}>
        <h1>{data?.jobReference}</h1>
        <Message
          text={{ errors: warnings, errorTitle: 'Attention!' }}
          type={'error non-dismissable'}
          visible={warnings.length > 0}
          dismissable={false}
        />
        <Message
          text={error?.text}
          type={'error'}
          onClick={() => setError()}
          visible={!!error}
          onClose={setError}
        />
        <TabGroup
          defaultTab={notesTab ? 5 : 0}
          labels={['General', 'Advanced', 'Billing', 'Attachments', 'Emails', 'Notes']}
        >
          <BookingShowGeneral
            data={data}
            notes={notes}
            restrictions={restrictions}
            handleNoteClick={handleNoteClick}
            handleNoteClear={handleNoteClear}
          />
          <BookingShowAdvanced data={data} setError={setError} />
          <BookingShowBilling data={data} fsId={fsLocation} />
          <AttachmentsTab
            permissions={permissions}
            parentId={data.id}
            parentType={'Jobs::Booking'}
            requiredPermit={'jobsBookings'}
            setError={setError}
            dataLoad={buttonLoad}
          />
          <EmailsTab parentType={data.modelName} parentId={data.id} />
          <BookingShowNotes
            booking={data}
            user={user}
            permissions={permissions}
            subTab={notesTab?.subTab}
          />
        </TabGroup>
      </div>
    </div>
  );
};

BookingShow.propTypes = {
  permissions: PropTypes.object,
  user: PropTypes.object,
};

export default BookingShow;
