import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../../../components/FormSection/FormSection';
import FieldList from '../../../../../../../../components/FieldList/FieldList';
import FormField from '../../../../../../../../components/FormField/FormField';

const BookingFormMisc = ({ data, alter }) => {
  return (
    <FormSection title={'Miscellaneous'}>
      <FieldList>
        <FormField label={'Booking Date'}>
          <input
            type={'date'}
            data-testid={'bfg-booking-date'}
            value={data.bookingDate}
            onChange={e => alter('bookingDate', e.target.value)}
          />
        </FormField>
        <FormField label={'Additional Details'}>
          <input
            data-testid={'bfg-additional-information'}
            value={data.additionalInformation}
            onChange={e => alter('additionalInformation', e.target.value)}
          />
        </FormField>
      </FieldList>
    </FormSection>
  );
};

BookingFormMisc.propTypes = {
  data: PropTypes.object,
  alter: PropTypes.func,
};

export default BookingFormMisc;
