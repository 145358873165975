import React, { useEffect, useState } from 'react';
import '../../Document/InvoiceForm/InvoiceForm.scss';
import PurchaseCredit from '../../../../api/controllers/Financial/PurchaseCredit/PurchaseCredit';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import DocumentEdit from '../../Document/DocumentEdit/DocumentEdit';
import { useParams } from 'react-router';

const PurchaseNoteEdit = () => {
  const { id } = useParams();
  const [error, setError] = useState();
  const [lineItems, setLineItems] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const handleErrors = json => {
    loading && setLoading(false);
    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };

  useEffect(() => {
    PurchaseCredit.show(id).then(
      json => {
        setData(json);
        PurchaseCredit.lineItems({ entityId: json.entity.id, documentId: id }).then(
          json => {
            setLineItems(json.items);
            setLoading(false);
          },
          json => handleErrors(json),
        );
      },
      json => handleErrors(json),
    );
  }, []);

  return (
    <DocumentEdit
      formData={data}
      lineItems={lineItems}
      error={error}
      setError={setError}
      title={'Purchase Credit Note'}
      index={'/financials/purchase_credit_notes/'}
      loading={loading}
    />
  );
};

PurchaseNoteEdit.propTypes = {};

export default PurchaseNoteEdit;
