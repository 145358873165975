import Action from '../Action/Action';

/*
  An abstract MultipleAction class. 
  This is not to be called directly, but the be extended 
  with the intension of having an execute and undo function, 
  which perform some action
*/
class MultipleAction extends Action {
  constructor() {
    super();
    if (new.target === MultipleAction)
      throw new TypeError('Abstract classes cannot be directly constructed.');
  }

  static execute() {}

  static undo() {}

  static undoAll() {}
}

export default MultipleAction;
