import React from 'react';
import './ReportShow.scss';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { Link, useParams } from 'react-router-dom';
import useApi from '../../../common/hooks/useApi/useApi';
import Report from '../../../api/controllers/Report/Report';
import Ribbon from '../../../components/Ribbon/Ribbon';
import Table from '../../../components/Table/Table/Table';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faFileCsv, faFileExcel, faClock } from '@fortawesome/free-solid-svg-icons';
import DateHelper from '../../../utils/DateHelper/DateHelper';
import LoadingCog from './LoadingCog/LoadingCog';
import { useState } from 'react';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatCell, formatVal } from './reportCellHandler/reportCellHandler';
import { utils, writeFile } from 'xlsx-js-style';
import { downloadCsv } from '../../../utils/generateCsv/generateCsv';
import ReportHelper from '../../../utils/ReportHelper/ReportHelper';

// When a report id is supplied alongside an optional date range, return a table
// filled with relevant information and allow the user to generate xlsx/csv's
const ReportShow = () => {
  const [errors, setErrors] = useState();
  const searchParams = new URLSearchParams(location.search);
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');

  // Reasoning for below is here under "An important note about date ranges"
  // https://yozudev.atlassian.net/browse/TSR-1288
  const getEndDate = () => {
    if (!startDate || !endDate) return endDate;

    const tomorrowSeconds = new Date(endDate).setDate(new Date(endDate).getDate() + 1);
    const tomorrowDate = new Date(tomorrowSeconds).toJSON().split('T')[0];
    return tomorrowDate;
  };

  const { id } = useParams();

  const { report, reportLoading, reportTimer } = useApi({
    call: () => Report.show(id, { startDate, endDate: getEndDate() }),
    name: 'report',
    handleError: err => setErrors(formatError(err)?.errors),
  });

  const formatHeaders = cols => cols.map(col => ({ text: col, className: 'tiny' }));

  const genRow = row => <tr>{report['columns'].map((key, i) => formatCell(row, key, i))}</tr>;

  const buildTableTitle = () => {
    if (!startDate || !endDate) return report?.name;

    return `${report?.name} (${DateHelper.dateOnly(startDate)} to ${DateHelper.dateOnly(endDate)})`;
  };

  const buildReportTitle = () => {
    if (!startDate || !endDate) return report?.name;

    const formattedStartDate = formatDateSeperator(DateHelper.dateOnly(startDate));
    const formattedEndDate = formatDateSeperator(DateHelper.dateOnly(endDate));

    return `${report?.name} (${formattedStartDate} to ${formattedEndDate})`;
  };

  const formatDateSeperator = date => {
    return date.replace(/\//g, '-');
  };

  // Generates our worksheet for the current report
  const generateXlsx = () => {
    const book = utils.book_new(); // Creates the book

    // Formats the header row cells
    const headers = report['columns'].map(col => ({
      v: col,
      s: { bold: true, fill: { fgColor: { rgb: 'ADD8E6' } } },
    }));

    // Formats all the rows and maps them to the row order
    const rows = report['rows'].map(row =>
      report['columns'].map(col => ({ v: formatVal(row, col) })),
    );

    // Generates the sheet
    const sheet = utils.aoa_to_sheet([headers, ...rows]);

    // Assigns width values to each row
    sheet['!cols'] = report['columns'].map(() => ({ width: 20 }));

    // Adds sheet, then downloads it
    utils.book_append_sheet(book, sheet, 'report');
    writeFile(book, `${buildReportTitle()}.xlsx`);
  };

  document.title = report?.name;
  const time = Math.ceil(reportTimer);

  return (
    <div className="report-show">
      <Breadcrumbs>
        <Link to="/reports/standard">Reports</Link>
        <Link to={`/reports/standard/${id}?${searchParams.toString()}`}>{report?.name}</Link>
      </Breadcrumbs>
      {!errors && !reportLoading && (
        <Ribbon>
          <div className="ribbon-message split">
            <FontAwesomeIcon icon={faClock} />
            Completed in about {time} second{time > 1 ? 's' : ''}
          </div>
          <IconButton icon={faFileExcel} text="Download XLSX" onClick={generateXlsx} />
          <IconButton
            icon={faFileCsv}
            text="Download CSV"
            onClick={() =>
              downloadCsv(report['rows'], report['columns'], buildReportTitle(), formatVal)
            }
          />
        </Ribbon>
      )}
      <div className="page-content">
        <LoadingCog loading={reportLoading} errors={errors}>
          {report && (
            <Table
              title={buildTableTitle()}
              headers={formatHeaders(report['columns'])}
              data={{ items: report['rows'] }}
              onDrawRow={genRow}
              searchable={ReportHelper.getSearchables()}
            />
          )}
        </LoadingCog>
      </div>
    </div>
  );
};

ReportShow.propTypes = {};

export default ReportShow;
