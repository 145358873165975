import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Area from '../../../api/controllers/Tariff/Area/Area';
import IconButton from '../../../common/button/IconButton/IconButton';
import useApi from '../../../common/hooks/useApi/useApi';
import useArray from '../../../common/hooks/useArray/useArray';
import Message from '../../../common/layout/Message/Message';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import FormSection from '../../../components/FormSection/FormSection';
import TagInput from '../../../components/TagInput/TagInput';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import './TariffArea.scss';

const defaultArea = {
  name: '',
  postcodes: [],
};

const TariffArea = () => {
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState();

  const { defaultAreas } = useApi({ name: 'defaultAreas', call: Area.all });
  const { areas, replaceAreas, addAreas, handleAreas, removeIdxAreas } = useArray({
    name: 'areas',
  });
  const { destroyedAreas, addDestroyedAreas } = useArray({ name: 'destroyedAreas' });
  //Handles if a tag is yet to be saved.
  const { newTags, addNewTags, removeNewTags, clearNewTags } = useArray({ name: 'newTags' });

  useEffect(() => {
    if (areas.length === 0 && defaultAreas) handleAreas(defaultAreas.items);
  }, [defaultAreas]);

  useEffect(() => {
    document.title = 'Manage Tariff Areas';
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    Area.updateAll({ areasAttributes: Object.assign({}, [...areas, ...destroyedAreas]) })
      .then(
        () => {
          setMessage({ type: 'success', text: 'Your changes have been saved' });
          clearNewTags(); //clears the highlighted tags so they all appear as normal
        },
        err => setMessage({ type: 'error', text: formatError(err) }),
      )
      .finally(() => setLoading(false));
  };

  const handleDelete = (area, i) => {
    if (area.modelName) addDestroyedAreas({ ...area, _destroy: 1 });
    removeIdxAreas(i);
  };

  return (
    <div className="tariff-area">
      <Breadcrumbs>
        <Link to="/tariffs/areas">Tariff Areas</Link>
      </Breadcrumbs>

      <div className="page-content">
        <h1>Manage Areas</h1>

        {message && (
          <Message
            type={message.type}
            text={message.text}
            visible={!!message}
            onClick={() => setMessage()}
          />
        )}

        <FormSection
          title="Tariff Areas"
          optionalElement={
            <IconButton
              text={'Add Area'}
              icon={faPlus}
              className={'success'}
              onClick={() => addAreas(defaultArea)}
            />
          }
        >
          <div className="area-headers">
            <div>Name</div>
            <div>Postcodes</div>
            <div className="ah-actions"></div>
          </div>
          {areas.map((area, i) => (
            <div key={i} className="ma-row">
              <div className="ma-col name">
                <input
                  value={area.name}
                  onChange={e => replaceAreas(i, { ...area, name: e.target.value })}
                />
              </div>
              <div className="ma-col postcodes">
                <TagInput
                  tags={area.postcodes}
                  addTag={postcode =>
                    replaceAreas(i, { ...area, postcodes: [...area.postcodes, postcode] })
                  }
                  removeTag={postcode =>
                    replaceAreas(i, {
                      ...area,
                      postcodes: area.postcodes.filter(code => code !== postcode),
                    })
                  }
                  newTags={newTags}
                  removeNewTags={removeNewTags}
                  addNewTags={addNewTags}
                  onDisplay={postcode => postcode}
                />
              </div>
              <div className="ma-col actions">
                <IconButton
                  iconOnly
                  icon={faTimes}
                  className={'destructive'}
                  onClick={() => handleDelete(area, i)}
                  testId={`-${i}`}
                />
              </div>
            </div>
          ))}
        </FormSection>
        <div className="ta-button-group">
          <IconButton text={'Save'} icon={faCheck} onClick={handleSubmit} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default TariffArea;
