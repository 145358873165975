class EmailHelper {
  //determines the time of day for the salutation
  static getTime() {
    const date = new Date();
    const hrs = date.getHours();
    if (hrs < 12) return 'Morning';
    if (hrs < 18) return 'Afternoon';
    return 'Evening';
  }
  //gets the first name of the first CC, (usually To person)
  static firstCCName(ccArray) {
    const firstTo = ccArray.find(cc => cc.ccType === 'to');
    if (firstTo?.contact?.firstName) return ` ${firstTo?.contact?.firstName}`;
    return '';
  }

  static getSalutation(nameWithSpace = '') {
    return `Good ${EmailHelper.getTime()}${nameWithSpace},\n\n`;
  }
}

export default EmailHelper;
