class UserHelper {
  static getRoleString(item) {
    const { administrator, operations, sales, accounts } = item;
    if (!administrator && !operations && !sales && !accounts) return 'None';
    const roleStr = [
      administrator && 'Administrator',
      operations && 'Operations',
      sales && 'Sales',
      accounts && 'Accounts',
    ]
      .filter(role => role)
      .join(' / ');
    return roleStr;
  }
}

export default UserHelper;
