import React from 'react';
import logo from '../../images/logo/transswift.png';
import './NoPage.scss';
import { Link } from 'react-router-dom';

const NoPage = () => (
  <div className={'nopage-container'}>
    <img src={logo} />
    <p>Sorry, the requested page could not be found.</p>
    <p>
      <Link to={'/jobs/bookings'}>Click here</Link> to return to the dashboard.
    </p>
  </div>
);

NoPage.propTypes = {};

export default NoPage;
