import React from 'react';
import PropTypes from 'prop-types';
import FieldList from '../FieldList/FieldList';
import FormField from '../FormField/FormField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import './IterativeDropdown.scss';

const IterativeDropdown = ({
  idx,
  item,
  fieldLabel,
  secondLabel,
  drawDropdown,
  drawDropdownSecond,
  handleMinus,
  disabled = false,
}) => (
  <FieldList className="id-wrap">
    <FormField label={fieldLabel} className="short">
      {drawDropdown(idx, item)}
    </FormField>
    <FormField label={secondLabel} className="short">
      <div className={'if-wrap'}>
        {drawDropdownSecond(idx, item)}
        <button
          title={'minus'}
          onClick={handleMinus}
          className={'minus'}
          disabled={disabled}
          data-testid={'minus'}
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
      </div>
    </FormField>
  </FieldList>
);

IterativeDropdown.propTypes = {
  idx: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  fieldLabel: PropTypes.string,
  secondLabel: PropTypes.string,
  drawDropdown: PropTypes.func.isRequired,
  drawDropdownSecond: PropTypes.func.isRequired,
  handleMinus: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default IterativeDropdown;
