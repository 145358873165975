import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../components/FormSection/FormSection';
import ContactApi from '../../../../../../api/controllers/Company/Contact/Contact';
import useApi from '../../../../../../common/hooks/useApi/useApi';
import Address from '../../../../../../api/controllers/Company/Address/Address';
import useForm from '../../../../../../common/hooks/useForm/useForm';
import Modal from '../../../../../../common/Modal/Modal';
import { formatError } from '../../../../../../utils/Formatters/Errors/formatError';
import AttributeHelper from '../../../../../../utils/AttributeHelper/AttributeHelper';
import ConfirmationModal from '../../../../../../components/ConfirmationModal/ConfirmationModal';
import NewAddress from '../../../../../../components/modals/NewAddress/NewAddress';
import NewOpeningTime from '../../../../../../components/modals/NewOpeningTime/NewOpeningTime';
import { warehouseFactors } from '../../../../../../strings/enums';
import AddressSection from './subComponents/AddressSection/AddressSection';
import ContactsSection from './subComponents/ContactsSection/ContactsSection';
import NewContactAlt from '../../../../../../components/modals/NewContactAlt/NewContactAlt';
import EditContactAlt from '../../../../../../components/modals/EditContactAlt/EditContactAlt';

const ContactTab = ({ entity, entityRefresh, setError }) => {
  const entityId = entity.id;
  const [newContact, setNewContact] = useState();
  const [editContact, setEditContact] = useState();
  const { form, alter, handleForm } = useForm();
  const { addressData, refreshAddressData, addressDataLoading } = useApi({
    call: () => Address.all({ entityId }),
    name: 'addressData',
  });
  const { contactData, refreshContactData, contactDataLoading } = useApi({
    name: 'contactData',
    call: () => ContactApi.all({ entityId }),
  });
  const { contactKinds } = useApi({
    call: ContactApi.contactMethodsKinds,
    name: 'contactKinds',
  });
  const [modalError, setModalError] = useState();

  const [confirmOpen, setConfirmOpen] = useState();

  const handleCancel = e => {
    e.preventDefault();
    setModalError();
    handleForm();
  };

  const handleOpeningDelete = item => {
    const params = { openingTimesAttributes: [{ id: item.id, _destroy: true }] };
    Address.update(item.addressId, params).then(
      () => {
        setConfirmOpen();
        refreshAddressData();
      },
      json => setError(formatError(json)),
    );
  };

  const handleDelete = (item, type) => {
    if (type === 'opening') return handleOpeningDelete(item);
    const handler = type === 'address' ? Address : ContactApi;
    const refresh = type === 'address' ? refreshAddressData : refreshContactData;
    handler.destroy(item.id).then(
      () => {
        setConfirmOpen();
        refresh();
      },
      json => {
        setConfirmOpen();
        setError(formatError(json));
      },
    );
  };

  const handleAddressSubmit = e => {
    e.preventDefault();
    const params = { ...form, entityId };
    const call = params.modelName
      ? () => Address.update(params.id, params)
      : () => Address.create(params);
    call().then(
      () => {
        refreshAddressData();
        handleForm();
      },
      res => setModalError({ ...form, ...formatError(res) }),
    );
  };

  const openCreateAddress = () =>
    handleForm({
      line1: '',
      line2: '',
      line3: '',
      line4: '',
      region: '',
      postcode: '',
      office: false,
      warehouse: false,
      warehouseRequirementsAttributes: [],
      maxForkliftWeight: null,
      loadingDoorHeight: null,
      loadingEavesHeight: null,
      topLoading: false,
      sideLoading: false,
      rearLoading: false,
      errors: [],
    });

  const openEditAddress = idx =>
    handleForm({
      ...addressData.items[idx],
      warehouseRequirementsAttributes: AttributeHelper.importAttributes(
        warehouseFactors,
        addressData.items[idx].warehouseRequirements,
      ),
      errors: [],
    });

  const openOpeningTime = (id, idx, time = {}) => {
    let formVal = {
      id,
      timeId: idx,
      openingTimesAttributes: [],
    };
    formVal.openingTimesAttributes[idx] = {
      id: time.id || null,
      opensAt: time.opensAt || '09:00',
      closesAt: time.closesAt || '17:00',
      monday: typeof time.monday === 'boolean' ? time.monday : true,
      tuesday: typeof time.tuesday === 'boolean' ? time.tuesday : true,
      wednesday: typeof time.wednesday === 'boolean' ? time.wednesday : true,
      thursday: typeof time.thursday === 'boolean' ? time.thursday : true,
      friday: typeof time.friday === 'boolean' ? time.friday : true,
      saturday: typeof time.saturday === 'boolean' ? time.saturday : false,
      sunday: typeof time.sunday === 'boolean' ? time.sunday : false,
    };
    handleForm(formVal);
  };

  const handleUpdateOpening = e => {
    e.preventDefault();
    Address.update(form.id, form).then(
      () => {
        handleForm();
        refreshAddressData();
      },
      json => setModalError(formatError(json)),
    );
  };

  const setAddressStrategy = {
    invoicing: Address.markAsInvoicing,
    main: Address.markAsMain,
  };

  const handleSetAddress = (id, type) =>
    setAddressStrategy[type](id).then(
      () => {
        handleForm();
        entityRefresh();
      },
      json => setError(formatError(json)),
    );

  return (
    <div className="tab-content-grid">
      {newContact && (
        <NewContactAlt
          handleClose={() => {
            setNewContact();
            refreshContactData();
          }}
          entityId={entityId}
        />
      )}
      {editContact && (
        <EditContactAlt
          handleClose={() => {
            setEditContact();
            refreshContactData();
          }}
          contact={editContact}
        />
      )}
      <Modal isOpen={!!form} handleClose={() => handleForm()}>
        {!!form?.openingTimesAttributes && (
          <FormSection title={'Opening Time'}>
            <NewOpeningTime
              idx={form.timeId}
              form={form}
              onChange={alter}
              handleCancel={handleCancel}
              handleSubmit={handleUpdateOpening}
              error={modalError}
              setError={setModalError}
            />
          </FormSection>
        )}
        {!!form && (typeof form.region === 'string' || form.region === null) && (
          <FormSection title={form.modelName ? 'Edit Address' : 'New Address'}>
            <NewAddress
              form={form}
              onChange={alter}
              onSet={handleForm}
              handleCancel={handleCancel}
              handleSubmit={handleAddressSubmit}
              error={modalError}
              setError={setModalError}
            />
          </FormSection>
        )}
      </Modal>
      <ConfirmationModal
        question={`Are you sure you want to delete this ${confirmOpen?.type}?`}
        subText={confirmOpen?.subText}
        confirmText={'Delete'}
        isOpen={!!confirmOpen}
        handleConfirm={() => handleDelete(confirmOpen?.item, confirmOpen?.type)}
        handleCancel={() => setConfirmOpen()}
      />
      <ContactsSection
        data={contactData}
        contactKinds={contactKinds}
        createContact={() => setNewContact(true)}
        editContact={con => setEditContact(con)}
        setConfirmOpen={setConfirmOpen}
        refreshData={refreshContactData}
        loading={contactDataLoading}
        setError={setError}
      />
      <AddressSection
        data={addressData}
        entity={entity}
        createAddress={openCreateAddress}
        editAddress={openEditAddress}
        setAddress={handleSetAddress}
        setConfirmOpen={setConfirmOpen}
        openOpeningTime={openOpeningTime}
        setError={setError}
        refreshData={refreshAddressData}
        loading={addressDataLoading}
      />
    </div>
  );
};

ContactTab.propTypes = {
  entity: PropTypes.object,
  entityRefresh: PropTypes.func,
  setError: PropTypes.func,
};

export default ContactTab;
