import React from 'react';
import PropTypes from 'prop-types';
import './VehicleRunFooter.scss';
import DirectionLabel from '../../DirectionLabel/DirectionLabel';
import VehicleRunHelper from '../../../../../../utils/VehicleRunHelper/VehicleRunHelper';
import NumberHelper from '../../../../../../utils/NumberHelper/NumberHelper';

const VehicleRunFooter = ({ jobCount, bookings, fsId }) => {
  const getSectionInfo = (jobs, section) => {
    const filteredJobs = jobs.filter(
      job =>
        VehicleRunHelper.getJobDirection(fsId, job.collectionAddressId, job.deliveryAddressId) ===
        section,
    );
    const totalPackages = filteredJobs.reduce((prev, curr) => prev + Number(curr.quantity), 0);
    const totalGrossWeight = filteredJobs.reduce(
      (prev, curr) => prev + Number(curr.grossWeightKg),
      0,
    );
    const totalCubicMetres = NumberHelper.formatNumToDecPlaces(
      filteredJobs.reduce((prev, curr) => prev + Number(curr.cubicMetres), 0),
      2,
    );
    const totalLoadingMetres = NumberHelper.formatNumToDecPlaces(
      filteredJobs.reduce((prev, curr) => prev + Number(curr.loadingMetres), 0),
      2,
    );
    return [
      `${totalPackages} pkg${totalPackages !== 1 ? 's' : ''}`,
      `${totalGrossWeight}kg`,
      `${totalCubicMetres}m³`,
      `${totalLoadingMetres}m`,
    ];
  };

  return (
    <div className="vehicle-run-footer">
      <div className="vrf-job-count">
        {jobCount} Job{jobCount !== 1 && 's'}
      </div>
      <div className="vrf-direction-section collection">
        <DirectionLabel fsId={1} collectionId={2} deliveryId={1} />
        {getSectionInfo(bookings, 'COL').map((item, i) => (
          <div className="info-text" key={`dir-COL-${i}`}>
            {item}
          </div>
        ))}
      </div>
      <div className="vrf-direction-section delivery">
        <DirectionLabel fsId={1} collectionId={1} deliveryId={2} />
        {getSectionInfo(bookings, 'DEL').map((item, i) => (
          <div className="info-text" key={`dir-DEL-${i}`}>
            {item}
          </div>
        ))}
      </div>
      <div className="vrf-direction-section cross-trade">
        <DirectionLabel fsId={1} collectionId={2} deliveryId={3} />
        {getSectionInfo(bookings, 'XT').map((item, i) => (
          <div className="info-text" key={`dir-XT-${i}`}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

VehicleRunFooter.propTypes = {
  jobCount: PropTypes.number.isRequired,
  bookings: PropTypes.array,
  fsId: PropTypes.number,
};

export default VehicleRunFooter;
