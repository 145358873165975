import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../FormSection/FormSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconHelper from '../../../utils/IconHelper/IconHelper';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './AttachmentSection.scss';
import Item from '../../../api/controllers/File/Item/Item';
import DownloadHelper from '../../../utils/DownloadHelper/DownloadHelper';

const AttachmentSection = ({
  fileArray = [],
  removeIdxFileArray,
  setMessage,
  removeFileFromTemplate,
  addRemovalArray = () => {},
}) => {
  const handleDownload = (id, name) => {
    Item.download(id).then(
      res => {
        DownloadHelper.saveFile(name, res);
        setMessage();
      },
      err =>
        setMessage({ ...formatError(err), errorTitle: 'Could not download file', type: 'error' }),
    );
  };

  const handleRemove = (i, fileId) => {
    if (removeFileFromTemplate) {
      const removeFileOnSuccess = () => removeIdxFileArray(i);
      removeFileFromTemplate(fileId, removeFileOnSuccess);
    } else {
      addRemovalArray(fileId);
      removeIdxFileArray(i);
    }
  };

  return (
    <span className="attachment-section">
      <FormSection title={'Attached Files'}>
        {fileArray.length === 0 && <i>No files are currently attached.</i>}
        {fileArray.map((file, i) => (
          <div key={i} className="file-wrap">
            <div className="file-icon">
              <FontAwesomeIcon icon={IconHelper.getFolderIcon(file?.mimeType)} />
            </div>
            <a onClick={() => handleDownload(file.id, file.fileName)} className={'file-text'}>
              {file?.fileName}
            </a>
            <div
              data-testid={'remove-attachment'}
              className={'file-remove'}
              onClick={() => handleRemove(i, file.id)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        ))}
      </FormSection>
    </span>
  );
};

AttachmentSection.propTypes = {
  fileArray: PropTypes.array,
  removeIdxFileArray: PropTypes.func,
  setMessage: PropTypes.func,
  removeFileFromTemplate: PropTypes.func,
  addRemovalArray: PropTypes.func,
};

export default AttachmentSection;
