import React from 'react';
import PropTypes from 'prop-types';
import ErrorStatusMessage from '../ErrorStatusMessage/ErrorStatusMessage';
import './Toast.scss';

const Toast = ({ messages = [], setMessages }) => {
  const removeMessage = messageIdx => {
    const filtered = messages.filter((_, idx) => idx !== messageIdx);
    setMessages(filtered);
  };

  return (
    <div className="toast-container" role="toast-container">
      {messages.map((message, idx) => (
        <ErrorStatusMessage
          key={`toast-${idx}`}
          error={message}
          setError={() => removeMessage(idx)}
        />
      ))}
    </div>
  );
};

Toast.propTypes = {
  messages: PropTypes.array,
  setMessages: PropTypes.func.isRequired,
};

export default Toast;
