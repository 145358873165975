import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useWebSocket from '../../../common/hooks/useWebSocket/useWebSocket';
import VehicleRunCollapsibleTable from './subComponents/VehicleRunCollapsibleTable/VehicleRunCollapsibleTable';
import SectionCollapsibleTable from './subComponents/SectionCollapsibleTable/SectionCollapsibleTable';
import NewVehicleRun from '../../../components/modals/NewVehicleRun/NewVehicleRun';
import './VehicleRunPlanner.scss';
import VehicleRun from '../../../api/controllers/Movement/VehicleRun/VehicleRun';
import SystemSetting from '../../../api/controllers/SystemSetting/SystemSetting';
import EditVehicleRun from '../../../components/modals/EditVehicleRun/EditVehicleRun';
import useArray from '../../../common/hooks/useArray/useArray';
import useForm from '../../../common/hooks/useForm/useForm';
import DateHelper from '../../../utils/DateHelper/DateHelper';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import VehicleRunHeader from './subComponents/VehicleRunHeader/VehicleRunHeader';
import Vehicle from '../../../api/controllers/Movement/Vehicle/Vehicle';
import { useLocation, useNavigate } from 'react-router';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import CreateAction from './actions/CreateAction/CreateAction';
import DestroyAction from './actions/DestroyAction/DestroyAction';
import UpdateAction from './actions/UpdateAction/UpdateAction';
import GetAction from './actions/GetAction/GetAction';
import Booking from '../../../api/controllers/Job/Booking/Booking';
import search from '../../../utils/search/search';
import DestroyMultipleAction from './actions/DestroyMultipleAction/DestroyMultipleAction';
import Toast from '../../../common/Toast/Toast';
import VehicleRunHelper from '../../../utils/VehicleRunHelper/VehicleRunHelper';
import VehicleRunPlannerXlsx from '../../../utils/XlsxGenerators/VehicleRunPlannerXlsx/VehicleRunPlannerXlsx';
import UpdateMultipleAction from './actions/UpdateMultipleAction/UpdateMultipleAction';
import ShowJobNotes from '../../../components/modals/ShowJobNotes/ShowJobNotes';

const defaultOptions = {
  startDate: DateHelper.today(),
  endDate: DateHelper.today(),
  status: ['pending', 'in_progress', 'complete'],
  subcontracted: null,
  filter: '',
  deliveryTypePending: { type: '', value: 'Everything' },
  deliveryTypeUnassigned: { type: '', value: 'Everything' },
};

const VehicleRunPlanner = ({ user, permissions }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useRef();
  const [runs, setRuns] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [currentDraggingJobs, setCurrentDraggingJobs] = useState([]); // This will hold the current list of jobs we are dragging.
  const [rowRemovalAction, setRowRemovalAction] = useState();
  const [subwindow, setSubwindow] = useState();
  const [runsLoading, setRunsLoading] = useState(true);
  const [bookingsLoading, setBookingsLoading] = useState(true);
  const [stack, setStack] = useState([]);
  const [disconnected, setDisconnected] = useState(true);
  const [error, setError] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [availableRuns, setAvailableRuns] = useState([]); //Holds list of ids for what vehicles can be opened/closed
  const [fsLocation, setFsLocation] = useState(); //handles the freight entity for determining direction of jobs
  const [newVehicleRunModal, setNewVehicleRunModal] = useState(false); // Initially NewVehicleRun modal should not be shown
  const [editVehicleRunModal, setEditVehicleRunModal] = useState(); // This will contain the id of the VehicleRun to edit
  const [deleteVehicleRunModal, setDeleteVehicleRunModal] = useState(); // This will contain the VehicleRun to delete
  const [noteModal, setNoteModal] = useState(); // Contains a booking or falsy value
  const [highlighted, setHighlighted] = useState([]); // This will store the currently highlighted jobs, added/removed though CTRL / WIN / CMD + click
  const [xlsxLoading, setXlsxLoading] = useState(false); //says if we are currently generating an xlsx, disables button while true
  const [runsGenerating, setRunsGenerating] = useState(false); //says if we are currently generating bulk vehicle runs
  const [openAll, setOpenAll] = useState(false); //handles if all open button clicked.
  const [parent, setParent] = useState();
  const [showArchived, setShowArchived] = useState({});

  //handles the filtering of vehicle runs
  const { form, alter, handleForm } = useForm(defaultOptions);

  /*
    Handles all the requests we can receive from the websocket
    Checks against the request type, and the message action to
    determine what should happen and responds accordingly
  */
  const reducer = request => {
    switch (true) {
      case request.type === 'ping':
        break;
      //Happens when we first connect, gets our vehicle runs and the list of jobs we need
      // also removes the 'Not Connected' Warning
      case request.type === 'confirm_subscription': {
        setDisconnected(false);

        setBookingsLoading(true);
        //initially just gets the unassigned jobs
        const bookingAction = new GetAction(
          Booking.all,
          setJobs,
          handleJobSuccess,
          handleRunError,
          { status: 'active', vehicleRunId: null, subcontracted: null },
        );
        bookingAction.execute();

        setStack([bookingAction]);
        break;
      }
      //Happens when we change the filter, fetches the vehicle runs
      case request.type === 'update_vehicle_runs': {
        setRunsLoading(true);
        const runAction = new GetAction(VehicleRun.all, setRuns, handleRunSuccess, handleRunError, {
          startDate: form.startDate,
          endDate: form.endDate,
        });
        setStack([...stack, runAction]);
        runAction.execute();
        break;
      }
      case request.type === 'close':
        return setDisconnected(true);
      case request.message?.action === 'update':
        handleUpdateRequest(request.message.record);
        break;
      case request.message?.action === 'create':
        handleCreateRequest(request.message.record);
        break;
      case request.message?.action === 'destroy':
        handleDestroyRequest(request.message.record);
        break;
      default:
        console.log('Unhandled request: ', request);
    }
  };

  //Sets up the websocket, we pass the reducer so it can be used in onMessage / onClose
  const { loading, reconnect } = useWebSocket({
    channel: 'VehicleRunPlannerChannel',
    reducer,
    dependencies: [jobs],
  });

  const { openRuns, handleOpenRuns, toggleOpenRuns, clearOpenRuns } = useArray({
    name: 'openRuns',
    defaultValue: ['unassigned'],
  });

  //sets both the runs and the id of runs available
  // first is for the display of them, second is so we can track which are open or closed
  const handleSetRuns = newRuns => {
    setAvailableRuns(newRuns.map(run => run.id));
    setRuns(newRuns);
  };

  //Runs we have a successful getAction call on vehicleRuns
  const handleRunSuccess = response => {
    handleSetRuns(response.items);
    clearOpenRuns();
    setRunsLoading(false);
  };

  const generateXlsx = () => {
    setXlsxLoading(true);
    const generator = new VehicleRunPlannerXlsx(
      jobs,
      runs,
      openRuns,
      form,
      fsLocation,
      !!subwindow,
    );
    try {
      generator.generate();
    } catch (e) {
      setError(error.concat({ error: 'Failed to generate Xlsx' }));
    }
    setXlsxLoading(false);
  };

  /*
    Handles the creation of a vehicle for any vehicle that doesn't have a
    run for that day. First Filters out runs that already exist for the day, 
    vehicles marked as "manual selection only" and archived vehicles,
    then sends a create request for each vehicle, handling the result.

    We use an let Errors, as using all/allSettled would not give information about
    vehicles as we need for display
  */
  const handleGenerateRuns = () => {
    setRunsGenerating(true);
    const vehiclesWithoutRuns = vehicles.filter(
      vehicle =>
        !runs.find(run => run.vehicle?.id === vehicle.id) &&
        !vehicle.manualSelectionOnly &&
        !vehicle.archived,
    );
    if (vehiclesWithoutRuns.length === 0) return setRunsGenerating(false);
    let errors = []; //stores any errors from promise
    Promise.allSettled(
      vehiclesWithoutRuns.map(vehicle =>
        VehicleRun.create({
          dateOfUse: form.startDate,
          vehicleId: vehicle.id,
          driverId: vehicle.driver?.id || null,
          status: 'pending',
        }).catch(res => errors.push({ ...res, status: 'RunCreate', vehicle })),
      ),
    )
      .then(res => {
        const newRuns = res
          .filter(item => item.status === 'fulfilled' && typeof item.value === 'object')
          .map(i => i.value);

        if (newRuns.length > 0) {
          errors.push({
            status: 'success',
            error: `Successfully created ${newRuns.length} new Vehicle Run${
              newRuns.length !== 1 ? 's' : ''
            }`,
          });
          //timeout which verifies the user has the most up-to-date view of all runs
          setTimeout(() => {
            const action = new UpdateMultipleAction(setRuns, runs, newRuns);
            action.execute();
          }, 1000);
        }
      })
      .finally(() => {
        setError(error.concat(errors));
        setRunsGenerating(false);
      });
  };

  /* Handles all update requests
    Essentially checks the model name for the update record to
    determine what has changed, then calls for an UpdateAction
    to alter the state accordingly
  */
  const handleUpdateRequest = updatedRecord => {
    if (updatedRecord.modelName === 'Movements::VehicleRun') {
      /*
        Checks the updated run's date, if its not the current filter
        check that it isn't already on the screen, doing nothing if it isn't
        and removing it if it currently is.
      */
      if (updatedRecord.dateOfUse !== form.startDate) {
        if (runs.find(item => item.id === updatedRecord.id))
          return handleDestroyRequest(updatedRecord);
        return;
      }
      const updateRunAction = new UpdateAction(setRuns, runs, updatedRecord);
      updateRunAction.execute();
      setStack([...stack, updateRunAction]);
    }
    if (updatedRecord.modelName === 'Jobs::Booking') {
      UpdateJobsVehicleRun(updatedRecord);
      const updateBookingAction = new UpdateAction(setJobs, jobs, updatedRecord);
      updateBookingAction.execute();
      setStack([...stack, updateBookingAction]);
    }
  };

  /*
    handles the updating of a vehicleRun associated to an updated job

    Finds the new run and the old one. Returns if they are the same
    then adds to the new run and removes from the old one.
  */
  const UpdateJobsVehicleRun = updatedJob => {
    //finds the runs we will be altering
    const newVehicleRun = runs.find(run => run.id === updatedJob.vehicleRunId);
    const oldVehicleRun = runs.find(run => run.bookings.find(item => item.id === updatedJob.id));

    //if its not moving, return
    if (newVehicleRun?.id === oldVehicleRun?.id) return;

    //sets the updated runs to current state, so we never full break everything
    let updatedRuns = runs;

    //check that it doesn't include the job already, and add
    if (newVehicleRun && !newVehicleRun.bookings.find(item => item.id === updatedJob.id)) {
      const newRunJobs = newVehicleRun.bookings;
      const filteredJobs = newRunJobs.filter(job => job.id !== updatedJob.id);

      //filters out the outdated run
      const filteredRuns = updatedRuns.filter(item => item.id !== newVehicleRun.id);

      //updates the runs to include the run with the job added.
      updatedRuns = [
        ...filteredRuns,
        {
          ...newVehicleRun,
          bookings: filteredJobs.concat(updatedJob),
        },
      ];
    }

    //remove it from the old vehicle run
    if (oldVehicleRun) {
      const oldRunJobs = oldVehicleRun.bookings;

      const filteredJobs = oldRunJobs.filter(job => job.id !== updatedJob.id);

      //filters the out of date run
      const filteredRuns = updatedRuns.filter(item => item.id !== oldVehicleRun.id);

      //updates the local runs
      updatedRuns = [
        ...filteredRuns,
        {
          ...oldVehicleRun,
          bookings: filteredJobs,
        },
      ];
    }

    //sets runs to its new value
    setRuns(updatedRuns.sort((a, b) => a.id - b.id));
  };

  /* Handles all deletion requests
    Essentially checks the model name for the deleted record to
    determine what has changed, then calls for an DestroyAction
    to alter the state accordingly
  */
  const handleDestroyRequest = removedRecord => {
    if (removedRecord.modelName === 'Movements::VehicleRun') {
      const destroyRunAction = new DestroyAction(setRuns, runs, removedRecord);
      destroyRunAction.execute();
      setStack([...stack, destroyRunAction]);
    }
    if (removedRecord.modelName === 'Jobs::Booking') {
      const destroyBookingAction = new DestroyAction(setJobs, jobs, removedRecord);
      destroyBookingAction.execute();
      setStack([...stack, destroyBookingAction]);
    }
  };

  /* Handles all creation requests
    Essentially checks the model name for the created record to
    determine what has changed, then calls for an CreateAction
    to alter the state accordingly
  */
  const handleCreateRequest = newRecord => {
    if (newRecord.modelName === 'Movements::VehicleRun') {
      if (newRecord.dateOfUse !== form.startDate) return; //doesn't add the run if it isn't for current filter
      const createRunAction = new CreateAction(setRuns, runs, newRecord);
      createRunAction.execute();
      setStack([...stack, createRunAction]);
    }
    if (newRecord.modelName === 'Jobs::Booking') {
      const createBookingAction = new CreateAction(setJobs, jobs, newRecord);
      createBookingAction.execute();
      setStack([...stack, createBookingAction]);
    }
  };

  // Handles what happens when jobs are fetched, essentially just sets the list of jobs
  const handleJobSuccess = response => {
    //We could already have this record on the jobs, so filter them out and use the most recent version
    const dupesRemoved = jobs.filter(job => !response.items.find(item => item.id === job.id));
    setJobs(dupesRemoved.concat(response.items));
    setBookingsLoading(false);
  };

  const handleMultipleJobSuccess = response => {
    //map the responses to an array
    const newJobs = response.map(res => res.items).flat();

    //We could already have this record on the jobs, so filter them out and use the most recent version
    const dupesRemoved = jobs.filter(job => !newJobs.find(item => item.id === job.id));
    setJobs(dupesRemoved.concat(newJobs));
    setBookingsLoading(false);
  };

  // Sets the error, could be updated in the future to
  // push to an array for instance that several errors happen at once
  const handleRunError = err => setError(error.concat(err));

  /*
    Handles messages sent through window.postMessage
    checks that it not from this page, has data and that the data is what we expect so we don't
    get any weird functionality

    It then handles certain instances
    helloWorld: a parent window sending a confirmation to its subwindow, so cross-communication can be handled
    dragStart: when an item starts to be dragged. Allows both windows to have the correct Row Removal.
    outBounds: Sent when an item is dropped in "unexpected" part of planner. resets the rows removed and clears dragging info
    droppedInSection: Sent when an item is dropped in some section, enables the handling of errors and to clear any drag information
    modalMove: Handles the moving of the modal between the main and sub window
  */
  const handleMessage = event => {
    if (event.source === window) return;
    if (!event.data) return;
    if (!event.data.type) return;
    const type = event.data.type;
    if (type === 'helloWorld') {
      setParent(event.source);
    }
    if (type === 'dragStart') {
      setRowRemovalAction(
        new DestroyMultipleAction(setJobs, event.data.oldState, event.data.removed),
      );
    }
    if (type === 'outBounds') {
      if (rowRemovalAction) rowRemovalAction.undoAll();

      setCurrentDraggingJobs([]);
      setHighlighted([]);
      const portal = document.getElementById('portal-root');
      portal.className = '';
      portal.innerHTML = '';
    }
    if (type === 'droppedInSection') {
      const failedUpdates = event.data.failedUpdates;
      if (failedUpdates.length > 0) {
        setError(event.data.moveErrors);
        if (rowRemovalAction) rowRemovalAction.undo(failedUpdates);
      }
      setCurrentDraggingJobs([]);
      setHighlighted([]);
    }
    if (type === 'modalMove') {
      if (event.data.variant === 'new')
        return setNewVehicleRunModal({ open: true, currentForm: event.data.currentForm });
      if (event.data.variant === 'edit')
        return setEditVehicleRunModal({ id: event.data.id, currentForm: event.data.currentForm });
    }
  };

  useEffect(() => {
    document.title = 'Vehicle Run Planner';
    //checks for search
    if (location.search) {
      const search = new URLSearchParams(location.search);
      //check date validity
      if (Date.parse(search.get('date')))
        handleForm({ ...form, startDate: search.get('date'), endDate: search.get('date') }); //sets date to passed value
    }

    SystemSetting.show().then(res => setFsLocation(res.freightsoftEntity?.mainAddress?.id));
    Vehicle.all().then(res => setVehicles(res.items));

    VehicleRunHelper.handleArchivedView(form, setShowArchived);

    //event listeners for handling messages
    window.addEventListener('message', e => handleMessage(e));
    return window.removeEventListener('message', e => handleMessage(e));
  }, []);

  //Handles the change of the date filter
  useEffect(() => {
    if (!isMounted) return; //dont do anything if not mounted
    setOpenAll(false);
    const search = new URLSearchParams(location.search); //checks for date
    search.delete('date'); //remove it because we will be changing it
    if (form.startDate || form.startDate !== DateHelper.today()) {
      navigate({ search: `date=${form.startDate}&${search.toString()}` }); //update location for refresh
    }
    reducer({ type: 'update_vehicle_runs' }); //fetch vehicle runs
  }, [form.startDate]);

  useEffect(() => {
    setHighlighted([]);
  }, [form]);

  const handleDelete = () => {
    VehicleRun.destroy(deleteVehicleRunModal.id).then(
      () => setDeleteVehicleRunModal(),
      err => {
        setError(error.concat(formatError(err)));
        setDeleteVehicleRunModal();
      },
    );
  };

  const handleOpenAll = () => {
    setOpenAll(true);

    handleOpenRuns(availableRuns);
    Promise.all(availableRuns.map(run => Booking.all({ vehicleRunId: run }))).then(
      handleMultipleJobSuccess,
    );
  };

  const handleCloseAll = () => {
    clearOpenRuns();
    setOpenAll(false);
  };

  /*
    When we start dragging, we set the currentDraggingJobs to the list of jobs being moved.
    This reacts to that, creating a delete and removing it locally from the list of jobs.
    This is then stored so we can revert it later if needed.
  */
  useEffect(() => {
    if (!isMounted || currentDraggingJobs.length === 0) return setRowRemovalAction();

    const action = new DestroyMultipleAction(setJobs, jobs, currentDraggingJobs);
    action.execute();

    //if theres a parent/subwindow send it a message
    if (subwindow) {
      subwindow.postMessage({
        type: 'dragStart',
        oldState: action.oldState,
        removed: currentDraggingJobs,
      });
    }
    if (parent) {
      parent.postMessage({
        type: 'dragStart',
        oldState: action.oldState,
        removed: currentDraggingJobs,
      });
    }

    setRowRemovalAction(action); //update state with set of actions
  }, [currentDraggingJobs]);

  const isSub = () => new URLSearchParams(location.search).get('mode') === 'unassigned';

  const handleSubwindow = () => {
    if (subwindow) {
      subwindow.close();
      setSubwindow();
    } else {
      const features = 'resizable,scrollbars,width=1910';
      const newSubwindow = window.open(
        `${location.pathname}?mode=unassigned`,
        'unassigned',
        features,
      );
      setSubwindow(newSubwindow);

      //time out to ensure that the page is ready to handle message
      // sends a message to the subwindow to enable communication
      setTimeout(function () {
        newSubwindow.postMessage({ type: 'helloWorld' });
      }, 2000);
    }
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  /*
    Handles if we drop jobs outside a valid area of the page.
    essentially all it does is undo the local deletions, making the items re-appear in their
    original position, removes the highlight from those jobs (indicating that job would be dragged)
    and finally clears the portal-root, which we use to start the dragImage element as the browser requires it
    to currently be in the DOM.
  */
  const handleOutOfBoundsDrop = e => {
    e.preventDefault();
    e.stopPropagation();

    //Undoes the local deletion, making the jobs appear back in original pos
    if (rowRemovalAction) rowRemovalAction.undoAll();

    //sends a message to sub/parent if required
    if (subwindow) {
      subwindow.postMessage({ type: 'outBounds' });
    }

    if (parent) {
      parent.postMessage({ type: 'outBounds' });
    }

    setCurrentDraggingJobs([]);
    setHighlighted([]);
    const portal = document.getElementById('portal-root');
    portal.className = '';
    portal.innerHTML = '';
  };

  /*
    Handles the closing of vehicle run sections, Would usually just toggle if its in the array
    but we have to handle the set of selected jobs so that a user cannot drag something that is no longer
    present on the DOM
  */
  const handleVehicleRunToggle = runId => {
    if (open) setHighlighted([]);
    toggleOpenRuns(runId);
  };

  const checkDeliveryType = (job, deliveryType) => {
    if (!deliveryType?.type) return true;
    return (
      deliveryType.type ===
      VehicleRunHelper.getJobDirection(
        fsLocation,
        job.collectionAddress?.id,
        job.deliveryAddress?.id,
      )
    );
  };

  /*
    Function passed to filter pending jobs for display.

    Checks that a job exits, that it has the right status,
    that it is not subcontracted and not part of a vehicle run
    then finally checked that it matches the current filter for delivery type (COL/DEL/XT/ALL)
  */
  const pendingJobsFilter = job => {
    return (
      job &&
      job.status === 'pending' &&
      !job.subcontracted &&
      !job.vehicleRunId &&
      checkDeliveryType(job, form.deliveryTypePending)
    );
  };

  /*
    Function passed to filter unassigned jobs for display.

    Checks that a job exits, that it has the right status,
    that it is not subcontracted and not part of a vehicle run
    then finally checked that it matches the current filter for delivery type (COL/DEL/XT/ALL)
  */
  const unassignedJobsFilter = job => {
    return (
      job &&
      job.status === 'active' &&
      !job.subcontracted &&
      !job.vehicleRunId &&
      checkDeliveryType(job, form.deliveryTypeUnassigned)
    );
  };

  const renderVehicleRuns = run => {
    if (form.subcontracted !== null && run.subcontracted !== form.subcontracted) return;
    if (!form.status.includes(run.status)) return;

    return (
      <VehicleRunCollapsibleTable
        key={run.id}
        fsId={fsLocation}
        runInfo={run}
        hasWarnings={true}
        handleNoteModal={setNoteModal}
        handleEdit={() => setEditVehicleRunModal({ id: run.id })}
        handleDelete={() => setDeleteVehicleRunModal(run)}
        data={jobs.filter(item => item?.vehicleRunId === run.id)}
        open={openRuns.includes(run.id)}
        toggleOpen={() => handleVehicleRunToggle(run.id)}
        action={
          new GetAction(Booking.all, setJobs, handleJobSuccess, handleRunError, {
            vehicleRunId: run.id,
          })
        }
        setError={setError}
        error={error}
        vehicles={vehicles}
        setJobs={setJobs}
        jobs={jobs}
        bookingsLoading={bookingsLoading}
        setCurrentDraggingJobs={setCurrentDraggingJobs}
        rowRemovalAction={rowRemovalAction}
        highlighted={highlighted}
        setHighlighted={setHighlighted}
        allOpen={openAll}
        subwindow={subwindow}
        parent={parent}
      />
    );
  };

  return (
    <div
      ref={isMounted}
      className={isSub() ? 'wrap sub' : 'wrap'}
      onDrop={handleOutOfBoundsDrop}
      onDragOver={handleDrag}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
    >
      {noteModal && (
        <ShowJobNotes
          booking={noteModal}
          user={user}
          permissions={permissions}
          handleClose={() => setNoteModal()}
        />
      )}
      {newVehicleRunModal && (
        <NewVehicleRun
          handleClose={() => setNewVehicleRunModal(false)}
          currentFilterDate={form.startDate}
          subwindow={subwindow}
          parent={parent}
          currentFormContent={newVehicleRunModal?.currentForm}
          showMileage={false}
          showArchived={{ drivers: false, vehicles: false }}
        />
      )}
      {editVehicleRunModal && (
        <EditVehicleRun
          vehicleId={editVehicleRunModal.id}
          handleClose={() => setEditVehicleRunModal()}
          subwindow={subwindow}
          parent={parent}
          currentFormContent={editVehicleRunModal?.currentForm}
          showArchived={showArchived}
        />
      )}
      {deleteVehicleRunModal && (
        <ConfirmationModal
          isOpen
          question={'Are you sure you want to delete this vehicle run?'}
          subText={deleteVehicleRunModal.vehicle?.reference}
          handleCancel={() => setDeleteVehicleRunModal()}
          handleConfirm={handleDelete}
          destructive
        />
      )}
      <Toast messages={error} setMessages={setError} />
      <VehicleRunHeader
        disconnected={disconnected}
        openNewVehicleModal={() => setNewVehicleRunModal(true)}
        allOpen={JSON.stringify(openRuns) === JSON.stringify(availableRuns)}
        closeAll={handleCloseAll}
        openAll={handleOpenAll}
        form={form}
        alter={alter}
        handleForm={handleForm}
        subwindow={subwindow}
        toggleSubwindow={handleSubwindow}
        isSub={isSub()}
        reconnect={reconnect}
        loading={loading}
        xlsxLoading={xlsxLoading}
        handleXlsxClick={generateXlsx}
        handleGenerateRuns={handleGenerateRuns}
        runsGenerating={runsGenerating}
        generateRunsDisabled={
          vehicles.length === 0 || vehicles.length === runs.filter(run => !run.subcontracted).length
        }
      />
      <div className="main-container">
        <div className="content-container">
          <div className="content">
            {!isSub() &&
              (runsLoading ? (
                <div className="run-loading">
                  <LoadingSpinner />
                </div>
              ) : (
                search(form.filter, runs, ['vehicle.reference', 'subcontractReference']) //apply search
                  .filter(run => run.dateOfUse === form.startDate) //check its current date runs
                  .sort((a, b) => a.id - b.id) //sort into order
                  .map(renderVehicleRuns) //render
              ))}
            {!subwindow && (
              <>
                <SectionCollapsibleTable
                  title="Unassigned"
                  fsId={fsLocation}
                  data={jobs.filter(unassignedJobsFilter)}
                  colours={{
                    icon: 'var(--blue-1)',
                    header: 'var(--dark-blue)',
                    table: 'var(--main-blue)',
                    tableText: 'var(--white)',
                    footer: 'var(--main-blue)',
                  }}
                  defaultOpen
                  vehicles={vehicles}
                  setJobs={setJobs}
                  jobs={jobs}
                  setError={setError}
                  bookingsLoading={bookingsLoading}
                  setCurrentDraggingJobs={setCurrentDraggingJobs}
                  rowRemovalAction={rowRemovalAction}
                  error={error}
                  action={
                    new GetAction(Booking.all, setJobs, handleJobSuccess, handleRunError, {
                      status: 'active',
                      subcontracted: null,
                      vehicleRunId: null,
                    })
                  }
                  highlighted={highlighted}
                  setHighlighted={setHighlighted}
                  deliveryFilter={form.deliveryTypeUnassigned}
                  alter={alter}
                  subwindow={subwindow}
                  parent={parent}
                  handleNoteModal={setNoteModal}
                />
                <SectionCollapsibleTable
                  title="Pending"
                  data={jobs.filter(pendingJobsFilter)}
                  fsId={fsLocation}
                  colours={{
                    icon: 'var(--blue-4)',
                    header: 'var(--blue-2)',
                    table: 'var(--blue-3)',
                    tableText: 'var(--white)',
                    footer: 'var(--blue-3)',
                  }}
                  vehicles={vehicles}
                  setError={setError}
                  error={error}
                  setJobs={setJobs}
                  jobs={jobs}
                  bookingsLoading={bookingsLoading}
                  setCurrentDraggingJobs={setCurrentDraggingJobs}
                  rowRemovalAction={rowRemovalAction}
                  action={
                    new GetAction(Booking.all, setJobs, handleJobSuccess, handleRunError, {
                      status: 'pending',
                      subcontracted: null,
                      vehicleRunId: null,
                    })
                  }
                  highlighted={highlighted}
                  setHighlighted={setHighlighted}
                  deliveryFilter={form.deliveryTypePending}
                  alter={alter}
                  subwindow={subwindow}
                  parent={parent}
                  handleNoteModal={setNoteModal}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

VehicleRunPlanner.propTypes = {
  user: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default VehicleRunPlanner;
