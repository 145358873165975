import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CollapsibleTable from '../../../../../components/CollapsibleTable/CollapsibleTable';
import { faMousePointer, faTruck, faWarning } from '@fortawesome/free-solid-svg-icons';
import IconDropdown from '../../../../../common/button/IconDropdown/IconDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VehicleRunRow from '../VehicleRunRow/VehicleRunRow';
import { humanize } from '../../../../../utils/EnumHelper/EnumHelper';
import './VehicleRunCollapsibleTable.scss';
import DateHelper from '../../../../../utils/DateHelper/DateHelper';
import Dropzone from '../Dropzone/Dropzone';
import VehicleRunStatus from './VehicleRunStatus/VehicleRunStatus';
import Pdf from '../../../../../api/controllers/File/Pdf/Pdf';
import { formatError } from '../../../../../utils/Formatters/Errors/formatError';
import Item from '../../../../../api/controllers/File/Item/Item';
import DownloadHelper from '../../../../../utils/DownloadHelper/DownloadHelper';
import VehicleRunWarningsHelper from '../../../../../utils/VehicleRunWarningsHelper/VehicleRunWarningsHelper';
import { vehicleRunHeaders as headers } from '../../../../../strings/arrays/VehicleRun/vehicleRunHeaders';
import DestroyMultipleAction from '../../actions/DestroyMultipleAction/DestroyMultipleAction';
import VehicleRunFooter from './VehicleRunFooter/VehicleRunFooter';
import { Link } from 'react-router-dom';

/*
    Why? Draws the dropdown actions to view the driver sheet of, edit and delete a VehicleRun
  */
const VehicleRunCollapsibleTable = ({
  runInfo,
  fsId,
  data, //the list of jobs for the run
  open,
  toggleOpen,
  handleNoteModal,
  handleEdit,
  handleDelete,
  setError,
  vehicles = [],
  action,
  bookingsLoading, //whether we are currently fetching the list of jobs
  jobs = [],
  setJobs = () => {},
  setCurrentDraggingJobs,
  rowRemovalAction,
  highlighted,
  error,
  setHighlighted,
  allOpen = false,
  parent,
  subwindow,
}) => {
  const drawRow = (item, idx) => (
    <VehicleRunRow
      key={item.id}
      job={item}
      fsId={fsId}
      runInfo={runInfo}
      jobsIndex={idx}
      setError={setError}
      error={error}
      vehicles={vehicles}
      setCurrentDraggingJobs={setCurrentDraggingJobs}
      highlighted={highlighted}
      setHighlighted={setHighlighted}
      handleNoteModal={handleNoteModal}
    />
  );

  //This decides if we need to get the list of jobs for this run
  // if its not open or the jobs (Data) length and runinfo length are the same, that means we should be
  //safe not to fetch
  useEffect(() => {
    if (!open || data.length === runInfo.bookings.length || allOpen) return;
    action.execute();
  }, [open]);

  const handleError = err => setError(error.concat(formatError(err)));

  const handleSheet = () =>
    Pdf.generate({ kind: 'driver_running_sheet', id: runInfo.id }).then(
      pdfRes =>
        Item.download(pdfRes.id).then(
          item => DownloadHelper.saveFile(pdfRes.fileName, item),
          handleError,
        ),
      handleError,
    );

  //displays the dropdown and warning icon if there is any
  const drawActions = () => {
    const warnings = VehicleRunWarningsHelper.getWarnings(runInfo);

    return (
      <>
        <div className="vehicle-info-status">
          {runInfo.subcontracted && <div className={'subcontracted'}>Subcontracted</div>}
          <VehicleRunStatus runInfo={runInfo} setToast={setError} />
        </div>
        {warnings.length > 0 && (
          <div className="hover-cell warning-cell">
            <div className="c-table-header-icon warning-icon">
              <FontAwesomeIcon icon={faWarning} />
            </div>
            <div className="hover-cell-info cube-info warning">
              <div className="top">Warning</div>
              <div className="main">
                {warnings.map((warning, idx) => (
                  <div key={idx} className="warning-info">
                    {warning}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {warnings.length === 0 && <div className="c-table-header-icon no-icon"></div>}
        <Link
          className="c-table-header-icon run-icon"
          to={`/movements/runs/${runInfo.id}`}
          target="_blank"
        >
          VR
        </Link>
        <IconDropdown
          text={'Actions'}
          icon={faMousePointer}
          action={'click'}
          iconOnly
          testId="actions-dropdown"
        >
          <button onClick={handleSheet}>Driver Running Sheet</button>
          <hr />
          <button onClick={handleEdit}>Edit Vehicle Run</button>
          <button onClick={handleDelete}>Delete Vehicle Run</button>
        </IconDropdown>
      </>
    );
  };

  //The hover details explaining the vehicle information
  const renderVehicleDetails = vehicle => (
    <div className="hover-cell-info cube-info">
      <div className="top">Vehicle Details {vehicle?.kind && `(${humanize(vehicle?.kind)})`}</div>
      <div className="main">
        <div style={{ marginBottom: '6px' }}>
          <strong>Capacity:</strong> {vehicle?.weightLimitKg && `${vehicle?.weightLimitKg}kg`} (
          {vehicle?.lengthCm || '---'} × {vehicle?.widthCm || '---'} × {vehicle?.heightCm || '---'}
          cm)
        </div>
      </div>
    </div>
  );

  /*
    Why? Displays the vehicle details, whether its subcontracted and its current status for the header
    Notes? The logic in this function is very messy, could use a bit of spring cleaning
  */
  const drawInfo = runInfo => (
    <div className="c-table-vehicle-info">
      <div className="vehicle-info-main">
        <div
          className={`hover-cell${runInfo.subcontracted ? ' subcontracted' : ''}`}
          title={runInfo.subcontracted ? 'No vehicle information available (Subcontracted)' : ''}
        >
          {runInfo.subcontracted &&
            runInfo.subcontractReference &&
            `${runInfo.subcontractReference} - `}
          {runInfo.subcontracted && !runInfo.subcontractReference && <div>??? - </div>}
          {!runInfo.subcontracted && !runInfo.vehicle && <div>??? - </div>}
          {!runInfo.subcontracted &&
            runInfo.vehicle?.reference &&
            `${runInfo.vehicle?.reference} - `}
          {!runInfo.subcontracted && renderVehicleDetails(runInfo.vehicle)}
        </div>
        <div>{DateHelper.dateOnly(runInfo.dateOfUse)}</div>
        <div>
          {!runInfo.subcontracted
            ? (runInfo.driver &&
                `- ${humanize(runInfo.driver?.firstName)} ${humanize(runInfo.driver?.lastName)}`) ||
              '- ???'
            : (runInfo.subcontractor &&
                `- ${runInfo.subcontractor?.shortName || runInfo.subcontractor?.internalName}`) ||
              '- ???'}
        </div>
      </div>
    </div>
  );

  return (
    <Dropzone
      section={runInfo.id}
      setError={setError}
      set={setJobs}
      current={jobs}
      rowRemovalAction={rowRemovalAction}
      setCurrentDraggingJobs={setCurrentDraggingJobs}
      setHighlighted={setHighlighted}
      error={error}
      subwindow={subwindow}
      parent={parent}
    >
      <CollapsibleTable
        title={drawInfo(runInfo)}
        icon={faTruck}
        headers={headers}
        rows={data}
        onDrawRow={drawRow}
        colours={{
          icon: 'var(--grey-3)',
          header: 'var(--text)',
          table: 'var(--grey-1)',
          tableText: 'var(--text)',
          footer: 'var(--collapsible-footer)',
        }}
        footer={
          <VehicleRunFooter
            jobCount={runInfo.bookings.length || 0}
            bookings={runInfo.bookings}
            fsId={fsId}
          />
        }
        actions={drawActions()}
        open={open}
        setOpen={toggleOpen}
        defaultSort={{ sort: headers.job.sortingAttribute, order: 'asc' }}
        loading={bookingsLoading}
      />
    </Dropzone>
  );
};

VehicleRunCollapsibleTable.propTypes = {
  title: PropTypes.string,
  runInfo: PropTypes.object,
  rows: PropTypes.arrayOf(PropTypes.object),
  colours: PropTypes.object,
  actions: PropTypes.element,
  data: PropTypes.arrayOf(PropTypes.object),
  fsId: PropTypes.number,
  handleNoteModal: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  open: PropTypes.bool,
  toggleOpen: PropTypes.func,
  setError: PropTypes.func.isRequired,
  bookingsLoading: PropTypes.bool.isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.object),
  setJobs: PropTypes.func,
  setCurrentDraggingJobs: PropTypes.func.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.object),
  rowRemovalAction: PropTypes.instanceOf(DestroyMultipleAction),
  action: PropTypes.object.isRequired,
  highlighted: PropTypes.arrayOf(PropTypes.object).isRequired,
  setHighlighted: PropTypes.func.isRequired,
  error: PropTypes.array,
  allOpen: PropTypes.bool,
  parent: PropTypes.object,
  subwindow: PropTypes.object,
};

export default VehicleRunCollapsibleTable;
