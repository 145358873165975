import React from 'react';
import '../../../common/DocumentShowTable/DocumentShowTable.scss';
import PropTypes from 'prop-types';
import NumberHelper from '../../../utils/NumberHelper/NumberHelper';
import Checkbox from '../../Checkbox/Checkbox';
import LineItemHelper from '../../../utils/LineItemHelper/LineItemHelper';

const LineItemTable = ({ data, editable, form, invoice, onlyJobs = false }) => {
  return (
    <table border="1" frame="void" rules="rows">
      <thead>
        <tr>
          {onlyJobs ? <th>Job</th> : <th>Job / Vehicle Run</th>}
          <th>Code</th>
          <th>Description</th>
          <th>TC</th>
          <th>Amount</th>
          {editable && (
            <th className={'edit-head'}>Include in {invoice ? 'Invoice' : 'Credit Note'}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map(li => (
          <tr key={li.id} className={'show-table-row'} data-testid={'bsg-line-item'}>
            <td className={'small'}>{LineItemHelper.renderSourceLine(li.parent)}</td>
            <td className={'small'}>{li.chargeCode.code}</td>
            <td className={'medium'}>{li.description}</td>
            <td className={'small'}>{li.taxCode.code}</td>
            <td className={'small'}>{NumberHelper.formatNumToCurrency(li.value)}</td>
            {editable && (
              <td className={'small'}>
                <Checkbox
                  checked={
                    form?.lineItemsAttributes.filter(item => item.id === li.id && !item._remove)
                      .length > 0
                  }
                  onClick={() => editable(li.id)}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

LineItemTable.propTypes = {
  data: PropTypes.array,
  editable: PropTypes.func,
  form: PropTypes.object,
  invoice: PropTypes.bool,
  onlyJobs: PropTypes.bool,
};

export default LineItemTable;
