import apiCall from '../../../apiCall/apiCall';

class Pdf {
  // params = { kind: string, id: integer }
  static generate(params) {
    return apiCall('POST', 'files/pdfs/generate', params);
  }
}

export default Pdf;
