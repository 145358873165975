import React from 'react';
import PropTypes from 'prop-types';
import './ShowField.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

// Wraps a list of fields within a particular form section.
const ShowField = ({ value, label, className }) => {
  let el = <input className="show-field-value" value={value} readOnly={true} tabIndex="-1" />;

  // Display boolean values differently:
  if (value === true) {
    el = (
      <span className="show-field-true">
        <FontAwesomeIcon icon={faCheck} />
      </span>
    );
  } else if (value === false) {
    el = (
      <span className="show-field-false">
        <FontAwesomeIcon icon={faTimes} />
      </span>
    );
  }

  return (
    <label className={`show-field ${className}`} data-testid={'show-field'}>
      <span className="show-field-label">{label}</span>
      {el}
    </label>
  );
};

ShowField.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default ShowField;
