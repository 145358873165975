import { useState } from 'react';
import PropTypes from 'prop-types';

const useArray = ({ defaultValue = [], name = 'array' }) => {
  const [array, setArray] = useState(defaultValue);

  const capitalName = `${name.slice(0, 1).toUpperCase()}${name.slice(1)}`;
  const actionName = action => (name === 'array' ? action : `${action}${capitalName}`);

  const clear = () => setArray([]);
  const reset = () => setArray(defaultValue);
  const remove = id => setArray(array.filter(folder => folder !== id));
  const removeIdx = idx =>
    setArray(array.map((entity, i) => i !== idx && entity).filter(entity => entity !== false));
  const replace = (idx, val) => setArray(array.map((entity, i) => (i === idx ? val : entity)));
  const add = id => setArray([...array, id]);
  const toggle = id => (array.includes(id) ? remove(id) : add(id));
  const set = id => setArray([id]);
  const clearType = (type, exception) =>
    setArray(array.filter(folder => typeof folder !== type || folder === exception));
  const swap = (idOne, idTwo) => {
    const valueStore = array[idOne]; // Store the first value
    const newArray = [...array]; // Clone the array to ensure no mutation
    newArray[idOne] = array[idTwo]; // Set the first value to the second
    newArray[idTwo] = valueStore; // Set the second value to the first
    setArray(newArray); // Set the state
  };

  return {
    [name]: array,
    [`handle${capitalName}`]: setArray,
    [actionName('remove')]: remove,
    [actionName('removeIdx')]: removeIdx,
    [actionName('add')]: add,
    [actionName('replace')]: replace,
    [actionName('toggle')]: toggle,
    [actionName('clear')]: clear,
    [actionName('set')]: set,
    [actionName('clearType')]: clearType,
    [actionName('reset')]: reset,
    [actionName('swap')]: swap,
  };
};

useArray.propTypes = {
  defaultValue: PropTypes.array,
  name: PropTypes.string,
};

export default useArray;
