import React from 'react';
import './DocumentShowGeneral.scss';
import PropTypes from 'prop-types';
import FormSection from '../FormSection/FormSection';
import AddressHelper from '../../utils/AddressHelper/AddressHelper';
import NumberHelper from '../../utils/NumberHelper/NumberHelper';
import { Link } from 'react-router-dom';
import DocumentShowTable from '../../common/DocumentShowTable/DocumentShowTable';
import DateHelper from '../../utils/DateHelper/DateHelper';

const DocumentShowGeneral = ({ data, documentDetails }) => {
  document.title = data.documentReference;
  return (
    <div className={'show-tab-content'}>
      <FormSection title={`${documentDetails.title} for ${data.entity.internalName}`}>
        <div className={'container'}>
          <div className={'company-details'}>
            <span className={'sm-detail'}>To</span>
            <div className={'company-name'}>
              <Link to={`/companies/entities/${data.entity.id}`} target="_blank">
                {data.entity.internalName}
              </Link>
            </div>
            <span className={'sm-detail'}>{data.entity.internalName}</span>
            {data?.entity?.invoicingAddress && (
              <span className={'sm-detail'}>
                Via{' '}
                {AddressHelper.formatAddress(
                  AddressHelper.extractAddressToArray(data.entity.invoicingAddress),
                )}
              </span>
            )}
          </div>
          <div className={'details-container'}>
            <div className={'detail'}>
              <div className={'sm-detail'}>{documentDetails.type}</div>
              <div className={'bg-detail'}>{data.documentReference}</div>
            </div>
            {data.entityReference && (
              <div className={'detail'}>
                <div className={'sm-detail'}>Their reference</div>
                <div className={'bg-detail'}>{data.entityReference}</div>
              </div>
            )}
            <div className={'detail'}>
              <div className={'sm-detail'}>Issue Date</div>
              {data.issueDate && (
                <div className={'bg-detail'}>{DateHelper.dateOnly(data.issueDate)}</div>
              )}
            </div>
            <div className={'detail'}>
              <div className={'sm-detail'}>Due Date</div>
              {data.dueDate && (
                <div className={'bg-detail'}>{DateHelper.dateOnly(data.dueDate)}</div>
              )}
            </div>
            <div className={'detail amount'}>
              <div className={'sm-detail'}>Amount</div>
              <div className={'bg-detail'}>
                {NumberHelper.formatNumToCurrency(NumberHelper.calTotal(data.lineItems))}
              </div>
            </div>
          </div>
          <DocumentShowTable data={data} onlyJobs={documentDetails.title.includes('Sale')} />
        </div>
      </FormSection>
    </div>
  );
};

DocumentShowGeneral.propTypes = {
  data: PropTypes.object,
  documentDetails: PropTypes.object,
};

export default DocumentShowGeneral;
