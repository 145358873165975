class TreeView {
  constructor() {
    this.placeholders = [];
    this.subModel = {};
    this.model = null;
  }
  create(model, data) {
    this.model = model;
    this.placeholders = data
      .filter(el => el.indexOf('__') === -1 && el)
      .filter((v, i, a) => a.indexOf(v) === i);

    let subModels = data.filter(el => el.indexOf('__') !== -1 && el);
    if (subModels.length === 0) return;

    let subModelNames = subModels.map(el => el.substring(0, el.indexOf('__')));
    let subModelNamesSet = new Set(subModelNames);

    for (let subModelName of subModelNamesSet) {
      let subModelData = subModels.filter(el =>
        el.substring(0, el.indexOf('__')).includes(subModelName),
      );
      let subModelTree = new TreeView();
      subModelTree.create(
        subModelName,
        subModelData.map(el => el.substring(el.indexOf('__') + 2, el.length)),
      );
      this.subModel[subModelName] = subModelTree;
    }
  }
}
export default TreeView;
