// An api call specifically for postcodes.io
const postcodeCall = (verb, path, data) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const server = 'https://postcodes.io';
    let url = [server, path].join('/');

    if (data instanceof URLSearchParams) url = [url, data].join('?');
    xhr.open(verb, url, true);

    if (!data || data instanceof URLSearchParams) {
      xhr.send(); // No request body; the params (if any) are part of the URL.
    } else if (data instanceof FormData) {
      xhr.send(data); // Binary file uploads (such as avatar images) use this.
    } else {
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(data)); // Unlike FormData, this is simply text.
    }

    xhr.onload = () => {
      const json = JSON.parse(xhr.responseText || 'null');
      if (xhr.status < 200 || xhr.status >= 300) reject(json);
      resolve(json);
    };
  });

export default postcodeCall;
