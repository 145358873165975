import React from 'react';
import PropTypes from 'prop-types';
import './LineTitle.scss';

const LineTitle = ({ text }) => (
  <div className={'line-title'}>
    <span>{text}</span>
  </div>
);

LineTitle.propTypes = {
  text: PropTypes.string,
};

export default LineTitle;
