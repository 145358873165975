import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal/Modal';
import FormSection from '../../FormSection/FormSection';
import './EditContactAlt.scss';
import Message from '../../../common/layout/Message/Message';
import ContactForm from '../../forms/ContactForm/ContactForm';
import Contact from '../../../api/controllers/Company/Contact/Contact';
import { formatError } from '../../../utils/Formatters/Errors/formatError';

// Why? Created for the EntityShow screen Contact tab, allows the user to edit Contact's
const EditContactAlt = ({ handleClose, contact }) => {
  const [submitting, setSubmitting] = useState(false);
  const [messages, setMessages] = useState({});

  const handleSubmit = (e, form) => {
    e.preventDefault();
    setSubmitting(true);
    Contact.update(contact.id, form)
      .then(handleClose, err => setMessages(formatError(err)))
      .finally(setSubmitting(false));
  };

  const formatDefaultForm = () => ({
    ...contact,
    contactMethodsAttributes: contact.contactMethods,
    emailInclusionsAttributes: contact.emailInclusions?.map(ei => ({
      ...ei,
      templateTypeId: ei.templateType.id,
    })),
  });

  return (
    <Modal isOpen handleClose={handleClose}>
      <FormSection title={'Update Contact Details'} draggable>
        <Message
          visible={!!messages?.errors}
          text={messages.errors}
          onClick={() => setMessages([])}
          type={'error'}
        />
        <ContactForm
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          defaultForm={formatDefaultForm()}
          submitting={submitting}
        />
      </FormSection>
    </Modal>
  );
};

EditContactAlt.propTypes = {
  handleClose: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
};

export default EditContactAlt;
