import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:3000/websocket';
const PROTOCOLS = ['actioncable-v1-json', 'actioncable-unsupported'];

// This hook should subscribe and safely unsubscribe from an ActiveCable channel
// The next step for this would be to provide a reducer prop that will be passed any events recieved
const useWebSocket = ({
  url = WEBSOCKET_URL,
  protocols = PROTOCOLS,
  channel,
  reducer,
  dependencies = [],
}) => {
  const [latestRequest, setLatestRequest] = useState();
  const [loading, setLoading] = useState(false);
  const ws = useRef(null);

  const connect = () => {
    // The below could probably work inside apiCall.js, in-fact it should be in there
    // This eliminates the possibility of getting an out of date jwt mixed up here
    // It also removes the localStorage token call as we could just get the token from the request
    const token = localStorage.getItem('token');

    // One thing to note, the path=/ is required, without it the cookie scopes itself to a particular path
    document.cookie = `WSAUTH=${token};SameSite=Lax;path=/;domain=${
      process.env.REACT_APP_DOMAIN || '127.0.0.1'
    }`;

    ws.current = new WebSocket(url, protocols);

    // This is our subscription to tell the backend "we're open please send stuff"
    ws.current.onopen = () =>
      ws.current.send(
        JSON.stringify({ command: 'subscribe', identifier: JSON.stringify({ channel }) }),
      );
  };

  const reconnect = () => {
    setLoading(true);
    connect();

    ws.current.onmessage = e => {
      const request = JSON.parse(e.data);
      reducer(request);
      setLatestRequest(request);
    };

    setLoading(false);
  };

  useEffect(() => {
    connect();
    // When the hook unmounts we should close the websocket connection
    return () => ws.current.close();
  }, []);

  // This useEffect handles all the messages coming our way
  // If there is issues with state, make sure you're not mutating it directly / clone the object you're providing
  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = e => {
      const request = JSON.parse(e.data);
      reducer(request);
      setLatestRequest(request);
    };

    ws.current.onclose = e => {
      reducer(e);
    };
  }, [latestRequest, ...dependencies]);

  return { latestRequest, reconnect, loading };
};

useWebSocket.propTypes = {
  url: PropTypes.string,
  protocols: PropTypes.arrayOf(PropTypes.string),
  channel: PropTypes.string,
  reducer: PropTypes.func.isRequired,
  dependencies: PropTypes.array,
};

export default useWebSocket;
