import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './FormSection.scss';

// Wraps a list of fields within a particular form section.
const FormSection = ({ title, children, optionalElement, extraClassName, draggable = false }) => {
  const nodeRef = useRef();

  return (
    <div className="form-section" ref={nodeRef}>
      <div id="form-section-head" className={`form-section-head${draggable ? ' draggable' : ''}`}>
        <div>{title}</div>
        {optionalElement && <div className="optionalElement">{optionalElement}</div>}
      </div>
      <div className={`form-section-body ${extraClassName}`}>{children}</div>
    </div>
  );
};

FormSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  optionalElement: PropTypes.node,
  extraClassName: PropTypes.string,
  draggable: PropTypes.bool,
};

export default FormSection;
