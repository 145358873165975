import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../components/FormSection/FormSection';
import ShowField from '../../../../../../components/ShowField/ShowField';
import FieldList from '../../../../../../components/FieldList/FieldList';
import FormField from '../../../../../../components/FormField/FormField';
import { labelForEnum } from '../../../../../../utils/EnumHelper/EnumHelper';
import LinkShow from '../../../../../../common/LinkShow/LinkShow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { bulkInvoicingValues, entityStatuses } from '../../../../../../strings/enums';

/*
  This component simply displays read only content for EntityShow

  This was originally included in the Entity Show component but was 
  moved for code cleanliness 
*/
const GeneralTab = ({ data }) => (
  <div className="tab-content">
    <FormSection
      title="Entity Details"
      optionalElement={<ShowField value={data.allowAutolining} label={'Allow Autolining'} />}
    >
      <FieldList>
        <FormField label="System Name">
          <input type="text" value={data.internalName || ''} placeholder={'System Name'} readOnly />
        </FormField>
        <FormField label="Business Type">
          <input
            type="text"
            value={data.businessType?.name || ''}
            placeholder={'Business Type'}
            readOnly
          />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label="Display Name">
          <input
            type="text"
            value={data.externalName || ''}
            placeholder={'Display Name'}
            readOnly
          />
        </FormField>
        <FormField label="Short Name">
          <input type="text" value={data.shortName || ''} placeholder={'Short Name'} readOnly />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label="Status">
          <input
            type="text"
            value={labelForEnum(entityStatuses, data.status)}
            placeholder={'Status'}
            readOnly
          />
        </FormField>
        <FormField label="Parent Entity" className={'clickable'}>
          <LinkShow
            text={data.parent?.internalName || ''}
            url={data.parent?.id && `/companies/entities/${data.parent?.id}`}
            placeholder={'Parent Entity'}
            testId={'es-parent-entity'}
          />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label="Company Number">
          <input
            type="text"
            value={data.companyNumber || ''}
            placeholder={'Company Number'}
            readOnly
          />
        </FormField>
        <FormField label="VAT Number">
          <input type="text" value={data.vatNumber || ''} placeholder={'VAT Number'} readOnly />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label="Customer" value={data.customer} className="bool">
          <FontAwesomeIcon icon={data.customer ? faCheck : faTimes} />
        </FormField>
        <FormField label="Customer Account">
          <input
            type="text"
            value={data.customerAccount || ''}
            placeholder={'Customer Account'}
            readOnly
          />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label="Supplier" value={data.supplier} className="bool">
          <FontAwesomeIcon icon={data.supplier ? faCheck : faTimes} />
        </FormField>
        <FormField label="Supplier Account">
          <input
            type="text"
            value={data.supplierAccount || ''}
            placeholder={'Supplier Account'}
            readOnly
          />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label="Forwarder" value={data.forwarder} className="bool">
          <FontAwesomeIcon icon={data.forwarder ? faCheck : faTimes} />
        </FormField>
        {data.customer && (
          <FormField label="Bulk Invoicing">
            <input
              type="text"
              value={labelForEnum(bulkInvoicingValues, data.bulkInvoicing) || 'No'}
              placeholder={'Bulk Invoicing'}
              readOnly
            />
          </FormField>
        )}
      </FieldList>
    </FormSection>
  </div>
);

GeneralTab.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GeneralTab;
