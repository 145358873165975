import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import './NoteForm.scss';
import FormField from '../../FormField/FormField';
import Checkbox from '../../Checkbox/Checkbox';

/*
  This form was added for the creation or editing of a Note

  Design: https://xd.adobe.com/view/9dafebc2-7336-4b78-a621-6616fb06f140-c8fc/screen/9e8d3248-b5d1-44c5-b1d6-cab23f6dcf3b/?x_product=xd-jira%2F2.0
*/
const NoteForm = ({
  form,
  alter,
  handleSubmit,
  handleClose,
  submitting = false,
  submitText = 'Create Note',
}) => (
  <div className={'note-form'}>
    <FormField label="Title">
      <input
        value={form.summary || ''}
        onChange={e => alter('summary', e.target.value)}
        data-testid={'note-form-title'}
      />
    </FormField>
    <FormField label="Content" className="top">
      <textarea
        value={form.details || ''}
        rows={5}
        onChange={e => alter('details', e.target.value)}
        data-testid={'note-form-content'}
      />
    </FormField>
    <div className="note-form-button-section">
      <Checkbox
        label={'Show On PDFs'}
        checked={form.showOnPdfs}
        onClick={() => alter('showOnPdfs', !form.showOnPdfs)}
        reverse
      />
      <IconButton
        text={submitText}
        icon={faCheck}
        onClick={e => handleSubmit(e, form)}
        loading={submitting}
      />
      <IconButton text={'Cancel'} icon={faTimes} className={'edit'} onClick={handleClose} />
    </div>
  </div>
);

NoteForm.propTypes = {
  form: PropTypes.object.isRequired,
  alter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired, // This function will be called upon form submission
  handleClose: PropTypes.func.isRequired, // This function is called when cancel is clicked
  submitting: PropTypes.bool, // This bool handles whether or not the form is in the process of being submitted
  submitText: PropTypes.string, //For display in the submit button, we change between create and update as needed
};

export default NoteForm;
