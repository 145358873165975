import Action from '../Action/Action';

/*
  Takes some api call, the relevant setstate we would be updating and a success and error handler
*/
class GetAction extends Action {
  constructor(call, setState, handleSuccess, handleError, params = {}) {
    super();
    this.call = call;
    this.setState = setState;
    this.handleSuccess = handleSuccess;
    this.handleError = handleError;
    this.params = params;
  }

  //calls the api with the provided params, and passes the result to handleSuccess / Error respectively
  execute() {
    this.call(this.params).then(this.handleSuccess, this.handleError);
  }

  //resets the state back to its former condition before fetch
  undo() {
    this.setState([]);
  }
}

export default GetAction;
