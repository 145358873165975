// I'm fairly certain there's a better way to do this but ¯\_(ツ)_/¯
// This only handles entities that begin at the start of the block but atm that's all we need

import HorizontalRule from '../../subComponents/blocks/HorizontalRule/HorizontalRule';

// Note that to style these element you'll need to add a class to the component supplied and add an entry to the stylemap
const blockRendererFn = (block, editorState) => {
  // So a little background since loose typing sucks, the "block" variable is a ContentBlock
  // This means we have to go on an adventure to try and work out what the hell we're trying to render
  // The way I handle inserting blocks into the editor is through the AtomicUtils, this means every custom block is "atomic" in type
  const type = block.getType();
  // If it's "atomic" i.e. a custom block OR there's a custom block available, we try to render some of our custom react components
  if (type !== 'atomic' || !block.getEntityAt(0)) return;
  // Here we get the entity key, basically it's a GUID attached to a specific entity we created
  const entityKey = block.getEntityAt(0);
  // Here we get the content of the editor which gives us access to all the searcher functions
  const contentState = editorState.getCurrentContent();
  // We search for the entity in state via the entityKey above
  const entity = contentState.getEntity(entityKey);
  // We check the metadata on this entity and return the component we want to render (we can add props to this also)
  const customType = entity.getData().customType;
  if (customType === 'horizontalRule')
    return {
      component: HorizontalRule,
      editable: false,
    };
};

export default blockRendererFn;
