import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class Customer {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'financials/customers', data);
  }

  static show(accountRef) {
    if (!accountRef)
      return new Promise((_, reject) =>
        reject({
          error: 'This entity does not specify a customer account reference',
          status: null,
        }),
      );
    return apiCall('GET', `financials/customers/${accountRef}`);
  }
}

export default Customer;
