import React from 'react';
import PropTypes from 'prop-types';
import Account from '../../api/controllers/Account/Account';
import useForm from '../../common/hooks/useForm/useForm';
import Message from '../../common/layout/Message/Message';
import LineTitle from '../../common/title/LineTitle/LineTitle';
import LoginForm from '../../components/forms/LoginForm/LoginForm';
import './Login.scss';
import logo from '../../images/logo/transswift.png';
import logoDev from '../../images/logo/transswift-development.png';
import { useSelector, connect } from 'react-redux';
import * as actions from '../../actions';
import { useNavigate } from 'react-router';

const Login = ({ onLogin, setUserError }) => {
  const navigate = useNavigate();
  const { alter, form } = useForm({});
  const { userError } = useSelector(state => state.userReducer);

  const submit = async e => {
    e.preventDefault();
    const { email, password } = form;
    Account.login(email, password).then(
      json =>
        onLogin(json).then(res => {
          if (res) setUserError(res);
          else setUserError();
          navigate('/jobs/bookings');
        }),
      json => setUserError(json.error),
    );
  };

  return (
    <div className={'login-page'}>
      <div
        className={`login-content ${process.env.REACT_APP_ENV !== 'production' && 'development'}`}
      >
        <img src={process.env.REACT_APP_ENV === 'production' ? logo : logoDev} />
        <div className={'login-form'}>
          <LineTitle text={'Sign In'} />
          <Message
            type={'error'}
            text={userError}
            onClose={() => setUserError()}
            visible={!!userError}
          />
          <LoginForm onChange={alter} onSubmit={submit} />
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func,
  setUserError: PropTypes.func,
};
export { Login as UnwrappedLogin };
export default connect(null, actions)(Login);
