import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import JobWarningsHelper from '../../../../../../utils/JobWarningsHelper/JobWarningsHelper';

//Works out a job's warnings and displays them on a row
const VehicleRunRowWarnings = ({ job, runInfo }) => {
  const warnings = JobWarningsHelper.getWarnings(job, runInfo);

  if (warnings.length === 0) return null;
  return (
    <div className="hover-cell">
      <div className="c-table-header-icon warning-icon">
        <FontAwesomeIcon icon={faWarning} />
      </div>
      <div className="hover-cell-info cube-info warning">
        <div className="top">Warning</div>
        <div className="main">
          {warnings.map((warning, idx) => (
            <div key={idx} style={{ marginBottom: '6px' }}>
              {warning}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

VehicleRunRowWarnings.propTypes = {
  job: PropTypes.object.isRequired,
  runInfo: PropTypes.object,
};

export default VehicleRunRowWarnings;
