import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FinaliseJob.scss';
import Modal from '../../../common/Modal/Modal';
import Message from '../../../common/layout/Message/Message';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Booking from '../../../api/controllers/Job/Booking/Booking';
import { formatError } from '../../../utils/Formatters/Errors/formatError';

const FinaliseJob = ({ isOpen, setIsOpen, jobId, jobReference, setSuccessMessage, refresh }) => {
  const [error, setError] = useState();

  const handleClick = e => {
    e.preventDefault();
    Booking.update(jobId, { status: 'finalised' }).then(
      () => {
        setSuccessMessage('Job has successfully been Finalised.');
        setIsOpen(false);
        refresh();
      },
      err => setError(formatError(err)),
    );
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <Modal isOpen={isOpen} handleClose={handleCancel}>
      <div className={'finalise-job-modal'}>
        <Message
          text={error?.errors}
          type={'error'}
          onClick={() => setError()}
          visible={!!error}
          onClose={setError}
        />
        <div className={'finalise-text'}>
          Are you sure you want to finalise job
          <div className={'job-id'}>{jobReference}</div>
        </div>
        <div className={'af-button-line'}>
          <IconButton
            text={'Finalise Job'}
            icon={faCheck}
            className={'small'}
            onClick={handleClick}
          />
          <IconButton
            text={'Cancel'}
            icon={faTimes}
            onClick={handleCancel}
            className={'edit small'}
          />
        </div>
      </div>
    </Modal>
  );
};
FinaliseJob.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  jobId: PropTypes.number,
  jobReference: PropTypes.string,
  setSuccessMessage: PropTypes.func,
  refresh: PropTypes.func,
};
export default FinaliseJob;
