import downloadFile from '../../../downloadFile/downloadFile';
import apiCall from '../../../apiCall/apiCall';
import buildFormData from '../../../helpers/buildFormData/buildFormData';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class Item {
  static download(id) {
    return downloadFile(id);
  }

  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'files/items', data);
  }

  static show(id) {
    return apiCall('GET', `files/items/${id}`);
  }

  static create(params) {
    const data = buildFormData('item', params);
    return apiCall('POST', 'files/items', data);
  }

  static update(id, params) {
    const data = { item: params };
    return apiCall('PATCH', `files/items/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `files/items/${id}`);
  }

  static allArr(paramsArr) {
    const calls = paramsArr.map(this.all);
    return Promise.all(calls);
  }
}

export default Item;
