import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class BusinessType {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'companies/business_types', data);
  }

  static show(id) {
    return apiCall('GET', `companies/business_types/${id}`);
  }

  static create(params) {
    const data = { business_type: params };
    return apiCall('POST', 'companies/business_types', data);
  }

  static update(id, params) {
    const data = { business_type: params };
    return apiCall('PATCH', `companies/business_types/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `companies/business_types/${id}`);
  }
}

export default BusinessType;
