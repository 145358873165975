export const mainAddressAttributes = {
  mainAddressAttributes: {
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    region: '',
    postcode: '',
    office: false,
    warehouse: false,
    warehouseRequirementsAttributes: [],
    maxForkliftWeight: '',
    loadingDoorHeight: '',
    loadingEavesHeight: '',
    topLoading: false,
    sideLoading: false,
    rearLoading: false,
  },
};

export const contactsAttributes = {
  contactsAttributes: [
    {
      firstName: '',
      lastName: '',
      jobTitle: '',
      jobRole: '',
      contactMethodsAttributes: [{ kind: 0, text: '' }],
    },
  ],
};
