import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import '../../Document/InvoiceForm/InvoiceForm.scss';
import useForm from '../../../../common/hooks/useForm/useForm';
import Purchase from '../../../../api/controllers/Financial/Purchase/Purchase';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import Supplier from '../../../../api/controllers/Financial/Supplier/Supplier';
import DateHelper from '../../../../utils/DateHelper/DateHelper';
import Entity from '../../../../api/controllers/Company/Entity/Entity';
import InvoiceForm from '../../Document/InvoiceForm/InvoiceForm';
import LineItemHelper from '../../../../utils/LineItemHelper/LineItemHelper';
import { useNavigate, useParams } from 'react-router';

const PurchaseInvoiceNew = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [warning, setWarning] = useState();
  const [lineItems, setLineItems] = useState([]);
  const [supplier, setSupplier] = useState(null);
  const [loading, setLoading] = useState(true);
  const { form, alter } = useForm({
    entityId: id,
    entityReference: '',
    issueDate: DateHelper.today(),
    dueDate: '',
    lineItemsAttributes: [],
  });

  const handleErrors = json => {
    loading && setLoading(false);
    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };

  useEffect(() => {
    Purchase.lineItems({ entityId: id }).then(
      json => {
        setLineItems(json.items);
        setLoading(false);
      },
      json => handleErrors(json),
    );
    Entity.show(id).then(json => {
      setSupplier(json);
      Supplier.show(json.supplierAccount).then(
        json => {
          calDefaultDue(json);
          setLoading(false);
        },
        json => {
          setWarning({ ...json, ...{ status: 'Warning' } });
          setLoading(false);
        },
      );
    });
  }, []);

  const calDefaultDue = supplier => {
    if (!supplier) return alter('dueDate', DateHelper.today());
    alter('dueDate', DateHelper.dueDateCalculate(supplier));
  };

  const handleLineItemToggle = itemId => {
    const toggledLines = LineItemHelper.toggleLineNew(form.lineItemsAttributes, itemId);
    alter('lineItemsAttributes', toggledLines);
  };

  const handleSubmit = () => {
    const params = form;
    Purchase.create(params).then(
      json => navigate(`/financials/purchase_invoices/${json.id}`),
      json => handleErrors(json),
    );
  };

  return (
    <div className={'invoice-form'}>
      <Breadcrumbs>
        <Link to={'/financials/purchase_invoices'}>Purchase Invoices</Link>
        <Link to={`/financials/purchase_invoices/new/${id}`}>New</Link>
      </Breadcrumbs>
      <div className={'page-content'}>
        <InvoiceForm
          error={error}
          setError={setError}
          alter={alter}
          form={form}
          lineItems={lineItems}
          handleSubmit={handleSubmit}
          lineItemToggle={handleLineItemToggle}
          cancelUrl="/financials/purchase_invoices"
          title={`Creating Purchase Invoice for ${supplier?.internalName}`}
          warning={warning}
          setWarning={setWarning}
          loading={loading}
        />
      </div>
    </div>
  );
};

PurchaseInvoiceNew.propTypes = {
  user: PropTypes.object,
};

export default PurchaseInvoiceNew;
