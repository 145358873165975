import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/Modal/Modal';
import './ConfirmationModal.scss';
import IconButton from '../../common/button/IconButton/IconButton';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

const ConfirmationModal = ({
  question,
  subText,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  isOpen,
  handleConfirm,
  handleCancel,
  destructive,
  loading,
}) => (
  <Modal isOpen={isOpen} handleClose={handleCancel}>
    <div className={'cm-container'}>
      <div className={'cm-question'}>{question}</div>
      {subText && <div className={'cm-subtext'}>{subText}</div>}
      <div className={'cm-button-line'}>
        <IconButton
          text={confirmText}
          icon={confirmText === 'Delete' || destructive ? faTrash : faCheck}
          className={confirmText === 'Delete' || destructive ? 'destructive small' : 'edit small'}
          onClick={handleConfirm}
          loading={loading}
        />
        <IconButton
          text={cancelText}
          icon={faTimes}
          className={'edit small'}
          onClick={handleCancel}
        />
      </div>
    </div>
  </Modal>
);

ConfirmationModal.propTypes = {
  question: PropTypes.string.isRequired,
  subText: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  destructive: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ConfirmationModal;
