import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../components/FormSection/FormSection';
import VehicleRunShowJobsSection from './VehicleRunShowJobsSection/VehicleRunShowJobsSection';
import Booking from '../../../../../../api/controllers/Job/Booking/Booking';

const VehicleRunShowJobs = ({ run }) => {
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    Booking.all({ vehicleRunId: run.id }).then(res => setBookings(res.items));
  }, [run.id]);

  return (
    <div className="vrs-jobs tab-content">
      <FormSection title="Jobs">
        {bookings.length === 0 && (
          <div className="no-jobs-message">There is no jobs on this vehicle run</div>
        )}
        {bookings.map(job => (
          <VehicleRunShowJobsSection key={job.id} job={job} />
        ))}
      </FormSection>
    </div>
  );
};

VehicleRunShowJobs.propTypes = { run: PropTypes.object };

export default VehicleRunShowJobs;
