import DateHelper from '../DateHelper/DateHelper';

class DocumentHelper {
  static getCalendarPeriodMismatches(lineItems, periodType) {
    if (lineItems.length < 2) return null;
    const firstLineDate = this.getReferenceDate(lineItems[0]);
    switch (true) {
      case periodType === 'monthly':
        return _handleMonthlyCheck(lineItems, firstLineDate);
      case periodType === 'weekly':
        return _handleWeeklyCheck(lineItems, firstLineDate);
      case periodType === 'daily':
        return _handleDailyCheck(lineItems);
      default:
        return null;
    }
  }

  //formats the calendar miss-matches into the error we show to the user
  static formatMissMatchToMessage(missMatches) {
    //do nothing if there are no miss-matches
    if (!missMatches || missMatches.length === 0) return;
    const baseMessage = 'This document contains line items from multiple calendar periods: ';

    //If its a week / month
    if (Array.isArray(missMatches)) {
      const converted = missMatches.map(({ start, end, items }) => {
        const parsedStart = DateHelper.dateOnly(start);
        const parsedEnd = DateHelper.dateOnly(end);
        const date = `${parsedStart}-${parsedEnd}`;
        const refs = items.map(({ parent }) => parent?.jobReference);
        const jobRefs = [...new Set(refs)];

        return `${date} (${jobRefs.join('/')})`;
      });
      return `${baseMessage}${converted.join(', ')}`;
    }

    //daily format
    const missArray = Object.keys(missMatches).map(k => [k, missMatches[k]]);
    const stringArray = missArray.map(item => {
      const items = item[1];
      const refs = items.map(({ parent }) => parent?.jobReference);
      const jobRefs = [...new Set(refs)];
      return `${DateHelper.dateOnly(item[0])} (${jobRefs.join('/')})`;
    });
    return `${baseMessage}${stringArray.join(', ')}`;
  }

  //Finds potential line items that are missing from document
  static getMissingLineItemsForPeriod(currentIds, potentialLines, start, end) {
    const filteredPotential = potentialLines.filter(li => !currentIds.includes(li.id));
    const missingItems = filteredPotential.filter(line => {
      if (!line.parent) return false;
      const dateToCheck = this.getReferenceDate(line);

      const date = new Date(dateToCheck);
      if (start === end) return dateToCheck === start;
      if (date < start) return false;
      if (date > end) return false;

      return true;
    });

    const parsedStart = DateHelper.dateOnly(start);
    const parsedEnd = DateHelper.dateOnly(end);
    const period = start === end ? parsedStart : `${parsedStart}-${parsedEnd}`;

    return { period, missingItems };
  }

  static getReferenceDate(line) {
    return line.parent.deliveryDate || line.parent.bookingDate;
  }

  //Formats the missing items into the message we display to user.
  //missing items is an array of items line items.
  //period is the time period string eg: 02/01/2022-03/02/2022 or 01/12/3033 etc
  static formatMissingItemsToMessage(missingItems, period) {
    if (!missingItems || missingItems.length === 0) return;
    const baseMessage = `One or more line items from ${period} is not included: `;
    const refs = missingItems.map(({ parent }) => parent?.jobReference);
    const jobRefs = [...new Set(refs)];
    return `${baseMessage}${jobRefs.join(', ')}`;
  }

  static getPeriods(initDate, timeRange) {
    if (timeRange === 'monthly') return DateHelper.getStartAndEndOfMonth(initDate);
    if (timeRange === 'weekly') return DateHelper.getStartAndEndOfWeek(initDate);
    return { start: initDate, end: initDate };
  }
}

//HELPER FUNCTIONS

function _handleWeeklyCheck(lineItems, lineDate) {
  const firstLineDates = DateHelper.getStartAndEndOfWeek(lineDate);
  return _handleWeekOrMonth(lineItems, firstLineDates, 'weekly');
}

function _handleMonthlyCheck(lineItems, lineDate) {
  const firstLineDates = DateHelper.getStartAndEndOfMonth(lineDate);
  return _handleWeekOrMonth(lineItems, firstLineDates, 'monthly');
}

//Used to check is everything is in same calendar period for both months and weeks
function _handleWeekOrMonth(lineItems, firstLineDates, periodType) {
  //decide what function to use when getting the period
  const periodFunctions = {
    weekly: DateHelper.getStartAndEndOfWeek,
    monthly: DateHelper.getStartAndEndOfMonth,
    default: DateHelper.getStartAndEndOfMonth,
  };
  const functionToUse = periodFunctions[periodType] || periodFunctions.default;

  //Groups by the period, so that its an array of jobs,
  //each object represents 1 period.
  const sortedToDates = lineItems.reduce(
    (group, item) => {
      const dateToUse = DocumentHelper.getReferenceDate(item);
      const idx = group.findIndex(({ start, end }) =>
        DateHelper.dateWithinRange(dateToUse, start, end),
      );
      //Check if period already exists, add item if true, otherwise make period.
      if (idx !== -1) group[idx].items.push(item);
      else {
        const lineDates = functionToUse(dateToUse);
        group.push({ ...lineDates, items: [item] });
      }
      return group;
    },
    [{ ...firstLineDates, items: [] }],
  );

  //more than one period?
  if (sortedToDates.length > 1) return sortedToDates;

  return null;
}

function _handleDailyCheck(lineItems) {
  //groups by period as object of { date: [items], date2: [items]}
  const sortedToDates = lineItems.reduce((group, item) => {
    const dateToUse = DocumentHelper.getReferenceDate(item);
    group[dateToUse] = group[dateToUse] ?? [];
    group[dateToUse].push(item);
    return group;
  }, {});

  //more than one date?
  if (Object.keys(sortedToDates).length > 1) return sortedToDates;

  return null;
}

export default DocumentHelper;
