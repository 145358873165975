import DateHelper from '../DateHelper/DateHelper';
import { camelCaseToWords } from '../EnumHelper/EnumHelper';

class JobWarnings {
  // check if there is job level warnings for job in a vehicle run
  static getWarnings(data, runInfo = null) {
    let warnings = [];
    //check collection address exists
    if (!data.collectionAddress) warnings.push('No collection address is specified');

    //check delivery address exists
    if (!data.deliveryAddress) warnings.push('No delivery address is specified');

    //if theres no vehicle run, dont check the rest
    if (!runInfo) return warnings;

    warnings.push(
      this.stopsAtAddress(runInfo.waypoints, data, 'collectionAddress'),
      this.stopsAtAddress(runInfo.waypoints, data, 'deliveryAddress'),
      this.waypointOrderIncorrect(runInfo.waypoints, data),
      this.dateOrderIncorrect(data, runInfo.dateOfUse, 'goodsReadyDate'),
      this.dateOrderIncorrect(data, runInfo.dateOfUse, 'deliveryDeadlineDate'),
      this.packagesWillNotFit(data, runInfo.vehicle, 'lengthCm'),
      this.packagesWillNotFit(data, runInfo.vehicle, 'widthCm'),
      this.packagesWillNotFit(data, runInfo.vehicle, 'heightCm'),
    );

    return warnings.filter(a => a);
  }

  static stopsAtAddress(waypoints, data, type) {
    if (!data[type]) return;
    //check collection address has a waypoint
    if (waypoints.filter(waypoint => waypoint.address?.id === data[type].id).length === 0)
      return `The assigned vehicle run does not stop at this ${type.split('Address')[0]} address (${
        data[type].postcode
      })`;
  }

  static waypointOrderIncorrect(waypoints, data) {
    if (!data.deliveryAddress || !data.collectionAddress) return;
    //check waypoint order matches job collection / delivery order

    const collectionStop = waypoints.findIndex(
      waypoint => waypoint.address.id === data.collectionAddress.id,
    );
    if (!collectionStop) return;

    const beforeStops = waypoints.slice(0, collectionStop);
    const afterStops = waypoints.slice(collectionStop);

    const deliveryStopBefore = beforeStops.find(
      waypoint => waypoint.address.id === data.deliveryAddress.id,
    );

    const deliveryStopAfter = afterStops.find(
      waypoint => waypoint.address.id === data.deliveryAddress.id,
    );

    if (deliveryStopAfter) return;
    if (deliveryStopBefore)
      return `The assigned vehicle run stops at the delivery address (${data.deliveryAddress.postcode}) before the collection address (${data.collectionAddress.postcode})`;
  }

  static dateOrderIncorrect(data, dateOfUse, type) {
    const types = {
      deliveryDeadlineDate: { func: (a, b) => a < b, wording: 'after' },
      goodsReadyDate: { func: (a, b) => a > b, wording: 'before' },
    };

    if (!data[type] || !types[type] || !dateOfUse) return;
    // make sure delivery deadline isn't before the date of use
    if (types[type].func(data[type], dateOfUse))
      return `The date-of-use for the assigned vehicle (${DateHelper.dateOnly(dateOfUse)}) is ${
        types[type].wording
      } the ${camelCaseToWords(type).toLowerCase()} (${DateHelper.dateOnly(data[type])})`;
  }

  static packagesWillNotFit(data, vehicle, type) {
    if (!vehicle || !vehicle[type]) return;
    if (data.dimensionLineItems.filter(item => item[type] > vehicle[type]).length > 0)
      return `At least one package exceeds the maximum allowed ${
        type.split('Cm')[0]
      } for the vehicle (${vehicle[type]}cm)`;
  }
}

export default JobWarnings;
