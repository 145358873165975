import React from 'react';
import PropTypes from 'prop-types';
import NoPage from '../../pages/NoPage/NoPage';

const ProtectedRoute = ({ type, action, permissions, children }) =>
  permissions?.[type]?.[action] ? children : <NoPage />;

ProtectedRoute.propTypes = {
  type: PropTypes.string,
  action: PropTypes.string,
  permissions: PropTypes.object,
  children: PropTypes.element,
};

export default ProtectedRoute;
