import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import useApi from '../../../../../../../common/hooks/useApi/useApi';
import Report from '../../../../../../../api/controllers/Report/Report';
import FormSection from '../../../../../../../components/FormSection/FormSection';
import Table from '../../../../../../../components/Table/Table/Table';
import ReportHelper from '../../../../../../../utils/ReportHelper/ReportHelper';
import LoadingSpinner from '../../../../../../../common/LoadingSpinner/LoadingSpinner';
import DateHelper from '../../../../../../../utils/DateHelper/DateHelper';

const totalStatCols = [
  'No. Jobs',
  'Gross Weight',
  'Volume',
  'Taxable Weight',
  'Revenue (£)',
  'Projected Profit (£)',
  'Profit/Revenue (%)',
];

const ProfitabilitySubTab = ({ entityId, year }) => {
  const { report } = useApi({
    name: 'report',
    call: () =>
      Report.show('entity_profitability', {
        startDate: `${year}-01-01`,
        endDate: DateHelper.tomorrow(`${year}-12-31`),
        entityId,
      }),
  });

  return (
    <div className="tab-content">
      {report ? (
        <Fragment>
          <FormSection title="Total - Statistics for Finalised Jobs">
            <table>
              <thead>
                <tr>
                  {totalStatCols.map((col, i) => (
                    <th key={i}>{col}</th>
                  ))}
                </tr>
                <tr>
                  <td>{report['rows'].length}</td>
                  <td>{ReportHelper.sumAttr(report, 'Gross Weight')}</td>
                  <td>{ReportHelper.sumAttr(report, 'Volume')}</td>
                  <td>{ReportHelper.sumAttr(report, 'Taxable Weight')}</td>
                  <td>{ReportHelper.sumAttr(report, 'Revenue (£)')}</td>
                  <td>{ReportHelper.sumAttr(report, 'Projected Profit (£)')}</td>
                  <td>{ReportHelper.avgAttr(report, 'Profit/Revenue (%)')}</td>
                </tr>
              </thead>
            </table>
          </FormSection>
          <Table
            title={'Profitability by Route'}
            headers={ReportHelper.formatHeaders(report['columns'])}
            data={{ items: report['rows'] }}
            onDrawRow={(row, i) => ReportHelper.genRow(report['columns'], row, i)}
            searchable={ReportHelper.getSearchables()}
          />
        </Fragment>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

ProfitabilitySubTab.propTypes = {
  entityId: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
};

export default ProfitabilitySubTab;
