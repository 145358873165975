import React from 'react';
import PropTypes from 'prop-types';
import './DirectionLabel.scss';

/* This may seem counter intuitive at first but this is because 
   we a thinking about this in terms of FS and ITAL, therefore
   if the Freightsoft entity is the collection address, 
   that means we are collecting the package from FS and moving it somewhere,
   making a delivery to someone/somewhere, whereas if its the delivery address,
   we are receiving the item, therefore making a collection.
   XT means that it neither.
*/
const DirectionLabel = ({ fsId, deliveryId, collectionId }) => {
  if (!fsId) return <div className="direction-label other">XT</div>;
  if (fsId === deliveryId) return <div className="direction-label collect">COL</div>;
  if (fsId === collectionId) return <div className="direction-label deliver">DEL</div>;
  return <div className="direction-label other">XT</div>;
};

DirectionLabel.propTypes = {
  fsId: PropTypes.number,
  deliveryId: PropTypes.number,
  collectionId: PropTypes.number,
};

export default DirectionLabel;
