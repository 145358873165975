import React from 'react';
import { formatCell } from '../../pages/Report/ReportShow/reportCellHandler/reportCellHandler';

class ReportHelper {
  static formatHeaders(cols) {
    return cols.map(col => ({ text: col }));
  }

  // Takes columns and a single row object to generate table rows
  static genRow(cols, row, i) {
    return <tr key={i}>{cols.map((key, i) => formatCell(row, key, i))}</tr>;
  }

  // Just returns a bunch of common attributes that are searchable
  static getSearchables() {
    return [
      'Job Reference.text',
      'Customer Reference',

      'Job Reference.text',
      'Vehicle Run Reference.text',
      'Vehicle Details',
      'Customer.text',
      'Customer Reference',
      'Status',

      'Invoice Number.text',
      'Customer.text',
      'Customer Reference',
      'Vehicle Run Reference.text',
      'Vehicle Reference',
      'Status',
    ];
  }

  static sumAttr(report, attr) {
    return report['rows'].reduce((a, b) => (a += Number(b[attr])), 0);
  }

  static avgAttr(report, attr) {
    const res = report['rows'].reduce((a, b) => (a += Number(b[attr])), 0) / report['rows'].length;
    return isNaN(res) ? '' : res;
  }
}

export default ReportHelper;
