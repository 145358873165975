// To upload files, we must send them as fields on a FormData object.
function buildFormData(name, params) {
  const data = new FormData();

  Object.keys(params).forEach(key => {
    const value = params[key];

    // Most values (including files) can simply be used as they are...
    if (!Array.isArray(value)) {
      return data.append(`${name}[${key}]`, value);
    }

    // ...but arrays-of-objects must be "flattened" instead:
    value.forEach((item, num) => {
      Object.keys(item).forEach(subkey =>
        data.append(`${name}[${key}][${num}][${subkey}]`, item[subkey]),
      );
    });
  });

  return data;
}

export default buildFormData;
