import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Document from '../../../api/controllers/Financial/Document/Document';
import useApi from '../../../common/hooks/useApi/useApi';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import './AccountSync.scss';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import useArray from '../../../common/hooks/useArray/useArray';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import AccountSyncTable from '../../../components/Table/AccountSyncTable/AccountSyncTable';

const AccountSync = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedDocs, toggleSelectedDocs, handleSelectedDocs } = useArray({
    name: 'selectedDocs',
  });

  const { data, dataLoading } = useApi({
    call: Document.all,
    //We expect the documents on this page to be ready to submit, but haven't yet.
    params: { submitted: false, finalised: true },
  });

  useEffect(() => {
    document.title = 'Accounts Sync';
  }, []);

  const submitSelected = () => {
    const calls = selectedDocs.map(id => ({ promise: Document.submit_to_sage(id), id }));
    setResults(calls); //allows the loading spinners to only appear on the relevant rows
    setLoading(true);
    //Wait until we've received results for all promises and update with their result
    Promise.allSettled(calls.map(i => i.promise)).then(res => {
      setResults(res.map((result, idx) => ({ id: calls[idx].id, result })));
      setLoading(false);
      handleSelectedDocs([]);
    });
  };

  return (
    <div className="accounts-sync">
      <Breadcrumbs>
        <Link to={'/financials/accounts_sync'}>Accounts Sync</Link>
      </Breadcrumbs>
      <div className="page-content">
        {dataLoading && <LoadingSpinner />}
        {!dataLoading && (
          <AccountSyncTable
            data={data}
            selectedDocs={selectedDocs}
            calls={results}
            loading={loading}
            toggleSelectedDocs={toggleSelectedDocs}
            handleSelectedDocs={handleSelectedDocs}
          />
        )}
        <div className="as-footer">
          <div className="as-selected-info">{selectedDocs.length} Documents selected</div>
          <IconButton
            loading={loading}
            icon={faUpload}
            text="Post Selected"
            onClick={submitSelected}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountSync;
