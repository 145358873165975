import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class FreightsoftBooking {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'jobs/freightsoft_bookings', data);
  }
  static import(id) {
    return apiCall('POST', `jobs/freightsoft_bookings/${id}/import`);
  }
}
export default FreightsoftBooking;
