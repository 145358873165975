import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../components/FormSection/FormSection';
import FieldList from '../../../../../../components/FieldList/FieldList';
import FormField from '../../../../../../components/FormField/FormField';
import { capitalize } from 'lodash';
import AddressHelper from '../../../../../../utils/AddressHelper/AddressHelper';
import ShowField from '../../../../../../components/ShowField/ShowField';
import CollapsibleFormSection from '../../../../../../components/CollapsibleFormSection/CollapsibleFormSection';
import ValueInput from '../../../../../../common/input/ValueInput/ValueInput';
import useArray from '../../../../../../common/hooks/useArray/useArray';
import TimeInput from '../../../../../../common/input/TimeInput/TimeInput';
import DateHelper from '../../../../../../utils/DateHelper/DateHelper';
import LinkShow from '../../../../../../common/LinkShow/LinkShow';
import BookingHelper from '../../../../../../utils/BookingHelper/BookingHelper';

const BookingShowGeneral = ({
  data,
  notes = {},
  restrictions = {},
  handleNoteClick,
  handleNoteClear,
}) => {
  const { array, toggle } = useArray([]);

  const getAddress = attr => {
    if (!data[attr]) return;
    const { line1, region, postcode } = data[attr];
    const addressArr = AddressHelper.extractAddressToArray({ line1, region, postcode });
    return AddressHelper.formatAddress(addressArr);
  };
  const getFullAddress = attr => {
    if (!data[attr]) return;
    return AddressHelper.formatAddress(AddressHelper.extractAddressToArray(data[attr]));
  };

  useEffect(() => {
    handleNoteClear();
  }, []);

  return (
    <div className={'tab-content'}>
      <FormSection title={'Job Summary'}>
        <FieldList>
          <FormField label={'Controller'}>
            <input value={`${data.owner.firstName} ${data.owner.lastName}`} readOnly />
          </FormField>
          <FormField label={'Status'}>
            <input value={capitalize(data.status)} readOnly />
          </FormField>
        </FieldList>
      </FormSection>
      <FormSection
        title={'Entities, Addressing & References'}
        optionalElement={<ShowField label={'Subcontracted'} value={data.subcontracted} />}
      >
        <FieldList>
          <FormField
            label={'Customer'}
            hasNotes={notes['customer']}
            handleNoteClick={() => handleNoteClick(1)}
          >
            <LinkShow
              testId="bsg-customer"
              text={data.customer?.internalName}
              url={data.customer?.id && `/companies/entities/${data.customer?.id}`}
            />
          </FormField>
          <FormField label={'Customer Reference'}>
            {BookingHelper.canBeLinked(data) ? (
              <LinkShow
                testId={'bsg-customer-ref'}
                text={data.customerReference || ''}
                url={`${process.env.REACT_APP_FREIGHTSOFT_URL}/jobs/${data.freightsoftReference}`}
                anchor
              />
            ) : (
              <input
                data-testid={'bsg-customer-ref'}
                value={data.customerReference || ''}
                readOnly
              />
            )}
          </FormField>
        </FieldList>
        <FieldList>
          <FormField
            label={'Collection Entity'}
            hasNotes={notes['collectionEntity']}
            handleNoteClick={() => handleNoteClick(2)}
          >
            <LinkShow
              text={data.collectionEntity?.internalName}
              url={data.collectionEntity?.id && `/companies/entities/${data.collectionEntity?.id}`}
              testId={'bsg-collection-entity'}
            />
          </FormField>
          <FormField label={'Collection On Behalf Of'}>
            <LinkShow
              text={data.collectionOnBehalfOf?.internalName}
              url={
                data.collectionOnBehalfOf?.id &&
                `/companies/entities/${data.collectionOnBehalfOf?.id}`
              }
              testId={'bsg-collection-obo'}
            />
          </FormField>
        </FieldList>
        <FieldList>
          <FormField
            label={'Collection Address'}
            hasRestrictions={restrictions['collectionAddress']}
          >
            <input
              data-testid={'bsg-collection-address'}
              value={getAddress('collectionAddress') || ''}
              readOnly
              title={getFullAddress('collectionAddress') || ''}
            />
          </FormField>
          <FormField label={'Collection Reference'}>
            <input
              data-testid={'bsg-collection-reference'}
              value={data.collectionReference || ''}
              readOnly
            />
          </FormField>
        </FieldList>
        <FieldList>
          <FormField
            label={'Delivery Entity'}
            hasNotes={notes['deliveryEntity']}
            handleNoteClick={() => handleNoteClick(3)}
          >
            <LinkShow
              text={data.deliveryEntity?.internalName}
              url={data.deliveryEntity?.id && `/companies/entities/${data.deliveryEntity?.id}`}
              testId={'bsg-delivery-entity'}
            />
          </FormField>
          <FormField label={'Delivery On Behalf Of'}>
            <LinkShow
              text={data.deliveryOnBehalfOf?.internalName}
              url={
                data.deliveryOnBehalfOf?.id && `/companies/entities/${data.deliveryOnBehalfOf?.id}`
              }
              testId={'bsg-delivery-obo'}
            />
          </FormField>
        </FieldList>
        <FieldList>
          <FormField label={'Delivery Address'} hasRestrictions={restrictions['deliveryAddress']}>
            <input
              data-testid={'bsg-delivery-address'}
              value={getAddress('deliveryAddress') || ''}
              readOnly
              title={getFullAddress('deliveryAddress') || ''}
            />
          </FormField>
          <FormField label={'Delivery Reference'}>
            <input
              data-testid={'bsg-delivery-reference'}
              value={data.deliveryReference || ''}
              readOnly
            />
          </FormField>
        </FieldList>
        {data.subcontracted && (
          <FieldList>
            <FormField label={'Subcontractor'}>
              <LinkShow
                text={data.subcontractor?.internalName}
                url={data.subcontractor?.id && `/companies/entities/${data.subcontractor?.id}`}
                testId={'bsg-subcontractor'}
              />
            </FormField>
          </FieldList>
        )}
      </FormSection>
      <FormSection title={'Schedule'}>
        <FieldList>
          <FormField label={'Collection Date'}>
            <input
              data-testid={'bsg-collection-date'}
              value={(data.collectionDate && DateHelper.dateOnly(data.collectionDate)) || ''}
              readOnly
            />
          </FormField>
          <FormField label={'Collection Time'}>
            <TimeInput
              opensAt={data.collectionFromTime || ''}
              closesAt={data.collectionUntilTime || ''}
              readOnly
            />
          </FormField>
        </FieldList>
        <FieldList>
          <FormField label={'Delivery Date'}>
            <input
              data-testid={'bsg-delivery-date'}
              value={(data.deliveryDate && DateHelper.dateOnly(data.deliveryDate)) || ''}
              readOnly
            />
          </FormField>
          <FormField label={'Delivery Time'}>
            <TimeInput
              opensAt={data.deliveryFromTime || ''}
              closesAt={data.deliveryUntilTime || ''}
              readOnly
            />
          </FormField>
        </FieldList>
        <FieldList>
          <FormField label={'Goods Ready Date'}>
            <input
              data-testid={'bsg-goods-ready-date'}
              value={(data.goodsReadyDate && DateHelper.dateOnly(data.goodsReadyDate)) || ''}
              readOnly
            />
          </FormField>
          <FormField label={'Goods Ready Time'}>
            <TimeInput
              opensAt={data.goodsReadyFromTime || ''}
              closesAt={data.goodsReadyUntilTime || ''}
              readOnly
            />
          </FormField>
        </FieldList>
        <FieldList>
          <FormField label={'Delivery Deadline Date'}>
            <input
              data-testid={'bsg-delivery-deadline-date'}
              value={
                (data.deliveryDeadlineDate && DateHelper.dateOnly(data.deliveryDeadlineDate)) || ''
              }
              readOnly
            />
          </FormField>
          <FormField label={'Delivery Deadline Time'}>
            <TimeInput
              opensAt={data.deliveryDeadlineFromTime || ''}
              closesAt={data.deliveryDeadlineUntilTime || ''}
              readOnly
            />
          </FormField>
        </FieldList>
      </FormSection>
      {data.dimensionLineItems.length > 0 && (
        <CollapsibleFormSection
          title={'Package Dimensions'}
          open={array.includes('Package Dimensions')}
          onClick={() => toggle('Package Dimensions')}
        >
          <table>
            <thead>
              <tr>
                <th>Quantity</th>
                <th>Package Type</th>
                <th>Length</th>
                <th>Width</th>
                <th>Height</th>
              </tr>
            </thead>
            <tbody>
              {data.dimensionLineItems.map((li, i) => (
                <tr key={i} data-testid={'bsg-line-item'}>
                  <td className={'small'}>
                    <input value={li.quantity || ''} readOnly />
                  </td>
                  <td className={'medium'}>
                    <input value={li.packageType.name || ''} readOnly />
                  </td>
                  <td className={'small'}>
                    <ValueInput unit={'CM'} value={li.lengthCm || ''} readOnly />
                  </td>
                  <td className={'small'}>
                    <ValueInput unit={'CM'} value={li.widthCm || ''} readOnly />
                  </td>
                  <td className={'small'}>
                    <ValueInput unit={'CM'} value={li.heightCm || ''} readOnly />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CollapsibleFormSection>
      )}
      <FormSection
        title={'Consignment Details'}
        optionalElement={
          <ShowField value={data.dimensionLineItems.length > 0} label={'Dimensions Lines'} />
        }
      >
        <FieldList>
          <FormField label={'Quantity'}>
            <input data-testid={'bsg-quantity'} value={data.quantity || ''} readOnly />
          </FormField>
          <FormField label={'Package Type'}>
            <input data-testid={'bsg-package-type'} value={data.packageType?.name || ''} readOnly />
          </FormField>
        </FieldList>
        <FieldList>
          <FormField label={'Gross Weight'}>
            <ValueInput unit={'kg'} value={data.grossWeightKg || ''} readOnly />
          </FormField>
          <FormField label={'Net Weight'}>
            <ValueInput unit={'kg'} value={data.netWeightKg || ''} readOnly />
          </FormField>
        </FieldList>
        <FieldList>
          <FormField label={'Cubic Metres'}>
            <ValueInput unit={'m³'} value={data.cubicMetres || ''} readOnly />
          </FormField>
          <FormField label={'Loading Metres'}>
            <ValueInput unit={'m'} value={data.loadingMetres || ''} readOnly />
          </FormField>
        </FieldList>
      </FormSection>
      <FormSection
        title={'Goods Details'}
        optionalElement={<ShowField value={!!data.hazardousCargo} label={'Cargo Hazardous'} />}
      >
        <div className={'space-fields'}>
          <FormField label={'Description'} className={'fill'}>
            <input data-testid={'bsg-description'} value={data.description || ''} readOnly />
          </FormField>
          {data.hazardousCargo && (
            <FormField label={'Hazard Details'} className={'fill'}>
              <input data-testid={'bsg-hazard-details'} value={data.hazardDetails || ''} readOnly />
            </FormField>
          )}
        </div>
      </FormSection>
    </div>
  );
};

BookingShowGeneral.propTypes = {
  data: PropTypes.object,
  notes: PropTypes.object,
  restrictions: PropTypes.object,
  handleNoteClick: PropTypes.func,
  handleNoteClear: PropTypes.func.isRequired,
};

export default BookingShowGeneral;
