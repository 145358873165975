import React from 'react';
import PropTypes from 'prop-types';
import CheckPermit from '../../../../common/CheckPermit/CheckPermit';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faPen, faTrash, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DriverRow = ({ driver, id, handleEdit, handleDelete, permissions }) => {
  return (
    <tr key={id} className={id % 2 === 0 ? '' : 'coloured'}>
      <td>{driver.firstName}</td>
      <td>{driver.lastName}</td>
      <td data-testid="vrr-bool" className="tiny">
        <FontAwesomeIcon icon={driver.archived ? faCheck : faTimes} />
      </td>
      <td>
        <span className="td-buttons">
          <CheckPermit type={'movementsDrivers'} action={'update'} permissions={permissions}>
            <IconButton
              text={'Edit'}
              icon={faPen}
              onClick={() => handleEdit(driver.id)}
              className="edit small"
            />
          </CheckPermit>
          <CheckPermit type={'movementsDrivers'} action={'destroy'} permissions={permissions}>
            <IconButton
              text={'Delete'}
              icon={faTrash}
              className="destructive small"
              onClick={() => handleDelete(driver)}
            />
          </CheckPermit>
        </span>
      </td>
    </tr>
  );
};

DriverRow.propTypes = {
  driver: PropTypes.object,
  id: PropTypes.number,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  permissions: PropTypes.object,
};

export default DriverRow;
