import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../Checkbox/Checkbox';
import './ContactFunctionForm.scss';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AttributeHelper from '../../../../utils/AttributeHelper/AttributeHelper';
import useApi from '../../../../common/hooks/useApi/useApi';
import EmailTemplateType from '../../../../api/controllers/EmailTemplateType/EmailTemplateType';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import IterativeDropdown from '../../../IterativeDropdown/IterativeDropdown';
import Recipient from '../../../../api/controllers/Email/Recipient/Recipient';
import GenericDropdown from '../../../../common/GenericDropdown/GenericDropdown';
import { humanize, labelForEnum } from '../../../../utils/EnumHelper/EnumHelper';
import { emailTemplateTypes } from '../../../../strings/enums';

const ContactFunctionForm = ({ form, onChange }) => {
  const { types, typesLoading } = useApi({ call: EmailTemplateType.all, name: 'types' });
  const { ccType, ccTypeLoading } = useApi({ call: Recipient.ccTypes, name: 'ccType' });

  const handleKindChange = (idx, val) =>
    AttributeHelper.handleAttrChange(idx, val, 'emailInclusionsAttributes', form, onChange);

  const handlePlus = e => {
    e.preventDefault();
    handleKindChange(form.emailInclusionsAttributes.length, { name: null, ccType: 'to' });
  };

  const handleMinus = (e, idx) => {
    e.preventDefault();
    const inc = form.emailInclusionsAttributes[idx];
    const canDestroy = form.modelName && inc.createdAt;
    if (canDestroy) return handleKindChange(idx, { ...inc, _destroy: !inc._destroy });
    return handleKindChange(idx, null);
  };

  const onSelectType = (idx, type) => handleKindChange(idx, { templateTypeId: type.id });
  const onSelectCc = (idx, cc) => handleKindChange(idx, { ccType: cc });

  const drawTypes = (i, item) =>
    types && (
      <GenericDropdown
        value={form.emailInclusionsAttributes[i]}
        items={types.items}
        onDisplay={temp => {
          const val =
            temp.name || types.items.filter(type => type.id === temp.templateTypeId)[0]?.name;
          return labelForEnum(emailTemplateTypes, temp?.name || val);
        }}
        onSelect={temp => onSelectType(i, temp)}
        disabled={item._destroy}
        testId={'types-dropdown'}
      />
    );

  const drawCc = (i, item) => (
    <GenericDropdown
      value={form.emailInclusionsAttributes[i]}
      items={ccType}
      onDisplay={ei => humanize(typeof ei === 'string' ? ei : ei.ccType)}
      onSelect={cc => onSelectCc(i, cc)}
      disabled={item._destroy}
      testId={'cc-dropdown'}
    />
  );

  if (typesLoading || !types?.items || ccTypeLoading) return <LoadingSpinner />;

  return (
    <div className="contact-function-form">
      <div className="cff-cb">
        <Checkbox
          label={'Accounts'}
          checked={form.accounts}
          onClick={() => onChange('accounts', !form.accounts)}
          reverse
        />
      </div>
      <div className="footer-button">
        <IconButton
          text="Add Email Inclusion"
          className={'success'}
          icon={faPlus}
          onClick={e => handlePlus(e)}
        />
      </div>
      {form.emailInclusionsAttributes.map(
        (item, idx) =>
          !item._destroy && (
            <IterativeDropdown
              key={idx}
              idx={idx}
              item={item}
              fieldLabel={'For these emails:'}
              drawDropdown={drawTypes}
              drawDropdownSecond={drawCc}
              secondLabel={'Include this contact as:'}
              handleMinus={e => handleMinus(e, idx)}
            />
          ),
      )}
    </div>
  );
};

ContactFunctionForm.propTypes = {
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ContactFunctionForm;
