import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import Message from '../../../../common/layout/Message/Message';
import FormSection from '../../../../components/FormSection/FormSection';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import FormField from '../../../../components/FormField/FormField';
import useApi from '../../../../common/hooks/useApi/useApi';
import useArray from '../../../../common/hooks/useArray/useArray';
import FuelPrice from '../../../../api/controllers/Movement/FuelPrice/FuelPrice';
import ValueInput from '../../../../common/input/ValueInput/ValueInput';
import './FuelCost.scss';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import NumberHelper from '../../../../utils/NumberHelper/NumberHelper';

const defaultFuelPrice = {
  startDate: '',
  pencePerLitre: '',
};

const FuelCost = () => {
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState();

  const { defaultFuelPrices, refreshDefaultFuelPrices, defaultFuelPricesLoading } = useApi({
    name: 'defaultFuelPrices',
    call: FuelPrice.all,
  });
  const { fuelPrices, replaceFuelPrices, addFuelPrices, handleFuelPrices, removeIdxFuelPrices } =
    useArray({
      name: 'fuelPrices',
    });
  const { destroyedFuelPrices, addDestroyedFuelPrices, handleDestroyedFuelPrices } = useArray({
    name: 'destroyedFuelPrices',
  });

  useEffect(() => {
    if (defaultFuelPrices) {
      //TSR-1272: when we save / load the page, format the price to 2dp
      const convertedTo2Dp = defaultFuelPrices.items?.map(item => ({
        ...item,
        pencePerLitre: NumberHelper.formatNumberUnlessBlank(item.pencePerLitre).toFixed(2),
      }));
      handleFuelPrices(convertedTo2Dp);
      handleDestroyedFuelPrices([]);
    }
  }, [JSON.stringify(defaultFuelPrices)]);

  useEffect(() => {
    document.title = 'Manage Fuel Costs';
  }, []);

  const handleDelete = (area, i) => {
    if (area.modelName) addDestroyedFuelPrices({ ...area, _destroy: 1 });

    removeIdxFuelPrices(fuelPrices.length - 1 - i);
  };

  const handleSubmit = () => {
    setLoading(true);
    /*
      destroyedArray before addedArray so that if a user removes a date then re-adds it in the same action,
      it will correctly be added back and wont cause issues.
    */
    FuelPrice.updateAll({
      fuelPricesAttributes: Object.assign({}, [...destroyedFuelPrices, ...fuelPrices]),
    })
      .then(
        () => {
          setMessage({ type: 'success', text: 'Your changes have been saved' });
          refreshDefaultFuelPrices();
        },
        err => {
          const errorContent = formatError(err);
          const errorCount = errorContent.errors.length;
          setMessage({
            type: 'error',
            text: {
              errorTitle: `Action Failed, ${errorCount} error${
                errorCount > 1 ? 's' : ''
              } to resolve.`,
              errors: errorContent.errors,
            },
          });
        },
      )
      .finally(() => setLoading(false));
  };

  const reverseArray = arr =>
    arr.reduceRight((previous, current) => {
      previous.push(current);
      return previous;
    }, []);

  const buttonHeader = (
    <div className="ta-button-group">
      <IconButton
        text={'Add Row'}
        icon={faPlus}
        className={'success'}
        disabled={defaultFuelPricesLoading}
        onClick={() => addFuelPrices(defaultFuelPrice)}
      />
      <IconButton
        text={'Save'}
        icon={faCheck}
        onClick={handleSubmit}
        loading={loading}
        disabled={defaultFuelPricesLoading}
      />
    </div>
  );

  return (
    <div className="fuel-costs">
      <Breadcrumbs>
        <Link to="/fuel_costs">Fuel Costs</Link>
      </Breadcrumbs>

      <div className="page-content">
        <h1>Manage Fuel Costs</h1>

        {message && (
          <Message
            type={message.type}
            text={message.text}
            visible={!!message}
            onClick={() => setMessage()}
          />
        )}

        <FormSection title="Fuel Costs" optionalElement={buttonHeader}>
          {defaultFuelPricesLoading && <LoadingSpinner />}
          {!defaultFuelPricesLoading &&
            reverseArray(fuelPrices).map((fuelPrice, i) => (
              <div key={i} className="fc-row">
                <FormField label={'Start Date'}>
                  <input
                    value={fuelPrice.startDate}
                    type={'date'}
                    onChange={e =>
                      replaceFuelPrices(fuelPrices.length - 1 - i, {
                        ...fuelPrice,
                        startDate: e.target.value,
                      })
                    }
                  />
                </FormField>
                <FormField label={'Price'}>
                  <ValueInput
                    value={fuelPrice.pencePerLitre}
                    unit="ppl"
                    step={0.01}
                    min={0}
                    onChange={e => {
                      replaceFuelPrices(fuelPrices.length - 1 - i, {
                        ...fuelPrice,
                        pencePerLitre: e.target.value,
                      });
                    }}
                  />
                </FormField>
                <IconButton
                  iconOnly
                  icon={faTimes}
                  className={'destructive'}
                  onClick={() => handleDelete(fuelPrice, i)}
                  testId={`-${i}`}
                />
              </div>
            ))}
        </FormSection>
      </div>
    </div>
  );
};

FuelCost.propTypes = {};

export default FuelCost;
