import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './VehicleRunFormWaypoints.scss';
import WaypointRow from './WaypointRow/WaypointRow';

/*
  Why? This section was created to contain the bare minimum functionality
    to display waypoints.

  Design: https://xd.adobe.com/view/3956ae15-d40c-4d6c-b54d-30083e6c2c8e-6c05/?x_product=xd-jira%2F2.0
*/
const VehicleRunFormWaypoints = ({
  waypoints,
  addDestroyedWaypoints,
  removeIdxWaypoints,
  replaceWaypoints,
  swapWaypoints,
}) => {
  // Handles the swapping of two waypoints' locations
  // idx refers to the index of the waypoint we're moving
  // mod refers to the modifier, 1 or -1 in this case
  const handleMove = (e, idx, mod) => {
    e.preventDefault();
    if (waypoints.length <= 1) return; // If there is 1 or less, we cannot swap or move the waypoint

    const newIndex = idx + mod;

    // If the index is out of bounds due to being too large, default it to swapping both ends
    if (waypoints.length === newIndex) return swapWaypoints(0, waypoints.length - 1);

    // If the index is out of bounds due to being too small, default it to swapping both ends
    if (newIndex === -1) return swapWaypoints(0, waypoints.length - 1);

    // If valid, switch the two indexes normally
    // There is an edge case where two indexes could be the same, this shouldn't matter though
    return swapWaypoints(idx, newIndex);
  };

  // Determines whether or not the waypoint can be swapped in a certain direction
  const handleSwapCapability = i => {
    const array = [];

    // The first waypoint should be unable to swap up
    if (i === 0) array.push('up');

    // The last waypoint should be unable to swap down
    if (i === waypoints.length - 1) array.push('down');

    return array;
  };

  /*
    Something important to note for the future. When waypoints are being used for editing,
      we will want to be able to delete them differently depending on whether or not they are
      stored on the backend. To differentiate waypoints stored on the backend from those created
      purely on the frontend, we can check whether or not the modelName attribute is present.
      We do not store this on the frontend therefore it must be present in the database, now to delete
      this, we will need to provide it as an attribute, but with the _destroy attribute set to 1.
      Example: { ...waypointRow, _destroy: 1 } - Providing this will ensure that it is removed.

      Locally, we can simply remove the index of that object and call it a day. This means that we have
        to filter the rows that are marked for destruction, we do this exactly as you would expect.
  */
  return (
    <Fragment>
      {waypoints.length <= 0 && <div className="waypoints-placeholder">No waypoints planned</div>}
      {waypoints.length > 0 &&
        waypoints.map((waypoint, i) => (
          <WaypointRow
            key={i}
            idx={i}
            row={waypoint}
            addDestroyedWaypoints={addDestroyedWaypoints}
            removeIdxWaypoints={removeIdxWaypoints}
            replaceWaypoints={replaceWaypoints}
            handleMove={handleMove}
            cannotSwap={handleSwapCapability(i)}
          />
        ))}
    </Fragment>
  );
};

VehicleRunFormWaypoints.propTypes = {
  waypoints: PropTypes.array.isRequired,
  addDestroyedWaypoints: PropTypes.func.isRequired,
  removeIdxWaypoints: PropTypes.func.isRequired,
  replaceWaypoints: PropTypes.func.isRequired,
  swapWaypoints: PropTypes.func.isRequired,
};

export default VehicleRunFormWaypoints;
