import React from 'react';
import PageNumbers from '../../components/PageNumbers/PageNumbers';
import search from '../search/search';
import Sorter from '../Sorter/Sorter';

class TableHelper {
  static onSort(header, sort, setSort) {
    if (!header.sortingAttribute) return;
    const headerAttr = header.sortingAttribute;
    if (!sort || sort.sort !== headerAttr)
      return setSort({ sort: headerAttr, order: 'asc', type: header.type });
    if (sort.sort === headerAttr && sort.order === 'desc')
      return setSort({ sort: headerAttr, order: 'asc', type: header.type });
    setSort({ sort: headerAttr, order: 'desc', type: header.type });
  }

  static genPageNumbers(currentPage, setCurrentPage, pageTotal) {
    return (
      <PageNumbers
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageTotal={pageTotal}
      />
    );
  }

  static paginationText(upperBound, lowerBound, total) {
    return `Showing ${upperBound <= 0 ? 0 : lowerBound} to ${
      upperBound <= 0 ? 0 : upperBound
    } of ${total}`;
  }

  static getLowerBound(currentPage, limit) {
    return (currentPage - 1) * limit + 1;
  }

  static getUpperBound(currentPage, limit, pageDataLength) {
    return currentPage * limit + (pageDataLength - limit);
  }

  static getPageNum(data, limit) {
    if (!data) return;
    return Math.ceil(data.length / limit);
  }

  static getSortFunction(sortType) {
    if (sortType === 'number') return Sorter.numberSort;
    return Sorter.stringSort;
  }

  static searchData(data, searchText, searchable) {
    if (!data) return;
    return search(searchText, data, searchable);
  }

  static sortData(sortFunction, data, sort) {
    if (!data) return;
    return sortFunction({ ...sort, data: [...data] });
  }

  static paginateData(data, limit, currentPage) {
    if (!data) return;

    return data.slice((currentPage - 1) * limit, currentPage * limit);
  }
}

export default TableHelper;
