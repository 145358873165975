import React from 'react';
import PropTypes from 'prop-types';
import Message from '../../../common/layout/Message/Message';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../common/button/IconButton/IconButton';
import OpeningTimesForm from '../../forms/OpeningTimesForm/OpeningTimesForm';
import './NewOpeningTime.scss';
import AttributeHelper from '../../../utils/AttributeHelper/AttributeHelper';

const NewOpeningTime = ({ idx, form, onChange, handleCancel, handleSubmit, error, setError }) => {
  const handleTimeChange = (attr, val) =>
    AttributeHelper.handlePersistentAttrChange(
      idx,
      { [attr]: val },
      'openingTimesAttributes',
      form,
      onChange,
    );

  return (
    <form className={'opening-time-form'} onSubmit={handleSubmit}>
      <Message text={error?.errors} type={'error'} onClose={() => setError()} visible={!!error} />
      <OpeningTimesForm idx={idx} form={form} onChange={handleTimeChange} />
      <div className={'form-button-line'}>
        <IconButton text={'Set'} icon={faCheck} className={'small'} />
        <IconButton
          text={'Cancel'}
          icon={faTimes}
          onClick={handleCancel}
          className={'edit small'}
        />
      </div>
    </form>
  );
};

NewOpeningTime.propTypes = {
  idx: PropTypes.number,
  form: PropTypes.object,
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  error: PropTypes.object,
  setError: PropTypes.func,
};

export default NewOpeningTime;
