import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Postcode from '../../../api/controllers/Postcode/Postcode';
import useApi from '../../hooks/useApi/useApi';
import DropdownPresenter from '../DropdownPresenter/DropdownPresenter';

const PostcodeDropdown = ({ value, drawOptions }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const { data } = useApi({
    call: () => Postcode.query(search),
    dependencies: [search],
  });

  const handleSearch = debounce(e => {
    setSearch(e.target.value);
  }, 200);

  const renderMessage = errStr => <div className={'dd-message'}>{errStr}</div>;

  const handleDropdownOptions = () => {
    const result = data?.result;
    const error = data?.error;
    if ((result === undefined || !search) && !error)
      return renderMessage('Please enter a postcode');
    if (result === null) return renderMessage('No results');
    if (error) return renderMessage(data.error);
    return drawOptions(data, setOpen);
  };

  return (
    <DropdownPresenter
      value={value}
      drawOptions={handleDropdownOptions}
      onSearch={handleSearch}
      open={open}
      testId={'postcode-dd'}
      setOpen={setOpen}
    />
  );
};

PostcodeDropdown.propTypes = {
  value: PropTypes.string,
  drawOptions: PropTypes.func,
};

export default PostcodeDropdown;
