import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import VehicleRun from '../../../../api/controllers/Movement/VehicleRun/VehicleRun';
import useApi from '../../../../common/hooks/useApi/useApi';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import useForm from '../../../../common/hooks/useForm/useForm';
import { formatVehicleRun } from '../../../../utils/Formatters/VehicleRun/formatVehicleRun';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import './VehicleRunEdit.scss';
import Message from '../../../../common/layout/Message/Message';
import NoPage from '../../../NoPage/NoPage';
import VehicleRunEditBilling from './VehicleRunEditBilling/VehicleRunEditBilling';
import VehicleRunEditGeneral from './VehicleRunEditGeneral/VehicleRunEditGeneral';
import VehicleRunHelper from '../../../../utils/VehicleRunHelper/VehicleRunHelper';

const VehicleRunEdit = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [found, setFound] = useState(true);
  const [messages, setMessages] = useState({});
  const [showArchived, setShowArchived] = useState({});

  // Handles errors caused by the vehicles useApi call below
  const handleError = err => setMessages(formatError(err));

  // Retrieves the vehicleRun entity for the Id provided
  const { vehicleRun, vehicleRunLoading } = useApi({
    call: () => VehicleRun.show(id),
    name: 'vehicleRun',
    handleError: () => setFound(false),
    dependencies: [id],
  });

  //Form is stored here now so we can actively access it
  const { form, alter, handleForm } = useForm();

  /*
   Updates the base form on Vehicle Run Change and sets the page name.
  */
  useEffect(() => {
    setMessages({});
    document.title = `Editing ${vehicleRun?.runReference || id}`;
    const usesKm = vehicleRun?.vehicle?.usesKilometres;
    const startMileage = usesKm
      ? VehicleRunHelper.mToKm(vehicleRun?.startMileage)
      : vehicleRun?.startMileage;
    const endMileage = usesKm
      ? VehicleRunHelper.mToKm(vehicleRun?.endMileage)
      : vehicleRun?.endMileage;

    handleForm({
      ...vehicleRun,
      startMileage,
      endMileage,
      financialLineItemsAttributes: vehicleRun?.financialLineItems || [],
      pcfi: false,
    });

    setShowArchived(VehicleRunHelper.handleArchivedView(vehicleRun));
  }, [vehicleRun]);

  // Submits the update request for this vehicle
  const submitVehicle = (e, form) => {
    e.preventDefault();
    setSubmitting(true);
    const params = formatVehicleRun(form);

    VehicleRun.update(id, params).then(
      () => {
        setSubmitting(false);
        navigate(`/movements/runs/${id}`);
      },
      err => {
        setSubmitting(false);
        handleError(err);
      },
    );
  };

  if (!found) return <NoPage />;
  return (
    <div className="edit-vehicle-run">
      <Breadcrumbs>
        <Link to={'/movements/runs'}>Vehicle Runs</Link>
        <Link to={`/movements/runs/${id}`}>{vehicleRun?.runReference || id}</Link>
        <Link to={`/movements/runs/${id}/edit`}>Edit</Link>
      </Breadcrumbs>
      <div className="page-content">
        {vehicleRunLoading && <LoadingSpinner />}
        {!vehicleRunLoading && form && (
          <>
            <Message
              visible={!!messages?.errors}
              text={messages.errors}
              onClick={() => setMessages([])}
              type={'error'}
            />
            <h1>Editing {vehicleRun?.runReference}</h1>
            <TabGroup
              labels={['General', 'Billing']}
              defaultTab={searchParams.get('tab') === 'billing' ? 1 : 0}
            >
              <VehicleRunEditGeneral form={form} alter={alter} showArchived={showArchived} />
              <VehicleRunEditBilling data={form} alter={alter} handleForm={handleForm} />
            </TabGroup>
            <div className="button-group">
              <IconButton
                text={'Save'}
                icon={faCheck}
                onClick={e => submitVehicle(e, form)}
                loading={submitting}
              />
              <IconButton
                text={'Cancel'}
                icon={faTimes}
                className={'edit'}
                onClick={() => navigate(`/movements/runs/${id}`)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

VehicleRunEdit.propTypes = {};

export default VehicleRunEdit;
