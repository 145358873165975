import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './PageWrapper.scss';
import { useLocation, useNavigate } from 'react-router';
import LeftBar from '../LeftBar/LeftBar';
import TopBar from '../TopBar/TopBar';
import Account from '../../api/controllers/Account/Account';
import store from '../../store';
import { DELETE_TOKEN } from '../../actions/types';
import ErrorStatusMessage from '../../common/ErrorStatusMessage/ErrorStatusMessage';

const DENYLIST = ['/movements/vehicle_runs'];

// Wraps the page with the top navbar, left nav, etc.
const PageWrapper = ({ user, permissions, children }) => {
  const [leftClosed, setLeftClosed] = useState(false);
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLogout = () =>
    Account.logout().then(
      () => store.dispatch({ type: DELETE_TOKEN }),
      () => {},
    );

  if (DENYLIST.filter(path => pathname.includes(path)).length > 0) return children;
  return (
    <div className="page-wrapper">
      <div className="page-wrapper-top">
        <TopBar
          user={user}
          toggleSidebar={() => setLeftClosed(!leftClosed)}
          permissions={permissions}
          leftClosed={leftClosed}
          onClick={() => navigate('/profile')}
          handleLogout={handleLogout}
          setMessage={setMessage}
        />
      </div>
      <div className="page-wrapper-body">
        <div className="page-wrapper-left">
          <LeftBar
            user={user}
            leftClosed={leftClosed}
            permissions={permissions}
            handleLogout={handleLogout}
          />
        </div>
        <div className="page-wrapper-content">
          <ErrorStatusMessage error={message} setError={setMessage} />
          {children}
        </div>
      </div>
    </div>
  );
};

PageWrapper.propTypes = {
  user: PropTypes.object,
  permissions: PropTypes.object,
  children: PropTypes.node,
};

export default PageWrapper;
