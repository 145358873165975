import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Placeholders.scss';
import { Modifier } from 'draft-js';
import EditorState from 'draft-js/lib/EditorState';
import TreeView from '../../../../../utils/TreeView/TreeView';
import { humanize } from '../../../../../utils/EnumHelper/EnumHelper';
import TreePanel from '../../../EmailTemplateShow/subComponents/TreePanel/TreePanel';
import SelectionState from 'draft-js/lib/SelectionState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Placeholders = ({
  data,
  editorState,
  setEditorState,
  subjectInput,
  setSubject,
  editorRef,
  associatedModel,
  editorFocused,
}) => {
  const [subjectActive, setSubjectActive] = useState(false);
  const [placeholdersTree, setPlaceholdersTree] = useState();
  const [placeholdersArray, setPlaceholdersArray] = useState();
  const [modelTitle, setModelTitle] = useState('');
  const [prefix, setPrefix] = useState('');
  const [cursorPosition, setCursorPosition] = useState();
  const [offset, setOffset] = useState();

  const handleClick = el => {
    if (subjectActive) {
      subjectInput.current.focus();
      subjectInput.current.selectionEnd = subjectInput.current.value.length - cursorPosition;
      return;
    }
    editorRef.current.focus();
    const selection = editorState.getSelection();
    const extraSpacerOffset = prefix.length > 0 ? 2 : 0; // if there is a prefix there is __ added between the end of prefix and el, this accounts for it
    /*
      el: final part of string inserted 
      prefix: the bit seperated by double underscores representing the folders of the tree
      offset: the cursor position
      4: accounts for the {{}} added to the string
      extraSpacerOffset: accounts for added __ between prefix and el
    */
    const updateSelection = new SelectionState({
      anchorKey: selection.anchorKey,
      anchorOffset: el.length + prefix.length + offset + 4 + extraSpacerOffset,
      focusKey: selection.anchorKey,
      focusOffset: el.length + prefix.length + offset + 4 + extraSpacerOffset,
      isBackward: false,
    });
    setEditorState(EditorState.forceSelection(editorState, updateSelection));
  };

  useEffect(() => {
    const placeholderData = new TreeView();
    placeholderData.create(associatedModel, data);
    setPlaceholdersTree(placeholderData);
    setPlaceholdersArray(placeholderData.placeholders);
    setModelTitle(placeholderData.model);
  }, []);

  const insertText = (selection, content, textToInsert) => {
    setSubjectActive(false);
    setOffset(selection.focusOffset);
    setEditorState(
      EditorState.set(editorState, {
        currentContent: Modifier.insertText(content, selection, textToInsert),
      }),
    );
  };
  const replaceText = (selection, content, textToInsert) => {
    setSubjectActive(false);
    setOffset(selection.focusOffset);
    return setEditorState(
      EditorState.set(editorState, {
        currentContent: Modifier.replaceText(content, selection, textToInsert),
      }),
    );
  };

  const handleMouseDown = el => {
    let insertEl;
    prefix === '' ? (insertEl = el) : (insertEl = prefix + '__' + el);
    const textToInsert = `{{${insertEl}}}`;

    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();

    if (selection.anchorOffset !== selection.focusOffset) {
      // If these offsets are not equal it means user have selected some text
      // than we need need to use replacetText
      return replaceText(selection, content, textToInsert);
    }

    if (!editorFocused) {
      setSubjectActive(true);
      let cursorPosition = subjectInput.current.selectionStart;
      setCursorPosition(subjectInput.current.value.length - cursorPosition);
      let textBeforeCursorPosition = subjectInput.current.value.substring(0, cursorPosition);
      let textAfterCursorPosition = subjectInput.current.value.substring(
        cursorPosition,
        subjectInput.current.value.length,
      );
      setSubject(textBeforeCursorPosition + textToInsert + textAfterCursorPosition);
    } else if (editorFocused) {
      insertText(selection, content, textToInsert);
    }
  };

  const renderHeaders = modelName => {
    if (!modelName) return;
    if (modelName === placeholdersTree.model) return humanize(modelName);
    return modelName
      .split('-')
      .filter(el => el !== placeholdersTree.model)
      .map((el, i, arr) => {
        return (
          <span key={el}>
            {humanize(el)}
            {i !== arr.length - 1 && arr.length !== 1 && <FontAwesomeIcon icon={faAngleRight} />}
          </span>
        );
      });
  };

  if (!placeholdersTree) return null;

  return (
    <div className="placeholders">
      <div className="form-section">
        <div className="form-section-head">
          <div>Placeholders</div>
        </div>
        <div className="panels">
          <div className="panel hint-panel">
            <div className="hint">
              <span className="hint-title">Hint:</span> See the{' '}
              <a
                href="https://github.com/Shopify/liquid/wiki/Liquid-for-Designers"
                target="_blank"
                rel="noreferrer"
              >
                Liquid Wiki
              </a>{' '}
              for advanced formatting tricks.
            </div>
          </div>
          <div className="panel">
            <div className="panel-tree-container">
              <TreePanel
                placeholdersTree={placeholdersTree}
                setPlaceholdersArray={setPlaceholdersArray}
                setPrefix={setPrefix}
                modelName={placeholdersTree.model}
                setModelTitle={setModelTitle}
              />
            </div>
          </div>
          <div className="panel-title">{renderHeaders(modelTitle)}</div>
          <div className="panel">
            <div className="panel-placeholders">
              {placeholdersArray &&
                placeholdersArray.map(el => {
                  return (
                    <div
                      key={el}
                      onMouseDown={() => handleMouseDown(el)}
                      onClick={() => handleClick(el)}
                      className="placeholder-item"
                    >
                      {humanize(el)}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Placeholders.propTypes = {
  data: PropTypes.array,
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  subjectInput: PropTypes.object,
  setSubject: PropTypes.func,
  editorRef: PropTypes.object,
  associatedModel: PropTypes.string,
  editorFocused: PropTypes.bool,
};

export default Placeholders;
