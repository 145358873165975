import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

// Handles all user requests.
class Entity {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'companies/entities', data);
  }

  static show(id) {
    return apiCall('GET', `companies/entities/${id}`);
  }

  static create(params) {
    const data = { entity: params };
    return apiCall('POST', 'companies/entities', data);
  }

  static update(id, params) {
    const data = { entity: params };
    return apiCall('PATCH', `companies/entities/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `companies/entities/${id}`);
  }

  /* Provide this with a list of ids and it will return a promise that resolves when all are retrieved */
  static showMany(arrayOfIds = []) {
    const promises = arrayOfIds.map(this.show);
    return Promise.all(promises);
  }
}

export default Entity;
