import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './VehicleGuideField.scss';

const VehicleGuideField = ({ icon, text, className }) => (
  <div className={`guide-field-wrap ${className}`}>
    <FontAwesomeIcon icon={icon} />
    <div className="guild-field-text">{text}</div>
  </div>
);

VehicleGuideField.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default VehicleGuideField;
