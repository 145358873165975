import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal/Modal';
import FormSection from '../../FormSection/FormSection';
import VehicleRunHelper from '../../../utils/VehicleRunHelper/VehicleRunHelper';
import FormField from '../../FormField/FormField';
import './FinalizeRun.scss';
import ValueInput from '../../../common/input/ValueInput/ValueInput';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Message from '../../../common/layout/Message/Message';

const FinalizeRun = ({ run, ppl, handleClose, refresh }) => {
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [finalCost, setFinalCost] = useState('');

  const handleSubmit = () => {
    VehicleRunHelper.toggleFinalize(
      run,
      {
        fuelCost: Number(finalCost),
        baseCost: Number(run?.vehicle?.baseCost),
      },
      setLoading,
      setMessage,
      refresh,
      handleClose,
    );
  };

  const labelStatus = label => (label > 0 ? 'fr-success' : 'fr-fail');

  return (
    <Modal isOpen handleClose={handleClose}>
      <FormSection title="Enter Fuel Cost">
        {message && (
          <Message type={message.type} text={message.text} visible onClick={() => setMessage()} />
        )}
        <div className="finalize-run-wrap">
          <div>A fuel cost could not be calculated automatically:</div>
          <div className="fr-known-values">
            <div className="fr-kv">
              <div className={labelStatus(run.startMileage)}>Start Mileage:</div>
              <div>{run.startMileage || '--'}</div>
            </div>
            <div className="fr-kv">
              <div className={labelStatus(run.endMileage)}>End Mileage:</div>
              <div>{run.endMileage || '--'}</div>
            </div>
            <div className="fr-kv">
              <div className={labelStatus(run?.vehicle?.milesPerGallon)}>Miles Per Gallon:</div>
              <div>{`${VehicleRunHelper.renderPrice(run?.vehicle?.milesPerGallon)}`}</div>
            </div>
            <div className="fr-kv">
              <div className={labelStatus(ppl)}>Fuel Price (ppl):</div>
              <div>{`${VehicleRunHelper.renderPrice(ppl)}`}</div>
            </div>
          </div>
          <p>Please enter a manual cost to continue:</p>
          <FormField label={'Final Fuel Cost'}>
            <ValueInput
              unit={'£'}
              reverse
              value={finalCost}
              onChange={e => setFinalCost(e.target.value)}
              min={0}
              step={0.01}
              autofocus
              testId="fr-input"
            />
          </FormField>
          <div className="fr-submit">
            <IconButton
              text="Finalise"
              icon={faCheck}
              loading={loading}
              onClick={handleSubmit}
              testId="fr-submit"
            />
            <IconButton text="Cancel" icon={faTimes} className={'edit'} onClick={handleClose} />
          </div>
        </div>
      </FormSection>
    </Modal>
  );
};

FinalizeRun.propTypes = {
  run: PropTypes.object.isRequired,
  ppl: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default FinalizeRun;
