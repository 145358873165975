export const backgroundColorStyleMap = {
  'bgcolor-red': {
    background: 'rgba(255, 0, 0, 1.0)',
  },
  'bgcolor-orange': {
    background: 'rgba(255, 127, 0, 1.0)',
  },
  'bgcolor-yellow': {
    background: 'rgba(180, 180, 0, 1.0)',
  },
  'bgcolor-green': {
    background: 'rgba(0, 180, 0, 1.0)',
  },
  'bgcolor-blue': {
    background: 'rgba(0, 0, 255, 1.0)',
  },
  'bgcolor-indigo': {
    background: 'rgba(75, 0, 130, 1.0)',
  },
  'bgcolor-violet': {
    background: 'rgba(127, 0, 255, 1.0)',
  },
  'bgcolor-black': {
    background: 'rgba(0, 0, 0, 1.0)',
  },
  'bgcolor-white': {
    background: 'rgba(255, 255, 255, 1.0)',
  },
};
