import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './UserIndex.scss';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import Ribbon from '../../../components/Ribbon/Ribbon';
import UserApi from '../../../api/controllers/User/User';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import useApi from '../../../common/hooks/useApi/useApi';
import Table from '../../../components/Table/Table/Table';
import DateHelper from '../../../utils/DateHelper/DateHelper';
import Message from '../../../common/layout/Message/Message';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import ErrorBoundary from '../../../common/ErrorBoundary/ErrorBoundary';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import UserHelper from '../../../utils/UserHelper/UserHelper';
import { useNavigate } from 'react-router';

// It's an index it indexes things
const UserIndex = () => {
  const { data, dataLoading, refresh } = useApi({
    call: UserApi.all,
    handleError: e => setError(formatError(e)),
  });
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  document.title = 'All Users';

  const handleDelete = id => {
    setLoading('delete');
    UserApi.destroy(id).then(
      () => {
        setLoading();
        setOpen();
        refresh();
      },
      json => {
        setLoading();
        setError(formatError(json));
      },
    );
  };

  const headers = [
    { text: 'Name', sortingAttribute: 'firstName' },
    { text: 'Email', sortingAttribute: 'email' },
    { text: 'Roles' },
    { text: 'Status' },
    { text: 'Last Active' },
    { text: 'Actions' },
  ];

  const handleDrawRow = (item, i) => (
    <tr key={i} className={i % 2 === 0 ? '' : 'coloured'}>
      <td className="large">
        <Link to={`/users/${item.id}`}>
          {item.firstName} {item.lastName}
        </Link>
      </td>
      <td>{item.email}</td>
      <td>{UserHelper.getRoleString(item)}</td>
      <td className="small">{item.lockedAt ? 'Locked' : 'Active'}</td>
      <td>{DateHelper.format(item.lastSignInAt)}</td>
      <td className="actions">
        <span className="td-buttons">
          <IconButton
            text={'Edit'}
            icon={faPen}
            onClick={() => navigate(`/users/${item.id}/edit`)}
            className="edit small"
          />
          <IconButton
            text={'Delete'}
            icon={faTrash}
            onClick={() => setOpen(item)}
            className="destructive small"
          />
        </span>
      </td>
    </tr>
  );

  return (
    <div className="all-users">
      <Breadcrumbs>
        <Link to={'/users'}>Users</Link>
      </Breadcrumbs>
      <Ribbon>
        <IconButton text={'Add User'} icon={faPlus} onClick={() => navigate('/users/new')} />
      </Ribbon>
      <div className="page-content">
        <ConfirmationModal
          question={'Are you sure you want to delete the user?'}
          subText={open?.email}
          confirmText={'Delete'}
          isOpen={open}
          handleConfirm={() => handleDelete(open?.id)}
          handleCancel={() => setOpen()}
          loading={loading === 'delete'}
        />
        <Message text={error?.errors} type={'error'} onClick={() => setError()} visible={!!error} />
        <ErrorBoundary>
          {!dataLoading && (
            <Table
              title={'Users'}
              headers={headers}
              data={data}
              onDrawRow={handleDrawRow}
              searchable={['firstName', 'email']}
              defaultSort={{ sort: headers[0].sortingAttribute, order: 'asc' }}
            />
          )}
          {dataLoading && <LoadingSpinner />}
        </ErrorBoundary>
      </div>
    </div>
  );
};

UserIndex.propTypes = {};

export default UserIndex;
