import SalesCredit from '../../api/controllers/Financial/SalesCredit/SalesCredit';
import { formatError } from '../Formatters/Errors/formatError';

export const handleDeleteDocument = (e, data, navigate, index, setError, setOpen) => {
  e.preventDefault();
  SalesCredit.destroy(data.id).then(
    () => {
      setError({
        message: `${data.documentReference} has successfully deleted`,
        type: 'success',
      });
      setOpen(false);
      navigate(index);
    },
    err => {
      setError({ ...formatError(err), errorTitle: 'Delete Failed!', type: 'error' });
    },
  );
};
