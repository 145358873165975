import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './LinkShow.scss';

const LinkShow = ({ text, url, testId, newTab = true, placeholder = '', anchor = false }) => {
  const target = newTab ? '_blank' : '';

  const renderLink = () =>
    anchor ? (
      <a href={url} target={target}>
        {text}
      </a>
    ) : (
      <Link to={url} target={target}>
        {text}
      </Link>
    );

  return (
    <div className="link-show-wrap" data-testid={testId}>
      {url && renderLink()}
      {!url && <a className={'ls-placeholder'}>{placeholder}</a>}
    </div>
  );
};

LinkShow.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  testId: PropTypes.string,
  newTab: PropTypes.bool,
  placeholder: PropTypes.string,
  anchor: PropTypes.bool,
};

export default LinkShow;
