import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TabGroup from '../../../../../../components/TabGroup/TabGroup';
import ProfitabilitySubTab from './ProfitabilitySubTab/ProfitabilitySubTab';
import './ProfitabilityTab.scss';

const ProfitabilityTab = ({ entityId }) => {
  const [years, setYears] = useState();

  useEffect(() => {
    if (!years) setYears(getYears());
  }, []);

  // Returns an array of years from this year to 2021
  const getYears = () => {
    const labels = [2021];
    const tabs = new Date().getFullYear() - 2021;
    const curYear = new Date().getFullYear();
    for (let i = 0; i < tabs; i++) labels.push(curYear - i);
    return labels.sort((a, b) => b - a);
  };

  return (
    <div className="tab-content profitab">
      <TabGroup labels={years}>
        {years &&
          years.map((year, i) => <ProfitabilitySubTab key={i} entityId={entityId} year={year} />)}
      </TabGroup>
    </div>
  );
};

ProfitabilityTab.propTypes = {
  entityId: PropTypes.string.isRequired,
};

export default ProfitabilityTab;
