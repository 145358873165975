import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CancelJob.scss';
import Modal from '../../../common/Modal/Modal';
import Message from '../../../common/layout/Message/Message';
import IconButton from '../../../common/button/IconButton/IconButton';
import FormSection from '../../FormSection/FormSection';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Booking from '../../../api/controllers/Job/Booking/Booking';
import { formatError } from '../../../utils/Formatters/Errors/formatError';

const CancelJob = ({ isOpen, setIsOpen, jobId, setSuccessMessage, refresh }) => {
  const [error, setError] = useState();
  const [reason, setReason] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    if (reason === '') return setError(formatError({ error: "Reason can't be blank!" }));
    Booking.update(jobId, { status: 'cancelled', cancellationReason: reason }).then(
      () => {
        setSuccessMessage('Job has successfully been Cancelled.');
        setIsOpen(false);
        refresh();
      },
      err => setError(formatError(err)),
    );
  };

  const handleCancel = () => {
    setIsOpen(false);
    setReason('');
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleCancel}>
      <FormSection title={'Cancelling Job'} onSubmit={handleSubmit} draggable>
        <div className={'cancel-job-modal'}>
          <Message
            text={error?.errors}
            type={'error'}
            onClick={() => setError()}
            visible={!!error}
            onClose={setError}
          />
          <div className={'cancel-text'}>
            <div className={'cancel-title'}>Reason</div>
            <textarea
              placeholder={'Enter reason'}
              value={reason}
              onChange={e => setReason(e.target.value)}
              className={'cancel-reason'}
            ></textarea>
          </div>
          <div className={'af-button-line'}>
            <IconButton
              text={'Cancel Job'}
              icon={faCheck}
              className={'small'}
              onClick={handleSubmit}
            />
            <IconButton
              text={'Cancel'}
              icon={faTimes}
              onClick={handleCancel}
              className={'edit small'}
            />
          </div>
        </div>
      </FormSection>
    </Modal>
  );
};
CancelJob.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  jobId: PropTypes.number,
  setSuccessMessage: PropTypes.func,
  refresh: PropTypes.func,
};
export default CancelJob;
