import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../../components/FormSection/FormSection';
import NumberHelper from '../../../../../../../utils/NumberHelper/NumberHelper';
import { Link } from 'react-router-dom';
import VehicleRunHelper from '../../../../../../../utils/VehicleRunHelper/VehicleRunHelper';

// Takes a { total: 1, items: [{ booking }, { booking }] } object as allJobs
// This is SPECIFICALLY for non-subcontracted jobs
const FinancialsTab = ({ allJobs, run }) => {
  const [revCost, setRevCost] = useState();

  // Gets the total sum of the attribute provided from all data.items
  // Likely salesValue and costsValue
  const calculateTotal = attr => allJobs.items.reduce((a, booking) => a + Number(booking[attr]), 0);

  const setFuelBase = async () => {
    if (run.subcontracted || !run.vehicle) return;

    const fuelAndBase = await VehicleRunHelper.getFuelAndBase(run);
    setRevCost(fuelAndBase);
  };

  useEffect(() => {
    setFuelBase();
  }, []);

  // Calculates the total profit of all line items
  const calculateProfit = () =>
    calculateTotal('salesValue') - calculateTotalCosts() - calculateTotal('costsValue');

  const calculateFliCosts = () =>
    Number(
      run.financialLineItems.reduce((a, b) => a + Number(b.credit ? `-${b.value}` : b.value), 0),
    );

  const sanitizeNaN = val => (isNaN(val) ? 0 : val);

  const calculateTotalCosts = () => {
    const fliCosts = calculateFliCosts();
    if (run.subcontracted) return fliCosts;

    return sanitizeNaN(Number(revCost.baseCost)) + sanitizeNaN(Number(revCost.fuelCost)) + fliCosts;
  };

  const canShowTotals = !!revCost || run.subcontracted;

  return (
    <Fragment>
      <FormSection title="Per-Job">
        <table>
          <thead>
            <tr>
              <th>Job Ref</th>
              <th className="right">Sales</th>
              <th className="right">Cost</th>
            </tr>
          </thead>
          <tbody>
            {allJobs.items.map((booking, i) => (
              <tr key={i}>
                <td className="small">
                  <Link to={`/jobs/bookings/${booking.id}`} target={'_blank'}>
                    {booking.jobReference}
                  </Link>
                </td>
                <td className="tiny right">
                  {Number(booking.salesValue) !== 0 &&
                    NumberHelper.formatNumToCurrency(booking.salesValue)}
                </td>
                <td className="tiny right">
                  {Number(booking.costsValue) !== 0 &&
                    NumberHelper.formatNumToCurrency(booking.costsValue)}
                </td>
              </tr>
            ))}
            <tr className="lined">
              <td className="small bold"></td>
              <td className="tiny bold right">
                {NumberHelper.formatNumToCurrency(calculateTotal('salesValue'))}
              </td>
              <td className="tiny bold right">
                {NumberHelper.formatNumToCurrency(calculateTotal('costsValue'))}
              </td>
            </tr>
          </tbody>
        </table>
      </FormSection>
      <FormSection title="Overall">
        <table>
          <thead>
            <tr>
              <th>Revenue/Cost</th>
              <th className="right">Sales</th>
              <th className="right">Cost</th>
            </tr>
          </thead>
          <tbody>
            <Fragment>
              {!run.subcontracted && (
                <Fragment>
                  <tr>
                    <td className="small">Base</td>
                    <td className="tiny"></td>
                    <td className="tiny right">
                      {VehicleRunHelper.renderPrice(revCost?.baseCost)}
                    </td>
                  </tr>
                  <tr>
                    <td className="small">Fuel</td>
                    <td className="tiny"></td>
                    <td className="tiny right">
                      {VehicleRunHelper.renderPrice(revCost?.fuelCost)}
                    </td>
                  </tr>
                </Fragment>
              )}
              {run.financialLineItems
                .sort((a, b) => Number(a.credit) - Number(b.credit))
                .map((fli, i) => (
                  <tr key={i}>
                    <td className="small">{fli.description}</td>
                    <td className="tiny"></td>
                    <td className="tiny right">
                      {NumberHelper.formatNumToCurrency(fli.credit ? fli.value * -1 : fli.value)}
                    </td>
                  </tr>
                ))}
              {canShowTotals && (
                <Fragment>
                  <tr className="lined">
                    <td className="small bold"></td>
                    <td className="tiny bold"></td>
                    <td className="tiny bold right">
                      {NumberHelper.formatNumToCurrency(calculateTotalCosts())}
                    </td>
                  </tr>
                  <tr className="lined">
                    <td className="small bold">Profit/Loss</td>
                    <td className="tiny bold"></td>
                    <td className="tiny bold right">
                      {NumberHelper.formatNumToCurrency(calculateProfit())}
                    </td>
                  </tr>
                </Fragment>
              )}
            </Fragment>
          </tbody>
        </table>
      </FormSection>
    </Fragment>
  );
};

FinancialsTab.propTypes = {
  allJobs: PropTypes.object.isRequired,
  run: PropTypes.object.isRequired,
};

export default FinancialsTab;
