import React from 'react';
import PropTypes from 'prop-types';
import './LabelBar.scss';

const LabelBar = ({ text = 'Label Name' }) => {
  return (
    <div className="label-container">
      <div className="lb-text">{text}</div>
    </div>
  );
};

LabelBar.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
};

export default LabelBar;
