import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BillingLines from '../../../../../../components/BillingLines/BillingLines';
import FormSection from '../../../../../../components/FormSection/FormSection';
import './VehicleRunShowBilling.scss';
import { faList } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../../../common/button/IconButton/IconButton';
import VehicleRun from '../../../../../../api/controllers/Movement/VehicleRun/VehicleRun';
import SystemSetting from '../../../../../../api/controllers/SystemSetting/SystemSetting';
import TaxCode from '../../../../../../api/controllers/Financial/TaxCode/TaxCode';
import { formatError } from '../../../../../../utils/Formatters/Errors/formatError';

const VehicleRunShowBilling = ({ run, navigate, setModalAlert, canAutoLine = false }) => {
  const [loading, setLoading] = useState(false);
  const purchaseItems = run.financialLineItems.filter(li => li.purchase && !li.credit);
  const purchaseCreditItems = run.financialLineItems.filter(li => li.purchase && li.credit);

  const handleCalculateCosts = async () => {
    setLoading(true);
    SystemSetting.show().then(({ subcontractChargeCode }) => {
      TaxCode.all()
        .then(taxCodes => {
          const code = taxCodes.items.find(code => code.code === '1');
          const res = run.financialLineItems.find(
            fli => fli.chargeCode?.id === subcontractChargeCode?.id,
          );

          const lineItem = {
            description: subcontractChargeCode?.description,
            entityId: run.subcontractor?.id,
            chargeCodeId: subcontractChargeCode?.id,
            purchase: true,
            credit: false,
            value: run.subcontractCost,
            taxCodeId: code.id,
            finalised: false,
          };

          if (res) lineItem['id'] = res.id;
          VehicleRun.update(run.id, {
            financialLineItemsAttributes: [lineItem],
          }).then(
            () => navigate(`/movements/runs/${run.id}/edit?tab=billing`),
            err => setModalAlert(formatError(err)),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const renderCostsCalculateButton = () =>
    run.subcontracted &&
    !run.closed &&
    canAutoLine && (
      <IconButton
        text="Calculate Costs"
        icon={faList}
        className={'calculate-costs-button'}
        onClick={handleCalculateCosts}
        loading={loading}
      />
    );

  return (
    <div className="vrs-billing tab-content">
      <FormSection title={'Costs'} optionalElement={renderCostsCalculateButton()}>
        <BillingLines items={purchaseItems} documentType={'cost'} documentTitle={'Invoice'} />
      </FormSection>
      {purchaseCreditItems.length > 0 && (
        <FormSection title={'Purchase Credits'}>
          <BillingLines
            items={purchaseCreditItems}
            documentType={'purchase credit'}
            documentTitle={'Credit Note'}
          />
        </FormSection>
      )}
    </div>
  );
};

VehicleRunShowBilling.propTypes = {
  run: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  setModalAlert: PropTypes.func.isRequired,
  canAutoLine: PropTypes.bool,
};

export default VehicleRunShowBilling;
