import TaxCode from '../../api/controllers/Financial/TaxCode/TaxCode';
import Booking from '../../api/controllers/Job/Booking/Booking';
import SystemSetting from '../../api/controllers/SystemSetting/SystemSetting';
import Area from '../../api/controllers/Tariff/Area/Area';
import BookingHelper from '../BookingHelper/BookingHelper';
import { formatBooking } from '../Formatters/Job/Booking/formatBooking';
import VehicleRunHelper from '../VehicleRunHelper/VehicleRunHelper';

/**
 * The Class for generating line items on a booking,
 * Takes a list of bookings then the id for fs, and when
 * generate() is called, will find and update the booking with the line
 * items, returning either {} or {error: ..} depending on its success or failure
 */
class AutoLineCalculator {
  constructor(booking, fsId) {
    this.booking = booking;
    this.fsId = fsId;
    this.area;
    //get the taxable weight for the main line item charge
    this.taxableWeight = BookingHelper.calculateTaxableWeight(booking);

    const direction = this._getDirection(this.booking);
    this.locationPostcode =
      direction === 'DEL'
        ? this.booking.deliveryAddress.postcode
        : this.booking.collectionAddress.postcode;

    //Define charge / tax codes to be used later
    this.waste,
      this.tailLift,
      this.overlength,
      this.areaSurcharges,
      this.taxCodes,
      this.collectionChargeCode,
      this.deliveryChargeCode,
      this.timed;
  }

  /*
    The main function to generate line items
    First fetching info to be used later, erroring if cant be found
    Then attempts to generate the main charge using the location's Area and the taxable weight
    Then will attempt to generate any surcharges.

    After we have these fresh line items, we check if we actually need to make an update request, 
    if the answer is no, we just return an error, otherwise we update.
  */
  async generate() {
    await this._fetchInfo();
    await this._fetchTaxCodes();
    //find and set the area or stop running due to no area
    await this._findArea(this.locationPostcode);
    if (!this.area)
      return {
        status: 'AutoLine',
        error: `[${this.locationPostcode}] is not assigned to a tariff area`,
      };
    //generate the main charge
    const mainCharge = this._getMainChargeLine(
      this._getChargeFromTaxableWeight(this.area, this.taxableWeight),
    );
    //find surcharges
    const otherCharges = this._getSurcharges(this.booking, mainCharge);
    const lineItems = [mainCharge, ...otherCharges];
    //check we need to update and do so.
    if (this._itemsHaveChanged(this.booking.financialLineItems, lineItems)) {
      return await this._updateBooking(lineItems);
    }
    return { status: 'AutoLine', error: 'No Changes are required' };
  }

  //checks that for any of the charges types, one of them has changed.
  _itemsHaveChanged(oldItems, newItems) {
    if (this._hasAttribChanged(oldItems, newItems, this.deliveryChargeCode)) return true; //Delivery
    if (this._hasAttribChanged(oldItems, newItems, this.collectionChargeCode)) return true; //Collection
    if (this._hasAttribChanged(oldItems, newItems, this.waste)) return true; //waste
    if (this._hasAttribChanged(oldItems, newItems, this.tailLift)) return true; //tailLift
    if (this._hasAttribChanged(oldItems, newItems, this.overlength, { isOverlength: true }))
      return true; //overlength
    if (this._areaChargesChanged(oldItems, newItems)) return true; //special areas
    if (this._hasAttribChanged(oldItems, newItems, this.timed)) return true; //timed
    return false; //if nothing changed return false
  }

  //finds the old charge and new charge for a give attribute and checks if its changed
  _hasAttribChanged(oldItems, newItems, attribute, { isOverlength } = { isOverlength: false }) {
    const oldCharge = oldItems.find(item =>
      this._findByAttribute(item, attribute, { isOverlength }),
    );
    const newCharge = newItems.find(item =>
      this._findByAttribute(item, attribute, { isOverlength }),
    );
    //if both exist, but the values are different, something is changed
    if (oldCharge && newCharge && String(newCharge.value) !== oldCharge.value) return true;
    if (oldCharge && !newCharge) return true; //has Old but no New
    if (newCharge && !oldCharge) return true; //has new but no Old
    return false;
  }

  _areaChargesChanged(oldItems, newItems) {
    const areaChargeCodes = this.areaSurcharges.map(area => area.chargeCode && area.chargeCode.id);
    //escape calculation if theres no areas
    if (areaChargeCodes.length === 0) return false;
    //find the old and new area charges
    const oldAreaCharges = oldItems.filter(li => areaChargeCodes.includes(li.chargeCode?.id));
    const newAreaCharges = newItems.filter(li => areaChargeCodes.includes(li.chargeCode?.id));
    //if they are not the same length, something has changed
    if (oldAreaCharges.length !== newAreaCharges.length) return true;

    //Checks if each value old set matches the new set.
    //This compares against the chargeCode to make sure the value match is relevant
    const chargeValuesSame =
      oldAreaCharges.filter(li =>
        newAreaCharges.find(
          newLi => newLi.chargeCode.id === li.chargeCode.id && newLi.value === li.value,
        ),
      ).length === oldAreaCharges.length;
    //true only if something changed
    if (!chargeValuesSame) return true;
    return false;
  }

  _findByAttribute({ chargeCode, value }, attr, { isOverlength } = { isOverlength: false }) {
    if (isOverlength) return chargeCode?.id === attr?.chargeCode?.id;
    if (attr?.chargeCode) {
      if (attr.value) return chargeCode?.id === attr?.chargeCode?.id && value === attr.value;
      return chargeCode?.id === attr?.chargeCode?.id && !attr.value;
    }
    return chargeCode?.id === attr?.id;
  }

  _getChargeFromTaxableWeight(area, taxableWeight) {
    const { bands, minimumValue, maximumValue } = area;
    //Find the bands we need for calculation
    const band = bands.find(band => band.taxableWeight > taxableWeight) || bands[bands.length - 1];
    const prevBand = bands[bands.findIndex(bnd => bnd.id === band.id) - 1];
    //work out the previous band's max cost
    const prevBandMax = (prevBand?.value * prevBand?.taxableWeight) / 100 || 0;
    //calculate the cost
    const price = (taxableWeight * band?.value) / 100 || 0;
    const finalPrice = Math.max(prevBandMax, price);
    //Stops the price from being over the max or min value for a given area.
    if (finalPrice < minimumValue) return minimumValue;
    if (finalPrice > maximumValue) return maximumValue;
    return finalPrice;
  }

  //Simply returns DEL/COL/XT but in a shorthand call.
  _getDirection(booking) {
    return VehicleRunHelper.getJobDirection(
      this.fsId,
      booking.collectionAddress?.id,
      booking.deliveryAddress?.id,
    );
  }

  /*
    Returns a line items for a give value.
    gets the chargeCode from the direction
    finds the relevant taxCode (Standard)
    and puts it all in the object that is returned.
  */
  _getMainChargeLine(value) {
    const dir = this._getDirection(this.booking);
    const chargeCode = dir === 'COL' ? this.collectionChargeCode : this.deliveryChargeCode;
    const taxCode = this.taxCodes.find(code => code.code === '1');
    return {
      chargeCode,
      taxCode,
      value,
      description: chargeCode?.description,
      entity: this.booking.customer,
    };
  }

  /*
    Generates any required surcharges

    Each surcharge is checked for it being active (its active if value is NOT empty / zero)
  */
  _getSurcharges(booking, mainCharge) {
    //Get the surcharge relevant info from the booking.
    const { tailLiftOnCollection, tailLiftOnDelivery, waste, timedCollection, timedDelivery } =
      booking;
    const entity = booking.customer; // Retrieve the customer for the charges.
    let surcharges = [];
    const taxCode = this.taxCodes.find(code => code.code === '1'); //get the "Standard" tax code
    //Check if waste surcharge is active.
    if (this.waste?.value) {
      const { chargeCode, value } = this.waste;
      //Add if booking has waste
      if (waste)
        surcharges.push({
          chargeCode,
          taxCode,
          description: chargeCode?.description,
          value,
          entity,
        });
    }
    //Check if tailLift surcharge is active
    if (this.tailLift?.value) {
      const { chargeCode, value } = this.tailLift;
      //If tailLift is on either direction, add the charge
      if (tailLiftOnCollection || tailLiftOnDelivery)
        surcharges.push({
          chargeCode,
          taxCode,
          description: chargeCode?.description,
          value,
          entity,
        });
    }
    //Check if overlength is active
    if (this.overlength.value) {
      const { chargeCode, value } = this.overlength;
      //Adds the surcharge if we find a dimensionLineItem over 300cm
      if (booking.dimensionLineItems?.find(item => item.lengthCm > 300 || item.widthCm > 300))
        surcharges.push({
          chargeCode,
          taxCode,
          description: chargeCode?.description,
          value: (mainCharge.value * value) / 100,
          entity,
        });
    }
    //Checks if there is current area surcharges
    if (this.areaSurcharges.length > 0) {
      //filter to the current area.
      const areaSurcharges = this.areaSurcharges.filter(({ area }) => area.id === this.area.id);
      //goes through each that can apply
      areaSurcharges.forEach(areaSurcharge => {
        //checks its active and adds the charge
        if (areaSurcharge.surcharge)
          surcharges.push({
            chargeCode: areaSurcharge.chargeCode,
            taxCode,
            description: areaSurcharge.chargeCode?.description,
            value: areaSurcharge.surcharge,
            entity,
          });
      });
    }
    // Check if timed is active
    if (this.timed.value) {
      const { chargeCode, value } = this.timed;
      // adds charge if timed collection or delivery is true
      if (timedCollection || timedDelivery)
        surcharges.push({
          chargeCode,
          taxCode,
          description: chargeCode?.description,
          value,
          entity,
        });
    }
    return surcharges;
  }

  _concatAndUpdateOld(oldLineItems, newLineItems) {
    //gets the ids for areas.
    const areaSurchargeIds = this.areaSurcharges.map(
      charge => charge.chargeCode && charge.chargeCode.id,
    );
    //filters surcharges out of bookings
    const oldFiltered = oldLineItems.filter(
      ({ chargeCode }) =>
        chargeCode.id !== this.deliveryChargeCode?.id &&
        chargeCode.id !== this.collectionChargeCode?.id &&
        chargeCode.id !== this.waste?.chargeCode?.id &&
        chargeCode.id !== this.tailLift?.chargeCode?.id &&
        chargeCode.id !== this.overlength?.chargeCode?.id &&
        chargeCode.id !== this.timed?.chargeCode?.id &&
        !areaSurchargeIds.includes(chargeCode.id),
    );
    //For the simple charges, chooses the charge we want to keep (new / old but finalised)
    const chosenItems = [
      this._checkAndUpdateAttribute(oldLineItems, newLineItems, this.deliveryChargeCode),
      this._checkAndUpdateAttribute(oldLineItems, newLineItems, this.collectionChargeCode),
      this._checkAndUpdateAttribute(oldLineItems, newLineItems, this.waste),
      this._checkAndUpdateAttribute(oldLineItems, newLineItems, this.tailLift),
      this._checkAndUpdateAttribute(oldLineItems, newLineItems, this.overlength, {
        isOverlength: true,
      }),
      this._checkAndUpdateAttribute(oldLineItems, newLineItems, this.timed),
    ].filter(item => item); //this filter makes sure we don't have any blank charges.

    //Check for area changes
    const oldAreaCharges = oldLineItems.filter(({ chargeCode }) =>
      areaSurchargeIds.includes(chargeCode.id),
    );
    //make sure these have changed
    if (this._areaChargesChanged(oldLineItems, newLineItems)) {
      let choseAreaCharges = [];
      //finds the new area charges
      const newAreaCharges = newLineItems.filter(({ chargeCode }) =>
        areaSurchargeIds.includes(chargeCode?.id),
      );
      let foundIds = [];
      //checks each old charge
      oldAreaCharges.forEach(areaCharge => {
        const newCharge = newAreaCharges.find(
          ({ chargeCode }) => chargeCode.id === areaCharge.chargeCode.id,
        );
        //if theres no new charge, check for finalised, then remove if can.
        if (!newCharge) {
          if (areaCharge.finalised) choseAreaCharges.push(areaCharge);
          return choseAreaCharges.push({ ...areaCharge, _destroy: true });
        }
        foundIds.push(areaCharge.chargeCode.id);
        if (areaCharge.finalised) return choseAreaCharges.push(areaCharge);
        //check for the value being changed
        if (newCharge.value !== areaCharge.value)
          return choseAreaCharges.push({ ...areaCharge, value: newCharge.value });
        return choseAreaCharges.push(areaCharge);
      });
      //if theres any new charge that was not present in old, add it.
      newAreaCharges.forEach(charge => {
        if (!foundIds.includes(charge.chargeCode?.id)) choseAreaCharges.push(charge);
      });
      //Adds all the required charges.
      return oldFiltered.concat(chosenItems.concat(choseAreaCharges));
    } else return oldFiltered.concat(chosenItems.concat(oldAreaCharges));
  }

  //Updates the default charges
  _checkAndUpdateAttribute(
    oldItems,
    newItems,
    attribute,
    { isOverlength } = { isOverlength: false },
  ) {
    //finds old Charge
    const oldDel = oldItems.find(item => this._findByAttribute(item, attribute, { isOverlength }));
    //if its not changed, just return the old charge
    if (!this._hasAttribChanged(oldItems, newItems, attribute, { isOverlength })) return oldDel;
    //Find the new charge
    const newDel = newItems.find(item => this._findByAttribute(item, attribute, { isOverlength }));
    //if theres no old, return the new.
    if (!oldDel) return newDel;
    //if theres no New, check for the finalization and remove if needed
    else if (!newDel) {
      if (oldDel.finalised) return oldDel;
      else return { ...oldDel, _destroy: true };
    } else {
      //Otherwise update the value.
      if (oldDel.finalised) return oldDel;
      else return { ...oldDel, value: newDel.value };
    }
  }

  //API CALLS

  //Finds the area for a postcode
  async _findArea(postcode) {
    try {
      const area = await Area.forPostcode(postcode);
      this.area = area;
    } catch (_) {
      return;
    }
  }

  //fetches settings to get the relevant chargeCodes and surcharges
  async _fetchInfo() {
    try {
      const settings = await SystemSetting.show();
      this.collectionChargeCode = settings.collectionChargeCode;
      this.deliveryChargeCode = settings.deliveryChargeCode;
      this.waste = { chargeCode: settings.wasteChargeCode, value: settings.wasteSurcharge };
      this.tailLift = {
        chargeCode: settings.tailLiftChargeCode,
        value: settings.tailLiftSurcharge,
      };
      this.overlength = {
        chargeCode: settings.overlengthChargeCode,
        value: settings.overlengthSurcharge,
      };
      this.areaSurcharges = settings.areaSurcharges;
      this.timed = {
        chargeCode: settings.timedChargeCode,
        value: settings.timedSurcharge,
      };
    } catch (_) {
      return;
    }
  }

  //Retrieve the taxCodes
  async _fetchTaxCodes() {
    try {
      const taxCodes = await TaxCode.all();
      this.taxCodes = taxCodes.items;
    } catch (_) {
      return;
    }
  }

  //Updates the job or returns an error.
  async _updateBooking(lineItems) {
    try {
      const financialLineItemsAttributes = this._concatAndUpdateOld(
        this.booking.financialLineItems,
        lineItems,
      );
      const updatedBooking = formatBooking({
        ...this.booking,
        ...{ financialLineItemsAttributes },
      });
      const x = await Booking.update(this.booking.id, updatedBooking);
      return x;
    } catch (err) {
      if (err.errors) return { status: 'AutoLine', error: err.errors[0] };
      return { status: 'AutoLine', error: err.error };
    }
  }
}

export default AutoLineCalculator;
