import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import Ribbon from '../../../components/Ribbon/Ribbon';
import CheckPermit from '../../../common/CheckPermit/CheckPermit';
import IconButton from '../../../common/button/IconButton/IconButton';
import Message from '../../../common/layout/Message/Message';
import Driver from '../../../api/controllers/Movement/Driver/Driver';
import useApi from '../../../common/hooks/useApi/useApi';
import ErrorBoundary from '../../../common/ErrorBoundary/ErrorBoundary';
import Table from '../../../components/Table/Table/Table';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import DriverRow from './subComponents/DriverRow';
import NewDriver from '../../../components/modals/NewDriver/NewDriver';
import EditDriver from '../../../components/modals/EditDriver/EditDriver';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import './DriverIndex.scss';

const headers = [
  { text: 'First Name', sortingAttribute: 'firstName' },
  { text: 'Last Name', sortingAttribute: 'lastName' },
  { text: 'Archived' },
  { text: 'Actions' },
];

const DriverIndex = ({ permissions }) => {
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [driverID, setDriverID] = useState(null);
  const [successMessage, setSuccessMessage] = useState();
  const [loading, setLoading] = useState(false);
  const { data, dataLoading, refresh } = useApi({
    call: Driver.all,
  });

  document.title = 'All Drivers';

  const onSuccess = () => {
    refresh();
    setError();
  };

  const handleEdit = id => {
    setOpenEdit(true);
    setDriverID(id);
  };

  const handleDelete = id => {
    setLoading(true);
    Driver.destroy(id).then(
      () => {
        setLoading(false);
        setConfirmOpen();
        onSuccess();
        setSuccessMessage('Driver has successfully been deleted.');
      },
      json => {
        setLoading(false);
        setError({ ...formatError(json), errorTitle: 'Delete Failed!' });
        setSuccessMessage();
        setConfirmOpen();
      },
    );
  };

  return (
    <div className="all-drivers">
      <ConfirmationModal
        question={'Are you sure you want to delete this driver?'}
        subText={`${confirmOpen?.firstName} ${confirmOpen?.lastName}`}
        confirmText={'Delete'}
        isOpen={!!confirmOpen}
        handleConfirm={() => handleDelete(confirmOpen.id)}
        handleCancel={() => setConfirmOpen()}
        loading={loading}
      />
      <Breadcrumbs>
        <Link to={'/drivers'}>Drivers</Link>
      </Breadcrumbs>
      <Ribbon>
        <CheckPermit type={'movementsDrivers'} action={'create'} permissions={permissions}>
          <IconButton text={'Add Driver'} icon={faPlus} onClick={() => setOpen(true)} />
        </CheckPermit>
        <NewDriver onSuccess={onSuccess} open={open} setOpen={setOpen} />
        {driverID && (
          <EditDriver
            id={driverID}
            setID={setDriverID}
            open={openEdit}
            setOpen={setOpenEdit}
            onSuccess={onSuccess}
          />
        )}
      </Ribbon>
      <div className="page-content">
        <Message text={error} type={'error'} visible={!!error} onClick={() => setError()} />
        <Message
          text={successMessage}
          type={'success'}
          onClick={() => setSuccessMessage()}
          visible={!!successMessage}
          onClose={setSuccessMessage}
        />
        {data && !dataLoading && (
          <ErrorBoundary>
            <Table
              title={'Drivers'}
              headers={headers}
              data={data}
              onDrawRow={(item, i) => (
                <DriverRow
                  key={i}
                  driver={item}
                  id={i}
                  permissions={permissions}
                  handleEdit={handleEdit}
                  handleDelete={setConfirmOpen}
                />
              )}
              searchable={['firstName', 'lastName']}
            />
          </ErrorBoundary>
        )}
        {dataLoading && <LoadingSpinner />}
      </div>
    </div>
  );
};

DriverIndex.propTypes = {
  permissions: PropTypes.object,
};

export default DriverIndex;
