import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ShowField from '../ShowField/ShowField';
import NumberHelper from '../../utils/NumberHelper/NumberHelper';

const BillingLines = ({ items = [], documentType, documentTitle }) => {
  const getTaxValue = (rate, value) => (rate / 100) * value;

  const getTotal = (rate, value) => Number(getTaxValue(rate, value)) + Number(value);

  const getLink = ({ credit, purchase, document }) => {
    const id = document?.id;
    if (id === null) return;
    if (purchase && credit) return `purchase_credit_notes/${id}`;
    if (purchase && !credit) return `purchase_invoices/${id}`;
    if (!purchase && credit) return `sales_credit_notes/${id}`;
    return `sales_invoices/${id}`;
  };

  if (items.length === 0)
    return <div className={'no-table'}>No {documentType} line items exist </div>;
  return (
    <table border="1" frame="void" rules="rows" className={'billing-table'}>
      <thead>
        <tr>
          <th>Code</th>
          <th>Description</th>
          <th>Invoicing Party</th>
          <th className={'value'}>Net</th>
          <th className={'value'}>Tax</th>
          <th className={'value'}>Total</th>
          <th>TC</th>
          <th>Finalised</th>
          <th>{documentTitle}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((li, i) => (
          <tr key={i}>
            <td className={'small'}>{li.chargeCode?.code}</td>
            <td className={'small'}>{li.description}</td>
            <td className={'small'}>{li.entity?.internalName}</td>
            <td className={'small value'}>{NumberHelper.formatNumToCurrency(li.value)}</td>
            <td className={'small value'}>
              {NumberHelper.formatNumToCurrency(getTaxValue(li.taxCode?.rate, li.value))}
            </td>
            <td className={'small value'}>
              {NumberHelper.formatNumToCurrency(getTotal(li.taxCode?.rate, li.value))}
            </td>
            <td className={'small'}>{li.taxCode?.code}</td>
            <td className={'small'}>
              <ShowField value={li.finalised} />
            </td>
            <td className={'small'}>
              <Link to={`/financials/${getLink(li)}`} target={'_blank'}>
                {li.document?.documentReference}
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

BillingLines.propTypes = {
  items: PropTypes.array,
  documentType: PropTypes.string,
  documentTitle: PropTypes.string,
};

export default BillingLines;
