import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../FormField/FormField';
import FieldList from '../../../FieldList/FieldList';
import ValueInput from '../../../../common/input/ValueInput/ValueInput';
import GenericAjaxDropdown from '../../../../common/GenericAjaxDropdown/GenericAjaxDropdown';
import Entity from '../../../../api/controllers/Company/Entity/Entity';
import Vehicle from '../../../../api/controllers/Movement/Vehicle/Vehicle';
import GenericDropdown from '../../../../common/GenericDropdown/GenericDropdown';
import { humanize } from '../../../../utils/EnumHelper/EnumHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLong } from '@fortawesome/free-solid-svg-icons';
import Driver from '../../../../api/controllers/Movement/Driver/Driver';

/*
  Why? This form holds only the base minimum inputs required for the General section
    when creating a new VehicleRun object

  Design: https://xd.adobe.com/view/3956ae15-d40c-4d6c-b54d-30083e6c2c8e-6c05/?x_product=xd-jira%2F2.0
*/
const VehicleRunFormGeneral = ({ form, alter, showMileage = false, showArchived = {} }) => {
  const [vehicles, setVehicles] = useState();
  const [drivers, setDrivers] = useState();
  const usesKm = !!form?.vehicle?.usesKilometres;
  // Just gets our unit for display
  const unit = usesKm ? 'km' : 'miles';

  useEffect(() => {
    Vehicle.all(showArchived?.vehicles ? {} : { archived: false }).then(res => setVehicles(res));
    Driver.all(showArchived?.drivers ? {} : { archived: false }).then(res => setDrivers(res));
  }, [JSON.stringify(showArchived)]);

  const renderBottomRow = () => {
    return (
      <FieldList>
        {form.subcontracted ? (
          <FormField label="Reference">
            <input
              value={form.subcontractReference || ''}
              onChange={e => alter('subcontractReference', e.target.value)}
              data-testid={'vrfg-subcontract-reference'}
            />
          </FormField>
        ) : (
          <FormField label="Vehicle">
            <GenericDropdown
              value={form.vehicle}
              items={vehicles?.items}
              onDisplay={veh => veh?.reference}
              onSelect={veh => {
                alter('vehicle', veh);
                if (veh.driver) {
                  alter('driver', veh.driver);
                  alter('driverId', veh.driver.id);
                }
              }}
              onClear={() => alter('vehicle', null)}
              testId={'vehicle-dropdown'}
            />
          </FormField>
        )}
        {form.subcontracted ? (
          <FormField label="Expected Cost">
            <ValueInput
              value={form.subcontractCost || ''}
              onChange={e => alter('subcontractCost', e.target.value)}
              min={0}
              step={0.01}
              unit={'£'}
              reverse
              testId={'vrfg-subcontract-cost'}
            />
          </FormField>
        ) : (
          <FormField label="Driver">
            <GenericDropdown
              value={form.driver || ''}
              items={drivers?.items}
              onDisplay={driver => `${humanize(driver.firstName)} ${humanize(driver.lastName)}`}
              onSelect={driver => {
                alter('driver', driver);
                alter('driverId', driver.id);
              }}
              onClear={() => {
                alter('driver', null);
                alter('driverId', null);
              }}
              testId={'driver-dropdown'}
            />
          </FormField>
        )}
      </FieldList>
    );
  };

  return (
    <Fragment>
      <FieldList>
        <FormField label="Date Of Use">
          <input
            type={'date'}
            value={form.dateOfUse || ''}
            onChange={e => alter('dateOfUse', e.target.value)}
            data-testid={'vrfg-date-input'}
          />
        </FormField>
        {form.subcontracted ? (
          <FormField label="Subcontractor">
            <GenericAjaxDropdown
              value={form.subcontractor}
              call={Entity.all}
              altParams={{ supplier: true }}
              onDisplay={ent => ent?.internalName}
              onSelect={ent => alter('subcontractor', ent)}
              onClear={() => alter('subcontractor', null)}
              testId={'subcontract-dropdown'}
            />
          </FormField>
        ) : (
          showMileage && (
            <FormField label="Mileage">
              <span className={'mileage'}>
                <ValueInput
                  type={'number'}
                  value={form.startMileage || ''}
                  onChange={e => alter('startMileage', e.target.value)}
                  min={0}
                  step={1}
                  testId={'vrfg-start-mileage'}
                  unit={<FontAwesomeIcon icon={faRightLong} />}
                />
                <ValueInput
                  type={'number'}
                  value={form.endMileage || ''}
                  onChange={e => alter('endMileage', e.target.value)}
                  min={0}
                  step={1}
                  testId={'vrfg-end-mileage'}
                  unit={unit}
                />
              </span>
            </FormField>
          )
        )}
      </FieldList>
      {renderBottomRow()}
    </Fragment>
  );
};

VehicleRunFormGeneral.propTypes = {
  form: PropTypes.object, // form contains the current state of the form for controlled inputs
  alter: PropTypes.func, // alter is the standard useForm function
  showMileage: PropTypes.bool,
  showArchived: PropTypes.object,
};

export default VehicleRunFormGeneral;
