import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FieldList from '../../FieldList/FieldList';
import FormField from '../../FormField/FormField';
import { warehouseFactors, warehouseFreq } from '../../../strings/enums';
import { capitalize } from 'lodash';
import ValueInput from '../../../common/input/ValueInput/ValueInput';
import AttributeHelper from '../../../utils/AttributeHelper/AttributeHelper';
import StaticCheckbox from '../../StaticCheckbox/StaticCheckbox';
import GenericDropdown from '../../../common/GenericDropdown/GenericDropdown';

const defaultWarehouse = { name: '', applies: 'never', description: '' };

const WarehouseForm = ({ form, onChange }) => {
  const handleAttrChange = (idx, val) =>
    AttributeHelper.handlePersistentAttrChange(
      idx,
      val?.applies === 'never' ? { ...val, description: '' } : val,
      'warehouseRequirementsAttributes',
      form,
      onChange,
      defaultWarehouse,
    );

  return (
    <Fragment>
      {Object.keys(warehouseFactors).map((factor, i) => {
        const warehouse = {
          ...defaultWarehouse,
          ...form.warehouseRequirementsAttributes[i],
          name: factor,
        };
        const value = capitalize(warehouse?.applies);
        return (
          <FieldList key={i}>
            <FormField label={warehouseFactors[factor]} className={'short'}>
              <GenericDropdown
                value={value}
                items={warehouseFreq}
                onDisplay={freq => freq}
                onSelect={freq =>
                  handleAttrChange(i, {
                    name: Object.keys(warehouseFactors)[i],
                    applies: freq.toLowerCase(),
                  })
                }
              />
            </FormField>
            <FormField label={'Details'} className={'short'}>
              <input
                value={warehouse.description}
                onChange={e =>
                  handleAttrChange(i, {
                    name: factor,
                    description: e.target.value,
                  })
                }
                readOnly={warehouse?.applies === 'never'}
              />
            </FormField>
          </FieldList>
        );
      })}
      <hr />
      <FieldList>
        <FormField label={'Max Fork Lift Weight'} className={'short'}>
          <ValueInput
            value={form.maxForkliftWeight}
            unit={'Kg'}
            onChange={e => onChange('maxForkliftWeight', e.target.value)}
          />
        </FormField>
        <FormField label={'Loading Door Height'} className={'short'}>
          <ValueInput
            value={form.loadingDoorHeight}
            unit={'M'}
            onChange={e => onChange('loadingDoorHeight', e.target.value)}
          />
        </FormField>
      </FieldList>
      <FieldList>
        <FormField label={'Loading Eaves Height'} className={'short'}>
          <ValueInput
            value={form.loadingEavesHeight}
            unit={'M'}
            onChange={e => onChange('loadingEavesHeight', e.target.value)}
          />
        </FormField>
      </FieldList>
      <div className={'section-title'}>Loading Directions</div>
      <div className={'button-line'}>
        <StaticCheckbox
          checked={form.topLoading}
          onClick={() => onChange('topLoading', !form.topLoading)}
          label={'Top'}
        />
        <StaticCheckbox
          checked={form.sideLoading}
          onClick={() => onChange('sideLoading', !form.sideLoading)}
          label={'Side'}
        />
        <StaticCheckbox
          checked={form.rearLoading}
          onClick={() => onChange('rearLoading', !form.rearLoading)}
          label={'Rear'}
        />
      </div>
    </Fragment>
  );
};

WarehouseForm.propTypes = {
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default WarehouseForm;
