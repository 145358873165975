import React from 'react';
import PropTypes from 'prop-types';
import './TabGroup.scss';

// A generic "tabs" container.
class TabGroup extends React.Component {
  constructor(props) {
    super(props);
    const { defaultTab } = props;

    this.state = { active: defaultTab || 0, prevDefaultTab: defaultTab };

    this.handleClick = this.handleClick.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.defaultTab !== state.prevDefaultTab) {
      return {
        prevDefaultTab: props.defaultTab,
        active: props.defaultTab,
      };
    }
    return null;
  }

  handleClick(event, index) {
    event.preventDefault();
    this.setState({ active: index });
  }

  render() {
    const e = React.createElement;
    const children = React.Children.toArray(this.props.children);

    const tabs = children.map(
      (_, i) =>
        i < this.props.labels?.length &&
        e(
          'button',
          {
            key: i,
            className: `tab ${i === this.state.active ? 'active' : ''}`,
            onClick: e => this.handleClick(e, i),
          },
          (this.props.labels || [])[i] || i + 1,
        ),
    );

    return e(
      'div',
      { className: 'tab-group' },
      e('div', { className: 'tabs' }, tabs),
      e('div', { className: 'body' }, children[this.state.active]),
    );
  }
}

TabGroup.propTypes = {
  labels: PropTypes.array,
  children: PropTypes.node,
  defaultTab: PropTypes.number,
};

export default TabGroup;
