import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleFormSection from '../CollapsibleFormSection/CollapsibleFormSection';
import IconButton from '../../common/button/IconButton/IconButton';
import { faFilePdf, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import './Note.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Note = ({
  note,
  expanded,
  handleExpand,
  handleEdit,
  readOnly = false,
  handleDelete,
  permits,
}) => {
  const renderNoteIcon = () =>
    note?.showOnPdfs && (
      <div
        className="c-form-pdf"
        title={'This note will appear on PDFs'}
        data-testid={'note-pdf-icon'}
      >
        <FontAwesomeIcon icon={faFilePdf} />
      </div>
    );

  return (
    <CollapsibleFormSection
      title={note.summary}
      onClick={() => handleExpand(note.id)}
      open={expanded}
      optionalElement={renderNoteIcon()}
    >
      <div className="note">
        <div className="note-button-section" style={{ flex: 1 }}>
          <p>{note.details}</p>
        </div>
        {!readOnly && (
          <div className="note-button-section">
            {permits.update && (
              <IconButton
                text={'Edit'}
                icon={faPen}
                className="edit"
                onClick={() => handleEdit(note.id)}
              />
            )}
            {handleDelete && permits.destroy && (
              <IconButton
                text={'Delete'}
                icon={faTrash}
                className={'destructive'}
                onClick={() => handleDelete(note.id)}
              />
            )}
          </div>
        )}
      </div>
    </CollapsibleFormSection>
  );
};

Note.propTypes = {
  note: PropTypes.object,
  expanded: PropTypes.bool,
  handleExpand: PropTypes.func.isRequired,
  handleDelete: PropTypes.func || PropTypes.bool,
  readOnly: PropTypes.bool,
  handleEdit: PropTypes.func.isRequired,
  permits: PropTypes.object,
};

export default Note;
