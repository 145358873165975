export const humanize = value => {
  if (!value) return '';
  let words = value.replace(/_/g, ' ').split(' ');
  return words
    .map(word => {
      if (!word) return; //if word is empty page would crash, eg when value ends in a space, this stops that
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

export const labelForEnum = (labels, value) => labels[value] || humanize(value);

export const camelCaseToWords = value => value.replace(/([a-z])([A-Z])/g, '$1 $2');
