import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../../../components/FormSection/FormSection';
import FieldList from '../../../../../../../../components/FieldList/FieldList';
import FormField from '../../../../../../../../components/FormField/FormField';
import User from '../../../../../../../../api/controllers/User/User';
import useApi from '../../../../../../../../common/hooks/useApi/useApi';
import GenericDropdown from '../../../../../../../../common/GenericDropdown/GenericDropdown';

const bookingStatuses = { active: 'Active', pending: 'Pending' };

const BookingFormJobSummary = ({ data, alter }) => {
  const { controllers } = useApi({
    call: User.all,
    name: 'controllers',
    params: { operations: true },
  });

  return (
    <FormSection title={'Job Summary'}>
      <FieldList>
        <FormField label={'Controller'}>
          {controllers && (
            <GenericDropdown
              value={data.owner}
              items={controllers.items}
              onSelect={sel => alter('owner', sel)}
              onDisplay={con => `${con.firstName} ${con.lastName}`}
              searchable={['email', 'firstName', 'lastName']}
              focusName={'job-controller'}
              testId={'job-controller'}
            />
          )}
        </FormField>
        <FormField label={'Status'}>
          <GenericDropdown
            value={data.status}
            items={Object.keys(bookingStatuses)}
            onSelect={sel => alter('status', sel)}
            onDisplay={status => bookingStatuses[status]}
          />
        </FormField>
      </FieldList>
    </FormSection>
  );
};

BookingFormJobSummary.propTypes = {
  data: PropTypes.object,
  alter: PropTypes.func,
};

export default BookingFormJobSummary;
