import React from 'react';
import PropTypes from 'prop-types';

const CheckPermit = ({ type, action, permissions, children }) =>
  permissions?.[type]?.[action] ? <>{children}</> : null;

CheckPermit.propTypes = {
  permissions: PropTypes.object,
  type: PropTypes.string,
  action: PropTypes.string,
  children: PropTypes.node,
};

export default CheckPermit;
