import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import LinkBar from '../LinkBar/LinkBar';
import { faAnkh } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import './Folder.scss';
import LabelBar from '../LabelBar/LabelBar';

const Folder = ({
  id,
  openFolders,
  toggle,
  leftClosed = false,
  icon = faAnkh,
  text = 'Link Name',
  children,
}) => {
  const nodeRef = createRef();

  const handleToggle = () => toggle(id || text);
  const open = openFolders.includes(id || text);

  /* Logic for showing hover dropdown if not frozen */
  const containsStrings = openFolders.filter(folder => typeof folder === 'string').length > 0;
  const canHover = !containsStrings || open;

  const openPresenter = () => (
    <CSSTransition
      nodeRef={nodeRef}
      in={open}
      timeout={300}
      classNames={'folder-transition'}
      unmountOnExit
    >
      <div ref={nodeRef} className="folder-content">
        {children}
      </div>
    </CSSTransition>
  );

  const closePresenter = () => (
    <div className={`folder-content hover-content ${open ? 'freeze' : ''}`}>
      <LabelBar icon={icon} text={text} />
      {children}
    </div>
  );

  return (
    <div className={'folder-container'}>
      <LinkBar icon={icon} text={text} onClick={handleToggle} open={open} />
      {leftClosed && canHover && closePresenter()}
      {!leftClosed && openPresenter()}
    </div>
  );
};

Folder.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  openFolders: PropTypes.array,
  toggle: PropTypes.func,
  leftClosed: PropTypes.bool,
  icon: PropTypes.object,
  text: PropTypes.string,
  children: PropTypes.node,
};

export default Folder;
