import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AttributeHelper from '../../../../../utils/AttributeHelper/AttributeHelper';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import FormSection from '../../../../../components/FormSection/FormSection';
import IconButton from '../../../../../common/button/IconButton/IconButton';
import VehicleRunEditLineItems from './VehicleRunEditLineItems/VehicleRunEditLineItems';
import './VehicleRunEditBilling.scss';

const VehicleRunEditBilling = ({ data, alter, handleForm }) => {
  const [shouldDisplayArchived, setShouldDisplayArchived] = useState(false);
  const handleKindChange = (idx, val) =>
    AttributeHelper.handleAttrChange(idx, val, 'financialLineItemsAttributes', data, alter);

  const purchaseItems = data.financialLineItemsAttributes.filter(li => li.purchase && !li.credit);
  const purchaseCreditItems = data.financialLineItemsAttributes.filter(
    li => li.purchase && li.credit,
  );

  const handleDelete = (idx, li) => {
    const val = li.modelName ? { _destroy: 1 } : null;
    handleKindChange(idx, val);
  };

  const handleNewLine = (credit, purchase) => {
    handleKindChange(data.financialLineItemsAttributes.length, {
      credit,
      purchase,
      entity: data.subcontractor,
    });
  };

  useEffect(() => {
    handleForm({
      ...data,
      pcfi: purchaseCreditItems.length > 0 ? true : data.pcfi,
    });
  }, []);

  useEffect(() => {
    setShouldDisplayArchived(
      !!data.financialLineItemsAttributes?.find(li => {
        return li.taxCode?.archived;
      }),
    );
  }, [data]);

  return (
    <div className={'tab-content evr-billing'}>
      <FormSection
        title={'Cost'}
        optionalElement={
          <Checkbox
            label={'Purchase Credits'}
            checked={data.pcfi || false}
            onClick={() => alter('pcfi', !data.pcfi)}
            reverse
          />
        }
      >
        <VehicleRunEditLineItems
          data={data}
          items={purchaseItems}
          handleKindChange={handleKindChange}
          handleDelete={handleDelete}
          shouldDisplayArchived={shouldDisplayArchived}
        />
        <div className={'button-section'}>
          <div className={'button-line'}>
            <IconButton
              icon={faPlus}
              className={'small'}
              text={'Add Cost Line Item'}
              onClick={() => handleNewLine(false, true)}
            />
          </div>
        </div>
      </FormSection>
      {data.pcfi && (
        <FormSection title={'Purchase Credits'}>
          <VehicleRunEditLineItems
            data={data}
            items={purchaseCreditItems}
            handleKindChange={handleKindChange}
            handleDelete={handleDelete}
            shouldDisplayArchived={shouldDisplayArchived}
          />
          <div className={'button-section'}>
            <div className={'button-line'}>
              <IconButton
                icon={faPlus}
                className={'small'}
                text={'Add Credit Line Item'}
                onClick={() => handleNewLine(true, true)}
              />
            </div>
          </div>
        </FormSection>
      )}
    </div>
  );
};

VehicleRunEditBilling.propTypes = {
  data: PropTypes.object,
  alter: PropTypes.func,
  handleForm: PropTypes.func.isRequired,
};

export default VehicleRunEditBilling;
