import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ShowField from '../ShowField/ShowField';
import { humanize } from '../../utils/EnumHelper/EnumHelper';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';
import FormSection from '../FormSection/FormSection';
import FieldList from '../FieldList/FieldList';
import FormField from '../FormField/FormField';
import DateHelper from '../../utils/DateHelper/DateHelper';
import LinkShow from '../../common/LinkShow/LinkShow';
import ValueInput from '../../common/input/ValueInput/ValueInput';
import VehicleRunHelper from '../../utils/VehicleRunHelper/VehicleRunHelper';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLong } from '@fortawesome/free-solid-svg-icons';
import './VehicleRunDetails.scss';

/*
  Displays information for a given vehicleRun
*/
const VehicleRunDetails = ({ run, loading = false, showMileage = false, showLink = false }) => {
  // If the api call has failed then do not return anything
  if (!run && !loading) return null;

  const optional = () => <ShowField label={'Subcontracted'} value={run?.subcontracted} />;

  const renderFormHeader = () => (
    <div className="with-status">
      <div className={'status-title'}>Vehicle Run Details</div>
      {showLink && (
        <Fragment>
          <div className="vrd-separator">|</div>
          <Link to={`/movements/runs/${run?.id}`} target="_blank">
            {run?.runReference}
          </Link>
        </Fragment>
      )}
      <div className={`status ${run?.status}`}>{humanize(run?.status)}</div>
    </div>
  );

  const usesKm = run?.vehicle?.usesKilometres;
  const unit = usesKm ? 'km' : 'miles';
  const inputMod = m => (usesKm ? VehicleRunHelper.mToKm(m) : m);
  const title = m => (usesKm ? `${m} miles` : `${VehicleRunHelper.mToKm(m)} km`);

  const strtM = inputMod(run?.startMileage) || '';
  const endM = inputMod(run?.endMileage) || '';

  const renderMileage = () => (
    <Fragment>
      <FormField label={'Mileage'} className={'short'}>
        <span className={'mileage'}>
          <ValueInput
            value={strtM}
            unit={<FontAwesomeIcon icon={faRightLong} />}
            testId={'vrd-start-mileage'}
            readOnly
            unitTitle={title(run.startMileage)}
          />
          <ValueInput
            value={endM}
            unit={unit}
            testId={'vrd-end-mileage'}
            readOnly
            unitTitle={title(run.endMileage)}
          />
        </span>
      </FormField>
    </Fragment>
  );

  /*
    If the vehicle run is loading, it will show a loading spinner
    If there is a subcontracted vehicle run, the subcontracted variant will be rendered
    If there is a non-subcontracted vehicle run, the regular variant will be rendered
  */
  return (
    <FormSection title={renderFormHeader()} optionalElement={optional()}>
      {loading && <LoadingSpinner />}
      {run && run.subcontracted && (
        <FieldList>
          <FormField label={'Date Of Use'} className={'short'}>
            <input
              value={DateHelper.dateOnly(run.dateOfUse)}
              data-testid={'vrd-date-of-use'}
              readOnly
            />
          </FormField>
          <FormField label={'Subcontractor'} className={'short'}>
            <LinkShow
              testId={'vrd-subcontractor'}
              text={run.subcontractor?.internalName || ''}
              url={run.subcontractor && `/companies/entities/${run.subcontractor?.id}`}
            />
          </FormField>
          <FormField label={'Reference'} className={'short'}>
            <input
              value={run.subcontractReference || ''}
              data-testid={'vrd-subcontract-reference'}
              readOnly
            />
          </FormField>
          <FormField label={'Expected Cost'} className={'short'}>
            <ValueInput
              value={run.subcontractCost ? Number(run.subcontractCost).toFixed(2) : ''}
              min={0}
              step={0.01}
              unit={'£'}
              reverse
              data-testid={'vrd-subcontract-cost'}
              readOnly
            />
          </FormField>
        </FieldList>
      )}
      {run && !run.subcontracted && (
        <Fragment>
          <FieldList>
            <FormField label={'Date Of Use'} className={'short'}>
              <input
                value={DateHelper.dateOnly(run.dateOfUse)}
                data-testid={'vrd-date-of-use'}
                readOnly
              />
            </FormField>
            {showMileage && renderMileage()}
          </FieldList>
          <FieldList>
            <FormField label={'Vehicle'} className={'short'}>
              <input data-testid={'vrd-vehicle'} value={run.vehicle?.reference || ''} readOnly />
            </FormField>
            <FormField label={'Driver'} className={'short'}>
              <input
                data-testid={'vrd-driver'}
                value={`${humanize(run.driver?.firstName)} ${humanize(run.driver?.lastName)}`}
                readOnly
              />
            </FormField>
          </FieldList>
        </Fragment>
      )}
    </FormSection>
  );
};

VehicleRunDetails.propTypes = {
  run: PropTypes.object,
  loading: PropTypes.bool,
  showMileage: PropTypes.bool,
  showLink: PropTypes.bool,
};

export default VehicleRunDetails;
