import React from 'react';
import PropTypes from 'prop-types';
import BookingFormConsignmentOptions from './subComponents/BookingFormConsignmentOptions/BookingFormConsignmentOptions';
import BookingFormMisc from './subComponents/BookingFormMisc/BookingFormMisc';

const BookingFormAdvanced = ({ data, alter }) => {
  return (
    <div className={'tab-content'}>
      <BookingFormConsignmentOptions data={data} alter={alter} />
      <BookingFormMisc data={data} alter={alter} />
    </div>
  );
};

BookingFormAdvanced.propTypes = {
  data: PropTypes.object,
  alter: PropTypes.func,
};

export default BookingFormAdvanced;
