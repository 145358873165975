import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Message.scss';
import { createRef, useEffect } from 'react';

/* Valid types: error | they change the colour */
const Message = ({
  text,
  type,
  onClick,
  onClose,
  visible = false,
  timeout,
  dismissable = true,
  snapTo = true,
}) => {
  /* The ref here is used to avoid the use of the deprecated findDOMNode method in React Transition Group */
  const nodeRef = createRef();

  useEffect(() => {
    let timerId;
    if (timeout) timerId = setTimeout(() => onClose(), timeout);
    return () => clearTimeout(timerId);
  }, [text]);

  useEffect(() => {
    //gets the Y value of the messageComponent, then checks if the message is visible and should scrollTo
    //then checks if the message Y value is before top of page (< 0) or if it is below the current bottom of the screen (screen.height + scrollY)
    //if true then scroll to it
    if (!nodeRef.current) return;
    const messageY = nodeRef.current?.getBoundingClientRect()?.y;
    if (
      visible &&
      snapTo &&
      !(messageY > 0 && messageY < screen.height + scrollY) &&
      nodeRef?.current?.scrollIntoView
    )
      nodeRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [visible]);

  if (!text || !visible) return null;

  return (
    <div
      ref={nodeRef}
      onClick={onClick || onClose}
      className={`message ${type ? `message-${type}` : ''}`}
    >
      {type === 'success' && (
        <div>
          <div>
            <span className={'success-title'}>Success!</span> {text?.message || text}
          </div>
        </div>
      )}
      {typeof text === 'string' && type !== 'success' && (
        <div className="no-title">{`${text}`}</div>
      )}

      {Array.isArray(text) && (
        <div className={'error-list'}>
          <div>{'The following errors must be resolved'}</div>
          <ul>
            {text.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </div>
      )}

      {!Array.isArray(text) && typeof text === 'object' && !text?.message && (
        <div className={'error-list'}>
          <div>{text?.errorTitle || 'The following errors must be resolved'}</div>
          <ul>
            {text?.errors.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <div onClick={onClose}>{dismissable && <FontAwesomeIcon icon={faTimes} />}</div>
    </div>
  );
};

Message.propTypes = {
  text: PropTypes.any,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  timeout: PropTypes.number,
  dismissable: PropTypes.bool,
  snapTo: PropTypes.bool,
};

export default Message;
