import React from 'react';
import PropTypes from 'prop-types';
import Message from '../../../common/layout/Message/Message';
import TabGroup from '../../TabGroup/TabGroup';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import WarehouseForm from '../../forms/WarehouseForm/WarehouseForm';
import './NewAddress.scss';
import AddressForm from '../../forms/AddressForm/AddressForm';

const NewAddress = ({ form, onSet, onChange, handleCancel, handleSubmit, error, setError }) => {
  const labels = form.warehouse ? ['Address', 'Warehouse'] : ['Address'];

  return (
    <form className={'new-address'} onSubmit={handleSubmit}>
      <Message text={error?.errors} type={'error'} onClick={() => setError()} visible={!!error} />
      <TabGroup labels={labels}>
        <div className={'tab-content'}>
          <AddressForm form={form} onSet={onSet} onChange={onChange} />
        </div>
        <div className={'tab-content'}>
          <WarehouseForm form={form} onChange={onChange} />
        </div>
      </TabGroup>
      <div className={'af-button-line'}>
        <IconButton text={'Submit'} icon={faCheck} className={'small'} />
        <IconButton
          text={'Cancel'}
          icon={faTimes}
          onClick={handleCancel}
          className={'edit small'}
        />
      </div>
    </form>
  );
};

NewAddress.propTypes = {
  form: PropTypes.object,
  onChange: PropTypes.func,
  onSet: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  error: PropTypes.object,
  setError: PropTypes.func,
};

export default NewAddress;
