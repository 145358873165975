import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

//Renders the collection or delivery info,
// Displays the given entity name and its address region
const VehicleRunRowColDelInfo = ({ entity, address }) => (
  <div className="vrr-job-field">
    <Link
      to={`/companies/entities/${entity?.id}`}
      target={'_blank'}
      className={'vrr-link'}
      title={entity?.shortName || entity?.internalName}
    >
      {entity?.shortName || entity?.internalName}
    </Link>
    <span style={{ fontSize: '9px' }}>{address?.region}</span>
  </div>
);

VehicleRunRowColDelInfo.propTypes = {
  entity: PropTypes.object,
  address: PropTypes.object,
};

export default VehicleRunRowColDelInfo;
