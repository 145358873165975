import React from 'react';
import PropTypes from 'prop-types';
import './FormField.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faRegistered } from '@fortawesome/free-solid-svg-icons';
import { capitalize } from 'lodash';
import { warehouseFactors } from '../../strings/enums';

// Labels an input, such as a textbox, checkbox, or a dropdown.
const FormField = ({
  label,
  className = '',
  hasNotes,
  hasRestrictions = null,
  handleNoteClick = () => {},
  children,
}) => {
  return (
    <div className={`form-field ${className}`}>
      <span className="form-field-label">
        {label}
        {hasNotes && (
          <span
            className="form-field-note"
            title={'This entity has one or more notes'}
            data-testid={'ff-notes'}
            onClick={handleNoteClick}
          >
            <FontAwesomeIcon icon={faFileLines} />
          </span>
        )}
        {hasRestrictions && hasRestrictions.length > 0 && (
          <span className="hover-cell form-field-restriction" data-testid={'ff-restrictions'}>
            <FontAwesomeIcon icon={faRegistered} />
            <div className="hover-cell-info cube-info">
              <div className="top">Restrictions</div>
              <div className="main">
                {hasRestrictions.map(({ applies, description, name }) => (
                  <div key={name} style={{ marginBottom: '10px' }}>
                    {capitalize(applies)} {warehouseFactors[name]}
                    <span>{description ? `: ${description}` : null}</span>
                  </div>
                ))}
              </div>
            </div>
          </span>
        )}
      </span>
      {children}
    </div>
  );
};

FormField.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  hasNotes: PropTypes.bool,
  hasRestrictions: PropTypes.arrayOf(PropTypes.object),
  handleNoteClick: PropTypes.func,
  children: PropTypes.node,
};

export default FormField;
