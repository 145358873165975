import AttributeHelper from '../../AttributeHelper/AttributeHelper';
import VehicleRunHelper from '../../VehicleRunHelper/VehicleRunHelper';

/*
  Why? This allows the main VehicleRunPlanner component to stay somewhat clean, we simply
    manage param formatting here instead of populating the submit function
*/
export const formatVehicleRun = form => {
  const {
    dateOfUse,
    vehicle,
    subcontracted,
    subcontractor,
    subcontractReference,
    subcontractCost,
    startMileage,
    endMileage,
    waypoints,
    driver,
  } = form;
  const mileages = _convertMileage(vehicle?.usesKilometres, startMileage, endMileage);
  const waypointsAttributes = _formatWaypoints(waypoints);
  const financialLineIds = _handleFinancialLine(form);

  if (subcontracted)
    return {
      dateOfUse,
      subcontractorId: subcontractor?.id || '',
      subcontractReference,
      subcontractCost,
      subcontracted,
      waypointsAttributes,
      financialLineItemsAttributes: financialLineIds || [],
    };

  return {
    dateOfUse,
    vehicleId: vehicle?.id || '',
    driverId: driver?.id || '',
    subcontracted,
    waypointsAttributes,
    financialLineItemsAttributes: financialLineIds || [],
    ...mileages,
  };
};

/*
  Why? Because we display the mileage in KM in the form, therefore we need
       to convert it back when we submit, the mileage in the db should always be miles
*/
const _convertMileage = (usesKm, strtM, endM) => {
  // If we don't use Km, then don't convert
  if (!usesKm) return { startMileage: strtM, endMileage: endM };
  return { startMileage: VehicleRunHelper.kmToM(strtM), endMileage: VehicleRunHelper.kmToM(endM) };
};

/*
  Why? To keep waypoints in order without needing to meticulously track the location within the other code.
    This keeps things neat and orderly without much overhead. Another thing we do here is strip all
    but the most necessary attributes, this assists the backend developers in debugging.
*/
const _formatWaypoints = waypoints => {
  // This counter will increment for each valid waypoint
  let stopNumber = 0;

  return waypoints.map(waypoint => {
    const { actualTime, estimatedTime, address, instructions, permanent, id, _destroy } = waypoint;

    // Increment the stopNumber if the waypoint is not going to be destroyed
    if (_destroy !== 1) stopNumber += 1;

    const newWaypoint = {
      actualTime,
      estimatedTime,
      addressId: address?.id || null,
      instructions,
      permanent,
      _destroy,
      stopNumber: _destroy !== 1 && stopNumber, // This shouldn't matter, but for clarity if it is to be destroyed, it should be undefined
    };

    // If the waypoint is already on the database include the id so it can PATCH it
    if (id) return { ...newWaypoint, id };
    return newWaypoint;
  });
};

const _handleFinancialLine = json => {
  const entityLine = AttributeHelper.convertObjectToId(json.financialLineItemsAttributes, 'entity');
  const chargeLine = AttributeHelper.convertObjectToId(entityLine, 'chargeCode');
  return AttributeHelper.convertObjectToId(chargeLine, 'taxCode');
};
