import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons';
import { formatError } from '../../utils/Formatters/Errors/formatError';
import Item from '../../api/controllers/File/Item/Item';
import './FileUpload.scss';

const FileUpload = ({ refresh, setError, parentType, parentId, canEditParent }) => {
  const [file, setFile] = useState();
  const [inputLabel, setInputLabel] = useState('No file chosen');
  const [loading, setLoading] = useState(false);
  const ref = useRef();

  const handleUpload = file => {
    setLoading(true);
    const params = parentId
      ? { fileData: file, parentType: parentType, parentId: parentId }
      : { fileData: file };
    Item.create(params)
      .then(
        () => {
          refresh();
          setError();
          if (ref.current) ref.current.value = '';
          setInputLabel('No file chosen');
        },
        err => {
          setError({ ...formatError(err), errorTitle: 'Could not upload file', type: 'error' });
          setFile(file);
        },
      )
      .then(() => setLoading(false));
  };

  const handleOnChange = e => {
    setFile(e.target.files[0]);
    setInputLabel(e.target.files[0].name);
  };
  return (
    <>
      {canEditParent && (
        <div className={'upload-container'}>
          <div className="input-file-section">
            <label htmlFor={'file'} className="input-file-label">
              <span className={'input-file-icon'}>
                {!loading && <FontAwesomeIcon icon={faUpload} className={'upload-icon'} />}
                {loading && <FontAwesomeIcon icon={faSpinner} className={'upload-icon fa-spin'} />}
              </span>
              <span className={'input-file-text'} title={inputLabel}>
                {inputLabel}
              </span>
            </label>
            <input
              type="file"
              id="file"
              data-testid="file"
              aria-label={'No file choosen'}
              onChange={handleOnChange}
              ref={ref}
            />
          </div>
          <button
            className={'upload-btn'}
            onClick={() => {
              handleUpload(file);
              setFile();
            }}
            disabled={!file}
          >
            Upload
          </button>
        </div>
      )}
    </>
  );
};

FileUpload.propTypes = {
  refresh: PropTypes.func,
  setError: PropTypes.func,
  parentId: PropTypes.number,
  parentType: PropTypes.string,
  permissions: PropTypes.object,
  canEditParent: PropTypes.bool,
};

export default FileUpload;
