import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class TaxCode {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'financials/tax_codes', data);
  }

  static show(id) {
    return apiCall('GET', `financials/tax_codes/${id}`);
  }

  static create(params) {
    const data = { tax_code: params };
    return apiCall('POST', 'financials/tax_codes', data);
  }

  static update(id, params) {
    const data = { tax_code: params };
    return apiCall('PATCH', `financials/tax_codes/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `financials/tax_codes/${id}`);
  }
}

export default TaxCode;
