import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../components/FormSection/FormSection';
import EmailHelper from '../../../../../utils/EmailHelper/EmailHelper';

const TemplateGeneralTab = ({ body, subject, user }) => {
  return (
    <div className="tab-content">
      <FormSection title={subject}>
        <div dangerouslySetInnerHTML={{ __html: EmailHelper.getSalutation(' John') }}></div>
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
        <div dangerouslySetInnerHTML={{ __html: user.emailSignature }}></div>
      </FormSection>
    </div>
  );
};

TemplateGeneralTab.propTypes = {
  body: PropTypes.string,
  subject: PropTypes.string,
  user: PropTypes.object,
};

export default TemplateGeneralTab;
