import React, { useState } from 'react';
import './EntityEdit.scss';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import useApi from '../../../../common/hooks/useApi/useApi';
import Entity from '../../../../api/controllers/Company/Entity/Entity';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import FormSection from '../../../../components/FormSection/FormSection';
import FieldList from '../../../../components/FieldList/FieldList';
import FormField from '../../../../components/FormField/FormField';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../common/button/IconButton/IconButton';
import useForm from '../../../../common/hooks/useForm/useForm';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import BusinessType from '../../../../api/controllers/Company/BusinessType/BusinessType';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import { bulkInvoicingValues, entityStatuses } from '../../../../strings/enums';
import Message from '../../../../common/layout/Message/Message';
import Customer from '../../../../api/controllers/Financial/Customer/Customer';
import Supplier from '../../../../api/controllers/Financial/Supplier/Supplier';
import ErrorStatusMessage from '../../../../common/ErrorStatusMessage/ErrorStatusMessage';
import InputMask from 'react-input-mask';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import { useNavigate, useParams } from 'react-router';
import GenericAjaxDropdown from '../../../../common/GenericAjaxDropdown/GenericAjaxDropdown';
import GenericDropdown from '../../../../common/GenericDropdown/GenericDropdown';

const acceptedChars = {
  a: '[a-zA-Z]',
  A: '[0-9a-zA-Z]',
  9: '[0-9]',
};

const EntityEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState();
  const [sageError, setSageError] = useState();
  const [loading, setLoading] = useState(false);
  const { data, dataLoading } = useApi({ call: () => Entity.show(id), setSageError });
  const { businessTypes, businessTypesLoading } = useApi({
    call: BusinessType.all,
    name: 'businessTypes',
    setSageError,
  });
  const { form, handleForm, alter } = useForm();

  document.title = `Editing ${data?.internalName}`;

  if (dataLoading || businessTypesLoading) return <LoadingSpinner />;
  if (!data || !businessTypes) return null;
  if (!form) {
    handleForm({
      internalName: data.internalName || '',
      businessType: data.businessType || '',
      externalName: data.externalName || '',
      shortName: data.shortName || '',
      status: data.status || '',
      parent: data.parent || null,
      companyNumber: data.companyNumber || '',
      vatNumber: data.vatNumber || '',
      customer: data.customer,
      customerAccount: data.customerAccount || '',
      supplier: data.supplier,
      supplierAccount: data.supplierAccount || '',
      forwarder: data.forwarder,
      allowAutolining: data.allowAutolining || false,
      bulkInvoicing: data.bulkInvoicing || null,
    });
    return null;
  }

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    if (form.companyNumber.includes('_')) {
      setError(formatError({ errors: ['Company number must be complete'] }));
      return;
    }

    //catches empty vat number weirdness
    if (form.vatNumber === '__ _') form.vatNumber = '';
    //if it contains any _ or is too short for min vat but not empty
    else if (form.vatNumber.includes('_') || (form.vatNumber.length < 4 && form.vatNumber)) {
      setError(formatError({ errors: ['VAT number must be complete'] }));
      return;
    }
    const params = { ...form, parentId: form.parent?.id, businessTypeId: form.businessType?.id };

    Entity.update(id, params).then(
      () => {
        setLoading(false);
        navigate(`/companies/entities/${id}`);
      },
      json => {
        setLoading(false);
        setError(formatError(json));
      },
    );
  };

  const handleCancel = () => navigate(`/companies/entities/${id}`);

  return (
    <div className={'edit-entity'}>
      <Breadcrumbs>
        <Link to={'/companies/entities'}>Entities</Link>
      </Breadcrumbs>
      <div className={'page-content'}>
        <Message text={error?.errors} type={'error'} onClick={() => setError()} visible={!!error} />
        <ErrorStatusMessage error={sageError} setError={setSageError} />
        <h1>Edit Entity: {`${data?.internalName || data?.externalName}`}</h1>

        <TabGroup labels={['General']}>
          <div className={'tab-content'}>
            <FormSection
              title={'Entity Details'}
              optionalElement={
                <Checkbox
                  checked={form.allowAutolining}
                  label={'Allow Autolining'}
                  onClick={() => alter('allowAutolining', !form.allowAutolining)}
                  reverse
                />
              }
            >
              <FieldList>
                <FormField label={'System Name'}>
                  <input
                    value={form.internalName}
                    onChange={e => alter('internalName', e.target.value)}
                  />
                </FormField>
                <FormField label={'Business Type'}>
                  <GenericAjaxDropdown
                    value={form.businessType}
                    call={BusinessType.all}
                    onDisplay={bt => bt?.name}
                    onSelect={bt => alter('businessType', bt)}
                    testId={'ee-business-types'}
                    onClear={() => alter('businessType', null)}
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label={'Display Name'}>
                  <input
                    value={form.externalName}
                    onChange={e => alter('externalName', e.target.value)}
                  />
                </FormField>
                <FormField label={'Short Name'}>
                  <input
                    value={form.shortName}
                    onChange={e => alter('shortName', e.target.value)}
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label={'Status'}>
                  <GenericDropdown
                    value={form.status}
                    items={Object.keys(entityStatuses)}
                    onDisplay={es => entityStatuses[es]}
                    onSelect={es => alter('status', es)}
                    onClear={es => alter('status', es)}
                  />
                </FormField>
                <FormField label={'Parent Entity'}>
                  <GenericAjaxDropdown
                    value={form.parent}
                    call={Entity.all}
                    onDisplay={pt => pt?.internalName}
                    onSelect={pt => alter('parent', pt)}
                    onClear={() => alter('parent', null)}
                    testId={'ee-parent-entity'}
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label={'Company Number'}>
                  <InputMask
                    mask="AAAAAAAA"
                    formatChars={acceptedChars}
                    value={form.companyNumber}
                    onChange={e => alter('companyNumber', e.target.value.toUpperCase())}
                    onKeyDown={e => (e.key !== '_' ? null : e.preventDefault())}
                  />
                </FormField>
                <FormField label={'VAT Number'}>
                  <InputMask
                    mask={form.vatNumber.length > 3 ? 'aa 999999999999' : 'aa 9'}
                    value={form.vatNumber}
                    formatChars={acceptedChars}
                    onChange={e =>
                      e.target.value !== '_'
                        ? alter('vatNumber', e.target.value.toUpperCase())
                        : null
                    }
                    onKeyDown={e => (e.key !== '_' ? null : e.preventDefault())}
                    maskChar={form.vatNumber.length > 3 ? '' : '_'}
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField>
                  <Checkbox
                    label={'Customer'}
                    checked={form.customer}
                    onClick={() => alter('customer', !form.customer)}
                  />
                </FormField>
                <FormField label={'Customer Account'}>
                  <GenericAjaxDropdown
                    value={form.customerAccount}
                    call={Customer.all}
                    onDisplay={cus => cus?.code || cus}
                    onSelect={cus => alter('customerAccount', cus?.code)}
                    testId={'ee-customer-account'}
                    onClear={() => alter('customerAccount', null)}
                    disabled={!form.customer}
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField>
                  <Checkbox
                    label={'Supplier'}
                    checked={form.supplier}
                    onClick={() => alter('supplier', !form.supplier)}
                  />
                </FormField>
                <FormField label={'Supplier Number'}>
                  <GenericAjaxDropdown
                    value={form.supplierAccount}
                    call={Supplier.all}
                    onDisplay={sup => sup?.code || sup}
                    onSelect={sup => alter('supplierAccount', sup?.code)}
                    testId={'ee-supplier-account'}
                    onClear={() => alter('supplierAccount', null)}
                    disabled={!form.supplier}
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField>
                  <Checkbox
                    label={'Forwarder'}
                    checked={form.forwarder}
                    onClick={() => alter('forwarder', !form.forwarder)}
                  />
                </FormField>
                {form.customer && (
                  <FormField label={'Bulk Invoicing'}>
                    <GenericDropdown
                      value={form.bulkInvoicing}
                      items={['', ...Object.keys(bulkInvoicingValues)]}
                      onDisplay={es => bulkInvoicingValues[es] || 'No'}
                      onSelect={es => alter('bulkInvoicing', es)}
                    />
                  </FormField>
                )}
              </FieldList>
            </FormSection>
            <div className="button-group">
              <IconButton text={'Save'} icon={faCheck} onClick={handleSubmit} loading={loading} />
              <IconButton text={'Cancel'} icon={faTimes} onClick={handleCancel} className="edit" />
            </div>
          </div>
        </TabGroup>
      </div>
    </div>
  );
};

EntityEdit.propTypes = {};

export default EntityEdit;
