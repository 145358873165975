import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

/*
  Why? This component is a wrapper component allowing for easy modal creation.
    It handles darkening the background and positioning of its children, it also allows
    for drag and drop as long as a form section is present.
  Notes? Because of how ReactModal works, unmounting this component via isOpen does not fully unmount it.
    This can cause problems, for example if you have submitted a form, then wish to submit another, upon
    opening you may find that the state has been maintained, despite the fact that it was "closed".
    You can fix this through unmounting it forcefully e.g. open && <Modal />
*/
const Modal = ({ isOpen, handleClose, children, extraPadding }) => {
  const nodeRef = useRef();
  const [coords, setCoords] = useState({ left: 0, top: 0 });
  const [dragging, setDragging] = useState(false);

  const customStyles = {
    overlay: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: `${extraPadding || '50px'}`,
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      cursor: 'pointer',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    content: {
      inset: 'auto',
      position: 'absolute',
      padding: 0,
      borderRadius: 0,
      zIndex: 99,
      overflow: 'visible',
      cursor: 'auto',
    },
  };

  // We want to get the first form section head to allow for dragging
  const getModalContent = () =>
    nodeRef.current?.node && nodeRef.current.node.getElementsByClassName('form-section-head')[0];

  /*
    Why? If there is a valid header then it will be returned else undefined will be returned
  */
  const getModalHeader = e => {
    const header = getModalContent();
    if ((!header && e.target !== header) || !header?.contains(e.target)) return;
    return header;
  };

  /*
    Why? We're using event listeners as they are the best means of doing realtime dragging of an element.
      This function handles the case that the mouse is moving while the left mouse button is pressed,
      this will constantly set the left and top absolute positions of the modal content.
  */
  const handleMouseMove = e => {
    if (!dragging) return; // If mousedown is not pressed do not drag
    const header = getModalContent(); // Return undefined or the header (modal header i.e. <FormSection />)
    if (!header) return; // If we find an invalid modal header then we do nothing
    const rect = header.getBoundingClientRect(); // Get the dimensions of the target
    if (!rect) return; // If there is no found modal content do nothing
    const left = e.clientX - dragging; // We want to grab the target based on the mouse offset
    const top = e.clientY - 20; // We need to minus 20 so that the pointer stay attached to the middle of the form section head
    setCoords({ left, top }); // Set our new coordinates for the modal, this can be seen within customStyles above
  };

  /*
    Why? If the target clicked is the modal header, then we allow dragging
  */
  const handleMouseDown = e => {
    const header = getModalHeader(e); // Return undefined or the header (modal header i.e. <FormSection />)
    if (!header) return; // If nothing found, don't
    if (e.target.style) e.target.style.cursor = 'grabbing'; // Sets the cursor on the formSection head to grabbing as we are interacting
    const rect = e.target.getBoundingClientRect();
    setDragging(e.clientX - rect.left); // Set the dragging property as the mouse offset inside the div
  };

  /*
    Why? We always want the user mouseup event to stop dragging, this removes many edge cases.
      Use your brain to work them out I'm done with this.
  */
  const handleMouseUp = e => {
    e.preventDefault();
    setDragging(false);
    const header = getModalHeader(e);
    if (!header) return;
    if (e.target.style) e.target.style.cursor = 'grab'; // Sets the cursor on the form section header as grab (the default)
  };

  /*
    Why? This useEffect is used to register event listeners and de-register them on modal unmount
  */
  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [dragging]);

  if (!isOpen) return null;
  return (
    <div>
      <ReactModal
        ref={nodeRef}
        isOpen={!!isOpen}
        onRequestClose={handleClose}
        style={{
          content: { ...customStyles.content, left: coords.left, top: coords.top },
          overlay: { ...customStyles.overlay },
        }}
        testId={'react-modal'}
        ariaHideApp={!process.env.NODE_ENV === 'test'}
      >
        {children}
      </ReactModal>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  handleClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  extraPadding: PropTypes.string,
};

export default Modal;
