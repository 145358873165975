import React from 'react';
import PropTypes from 'prop-types';
import VehicleFilterBar from '../VehicleFilterBar/VehicleFilterBar';
import VehicleTopBar from '../VehicleTopBar/VehicleTopBar';

const VehicleRunHeader = ({
  disconnected,
  openNewVehicleModal,
  allOpen,
  closeAll,
  openAll,
  form,
  alter,
  handleForm,
  subwindow,
  toggleSubwindow,
  isSub,
  reconnect,
  loading,
  xlsxLoading,
  handleXlsxClick,
  handleGenerateRuns,
  runsGenerating = false,
  generateRunsDisabled = false,
}) => (
  <div className="vr-header">
    <VehicleTopBar disconnected={disconnected} reconnect={reconnect} loading={loading} />
    {!isSub && (
      <VehicleFilterBar
        openNewVehicleModal={openNewVehicleModal}
        allOpen={allOpen}
        closeAll={closeAll}
        openAll={openAll}
        form={form}
        alter={alter}
        handleForm={handleForm}
        subwindow={subwindow}
        toggleSubwindow={toggleSubwindow}
        xlsxLoading={xlsxLoading}
        handleXlsxClick={handleXlsxClick}
        handleGenerateRuns={handleGenerateRuns}
        runsGenerating={runsGenerating}
        generateRunsDisabled={generateRunsDisabled}
      />
    )}
  </div>
);

VehicleRunHeader.propTypes = {
  disconnected: PropTypes.bool.isRequired,
  openNewVehicleModal: PropTypes.func,
  allOpen: PropTypes.bool,
  closeAll: PropTypes.func,
  openAll: PropTypes.func,
  form: PropTypes.object,
  alter: PropTypes.func,
  handleForm: PropTypes.func,
  subwindow: PropTypes.object,
  toggleSubwindow: PropTypes.func,
  reconnect: PropTypes.func,
  isSub: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  xlsxLoading: PropTypes.bool,
  handleXlsxClick: PropTypes.func.isRequired,
  handleGenerateRuns: PropTypes.func.isRequired,
  runsGenerating: PropTypes.bool,
  generateRunsDisabled: PropTypes.bool,
};
export default VehicleRunHeader;
