// Contains utility methods related to dates.
export default class DateHelper {
  static format(value) {
    if (!value) {
      return '';
    }

    const options = {
      // weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const formatter = new Intl.DateTimeFormat('en-GB', options);
    return formatter.format(new Date(value));
  }

  static openingTimeFormat(time) {
    if (!time) return 'Never';
    const weekObj = {
      Monday: time.monday,
      Tuesday: time.tuesday,
      Wednesday: time.wednesday,
      Thursday: time.thursday,
      Friday: time.friday,
      Saturday: time.saturday,
      Sunday: time.sunday,
    };
    const values = Object.keys(weekObj)
      .map(key => weekObj[key] && key)
      .filter(item => typeof item !== 'boolean');

    if (values.length === 0) return 'Never';
    if (values.length === 1) return `Only ${values[0]}`;
    if (values.length === 7) return 'Everyday';
    return values
      .map(day => day.slice(0, 3))
      .toString()
      .replace(/,/g, '/');
  }

  static dateOnly(value) {
    return new Intl.DateTimeFormat('en-GB').format(new Date(value));
  }

  static today() {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const formattedDate = new Date(date.getTime() - offset * 60 * 1000);
    return formattedDate.toISOString().split('T')[0];
  }

  static yesterday(current) {
    try {
      const date = new Date(current);
      date.setDate(date.getDate() - 1);
      return date.toISOString().split('T')[0];
    } catch (_) {
      return this.today();
    }
  }

  static tomorrow(current) {
    try {
      const date = new Date(current);
      date.setDate(date.getDate() + 1);
      return date.toISOString().split('T')[0];
    } catch (_) {
      return this.today();
    }
  }

  static dueDateCalculate(customer) {
    let todayDate = new Date();
    let date = customer.paymentTerms.includes('month')
      ? new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0, todayDate.getHours())
      : todayDate;
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + customer.paymentDays,
      date.getHours(),
    )
      .toISOString()
      .split('T')[0];
  }

  static formatVehicleRunDate(dateString) {
    if (!dateString) return '--/--';
    //format given from API is YYYY-MM-DD
    let dateParts = dateString.split('-');
    return `${dateParts[2]}/${dateParts[1]}`;
  }

  static getStartAndEndOfMonth(dateString) {
    const startOfMonth = new Date(dateString);
    const endOfMonth = new Date(dateString);

    //StartOfMonth
    startOfMonth.setDate(1);

    //EndOfMonth
    endOfMonth.setMonth(endOfMonth.getMonth() + 1);
    endOfMonth.setDate(0);

    return { start: startOfMonth, end: endOfMonth };
  }

  static getStartAndEndOfWeek(dateString) {
    const startOfWeek = new Date(dateString);
    const endOfWeek = new Date(dateString);

    const currentDayOfWeek = startOfWeek.getDay();

    //StartOfWeek: note +1 is because if js deciding weeks start on a sunday
    const first = startOfWeek.getDate() - currentDayOfWeek + 1;
    startOfWeek.setDate(first);

    //EndOfWeek
    endOfWeek.setDate(first + 6);

    return { start: startOfWeek, end: endOfWeek };
  }

  static dateWithinRange(queryDate, start, end) {
    const dated = new Date(queryDate);
    if (dated < start) return false;
    if (dated > end) return false;
    return true;
  }
}
