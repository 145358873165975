import Action from '../Action/Action';

/*
  performs the removal of an item from state
*/
class DestroyAction extends Action {
  constructor(setState, currentState, removedItem) {
    super();
    this.setState = setState;
    this.currentState = currentState;
    this.removedItem = removedItem;
  }

  execute() {
    this.setState(this.currentState.filter(item => item.id !== this.removedItem.id));
  }

  undo() {
    this.setState(this.currentState);
  }

  get itemRemoved() {
    return this.removedItem;
  }
}

export default DestroyAction;
