//A abstract XLSX class, used for generating Excel documents
class BaseXlsx {
  constructor() {
    if (new.target === BaseXlsx)
      throw new TypeError('Abstract classes cannot be directly constructed.');
  }

  generate() {}
}

export default BaseXlsx;
