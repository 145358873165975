import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import ChargeCode from '../../../../api/controllers/Financial/ChargeCode/ChargeCode';
import IconButton from '../../../../common/button/IconButton/IconButton';
import useApi from '../../../../common/hooks/useApi/useApi';
import Message from '../../../../common/layout/Message/Message';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Table from '../../../../components/Table/Table/Table';
import './ChargeIndex.scss';
import ErrorBoundary from '../../../../common/ErrorBoundary/ErrorBoundary';
import CheckPermit from '../../../../common/CheckPermit/CheckPermit';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';

const ChargeIndex = ({ permissions }) => {
  const { data, dataLoading, refresh } = useApi({
    call: ChargeCode.all,
    handleError: e => setError(formatError(e)),
  });
  const [open, setOpen] = useState();
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.detail === 'Charge Code Deleted') {
      setSuccessMessage('Charge Code has successfully been deleted.');

      location.state.detail = undefined;
    }
  }, []);
  document.title = 'All Charge Codes';

  const handleDelete = () => {
    ChargeCode.destroy(open.id).then(
      () => {
        refresh();
        setSuccessMessage('Charge Code has successfully been deleted.');
      },
      json => setError(formatError(json)),
    );
    setOpen();
  };

  const headers = [
    { text: 'Code', sortingAttribute: 'code' },
    { text: 'Description', sortingAttribute: 'description' },
    { text: 'Sales Nominal', sortingAttribute: 'salesNominal' },
    { text: 'Purchase Nominal', sortingAttribute: 'purchaseNominal' },
    { text: 'Actions' },
  ];

  const handleDrawRow = (item, i) => (
    <tr key={i} className={i % 2 === 0 ? '' : 'coloured'}>
      <td className="small">
        <Link to={`/financials/charge_codes/${item.id}`}>{item.code}</Link>
      </td>
      <td>{item.description}</td>
      <td className="small">{item.salesNominal}</td>
      <td className="small">{item.purchaseNominal}</td>
      <td className="small">
        <span className="td-buttons">
          <IconButton
            text={'Edit'}
            icon={faPen}
            onClick={() => navigate(`/financials/charge_codes/${item.id}/edit`)}
            className="edit small"
          />
          <CheckPermit type={'financialsChargeCodes'} action={'destroy'} permissions={permissions}>
            <IconButton
              text={'Delete'}
              icon={faTrash}
              onClick={() => setOpen(item)}
              className="destructive small"
            />
          </CheckPermit>
        </span>
      </td>
    </tr>
  );

  return (
    <div className="all-charge-codes">
      <ConfirmationModal
        question={'Are you sure you want to delete this code?'}
        subText={open?.code}
        confirmText={'Delete'}
        isOpen={!!open}
        handleConfirm={handleDelete}
        handleCancel={() => setOpen()}
      />
      <Breadcrumbs>
        <Link to={'/financials/charge_codes'}>Charge Codes</Link>
      </Breadcrumbs>
      <Ribbon>
        <IconButton
          text={'Add Charge Code'}
          icon={faPlus}
          onClick={() => navigate('/financials/charge_codes/new')}
        />
      </Ribbon>
      <div className="page-content">
        <Message text={error?.errors} type={'error'} onClick={() => setError()} visible={!!error} />
        <Message
          text={successMessage}
          type={'success'}
          onClick={() => setSuccessMessage()}
          visible={!!successMessage}
          onClose={setSuccessMessage}
        />
        {dataLoading ? (
          <LoadingSpinner />
        ) : (
          <ErrorBoundary>
            <Table
              title={'Charge Codes'}
              headers={headers}
              data={data}
              onDrawRow={handleDrawRow}
              searchable={['code', 'email']}
            />
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
};

ChargeIndex.propTypes = {
  permissions: PropTypes.object,
};

export default ChargeIndex;
