import React from 'react';
import PropTypes from 'prop-types';
import './Checkbox.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const Checkbox = ({
  checked,
  onClick,
  label,
  reverse,
  disabled,
  readOnly,
  className,
  title = '',
}) => (
  <div
    className={`checkbox${reverse ? ' reverse' : ''}${disabled ? ' disabled' : ''}${
      className ? ` ${className}` : ''
    }`}
    title={title}
  >
    <label className={checked ? 'switch checked' : 'switch'}>
      <FontAwesomeIcon icon={faCheck} />
      <input
        type="checkbox"
        checked={checked}
        onChange={onClick}
        data-testid={'checkbox'}
        disabled={disabled}
        readOnly={readOnly}
      />
      <div></div>
      <FontAwesomeIcon icon={faTimes} />
    </label>
    <div className="checkbox-text">{label}</div>
  </div>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  reverse: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default Checkbox;
