class StringHelper {
  // Please do not give this a user entity, it requires a RECIPIENT
  static formatRecipient(ent) {
    if (!ent.emailAddress && !ent.contact?.firstName && !ent.contact?.lastName) return '';
    if (!ent.contact || (!ent.contact.firstName && !ent.contact.lastName))
      return `<${ent.emailAddress}>`;
    if (!ent.contact.firstName) return `${ent.contact.lastName} <${ent.emailAddress}>`;
    if (!ent.contact.lastName) return `${ent.contact.firstName} <${ent.emailAddress}>`;
    return `${ent.contact.firstName} ${ent.contact.lastName} <${ent.emailAddress}>`;
  }
}

export default StringHelper;
