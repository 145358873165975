import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useApi from '../../../../common/hooks/useApi/useApi';
import SalesCredit from '../../../../api/controllers/Financial/SalesCredit/SalesCredit';
import NoPage from '../../../NoPage/NoPage';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import FinalisedOptions from '../../../../components/FinalisedOptions/FinalisedOptions';
import UnFinalisedOptions from '../../../../components/UnFinalisedOptions/UnFinalisedOptions';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import { handleDeleteDocument } from '../../../../utils/documentUtils/documentUtils';
import ErrorStatusMessage from '../../../../common/ErrorStatusMessage/ErrorStatusMessage';
import Message from '../../../../common/layout/Message/Message';
import DocumentShowGeneral from '../../../../components/DocumentShowGeneral/DocumentShowGeneral';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import AttachmentsTab from '../../../../components/AttachmentsTab/AttachmentsTab';
import '../../Document/DocumentShow/DocumentShow.scss';
import { useParams, useNavigate } from 'react-router';
import NotesTab from '../../../Company/Entity/EntityShow/subComponents/NotesTab/NotesTab';

const PurchaseInvoiceShow = ({ permissions, user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, refresh, dataLoading } = useApi({
    call: () => SalesCredit.show(id),
    handleError: () => setNotFound(true),
  });
  const [error, setError] = useState();
  const [notFound, setNotFound] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pdfAlert, setPdfAlert] = useState();

  if (notFound) return <NoPage />;
  if (dataLoading) return <LoadingSpinner />;
  if (!data) return null;
  return (
    <div className={'show-booking-page'}>
      <Breadcrumbs>
        <Link to="/financials/purchase_invoices">Purchase Invoices</Link>
        <Link to={`/financials/purchase_invoices/${id}`}>{data.documentReference}</Link>
      </Breadcrumbs>
      <Ribbon>
        <div className="split">
          {data.apiUrn !== null && (
            <IconButton
              text={`Ref. ${data.apiUrn}`}
              icon={faCheck}
              className={'success-disabled'}
            />
          )}
          {!data.apiUrn && data.finalised && (
            <FinalisedOptions
              documentId={id}
              refresh={refresh}
              setError={setError}
              title={'Purchase Invoice'}
              permissions={permissions}
            />
          )}
          {!data.apiUrn && !data.finalised && (
            <UnFinalisedOptions
              documentId={id}
              data={data}
              refresh={refresh}
              setError={setError}
              setOpen={setIsOpen}
              type={'Invoice'}
              title={'Purchase Invoice'}
              index={'/financials/purchase_invoices'}
              open={isOpen}
              permissions={permissions}
            />
          )}
        </div>
      </Ribbon>
      <ConfirmationModal
        question={`Are you sure you want to delete this Invoice?`}
        subText={data.documentReference}
        isOpen={isOpen}
        confirmText={'Delete'}
        handleCancel={() => setIsOpen(false)}
        handleConfirm={e =>
          handleDeleteDocument(
            e,
            data,
            navigate,
            '/financials/purchase_invoices',
            setError,
            setIsOpen,
          )
        }
      />
      <ErrorStatusMessage error={pdfAlert} setError={setPdfAlert} />

      <div className={'page-content'}>
        <h1>
          Purchase Invoice for {data.entity.internalName}
          <Message
            text={error}
            type={error?.type}
            onClick={() => setError()}
            visible={!!error}
            onClose={setError}
          />
        </h1>
        <TabGroup labels={['General', 'Attachments', 'Notes']}>
          <DocumentShowGeneral
            data={data}
            documentDetails={{
              title: 'Purchase Invoice',
              type: 'Invoice',
              index: '/financials/purchase_invoices',
            }}
          />
          <AttachmentsTab
            permissions={permissions}
            parentId={data.id}
            parentType={'Financials::Document'}
            requiredPermit={'financialsDocuments'}
            setError={setError}
          />
          <NotesTab
            parentId={data.id}
            parentType={data.modelName}
            canDelete={user.administrator}
            permissions={permissions}
            requiredPermit={'financialsDocuments'}
          />
        </TabGroup>
      </div>
    </div>
  );
};
PurchaseInvoiceShow.propTypes = {
  permissions: PropTypes.object,
  user: PropTypes.object,
};
export default PurchaseInvoiceShow;
