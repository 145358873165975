import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../../../components/FormSection/FormSection';
import CollapsibleFormSection from '../../../../../../../../components/CollapsibleFormSection/CollapsibleFormSection';
import IconButton from '../../../../../../../../common/button/IconButton/IconButton';
import {
  faTrash,
  faPlus,
  faPen,
  faExclamationTriangle,
  faEnvelope,
  faPhoneSquareAlt,
  faMobile,
  faMailBulk,
  faArchive,
  faCircleInfo,
  faClosedCaptioning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useArray from '../../../../../../../../common/hooks/useArray/useArray';
import { formatError } from '../../../../../../../../utils/Formatters/Errors/formatError';
import ContactApi from '../../../../../../../../api/controllers/Company/Contact/Contact';
import LoadingSpinner from '../../../../../../../../common/LoadingSpinner/LoadingSpinner';
import { humanize } from '../../../../../../../../utils/EnumHelper/EnumHelper';

const ContactsSection = ({
  data,
  contactKinds,
  createContact,
  editContact,
  setConfirmOpen,
  refreshData,
  setError,
  loading,
}) => {
  const { contactSections, toggleContactSections } = useArray({ name: 'contactSections' });

  const handleArchive = contact =>
    ContactApi.archive(contact.id).then(
      () => refreshData(),
      json => setError(formatError(json)),
    );

  const handleUnArchive = contact =>
    ContactApi.unArchive(contact.id).then(
      () => refreshData(),
      json => setError(formatError(json)),
    );

  const contactArchiveButton = contact => {
    const handleClick = () =>
      contact.archived ? handleUnArchive(contact) : handleArchive(contact);
    const text = contact.archived ? 'Un-Archive' : 'Archive';
    return (
      <IconButton
        text={text}
        icon={faArchive}
        className={'edit small'}
        onClick={() => handleClick()}
      />
    );
  };

  const contactIcon = cm => {
    if (cm === 'email') return faEnvelope;
    if (cm === 'landline_phone') return faPhoneSquareAlt;
    if (cm === 'mobile_phone') return faMobile;
    return faMailBulk;
  };

  const genPlaceholderRows = contact => {
    const cmKinds = contact.contactMethods.map(cm => cm.kind);
    const bools = contactKinds.map(kind => {
      if (!cmKinds.filter(localKind => localKind === kind).length > 0) return kind;
    });
    return bools
      .filter(bool => bool !== undefined)
      .map((row, i) => (
        <div key={i} className={'icon-line'}>
          <FontAwesomeIcon icon={contactIcon(row)} />
          <FontAwesomeIcon icon={faExclamationTriangle} className={'warning-icon'} />
          <em>Not Provided</em>
        </div>
      ));
  };

  const addContact = <IconButton text={'Add Contact'} icon={faPlus} onClick={createContact} />;
  return (
    <FormSection title={'All Contacts'} optionalElement={addContact}>
      {loading && <LoadingSpinner />}
      {data &&
        contactKinds &&
        data.items.map((contact, i) => {
          const contactName = `${contact.firstName} ${contact.lastName}${
            contact.accounts ? ' [Accounts]' : ''
          }`;
          const formTitle = contactName === ' ' ? 'Unnamed' : contactName;
          const roleArr = [contact.jobTitle, contact.jobRole].filter(attr => attr);

          return (
            <CollapsibleFormSection
              key={i}
              title={formTitle}
              open={contactSections.includes(contact.id)}
              onClick={() => toggleContactSections(contact.id)}
            >
              <div className={'section-container'}>
                <div className={'body-section'}>
                  {roleArr.length > 0 && (
                    <div key={i} className={'icon-line'}>
                      <FontAwesomeIcon icon={faCircleInfo} />
                      {roleArr.join(' / ')}
                    </div>
                  )}
                  {contact.contactMethods.map((cm, i) => (
                    <div key={i} className={'icon-line'}>
                      <FontAwesomeIcon icon={contactIcon(cm.kind)} />
                      {cm.text}
                    </div>
                  ))}
                  {genPlaceholderRows(contact)}
                  {contact.emailInclusions.map((ei, i) => (
                    <div key={i} className={'icon-line'}>
                      <FontAwesomeIcon icon={faClosedCaptioning} />
                      {`${humanize(ei.templateType.name)} (${humanize(ei.ccType)})`}
                    </div>
                  ))}
                </div>
                <div className={'button-section'}>
                  <div className={'button-line'}>
                    <IconButton
                      text={'Edit'}
                      icon={faPen}
                      className={'edit small'}
                      onClick={() => editContact(contact)}
                    />
                    {contactArchiveButton(contact)}
                    <IconButton
                      text={'Delete'}
                      icon={faTrash}
                      className={'edit small'}
                      onClick={() =>
                        setConfirmOpen({
                          item: contact,
                          type: 'contact',
                          subText: contactName,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </CollapsibleFormSection>
          );
        })}
    </FormSection>
  );
};

ContactsSection.propTypes = {
  data: PropTypes.object,
  contactKinds: PropTypes.array,
  createContact: PropTypes.func,
  editContact: PropTypes.func,
  setConfirmOpen: PropTypes.func,
  refreshData: PropTypes.func,
  setError: PropTypes.func,
  loading: PropTypes.bool,
};

export default ContactsSection;
