import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '../../../../common/button/IconButton/IconButton';
import useApi from '../../../../common/hooks/useApi/useApi';
import Message from '../../../../common/layout/Message/Message';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Table from '../../../../components/Table/Table/Table';
import './PackageIndex.scss';
import PackageType from '../../../../api/controllers/Job/PackageType/PackageType';
import ErrorBoundary from '../../../../common/ErrorBoundary/ErrorBoundary';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import { useLocation, useNavigate } from 'react-router';

const PackageIndex = () => {
  const location = useLocation();
  const { data, dataLoading, refresh } = useApi({
    call: PackageType.all,
    handleError: e => setError(formatError(e)),
  });
  const [open, setOpen] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state?.detail === 'Package Deleted') {
      setSuccessMessage('Package Type has successfully been deleted.');
      location.state.detail = undefined;
    }
  }, []);

  document.title = 'All Package Types';

  const handleDelete = () => {
    PackageType.destroy(open.id).then(
      () => {
        refresh();
        setSuccessMessage('Package Type has successfully been deleted.');
      },
      json => setError(formatError(json)),
    );
    setOpen();
  };

  const headers = [
    { text: 'Name', sortingAttribute: 'name' },
    { text: 'Description', sortingAttribute: 'description' },
    { text: 'Abbreviation', sortingAttribute: 'abbreviation' },
    { text: 'Length (cm)', sortingAttribute: 'lengthCm', type: 'number' },
    { text: 'Width (cm)', sortingAttribute: 'widthCm', type: 'number' },
    { text: 'Height (cm)', sortingAttribute: 'heightCm', type: 'number' },
    { text: 'Actions' },
  ];

  const handleDrawRow = (item, i) => (
    <tr key={i} className={i % 2 === 0 ? '' : 'coloured'}>
      <td>
        <Link to={`/jobs/package_types/${item.id}`}>{item.name}</Link>
      </td>
      <td>{item.description}</td>
      <td>{item.abbreviation}</td>
      <td className="small">{item.lengthCm}</td>
      <td className="small">{item.widthCm}</td>
      <td className="small">{item.heightCm}</td>
      <td className="small">
        <span className="td-buttons">
          <IconButton
            text={'Edit'}
            icon={faPen}
            onClick={() => navigate(`/jobs/package_types/${item.id}/edit`)}
            className="edit small"
          />
          <IconButton
            text={'Delete'}
            icon={faTrash}
            onClick={() => setOpen(item)}
            className="destructive small"
          />
        </span>
      </td>
    </tr>
  );

  return (
    <div className="all-package-types">
      <ConfirmationModal
        question={'Are you sure you want to delete this package type?'}
        subText={open?.name}
        confirmText={'Delete'}
        isOpen={!!open}
        handleConfirm={handleDelete}
        handleCancel={() => setOpen()}
      />
      <Breadcrumbs>
        <Link to={'/jobs/package_types'}>Package Types</Link>
      </Breadcrumbs>
      <Ribbon>
        <IconButton
          text={'Add Package Type'}
          icon={faPlus}
          onClick={() => navigate('/jobs/package_types/new')}
        />
      </Ribbon>
      <div className="page-content">
        <Message text={error?.errors} type={'error'} onClick={() => setError()} visible={!!error} />
        <Message
          text={successMessage}
          type={'success'}
          onClick={() => setSuccessMessage()}
          visible={!!successMessage}
          onClose={setSuccessMessage}
        />
        {dataLoading ? (
          <LoadingSpinner />
        ) : (
          <ErrorBoundary>
            <Table
              title={'Package Types'}
              headers={headers}
              data={data}
              onDrawRow={handleDrawRow}
              searchable={['name', 'email']}
            />
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
};

PackageIndex.propTypes = {};

export default PackageIndex;
