import React from 'react';
import PropTypes from 'prop-types';
import './ToolbarColourSelect.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const COLOURS = [
  { id: 1, value: 'red' },
  { id: 2, value: 'orange' },
  { id: 3, value: 'yellow' },
  { id: 4, value: 'green' },
  { id: 5, value: 'blue' },
  { id: 6, value: 'indigo' },
  { id: 7, value: 'violet' },
  { id: 8, value: 'black' },
  { id: 9, value: 'white' },
];

const ToolbarColourSelect = ({
  name,
  menu,
  setMenu,
  setColour,
  icon,
  modifier = '',
  currentStyle, //OrderedSet from Draft of currentInlineStyle
}) => {
  const handleToggle = e => {
    e.preventDefault();
    menu === name ? setMenu() : setMenu(name);
  };

  const getCurrentFill = () => {
    const styleArray = currentStyle.toArray(); //converts to array
    const filteredByMod = styleArray.filter(item => item.startsWith(modifier)); //only gets styles of right type
    if (filteredByMod.length === 0) return ''; //default for none
    return filteredByMod[filteredByMod.length - 1].split('-')[1]; //returns the current color if found
  };

  return (
    <div className={'toolbar-colour-select'}>
      <button
        className={'tcs-icon'}
        onMouseDown={handleToggle}
        tabIndex="-1"
        data-testid="toolbar-color-select"
      >
        <FontAwesomeIcon
          icon={icon}
          style={{ borderBottom: `3px solid ${getCurrentFill()}`, verticalAlign: '-0.3em' }}
        />
      </button>
      {menu === name && (
        <div className={'tcs-menu'} data-testid="toolbar-color-select-menu">
          {COLOURS.map(colour => (
            <button
              key={colour.id}
              data-testid={`toolbar-color-select-item-${colour.id}`}
              style={{ backgroundColor: colour.value }}
              onMouseDown={e => {
                e.preventDefault();
                setColour(`${modifier}${colour.value}`);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

ToolbarColourSelect.propTypes = {
  name: PropTypes.string,
  menu: PropTypes.string,
  setMenu: PropTypes.func,
  setColour: PropTypes.func,
  icon: PropTypes.object,
  modifier: PropTypes.string,
  currentStyle: PropTypes.object,
};

export default ToolbarColourSelect;
