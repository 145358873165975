import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faCog, faIndent, faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TopBar.scss';
import LinkBar from '../../common/sidebar/LinkBar/LinkBar';
import logo from '../../images/logo/transswift.png';
import logoDev from '../../images/logo/transswift-development.png';
import QuickLinks from '../QuickLinks/QuickLinks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import GenericDropdown from '../../common/GenericDropdown/GenericDropdown';
import Entity from '../../api/controllers/Company/Entity/Entity';
import Booking from '../../api/controllers/Job/Booking/Booking';
import VehicleRun from '../../api/controllers/Movement/VehicleRun/VehicleRun';
import { humanize } from '../../utils/EnumHelper/EnumHelper';

// The top navbar.
const TopBar = ({
  user,
  toggleSidebar,
  onClick,
  permissions,
  leftClosed,
  handleLogout,
  setMessage,
}) => {
  const endpoint = {
    entity: { call: Entity.all, attr: 'internalName', link: '/companies/entities/' },
    booking: { call: Booking.all, attr: 'jobReference', link: '/jobs/bookings/' },
    vehicle_run: { call: VehicleRun.all, attr: 'runReference', link: '/movements/runs/' },
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [ep, setEp] = useState('entity');
  const [search, setSearch] = useState('');

  // Entity: internalName  Job:: jobReference  Run: runReference
  const generateLinks = ({ attr, link }, items) =>
    items.map(item => ({
      text: item[attr],
      link: `${link}${item.id}`,
    }));

  // If one match found, automatically take the user there
  // If two or more found, display in message
  // If none found, tell the user that we couldn't find anything
  const handleSearch = e => {
    // If any key but enter pressed, just default functionality
    if (e.keyCode !== 13) return;
    const { call, link } = endpoint[ep];
    call({ filter: search, limit: 10 }).then(({ total, items }) => {
      if (total === 0)
        return setMessage({ error: `No ${humanize(ep)} found matching '${search}'` });
      if (total === 1) return navigate(`${link}${items[0].id}`);
      const links = generateLinks(endpoint[ep], items);
      setMessage({ status: 'dym', errors: links });
    });
  };

  useEffect(() => {
    if (search !== '') setSearch('');
    setMessage();
  }, [pathname]);

  return (
    <div className="top-bar">
      <div className="top-bar-section">
        <button className={`icon-button ${!leftClosed && 'closed'}`} onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faIndent} />
        </button>
        <Link
          className={`company-logo ${process.env.REACT_APP_ENV !== 'production' && 'development'}`}
          to={'/'}
        >
          <img src={process.env.REACT_APP_ENV === 'production' ? logo : logoDev} />
        </Link>
        <QuickLinks permissions={permissions} />
      </div>
      <div className="top-bar-section">
        <div className="top-bar-search">
          <GenericDropdown
            value={ep}
            items={Object.keys(endpoint)}
            onDisplay={key => humanize(key)}
            onSelect={key => setEp(key)}
          />
          <input
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={'Quick find...'}
            onKeyDown={handleSearch}
          />
        </div>
        <div className="top-bar-user">
          <div className="top-bar-icon">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <span className="user-name" title={`${user.firstName} ${user.lastName}`}>
            {`${user.firstName} ${user.lastName}`}
          </span>

          <div className="top-bar-user-dropdown">
            <LinkBar icon={faCog} text={'Settings'} onClick={onClick} />
            <LinkBar icon={faPowerOff} text={'Logout'} onClick={handleLogout} />
          </div>
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  user: PropTypes.object,
  toggleSidebar: PropTypes.func,
  onClick: PropTypes.func,
  permissions: PropTypes.object,
  leftClosed: PropTypes.bool,
  handleLogout: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default TopBar;
