import React from 'react';
import PropTypes from 'prop-types';
import './TimeInput.scss';

// MARKED FOR REFACTOR
// Why: Because not all times contain opensAt and closesAt so they must be variable
const TimeInput = ({ opensAt, closesAt, onChange, readOnly }) => (
  <div className={'ti-container'}>
    <input
      className={'ti-input'}
      type={'time'}
      value={opensAt}
      onChange={e => onChange('opensAt', e.target.value)}
      data-testid={'ti-first-time'}
      readOnly={readOnly}
    />
    <div className={'ti-gap'}>To</div>
    <input
      className={'ti-input'}
      type={'time'}
      value={closesAt}
      onChange={e => onChange('closesAt', e.target.value)}
      data-testid={'ti-last-time'}
      readOnly={readOnly}
    />
  </div>
);

TimeInput.propTypes = {
  opensAt: PropTypes.string,
  closesAt: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default TimeInput;
