import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TablePresenter from '../TablePresenter/TablePresenter';
import TableHelper from '../../../utils/TableHelper/TableHelper';

const Table = ({
  title,
  headers,
  data,
  onDrawRow,
  searchable,
  limit = 15,
  children,
  defaultSort = {},
}) => {
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState(defaultSort);
  const [currentPage, setCurrentPage] = useState(1);

  const onSearch = e => setSearchText(e.target.value);

  const searched = TableHelper.searchData(data?.items, searchText, searchable);
  const sortFunc = TableHelper.getSortFunction(sort.type);
  const sorted = TableHelper.sortData(sortFunc, searched, sort);
  const paginated = TableHelper.paginateData(sorted, limit, currentPage);

  const pageNum = TableHelper.getPageNum(searched, limit);

  useEffect(() => {
    if (currentPage > pageNum) setCurrentPage(pageNum);
    if (currentPage < 1) setCurrentPage(1);
  }, [searchText]);

  if (!data) return null;
  const lowerBound = TableHelper.getLowerBound(currentPage, limit);
  const upperBound = TableHelper.getUpperBound(currentPage, limit, paginated.length);

  return (
    <TablePresenter
      title={title}
      headers={headers}
      rows={paginated}
      onDrawRow={onDrawRow}
      sort={sort}
      onSort={header => TableHelper.onSort(header, sort, setSort)}
      onSearch={onSearch}
      pageElements={() => TableHelper.genPageNumbers(currentPage, setCurrentPage, pageNum)}
      pageText={TableHelper.paginationText(upperBound, lowerBound, searched.length)}
    >
      {children}
    </TablePresenter>
  );
};

Table.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.object,
  onDrawRow: PropTypes.func,
  searchable: PropTypes.arrayOf(PropTypes.string),
  limit: PropTypes.number,
  children: PropTypes.node,
  defaultSort: PropTypes.object,
};

export default Table;
