import React from 'react';
import PropTypes from 'prop-types';
import Booking from '../../../../../../api/controllers/Job/Booking/Booking';
import useApi from '../../../../../../common/hooks/useApi/useApi';
import './VehicleRunShowFinancials.scss';
import LoadingSpinner from '../../../../../../common/LoadingSpinner/LoadingSpinner';
import FinancialsTab from './FinancialsTab/FinancialsTab';

const VehicleRunShowFinancials = ({ run }) => {
  const { data } = useApi({ call: () => Booking.all({ vehicleRunId: run.id }) });

  if (!data) return <LoadingSpinner />;
  return (
    <div className="tab-content vrs-financials">
      <FinancialsTab allJobs={data} run={run} />
    </div>
  );
};

VehicleRunShowFinancials.propTypes = {
  run: PropTypes.object.isRequired,
};

export default VehicleRunShowFinancials;
