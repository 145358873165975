import React from 'react';
import PropTypes from 'prop-types';
import { humanize } from '../../../../../../../utils/EnumHelper/EnumHelper';
import { Link } from 'react-router-dom';
import FormSection from '../../../../../../../components/FormSection/FormSection';
import './VehicleRunShowJobsSection.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesStacked, faCircle } from '@fortawesome/free-solid-svg-icons';

const VehicleRunShowJobsSection = ({ job }) => {
  const title = (
    <div className="vrsjs-title">
      <FontAwesomeIcon icon={faBoxesStacked} className="vrsjs-icon" />
      <div>{job.jobReference}</div>
      <hr />
      <div>
        <FontAwesomeIcon className={`status-icon ${job.status}`} icon={faCircle} />
        {humanize(job.status)}
      </div>
      <hr />
      <div>{job.customer?.internalName}</div>
    </div>
  );

  return (
    <div className="vrsj-section">
      <FormSection title={title}>
        <div className="vrsjs-content">
          <div className="vrsjs-row">
            <div className="vrsjs-header">Job Number</div>
            <Link to={`/jobs/bookings/${job.id}`} target={'_blank'}>
              {job.jobReference}
            </Link>
          </div>
          <div className="vrsjs-row">
            <div className="vrsjs-header">Status</div>
            {humanize(job.status)}
          </div>
          <div className="vrsjs-row extended">
            <div className="vrsjs-header">Customer</div>
            <Link to={`/companies/entities/${job.customer?.id}`} target={'_blank'}>
              {job.customer?.internalName}
            </Link>
          </div>

          <div className="vrsjs-row">
            <div className="vrsjs-header">Quantity</div>
            {job.quantity || 0} pkg
          </div>
          <div className="vrsjs-row">
            <div className="vrsjs-header">Weight</div>
            {job.grossWeightKg || 0} kg
          </div>
          <div className="vrsjs-row">
            <div className="vrsjs-header">Volume</div>
            {job.cubicMetres || 0} m³
          </div>
          <div className="vrsjs-row extended">
            <div className="vrsjs-header">Collection Entity</div>
            <Link to={`/companies/entities/${job.collectionEntity?.id}`} target={'_blank'}>
              {job.collectionEntity?.internalName}
            </Link>
          </div>
          <div className="vrsjs-row extended">
            <div className="vrsjs-header">Delivery Entity</div>
            <Link to={`/companies/entities/${job.deliveryEntity?.id}`} target={'_blank'}>
              {job.deliveryEntity?.internalName}
            </Link>
          </div>
        </div>
      </FormSection>
    </div>
  );
};

VehicleRunShowJobsSection.propTypes = { job: PropTypes.object };

export default VehicleRunShowJobsSection;
