import React from 'react';
import PropTypes from 'prop-types';
import VehicleRunShowRoutesEntry from './VehicleRunShowRoutesEntry/VehicleRunShowRoutesEntry';
import './VehicleRunShowRoutes.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import Entity from '../../../../../../api/controllers/Company/Entity/Entity';
import LoadingSpinner from '../../../../../../common/LoadingSpinner/LoadingSpinner';

const VehicleRunShowRoutes = ({ run }) => {
  const [relevantEnts, setRelevantEnts] = useState();
  const { waypoints } = run;

  // This function simply gets all entity ids needed for name display above route addresses
  const getDistinctEntities = waypoints =>
    Array.from(new Set(waypoints.map(wp => wp.address?.entityId)));

  // This useEffect retrieves all relevant entities and assigns them to an object in state
  // This object uses the id of the entity as a key for easy retrieval
  useEffect(() => {
    if (!relevantEnts)
      Entity.showMany(getDistinctEntities(waypoints)).then(entityArr => {
        const obj = {};
        entityArr.forEach(ent => (obj[ent?.id] = ent));
        setRelevantEnts(obj);
      });
  }, []);

  // If we have not yet finished retrieving the entities, show nothing
  if (relevantEnts === undefined) return <LoadingSpinner />;
  return (
    <div className="vrs-routes tab-content">
      <div className="timeline">
        {waypoints.length === 0 && (
          <div className="no-wp-message">No waypoints have been defined yet</div>
        )}
        {waypoints.map(wp => (
          <VehicleRunShowRoutesEntry
            key={wp.id}
            waypoint={wp}
            entity={relevantEnts[wp.address.entityId]}
          />
        ))}
      </div>
    </div>
  );
};

VehicleRunShowRoutes.propTypes = {
  run: PropTypes.object.isRequired,
};

export default VehicleRunShowRoutes;
