export const entityStatuses = {
  active: 'Active',
  ceased_trading: 'Ceased Trading',
  downtrader: 'Downtrader',
  blocked: 'Blocked',
};

export const warehouseFactors = {
  restrictedAccess: 'Restricted',
  unmanned: 'Unstaffed',
  tailLiftRequired: 'Tail Lift Required',
  pumpTruckRequired: 'Pump Truck Required',
  hiabRequired: 'Hiab Required',
  bookingInForCollectionRequired: 'Col. Booking in Req',
  bookingInForDeliveryRequired: 'Del. Booking in Req',
  strictTimeSlots: 'Strict Time Slots',
  noBoxTrailers: 'No Box Trailers',
  collectionRefRequired: 'Col. Ref. Required',
  deliveryRefRequired: 'Del. Ref. Required',
};

export const fileCategories = {
  sales_invoice: 'Sales Invoice',
  sales_credit_note: 'Sales Credit Note',
  subcontract_instruction: 'Subcontract Instruction',
  booking_confirmation: 'Booking Confirmation',
  collection_delivery_note: 'Collection/Delivery Note',
};

export const warehouseFreq = ['Never', 'Sometimes', 'Always'];

export const emailTemplateTypes = {
  general_job: 'General (Job)',
  general_entity: 'General (Entity)',
  general_vehicle_run: 'General (Vehicle Run)',
  credit_limit: 'Credit Related',
  pod_request: 'POD Request',
};

export const contactMethodLabels = {
  email: 'Email',
  landline_phone: 'Phone',
  mobile_phone: 'Mobile',
};

export const vehicleRunStatuses = {
  closed: 'Finalised',
};

export const bulkInvoicingValues = {
  daily: 'Yes - Daily',
  weekly: 'Yes - Weekly',
  monthly: 'Yes - Monthly',
};
