import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import { faPlus, faFileImport } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../common/button/IconButton/IconButton';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import Message from '../../../../common/layout/Message/Message';
import './BookingIndex.scss';
import ImportJob from '../../../../components/modals/ImportJob/ImportJob';
import CheckPermit from '../../../../common/CheckPermit/CheckPermit';
import JobsTable from '../../../../common/JobsTable/JobsTable';
import ErrorBoundary from '../../../../common/ErrorBoundary/ErrorBoundary';
import { useLocation, useNavigate } from 'react-router';

const BookingIndex = ({ user, permissions }) => {
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state?.detail === 'Job Deleted') {
      setSuccessMessage('Job has successfully been deleted.');
      location.state.detail = undefined;
    }
  }, []);

  document.title = 'All Bookings';
  return (
    <div className="all-booking-types">
      <Breadcrumbs>
        <Link to={'/jobs/bookings'}>Jobs</Link>
      </Breadcrumbs>
      <Ribbon>
        <CheckPermit type={'jobsBookings'} action={'create'} permissions={permissions}>
          <IconButton
            text={'Import Job From FREIGHTsoft'}
            className={'import-job'}
            icon={faFileImport}
            onClick={() => setIsOpen(true)}
          />
        </CheckPermit>

        <IconButton
          text={'Create New Job'}
          icon={faPlus}
          onClick={() => navigate('/jobs/bookings/new')}
        />
      </Ribbon>

      <ImportJob isOpen={isOpen} setIsOpen={setIsOpen} />

      <div className="page-content">
        <Message text={error?.errors} type={'error'} onClick={() => setError()} visible={!!error} />
        <Message
          text={successMessage}
          type={'success'}
          onClick={() => setSuccessMessage()}
          visible={!!successMessage}
          onClose={setSuccessMessage}
        />
        <ErrorBoundary>
          <JobsTable user={user} tableTitle={'Jobs'} />
        </ErrorBoundary>
      </div>
    </div>
  );
};

BookingIndex.propTypes = {
  user: PropTypes.object,
  permissions: PropTypes.object,
};

export default BookingIndex;
