import React, { useEffect, useState } from 'react';
import PackageType from '../../../../api/controllers/Job/PackageType/PackageType';
import useForm from '../../../../common/hooks/useForm/useForm';
import Message from '../../../../common/layout/Message/Message';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import FormSection from '../../../../components/FormSection/FormSection';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import FieldList from '../../../../components/FieldList/FieldList';
import FormField from '../../../../components/FormField/FormField';
import './PackageForm.scss';
import ValueInput from '../../../../common/input/ValueInput/ValueInput';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import { useNavigate, useParams } from 'react-router';

const PackageForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { form, handleForm, alter } = useForm();
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const handleErrors = json => {
    setLoading(false);
    const errorContent = formatError(json);
    const errorCount = errorContent.errors.length;
    setError({
      type: 'error',
      text: {
        errorTitle: `Action Failed, ${errorCount} error${errorCount > 1 ? 's' : ''} to resolve.`,
        errors: errorContent.errors,
      },
    });
  };

  useEffect(() => {
    if (!id) return initCreate();

    PackageType.show(id).then(
      json => {
        setData(json);
        initUpdate(json);
        setLoading(false);
      },
      json => handleErrors(json),
    );
  }, []);

  const initUpdate = json => {
    document.title = `Editing ${json?.name}`;
    handleForm({
      id: json.id || null,
      name: json.name || '',
      description: json.description || '',
      abbreviation: json.abbreviation || '',
      lengthCm: json.lengthCm || null,
      widthCm: json.widthCm || null,
      heightCm: json.heightCm || null,
    });
  };

  const initCreate = () => {
    setLoading(false);
    document.title = 'New Package Type';
    handleForm({
      id: null,
      name: '',
      description: '',
      abbreviation: '',
      lengthCm: null,
      widthCm: null,
      heightCm: null,
    });
  };

  const handleSuccess = json => {
    setLoading(false);
    navigate(`/jobs/package_types/${json.id}`);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    if (id) return PackageType.update(id, form).then(handleSuccess, handleErrors);
    PackageType.create(form).then(handleSuccess, handleErrors);
  };

  const cancelUrl = id ? `/jobs/package_types/${id}` : '/jobs/package_types';
  const breadcrumbs = id ? (
    <Breadcrumbs>
      <Link to={'/jobs/package_types'}>Package Types</Link>
      <Link to={`/jobs/package_types/${id}`}>{data ? `${data.name}` : id}</Link>
      <Link to={`/jobs/package_types/${id}/edit`}>Edit</Link>
    </Breadcrumbs>
  ) : (
    <Breadcrumbs>
      <Link to={'/jobs/package_types'}>Package Types</Link>
      <Link to={'/jobs/package_types/new'}>New</Link>
    </Breadcrumbs>
  );

  return (
    <div className={'package-form'}>
      {breadcrumbs}

      <div className={'page-content'}>
        <Message type={'error'} text={error?.text} onClose={() => setError()} visible={!!error} />
        {!loading && form && <h1>{id ? `Editing ${data?.name}` : 'New Package Type'}</h1>}

        <TabGroup labels={['General']}>
          <div className={'tab-content'}>
            {!loading && form ? (
              <FormSection title={'Details'}>
                <FieldList>
                  <FormField label={'Name'} className={'short'}>
                    <input
                      value={form.name || ''}
                      onChange={e => alter('name', e.target.value)}
                      autoFocus
                    />
                  </FormField>
                  <FormField label={'Description'} className={'short'}>
                    <input
                      value={form.description || ''}
                      onChange={e => alter('description', e.target.value)}
                    />
                  </FormField>
                </FieldList>
                <FieldList>
                  <FormField label={'Abbreviation'} className={'short'}>
                    <input
                      value={form.abbreviation || ''}
                      onChange={e => alter('abbreviation', e.target.value)}
                    />
                  </FormField>
                  <FormField label={'Dimensions (L/W/H)'} className={'short'}>
                    <div className={'group'}>
                      <ValueInput
                        unit={'CM'}
                        value={form.lengthCm || ''}
                        onChange={e => alter('lengthCm', e.target.value)}
                        min={1}
                      />
                      <ValueInput
                        unit={'CM'}
                        value={form.widthCm || ''}
                        onChange={e => alter('widthCm', e.target.value)}
                        min={1}
                      />
                      <ValueInput
                        unit={'CM'}
                        value={form.heightCm || ''}
                        onChange={e => alter('heightCm', e.target.value)}
                        min={1}
                        testId={'height-cm-input'}
                      />
                    </div>
                  </FormField>
                </FieldList>
              </FormSection>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </TabGroup>
        <div className="button-group">
          <IconButton
            text={'Save'}
            icon={faCheck}
            onClick={e => handleSubmit(e)}
            disabled={loading}
          />
          <IconButton
            text={'Cancel'}
            icon={faTimes}
            onClick={() => navigate(cancelUrl)}
            className="edit"
          />
        </div>
      </div>
    </div>
  );
};

PackageForm.propTypes = {};

export default PackageForm;
