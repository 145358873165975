import React, { useState } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import Message from '../../../common/layout/Message/Message';
import Ribbon from '../../../components/Ribbon/Ribbon';
import FormField from '../../../components/FormField/FormField';
import './ReportIndex.scss';
import useApi from '../../../common/hooks/useApi/useApi';
import Report from '../../../api/controllers/Report/Report';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import Table from '../../../components/Table/Table/Table';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import ReportRow from './ReportRow/ReportRow';
import ErrorStatusMessage from '../../../common/ErrorStatusMessage/ErrorStatusMessage';

/* We want to sort by Name and Description, but not Dates Required */
const headers = [
  { text: 'Name', sortingAttribute: 'name' },
  { text: 'Description', sortingAttribute: 'description' },
  { text: 'Dates Required' },
];

/*
  Why? The ReportIndex should hold all standard report types.
    Reports need a date range most of the time, this allows them to generate
      data from all jobs, runs or other in that space
    Reports that do not need this, such as an Outstanding Sales Line Item will
      always allow you to generate a report, ignoring the range if given
*/
const ReportIndex = () => {
  const [message, setMessage] = useState(); /* The red message at the top of the index */
  const [warning, setWarning] = useState(); /* The yellow toast message */
  const [from, setFrom] = useState(''); /* The date input "from" */
  const [to, setTo] = useState(''); /* The date input "to" */
  const navigate = useNavigate();

  /* Handles errors coming from the Report.all endpoint */
  const handleError = err => setMessage({ type: 'error', text: formatError(err) });

  const { reports, reportsLoading } = useApi({ call: Report.all, name: 'reports', handleError });

  document.title = 'All Reports';

  // When the name of a report is clicked, check whether we need a date range
  // If we do, throw a small warning toast if not filled in, else redirect
  // If we don't, simply redirect using navigate
  const handleRedirect = row => {
    if (row.defaultDateField) {
      if (!from || !to)
        return setWarning({ error: 'You must specify a date range for this report.' });

      if (new Date(to) < new Date(from))
        return setWarning({ error: 'Invalid date range entered.' });

      navigate(`/reports/standard/${row.id}?startDate=${from}&endDate=${to}`);
    } else {
      navigate(`/reports/standard/${row.id}`);
    }
  };

  return (
    <div className="report-index">
      <Breadcrumbs>
        <Link to="/reports/standard">Reports</Link>
      </Breadcrumbs>
      <Ribbon>
        <FormField label="From">
          <input
            type={'date'}
            value={from}
            onChange={e => setFrom(e.target.value)}
            autoFocus
            data-testid="from-date"
          />
        </FormField>
        <FormField label="to">
          <input
            type={'date'}
            value={to}
            onChange={e => setTo(e.target.value)}
            data-testid="to-date"
          />
        </FormField>
      </Ribbon>
      <div className="page-content">
        {message && (
          <Message type={message.type} text={message.text} visible onClick={() => setMessage()} />
        )}
        {warning && <ErrorStatusMessage error={warning} setError={setWarning} />}
        {reportsLoading ? (
          <LoadingSpinner />
        ) : (
          <Table
            title={'Standard Reports'}
            headers={headers}
            data={reports}
            searchable={['name']}
            onDrawRow={row => <ReportRow key={row.id} row={row} handleRedirect={handleRedirect} />}
          />
        )}
      </div>
    </div>
  );
};

export default ReportIndex;
