import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import TabGroup from '../../../components/TabGroup/TabGroup';
import { Link } from 'react-router-dom';
import Message from '../../../common/layout/Message/Message';
import { labelForEnum } from '../../../utils/EnumHelper/EnumHelper';
import './EmailTemplateForm.scss';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Placeholders from './subComponents/Placeholders/Placeholders';
import TemplateForm from './subComponents/TemplateForm/TemplateForm';
import EmailTemplateType from '../../../api/controllers/EmailTemplateType/EmailTemplateType';
import EmailTemplate from '../../../api/controllers/EmailTemplate/EmailTemplate';
import useApi from '../../../common/hooks/useApi/useApi';
import { handleDefaultValue } from '../../../components/RichText/helpers/contentHelper/contentHelper';
import { draftjsToHtml } from '../../../components/RichText/helpers/contentHelper/contentHelper';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import { emailTemplateTypes } from '../../../strings/enums';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import { useLocation, useParams, useNavigate } from 'react-router';

const EmailTemplateForm = ({ user }) => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [templateTypeId, setTemplateTypeId] = useState();
  const [editorState, setEditorState] = useState(handleDefaultValue());

  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [edit, setEdit] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [editorFocused, setEditorFocused] = useState(false);
  const subjectInput = useRef(null);
  const editorRef = useRef(null);

  const searchParams = new URLSearchParams(location.search);
  const templateTypeIdParam = searchParams.get('templateTypeId');
  useEffect(() => {
    setDataLoading(true);
    if (id) {
      EmailTemplate.show(id).then(res => {
        setTemplateTypeId(res.templateType.id);
        setName(res.name);
        setSubject(res.subject);
        setEditorState(handleDefaultValue(`${res.body || ''}`));
        setEdit(true);
        setDataLoading(false);
      });
    } else {
      setTemplateTypeId(templateTypeIdParam);
      setDataLoading(false);
    }
  }, []);

  const { placeholders, placeholdersLoading } = useApi({
    call: () => EmailTemplateType.getPlaceholders(templateTypeId),
    name: 'placeholders',
    dependencies: [templateTypeId],
    condition: !!templateTypeId,
  });

  const { templateData, templateDataLoading } = useApi({
    call: () => EmailTemplateType.show(templateTypeId),
    name: 'templateData',
    dependencies: [templateTypeId],
    condition: !!templateTypeId,
  });

  if (!templateData || !placeholders) return null;

  document.title =
    `${edit ? 'Editing' : 'New'} Email Template | ` +
    labelForEnum(emailTemplateTypes, templateData.name);
  const handleSubmit = () => {
    setSaveInProgress(true);
    const body = draftjsToHtml(editorState);

    const params = {
      name: name,
      subject: subject,
      body: body,
      templateTypeId: templateTypeId,
    };
    const call = edit ? () => EmailTemplate.update(id, params) : () => EmailTemplate.create(params);
    call().then(
      res => {
        navigate(`/emails/templates/${res.id}`);
        setSaveInProgress(false);
      },
      err => {
        setMessage({ ...formatError(err), errorTitle: 'Save Failed!', type: 'error' });
        setSaveInProgress(false);
      },
    );
  };
  if (dataLoading || placeholdersLoading || templateDataLoading) return <LoadingSpinner />;
  return (
    <div className="email-template-new">
      <Breadcrumbs>
        <Link to={'/emails/templates'}>Email Templates</Link>
        {edit && <Link to={`/emails/templates/${id}/edit`}>Edit</Link>}
        {!edit && <Link to={`/emails/templates/new?templateTypeId=${templateTypeId}`}>New</Link>}
      </Breadcrumbs>
      <div className="page-content">
        <Message
          text={message}
          type={message?.type}
          onClick={() => setMessage()}
          visible={!!message}
          onClose={setMessage}
          snapTo
        />
        <h1 className="template-title">
          {edit ? `Editing ${name}` : 'New Email Template'} |
          <span className="latter-title">
            {labelForEnum(emailTemplateTypes, templateData.name)}
          </span>
        </h1>
        <TabGroup labels={['General']}>
          <div className="tab-content">
            <Placeholders
              data={placeholders}
              editorState={editorState}
              setEditorState={setEditorState}
              subjectInput={subjectInput}
              setSubject={setSubject}
              editorFocused={editorFocused}
              editorRef={editorRef}
              associatedModel={templateData.associatedModel}
            />
            <TemplateForm
              user={user}
              editorState={editorState}
              setEditorState={setEditorState}
              setEditorFocused={setEditorFocused}
              name={name}
              setName={setName}
              subject={subject}
              setSubject={setSubject}
              subjectInput={subjectInput}
              editorRef={editorRef}
            />
          </div>
        </TabGroup>
        <div className="button-group">
          <IconButton
            text={'Save'}
            icon={faCheck}
            onClick={() => handleSubmit()}
            loading={saveInProgress}
          />
          <IconButton
            text={'Cancel'}
            icon={faTimes}
            onClick={() =>
              id ? navigate(`/emails/templates/${id}`) : navigate('/emails/templates')
            }
            className="edit"
          />
        </div>
      </div>
    </div>
  );
};
EmailTemplateForm.propTypes = {
  user: PropTypes.object,
};

export default EmailTemplateForm;
