import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class Recipient {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'emails/recipients', data);
  }

  static ccTypes() {
    return apiCall('GET', 'emails/recipients/cc_types');
  }
}

export default Recipient;
