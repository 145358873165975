import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/Modal/Modal';
import TabGroup from '../TabGroup/TabGroup';
import useApi from '../../common/hooks/useApi/useApi';
import EmailTemplate from '../../api/controllers/EmailTemplate/EmailTemplate';
import ComposeField from './ComposeField/ComposeField';
import FormSection from '../FormSection/FormSection';
import './EmailComposeModal.scss';
import {
  draftjsToHtml,
  findWithRegex,
  getMatchStartAndEnd,
  handleDefaultValue,
} from '../RichText/helpers/contentHelper/contentHelper';
import RichText from '../RichText/RichText';
import useForm from '../../common/hooks/useForm/useForm';
import { faEnvelope, faPlus, faSortDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Recipient from '../../api/controllers/Email/Recipient/Recipient';
import useArray from '../../common/hooks/useArray/useArray';
import Envelope from '../../api/controllers/Email/Envelope/Envelope';
import IconButton from '../../common/button/IconButton/IconButton';
import StringHelper from '../../utils/StringHelper/StringHelper';
import Message from '../../common/layout/Message/Message';
import { formatError } from '../../utils/Formatters/Errors/formatError';
import EmailFileTab from './EmailFileTab/EmailFileTab';
import AttachmentSection from './AttachmentSection/AttachmentSection';
import { useEffect } from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';
import GenericAjaxDropdown from '../../common/GenericAjaxDropdown/GenericAjaxDropdown';
import GenericDropdown from '../../common/GenericDropdown/GenericDropdown';
import Contact from '../../api/controllers/Company/Contact/Contact';
import EmailHelper from '../../utils/EmailHelper/EmailHelper';
import { SelectionState, Modifier, EditorState } from 'draft-js';

/*
  Filetabs format: [{ tabName: 'Job Files', parent: { id: 420, modelName: Companies::Job } }]
  LEAVE PARENT NULL IF YOU WANT GENERAL FILES BUT DO GIVE IT A TABNAME
  Just for clarification, just pass the parent entity wholesale and it will be fine, you don't need to match that exact object format
*/
const EmailComposeModal = ({
  user, // A user object, like from User.show
  parent, // Any backend object that has attached files
  recipientEntity, // Limits the email recipients to whatever entity id you provide
  templateType, // The type of the email templates to display
  handleClose, // setModal('no')
  fileTabs, // See top of file
  setAlert = () => {}, //Alert for when an email successfully sent
  defaultAttachments = [],
}) => {
  const [message, setMessage] = useState();
  const [typesOpen, setTypesOpen] = useState();
  const handleError = err => setMessage({ type: 'error', text: formatError(err) });
  const { templates } = useApi({
    call: () => EmailTemplate.all({ templateType }),
    name: 'templates',
    handleError,
  });
  const { types } = useApi({ call: Recipient.ccTypes, name: 'types', handleError });
  const [editorState, setEditorState] = useState(() => handleDefaultValue(user?.emailSignature));
  const { form, alter, handleForm } = useForm({ name: '', subject: '' });
  const { ccArray, addCcArray, removeIdxCcArray, replaceCcArray, handleCcArray } = useArray({
    name: 'ccArray',
    defaultValue: [{ emailAddress: '', ccType: 'to' }],
  });
  const { fileArray, addFileArray, removeIdxFileArray, handleFileArray } = useArray({
    name: 'fileArray',
    defaultValue: defaultAttachments,
  });
  const { removalArray, addRemovalArray, removeIdxRemovalArray } = useArray({
    name: 'removalArray',
    defaultValue: [],
  });
  const [confirm, setConfirm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const getSalutation = () => EmailHelper.getSalutation(EmailHelper.firstCCName(ccArray));

  // This function will attempt to retrieve the template with the given index
  // It will then use this index to set selectedTemplate purely for the dropdown's use
  // Finally it will replace the state with a fresh editor state matching the provided template
  const setTemplate = templateIdx => {
    setLoading(true);
    const template = templates.items[templateIdx];
    EmailTemplate.show(template.id, parent.id)
      .then(res => {
        handleForm({ ...form, ...res });
        const templateAttachments = res.templateAttachments
          ? res.templateAttachments.map(att => att.file)
          : [];
        const optionalAttachments = fileArray.filter(file => file.optional);
        res.templateAttachments.length > 0 &&
          handleFileArray([
            ...defaultAttachments.filter(
              item => !removalArray.find(removal => removal === item.id),
            ),
            ...templateAttachments.filter(
              item => !removalArray.find(removal => removal === item.id),
            ),
            ...optionalAttachments,
          ]);
        res.templateAttachments.length === 0 &&
          handleFileArray([
            ...defaultAttachments.filter(
              item => !removalArray.find(removal => removal === item.id),
            ),
            ...optionalAttachments,
          ]);
        setEditorState(
          handleDefaultValue(`${getSalutation()}${res.body || ''}${user.emailSignature || ''}`),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setDisabled(true);
    Envelope.create({
      parentId: parent?.id,
      parentType: parent?.modelName,
      subject: form.subject,
      body: draftjsToHtml(editorState),
      recipientsAttributes: [...ccArray],
      attachmentsAttributes: fileArray.map(el => ({ ...el, attachmentId: el.id })),
    }).then(
      () => {
        setDisabled(false);
        setAlert({ status: 201, error: 'Email sent successfully.' });
        handleClose();
      },
      err => {
        setMessage({ type: 'error', text: formatError(err) });
        setDisabled(false);
      },
    );
  };

  const handleTypes = (idx, type) => replaceCcArray(idx, { ...ccArray[idx], ccType: type });
  const tabs = fileTabs ? ['Email', ...fileTabs.map(tab => tab.tabName)] : ['Email'];

  /*
    Finds the salutation line within the rich-text editor and updates it.
  */
  const updateSalutation = () => {
    //Regex to match the salutation line
    const regex = new RegExp('Good (Morning|Afternoon|Evening).*,\\n\\n', 'g');
    //get the current editor state information
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    //find the block that contains the salutation or return if none found
    const blockToReplace = blockMap.find(contentBlock => findWithRegex(regex, contentBlock));
    if (!blockToReplace) return;

    //Find the start and end index of the salutation text in the block
    const key = blockToReplace.getKey();
    const { start, end } = getMatchStartAndEnd(regex, blockToReplace);
    const sectionToReplace = SelectionState.createEmpty(key).merge({
      anchorOffset: start,
      focusOffset: end,
    });
    // if (!sectionToReplace) return;

    //Replace the old salutation with the new, then update state.
    const newContentState = Modifier.replaceText(contentState, sectionToReplace, getSalutation());
    const newEditorState = EditorState.set(editorState, { currentContent: newContentState });
    setEditorState(newEditorState);
  };

  /*
    This doesn't load the salutation correctly on mount without loading being passed,
    if you can figure out why please fix, i assume its some state miss-match nonsense 
  */
  useEffect(() => {
    updateSalutation();
  }, [loading, JSON.stringify(ccArray)]);

  // If templates api call is complete, find the default template and set it
  useEffect(() => {
    if (templates) {
      const defaultTemp = templates.items.filter(
        template => template.id === template.templateType.defaultTemplateId,
      );
      if (defaultTemp.length > 0) setTemplate(templates.items.indexOf(defaultTemp[0]));
      else setEditorState(handleDefaultValue(`${getSalutation()}${user.emailSignature || ''}`));
    } else {
      setEditorState(handleDefaultValue(`${getSalutation()}${user.emailSignature || ''}`));
    }
  }, [templates]);

  // Automatically assign contacts that contain valid emails and an email inclusion rule
  useEffect(() => {
    Contact.all({ entityId: recipientEntity?.id }).then(res => {
      // Create an array of all contacts that have email inclusion rules and contain an email contact
      const included = res.items.filter(
        contact =>
          contact.emailInclusions.filter(inc => inc.templateType?.name === templateType).length >
            0 && contact.contactMethods.filter(meth => meth.kind === 'email').length > 0,
      );

      // Create an array of recipients
      const validEmailContacts = included
        .map(contact => {
          // Get all valid email Contact Methods from the Contact
          const contacts = contact.contactMethods.filter(meth => meth.kind === 'email');
          return contacts.map(meth => ({
            contactMethodId: meth.id,
            emailAddress: meth.text,
            contact: contact,
            ccType: contact.emailInclusions.filter(inc => inc.templateType.name === templateType)[0]
              .ccType,
          }));
        })
        .flat();

      if (validEmailContacts.length > 0) handleCcArray(validEmailContacts);
    });
  }, []);

  return (
    <Modal isOpen handleClose={() => setConfirm(true)}>
      <ConfirmationModal
        question="Are you sure you want to discard this email?"
        confirmText="Yes"
        cancelText="No"
        isOpen={confirm}
        handleCancel={() => setConfirm(false)}
        handleConfirm={handleClose}
        destructive
      />
      <span className="ecm-wrap">
        <FormSection title={'Send Email'} extraClassName="modal-content" draggable>
          {message && (
            <Message type={message.type} text={message.text} onClick={() => setMessage()} visible />
          )}
          <TabGroup labels={tabs}>
            {templates && types && !loading ? (
              <div className="tab-content">
                <div className="email-details">
                  <ComposeField label={'Template'}>
                    <GenericDropdown
                      value={form}
                      items={templates.items}
                      onDisplay={tem => tem?.name}
                      onSelect={(_, i) => setTemplate(i)}
                      testId={'templateDropdown'}
                    />
                  </ComposeField>
                  <ComposeField label={'Subject'}>
                    <input value={form.subject} onChange={e => alter('subject', e.target.value)} />
                  </ComposeField>
                  {ccArray.map((contact, i) => (
                    <ComposeField
                      key={i}
                      label={contact.ccType}
                      icon={faSortDown}
                      types={types}
                      open={i === typesOpen}
                      typesOnClick={type => handleTypes(i, type)}
                      setTypesOpen={bool => (bool ? setTypesOpen(i) : setTypesOpen())}
                    >
                      <GenericAjaxDropdown
                        value={contact}
                        call={Recipient.all}
                        onDisplay={con => StringHelper.formatRecipient(con)}
                        onSelect={con =>
                          typeof i === 'number' &&
                          replaceCcArray(i, {
                            ...con,
                            contactMethodId: con.id,
                            ccType: ccArray[i].ccType,
                          })
                        }
                        altParams={{ entityId: recipientEntity.id }}
                        testId={`toRecipientDropdown${i}`}
                      />
                      {i === 0 && (
                        <button
                          className="cf-button add"
                          data-testid={'add-recipient'}
                          onClick={() => addCcArray({ emailAddress: '', ccType: 'to' })}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      )}
                      {i > 0 && (
                        <button className="cf-button close" onClick={() => removeIdxCcArray(i)}>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      )}
                    </ComposeField>
                  ))}
                </div>
                <div className="email-editor">
                  <RichText
                    editorState={editorState}
                    setEditorState={setEditorState}
                    placeholder={'Please enter your email here...'}
                  />
                </div>
                <div className="email-attachments">
                  <AttachmentSection
                    fileArray={fileArray}
                    removeIdxFileArray={removeIdxFileArray}
                    addRemovalArray={addRemovalArray}
                    setMessage={setMessage}
                  />
                </div>
                <div className="button-group">
                  <IconButton
                    text={'Send Email'}
                    icon={faEnvelope}
                    onClick={handleSubmit}
                    loading={disabled}
                  />
                  <IconButton
                    text={'Cancel'}
                    icon={faTimes}
                    className={'edit'}
                    onClick={() => setConfirm(true)}
                  />
                </div>
              </div>
            ) : (
              <LoadingSpinner />
            )}
            {fileTabs &&
              fileTabs.map((tab, i) => (
                <EmailFileTab
                  key={i}
                  parent={tab?.parent}
                  setMessage={setMessage}
                  addFileArray={addFileArray}
                  removeIdxRemovalArray={removeIdxRemovalArray}
                  removalArray={removalArray}
                  fileArray={fileArray}
                >
                  <AttachmentSection
                    fileArray={fileArray}
                    removeIdxFileArray={removeIdxFileArray}
                    setMessage={setMessage}
                    addRemovalArray={addRemovalArray}
                  />
                </EmailFileTab>
              ))}
          </TabGroup>
        </FormSection>
      </span>
    </Modal>
  );
};

EmailComposeModal.propTypes = {
  user: PropTypes.object,
  parent: PropTypes.object,
  recipientEntity: PropTypes.object,
  templateType: PropTypes.string,
  handleClose: PropTypes.func,
  fileTabs: PropTypes.array,
  defaultAttachments: PropTypes.array,
  setAlert: PropTypes.func,
};

export default EmailComposeModal;
