import React from 'react';
import { faAnkh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './IconInput.scss';

const IconInput = ({
  icon = faAnkh,
  type = 'text',
  onChange,
  placeholder = 'Enter value...',
  autoFocus = false,
}) => {
  return (
    <div className={'ii-wrap'}>
      <div className={'icon-container'}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <input
        className={'icon-input'}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
    </div>
  );
};

IconInput.propTypes = {
  icon: PropTypes.object,
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default IconInput;
