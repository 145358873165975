import apiCall from '../../apiCall/apiCall.js';

// Handles all sign in/out/up requests.
class Account {
  static async login(email, password) {
    const params = {
      email: email || '',
      password: password || '',
    };
    const res = await apiCall('POST', 'users/sign_in', { user: params });
    return { user: res, token: res.token };
  }

  static logout() {
    return apiCall('DELETE', 'users/sign_out');
  }

  static permissions() {
    return apiCall('GET', 'users/permissions');
  }

  static current() {
    return apiCall('GET', 'users/current');
  }
}

export default Account;
