import DateHelper from '../DateHelper/DateHelper';

export const formatDetails = item => {
  const suffixes = {
    quantity: item.packageType?.abbreviation || 'pkg',
    grossWeightKg: 'kg',
    cubicMetres: 'm³',
    loadingMetres: 'm',
  };
  const parts = Object.keys(suffixes).map(key => item[key] && `${item[key]} ${suffixes[key]}`);
  return parts.filter(part => part).join(', ');
};

const isNum = val => (isNaN(Number(val)) ? 0 : Number(val));

export const calcQuantity = data =>
  data.dimensionLineItemsAttributes.map(li => li.quantity).reduce((a, b) => isNum(a) + isNum(b), 0);

export const calcCubicMetres = data => {
  const res = data.dimensionLineItemsAttributes
    .map(li => {
      const cm = isNum(li.lengthCm) * isNum(li.widthCm) * isNum(li.heightCm) * isNum(li.quantity);
      const cubed = cm / 1000000;
      return cubed;
    })
    .reduce((a, b) => a + b, 0);
  return Number(parseFloat(res).toFixed(2));
};

export const getItemByVal = (data, attr, val) => {
  if (!data) return null;
  const res = data.filter(item => item[attr] === val);
  if (res.length === 0) return null;
  return res[0];
};

export const basicBooking = user => ({
  status: 'active',
  customerReference: '',
  collectionReference: '',
  deliveryReference: '',
  goodsReadyDate: '',
  goodsReadyFromTime: '',
  goodsReadyUntilTime: '',
  deliveryDeadlineDate: '',
  deliveryDeadlineFromTime: '',
  deliveryDeadlineUntilTime: '',
  collectionDate: '',
  collectionFromTime: '',
  collectionUntilTime: '',
  deliveryDate: '',
  deliveryFromTime: '',
  deliveryUntilTime: '',
  quantity: null,
  grossWeightKg: null,
  netWeightKg: null,
  cubicMetres: '',
  loadingMetres: '',
  subcontracted: false,
  hazardousCargo: false,
  hazardDetails: '',
  doNotStack: false,
  doNotTransship: false,
  doNotPartLoad: false,
  doNotBreakDown: false,
  tailLiftOnCollection: false,
  tailLiftOnDelivery: false,
  overheadLoad: false,
  overheadUnload: false,
  waste: false,
  foodstuffs: false,
  bookingDate: DateHelper.today(),
  description: null,
  additionalInformation: '',
  createdAt: '',
  updatedAt: '',
  cancellationReason: null,
  financialLineItemsAttributes: [],
  customer: null,
  collectionOnBehalfOf: null,
  deliveryOnBehalfOf: null,
  collectionEntity: null,
  deliveryEntity: null,
  subcontractor: null,
  owner: user || null,
  vehicle: null,
  packageTypeId: null,
  collectionAddressId: null,
  deliveryAddressId: null,
  dimensionLineItemsAttributes: [],
  allowAutolining: true,
});

export const getBookingWarnings = booking => {
  let warnings = [];
  if (!booking) return warnings;
  if (!booking.grossWeightKg) warnings.push('The total gross weight is blank');
  if (booking.collectionAddress) {
    const colAdd = booking.collectionAddress;
    const referenceRequirement = colAdd.warehouseRequirements.find(
      requirement => requirement.name === 'collectionRefRequired',
    );
    if (referenceRequirement?.applies === 'sometimes' && !booking.collectionReference)
      warnings.push('A collection reference may be required');
    if (referenceRequirement?.applies === 'always' && !booking.collectionReference)
      warnings.push('A collection reference is required');
  }
  if (booking.deliveryAddress) {
    const delAdd = booking.deliveryAddress;
    const referenceRequirement = delAdd.warehouseRequirements.find(
      requirement => requirement.name === 'deliveryRefRequired',
    );
    if (referenceRequirement?.applies === 'sometimes' && !booking.deliveryReference)
      warnings.push('A delivery reference may be required');
    if (referenceRequirement?.applies === 'always' && !booking.deliveryReference)
      warnings.push('A delivery reference is required');
  }
  if (booking.dimensionLineItems?.find(li => li.widthCm > 500 || li.lengthCm > 500))
    warnings.push('One or more packages exceeds 500cm');
  return warnings;
};
