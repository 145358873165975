import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateHelper from '../../../../../utils/DateHelper/DateHelper';
import ConsignmentFlagIcon from '../ConsignmentFlagIcon/ConsignmentFlagIcon';
import VehicleRunHelper from '../../../../../utils/VehicleRunHelper/VehicleRunHelper';
import { Link } from 'react-router-dom';
import { faCircle, faDownload, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../../common/button/IconButton/IconButton';
import './VehicleRunRow.scss';
import Pdf from '../../../../../api/controllers/File/Pdf/Pdf';
import DownloadHelper from '../../../../../utils/DownloadHelper/DownloadHelper';
import { formatError } from '../../../../../utils/Formatters/Errors/formatError';
import Item from '../../../../../api/controllers/File/Item/Item';
import VehicleRunRowPackageInfo from './VehicleRunRowPackageInfo/VehicleRunRowPackageInfo';
import VehicleRunRowColDelInfo from './VehicleRunRowColDelInfo/VehicleRunRowColDelInfo';
import VehicleRunRowWarnings from './VehicleRunRowWarnings/VehicleRunRowWarnings';
import VehicleRunRowHazards from './VehicleRunRowHazards/VehicleRunRowHazards';
import VehicleRunRowDirection from './VehicleRunRowDirection/VehicleRunRowDirection';
import { vehicleRunHeaders } from '../../../../../strings/arrays/VehicleRun/vehicleRunHeaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import BookingHelper from '../../../../../utils/BookingHelper/BookingHelper';

// A component for rendering table rows in the Vehicle Run Planner
const VehicleRunRow = ({
  job,
  fsId,
  setError,
  section = '',
  runInfo,
  vehicles = [],
  setCurrentDraggingJobs,
  highlighted,
  setHighlighted,
  handleNoteModal,
  error = [],
}) => {
  const [loading, setLoading] = useState(false);

  const handleError = err => {
    setLoading(false);
    setError(error.concat(formatError(err)));
  };

  const handleDownload = (e, id) => {
    e.preventDefault();
    setLoading(true);
    Pdf.generate({ kind: 'collection_delivery_note', id }).then(pdfRes => {
      Item.download(pdfRes.id).then(res => {
        setLoading(false);
        DownloadHelper.saveFile(pdfRes.fileName, res);
      }, handleError);
    }, handleError);
  };

  //provides the data required to update the job on drop
  const handleDragStart = e => {
    let currentJobs;

    /*
      Gets all the jobs that we are about to drag about, adds the current one if not
      previously highlighted, then sorts by id meaning that it will always be in the
      same order as it would on the page.
     */
    if (!isHighlighted()) {
      currentJobs = highlighted.concat(job).sort((a, b) => a.id - b.id);
    } else {
      currentJobs = highlighted.sort((a, b) => a.id - b.id);
    }

    const json = { jobs: currentJobs, oldSection: job.vehicleRunId || section };
    e.dataTransfer.setData('application/json', JSON.stringify(json));

    /*
     The tbody we will be using to build up the drag image.
     We use TBODY instead of DIV as it more represents how the normal view works
     so styling is easier and more consistent
    */
    const ele = document.createElement('div');

    //Adds a copy currently highlighted elements to the drag image, we use cloneNode because we dont want to remove it
    //from its original position here.
    currentJobs.map(highlightedJob =>
      ele.appendChild(document.getElementById(`vrr-${highlightedJob.id}`).cloneNode(true)),
    );

    /*
      Why? setDragImage requires the element to be on the DOM, so we put it
      in portal-root, then give it a class which means we can essentially
      "hide" it from the user, but allows us to use it in drag image and it still
      appear as we want.
    */
    const portal = document.getElementById('portal-root');
    portal.className = 'vehicle-run-drag-image';
    portal.appendChild(ele);

    e.dataTransfer.setDragImage(ele, 0, 0); //this sets the drag image the user will see with our combined element.

    //Sets the current list of jobs being dragged so we can remove them locally from view.
    setCurrentDraggingJobs(currentJobs);
  };

  //A nice shorthand for determining if the current job is selected for dragging.
  const isHighlighted = () => {
    return highlighted.find(highlightedJob => highlightedJob.id === job.id);
  };

  /*
    Handles the click on a row. If we hold down CTRL or WIN or ⌘, we instead either
    highlight the item for dragging, or un-highlight it, depending on the current state.

    If we dont hold done a modifier, allows base-browser functionality, meaning links and buttons
    still work as expected.
  */
  const handleClick = e => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      e.stopPropagation();
      if (isHighlighted()) {
        return setHighlighted(highlighted.filter(highlightedJob => highlightedJob.id !== job.id));
      }
      setHighlighted(highlighted.concat(job));
    }
  };

  return (
    <div
      className={`row vehicle-run-row${isHighlighted() ? ' highlighted' : ''}`}
      draggable
      onDragStart={handleDragStart}
      onClick={handleClick}
      id={`vrr-${job.id}`}
    >
      <div
        className={`cell ${vehicleRunHeaders.colDelStatus.shrinkClass}`}
        style={{ width: vehicleRunHeaders.colDelStatus.width }}
      >
        <VehicleRunRowDirection fsId={fsId} job={job} />
      </div>
      <div className="cell" style={{ width: vehicleRunHeaders.job.width }}>
        <div className={'vrr-job-field'}>
          <Link to={`/jobs/bookings/${job.id}`} target={'_blank'} className={'vrr-link'}>
            {job.jobReference}
          </Link>
          <div className={'vrr-status-container mob'}>
            <FontAwesomeIcon className={`status-icon ${job.status}`} icon={faCircle} />
            <div className={'vrr-status-text'}>{capitalize(job.status)}</div>
          </div>
        </div>
      </div>
      <div className="cell no-width">
        <Link
          to={`/companies/entities/${job.customer?.id}`}
          target={'_blank'}
          className={'vrr-link customer'}
        >
          {job.customer?.shortName || job.customer?.internalName}
        </Link>
      </div>

      <div
        className="cell large vrr-customer-ref"
        style={{ width: vehicleRunHeaders.customerRef.width }}
        title={job.customerReference}
      >
        {BookingHelper.canBeLinked(job) ? (
          <a
            href={`${process.env.REACT_APP_FREIGHTSOFT_URL}/jobs/${job.freightsoftReference}`}
            target={'_blank'}
            rel={'noreferrer'}
          >
            {job.customerReference}
          </a>
        ) : (
          job.customerReference
        )}
      </div>

      <div className="cell large no-width">
        <VehicleRunRowColDelInfo entity={job.collectionEntity} address={job.collectionAddress} />
      </div>
      <div
        className="cell vrr-align-center"
        style={{ width: vehicleRunHeaders.collectionPostcode.width }}
      >
        {job.collectionAddress?.postcode}
      </div>
      <div className="cell large no-width">
        <VehicleRunRowColDelInfo entity={job.deliveryEntity} address={job.deliveryAddress} />
      </div>
      <div
        className="cell vrr-align-center"
        style={{ width: vehicleRunHeaders.deliveryPostcode.width }}
      >
        {job.deliveryAddress?.postcode}
      </div>
      <div className="cell vrr-align-center" style={{ width: vehicleRunHeaders.quantity.width }}>
        {job.quantity && `${job.quantity} ${job.packageType?.abbreviation || 'pkg'}`}
      </div>
      <div className="cell vrr-align-center" style={{ width: vehicleRunHeaders.grossWeight.width }}>
        {job.grossWeightKg && `${job.grossWeightKg} kg`}
      </div>
      <div className="cell hover-cell" style={{ width: vehicleRunHeaders.cubicMetres.width }}>
        <VehicleRunRowPackageInfo job={job} />
      </div>
      <div
        className="cell vrr-align-center"
        style={{ width: vehicleRunHeaders.loadingMetres.width }}
      >
        {job.loadingMetres && `${job.loadingMetres} m`}
      </div>
      <div className="cell large flags" style={{ width: vehicleRunHeaders.consignmentFlags.width }}>
        <div className="vrr-consignment-flag-container">
          {VehicleRunHelper.getConsignmentFlags(job, vehicles).map((flag, idx) => (
            <ConsignmentFlagIcon key={idx} {...flag} />
          ))}
        </div>
      </div>
      <div
        className="cell hover-cell vrr-hazards"
        style={{ width: vehicleRunHeaders.hazards.width }}
      >
        <VehicleRunRowHazards job={job} />
      </div>
      <div
        className="cell vrr-align-center"
        style={{ width: vehicleRunHeaders.collectionDate.width }}
      >
        {DateHelper.formatVehicleRunDate(job.collectionDate)}
      </div>
      <div
        className="cell vrr-align-center"
        style={{ width: vehicleRunHeaders.deliveryDate.width }}
      >
        {DateHelper.formatVehicleRunDate(job.deliveryDate)}
      </div>
      <div className="cell vrr-align-center" style={{ width: vehicleRunHeaders.notes.width }}>
        {BookingHelper.getNoteNum(job) > 0 && (
          <div
            className="c-table-header-icon"
            title={'There are notes attached to this job. Click to check.'}
            onClick={() => handleNoteModal(job)}
            data-testid={'vrr-note-button'}
          >
            <FontAwesomeIcon icon={faStickyNote} />
          </div>
        )}
      </div>
      <div className="cell hover-cell" style={{ width: vehicleRunHeaders.actions.width }}>
        <div className="vrr-actions">
          <div className="warning-container">
            <VehicleRunRowWarnings job={job} runInfo={runInfo} />
          </div>
          <div className="run-spacer" />
          <div className="download-container">
            <IconButton
              icon={faDownload}
              iconOnly
              onClick={e => handleDownload(e, job.id)}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

VehicleRunRow.propTypes = {
  job: PropTypes.object.isRequired,
  fsId: PropTypes.number,
  setError: PropTypes.func.isRequired,
  section: PropTypes.string,
  runInfo: PropTypes.object,
  vehicles: PropTypes.arrayOf(PropTypes.object),
  setCurrentDraggingJobs: PropTypes.func.isRequired,
  highlighted: PropTypes.arrayOf(PropTypes.object).isRequired,
  setHighlighted: PropTypes.func.isRequired,
  handleNoteModal: PropTypes.func.isRequired,
  error: PropTypes.array,
};

export default VehicleRunRow;
