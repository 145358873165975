import { SAVE_TOKEN, DELETE_TOKEN, SET_USER_ERROR, EXTERNAL_ERROR } from '../actions/types';

const INITIALSTATE = {
  token: localStorage.getItem('token'),
  userError: null,
};
export default (state = INITIALSTATE, action) => {
  switch (action.type) {
    case SAVE_TOKEN:
      return localStorage.setItem('token', action.payload), { ...state, token: action.payload };
    case DELETE_TOKEN:
      return localStorage.removeItem('token'), { ...state, token: null };
    case EXTERNAL_ERROR:
      return localStorage.removeItem('token'), { ...state, token: null, userError: action.payload };
    case SET_USER_ERROR:
      return { ...state, userError: action.payload };
    default:
      return state;
  }
};
