import { useState } from 'react';

const useForm = defaultForm => {
  const [form, setForm] = useState(defaultForm);

  const alter = (attr, val, filter) =>
    setForm(state => {
      if (!filter) return { ...state, [attr]: val };
      return filter(val) ? { ...state, [attr]: val } : state;
    });

  // The reason for the below eslint ignore is that the value used is a dummy value
  const remove = attr => {
    // eslint-disable-next-line no-unused-vars
    const { [attr]: value, ...newForm } = form;
    setForm({ ...newForm });
  };

  const clear = () => setForm({});

  return { form, alter, remove, clear, handleForm: setForm };
};

export default useForm;
