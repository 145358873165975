export const vehicleRunHeaders = {
  colDelStatus: { text: '', width: '137.717px', shrinkClass: 'vrr-collection-shrink' },
  job: { text: 'Job', sortingAttribute: 'jobReference', width: '60px' },
  customer: { text: 'Customer', sortingAttribute: 'customer', type: 'entity' },
  customerRef: { text: 'Customer Ref', sortingAttribute: 'customerReference', width: '112px' },
  collection: { text: 'Collection', sortingAttribute: 'collectionEntity', type: 'entity' },
  collectionPostcode: {
    text: 'Col. PC',
    sortingAttribute: 'collectionAddress.postcode',
    width: '65px',
  },
  delivery: { text: 'Delivery', sortingAttribute: 'deliveryEntity', type: 'entity' },
  deliveryPostcode: {
    text: 'Del. PC',
    sortingAttribute: 'deliveryAddress.postcode',
    width: '65px',
  },
  quantity: { text: 'Pkg', sortingAttribute: 'quantity', type: 'number', width: '70px' },
  grossWeight: { text: 'Kg', sortingAttribute: 'grossWeightKg', type: 'number', width: '60px' },
  cubicMetres: { text: 'Cube', sortingAttribute: 'cubicMetres', type: 'number', width: '60px' },
  loadingMetres: { text: 'LDM', sortingAttribute: 'loadingMetres', type: 'number', width: '70px' },
  consignmentFlags: { text: 'Consignment Flags', width: '200px' },
  hazards: { text: 'Hazards', width: '80px' },
  collectionDate: { text: 'Col.', sortingAttribute: 'collectionDate', width: '50px' },
  deliveryDate: { text: 'Del.', sortingAttribute: 'deliveryDate', width: '50px' },
  notes: { text: 'Notes', width: '90px' },
  actions: { text: '', width: '120px' },
};
