import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

/*
  Some of these are duplicated in SaleCredit/Sale/Purchase etc,
  eventually any requests that can be, should only be contained in this 
  parent, which the specific ones can expand on
*/
class Document {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', '/financials/documents', data);
  }

  static submit_to_sage(id) {
    return apiCall('POST', `/financials/documents/${id}/submit_to_api`);
  }
}

export default Document;
