import React from 'react';
import PropTypes from 'prop-types';
import ValueInput from '../../../../../../../../common/input/ValueInput/ValueInput';
import AttributeHelper from '../../../../../../../../utils/AttributeHelper/AttributeHelper';
import PackageType from '../../../../../../../../api/controllers/Job/PackageType/PackageType';
import FormSection from '../../../../../../../../components/FormSection/FormSection';
import IconButton from '../../../../../../../../common/button/IconButton/IconButton';
import { faCalculator, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useApi from '../../../../../../../../common/hooks/useApi/useApi';
import GenericDropdown from '../../../../../../../../common/GenericDropdown/GenericDropdown';

const BookingFormPackageDimensions = ({ data, alter, autoTotal, changeFocus }) => {
  const { packages } = useApi({
    call: PackageType.all,
    name: 'packages',
  });
  const handleKindChange = (idx, val) =>
    AttributeHelper.handleAttrChange(idx, val, 'dimensionLineItemsAttributes', data, alter);

  const onSelectPackageType = (id, pt) =>
    handleKindChange(id, {
      packageType: pt,
      lengthCm: pt?.lengthCm || '',
      widthCm: pt?.widthCm || '',
      heightCm: pt?.heightCm || '',
    });

  const handleDelete = (idx, li) => {
    const val = li.modelName ? { _destroy: 1 } : null;
    handleKindChange(idx, val);
  };

  const handleNewLine = () => {
    handleKindChange(data.dimensionLineItemsAttributes.length, {
      quantity: '',
      packageType: '',
      lengthCm: '',
      widthCm: '',
      heightCm: '',
    });
    changeFocus(`bfg-line-item-${data.dimensionLineItemsAttributes.length}`);
  };

  return (
    <FormSection title={'Package Dimensions'}>
      <table>
        <thead>
          <tr>
            <th>Quantity</th>
            <th>Package Type</th>
            <th>Length</th>
            <th>Width</th>
            <th>Height</th>
            <th></th>
          </tr>
        </thead>
        <tbody data-testid={'dli-list'}>
          {data.dimensionLineItemsAttributes.map(
            (li, i) =>
              !li._destroy && (
                <tr key={i} data-testid={'bfg-line-item'}>
                  <td className={'small'}>
                    <input
                      type={'number'}
                      data-focus={`bfg-line-item-${i}`}
                      data-testid={'bfg-line-item-quantity'}
                      value={li.quantity}
                      onChange={e => handleKindChange(i, { quantity: e.target.value || '' })}
                      min={0}
                    />
                  </td>
                  <td className={'medium'}>
                    <GenericDropdown
                      value={li.packageType}
                      items={packages?.items}
                      onDisplay={pt => pt?.name}
                      onSelect={pt => onSelectPackageType(i, pt)}
                      onClear={() => handleKindChange(i, { packageType: { id: null } })}
                    />
                  </td>
                  <td className={'small'}>
                    <ValueInput
                      unit={'CM'}
                      value={li.lengthCm}
                      onChange={e => handleKindChange(i, { lengthCm: e.target.value || '' })}
                      min={0}
                    />
                  </td>
                  <td className={'small'}>
                    <ValueInput
                      unit={'CM'}
                      value={li.widthCm}
                      onChange={e => handleKindChange(i, { widthCm: e.target.value || '' })}
                      min={0}
                    />
                  </td>
                  <td className={'small'}>
                    <ValueInput
                      unit={'CM'}
                      value={li.heightCm}
                      onChange={e => handleKindChange(i, { heightCm: e.target.value || '' })}
                      min={0}
                    />
                  </td>
                  <td className={'tiny'}>
                    <button
                      data-testid={'bfg-delete'}
                      className={'fs-table-cross'}
                      onClick={() => handleDelete(i, li)}
                      tabIndex={'-1'}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </td>
                </tr>
              ),
          )}
        </tbody>
      </table>
      <div className={'button-section'}>
        <div className={'button-line'}>
          <IconButton icon={faPlus} className={'small'} text={'Add Line'} onClick={handleNewLine} />
          <IconButton
            icon={faCalculator}
            className={'small success'}
            text={'Auto Total'}
            onClick={() => autoTotal(data, packages)}
          />
        </div>
      </div>
    </FormSection>
  );
};

BookingFormPackageDimensions.propTypes = {
  data: PropTypes.object,
  alter: PropTypes.func,
  autoTotal: PropTypes.func,
  changeFocus: PropTypes.func,
};

export default BookingFormPackageDimensions;
