import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TablePresenter from '../../../../../../components/Table/TablePresenter/TablePresenter';
const InvoiceIndexTable = ({
  title,
  headers,
  data,
  sort,
  onSort,
  handleDrawRow,
  onSearch,
  pageText,
  pageElements,
  refresh,
  loading,
}) => {
  useEffect(() => {
    refresh();
  }, []);

  return (
    <div className="page-content">
      {data && (
        <TablePresenter
          title={title}
          headers={headers}
          rows={data?.items}
          onDrawRow={handleDrawRow}
          sort={sort}
          onSort={onSort}
          onSearch={onSearch}
          pageElements={pageElements}
          pageText={pageText}
          loading={loading}
        />
      )}
    </div>
  );
};

InvoiceIndexTable.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.array,
  data: PropTypes.object,
  sort: PropTypes.object,
  onSort: PropTypes.func,
  handleDrawRow: PropTypes.func,
  onSearch: PropTypes.func,
  pageText: PropTypes.string,
  pageElements: PropTypes.func,
  refresh: PropTypes.func,
  loading: PropTypes.bool,
};

export default InvoiceIndexTable;
