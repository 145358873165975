import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../components/FormSection/FormSection';
import IconButton from '../../../../../../common/button/IconButton/IconButton';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import LineItemTable from '../../../../../../components/Table/LineItemTable/LineItemTable';
import LineItemHelper from '../../../../../../utils/LineItemHelper/LineItemHelper';

const InvoiceFormLineItems = ({
  lineItems,
  alter,
  form,
  lineItemToggle,
  invoice,
  onlyJobs = false,
}) => {
  const toggleAllLineItems = () => {
    const newLineItems = LineItemHelper.toggleAllNew(form.lineItemsAttributes, lineItems);
    alter('lineItemsAttributes', newLineItems);
  };

  const toggleAllLineItemsEdit = () => {
    const newLineItems = LineItemHelper.toggleAllEdit(form.lineItemsAttributes, lineItems);
    alter('lineItemsAttributes', newLineItems);
  };

  return (
    <FormSection
      title={'Line Items'}
      optionalElement={
        <IconButton
          text={'Include all line items'}
          icon={faCheckDouble}
          className="success"
          onClick={form.documentId ? toggleAllLineItemsEdit : toggleAllLineItems}
        />
      }
    >
      <div className={'show-table'}>
        <LineItemTable
          data={lineItems}
          editable={lineItemToggle}
          form={form}
          invoice={invoice}
          onlyJobs={onlyJobs}
        />
      </div>
    </FormSection>
  );
};

InvoiceFormLineItems.propTypes = {
  alter: PropTypes.func,
  form: PropTypes.object,
  lineItems: PropTypes.array,
  lineItemToggle: PropTypes.func,
  invoice: PropTypes.bool,
  onlyJobs: PropTypes.bool,
};

export default InvoiceFormLineItems;
