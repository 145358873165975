import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRadiation } from '@fortawesome/free-solid-svg-icons';

//Displays Hazard info for a given job
const VehicleRunRowHazards = ({ job }) => {
  if (!job.hazardousCargo) return null;
  return (
    <>
      <div className="hazards">
        <FontAwesomeIcon icon={faRadiation} />
        Hazards
      </div>
      <div className="hover-cell-info hazards-info">
        <div className="top">Dangerous Goods Info</div>
        <div className="main">
          {job.hazardDetails || 'No hazard details have been provided yet'}
        </div>
      </div>
    </>
  );
};

VehicleRunRowHazards.propTypes = { job: PropTypes.object };

export default VehicleRunRowHazards;
